import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import GearLoader from './GearLoader';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShowLoader(true); // Muestra el loader con fade in al inicio

    const verifyAuth = async () => {
      try {
        const token = localStorage.getItem('authToken');
      
        if (!token) {
          setIsAuthenticated(false);
          // Inicia el fade out antes de ocultar el loader
          setShowLoader(false);
          setTimeout(() => setIsLoading(false), 300); // Espera a que termine la transición
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/verify_session.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          console.error('La respuesta no es JSON válido');
          throw new Error('Respuesta del servidor inválida');
        }

        const data = await response.json();

        if (data.success) {
          if (data.token) {
            localStorage.setItem('authToken', data.token);
          }

          sessionStorage.setItem('userId', data.user.id);
          sessionStorage.setItem('firstName', data.user.firstname);
          sessionStorage.setItem('lastName', data.user.lastname);
          sessionStorage.setItem('email', data.user.email);
          sessionStorage.setItem('isAdmin', data.user.is_admin ? '1' : '0');
          sessionStorage.setItem('gender', data.user.gender);
          sessionStorage.setItem('departamento', data.user.departamento);
          
          setIsAuthenticated(true);
        } else {
          throw new Error(data.message || 'Sesión inválida');
        }
      } catch (error) {
        console.error('Error verificando autenticación:', error);
        localStorage.removeItem('authToken');
        sessionStorage.clear();
        setIsAuthenticated(false);
      } finally {
        // Inicia el fade out antes de ocultar el loader
        setShowLoader(false);
        setTimeout(() => setIsLoading(false), 300); // Espera a que termine la transición
      }
    };

    verifyAuth();
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-white">
        <div 
          className={`transition-opacity duration-300 ease-in-out ${
            showLoader ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <GearLoader />
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/mvp" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;