import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoAnimation from './LogoAnimation';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAnimation, setShowAnimation] = useState<boolean>(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        if (tokenParam) {
            setToken(tokenParam);
        } else {
            setError('Token no proporcionado.');
        }
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        if (password !== confirmPassword) {
            setError('Las contraseñas no coinciden.');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pswd/reset_password.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, password }),
            });

            const responseText = await response.text();
            if (responseText.trim() === '') {
                setError('La respuesta del servidor está vacía.');
                setLoading(false);
                return;
            }

            try {
                const data = JSON.parse(responseText);

                if (data.success) {
                    setSuccess(data.message || 'Contraseña restablecida con éxito.');
                    setShowAnimation(true);
                    
                    // Simulamos un pequeño retraso antes de navegar
                    setTimeout(() => {
                        navigate('/ui');
                    }, 3000);
                } else {
                    setError(data.error || 'Error al restablecer la contraseña.');
                    setLoading(false);
                }
            } catch (jsonError) {
                console.error('Error al parsear JSON:', jsonError);
                setError('Error en la respuesta del servidor: ' + responseText);
                setLoading(false);
            }
        } catch (error) {
            console.error('Restablecimiento de contraseña fallido:', error);
            setError(`Ocurrió un error durante el restablecimiento. ${error}`);
            setLoading(false);
        }
    };

    return (
        <>
            <LogoAnimation show={showAnimation} type="password-reset" />
            <div className="login-container">
                <div className="login-box">
                    <div className="login-logo"></div>
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <i className="input-icon fas fa-lock"></i>
                            <input
                                type="password"
                                placeholder="Nueva Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="login-input"
                                required
                                disabled={loading}
                            />
                        </div>
                        <div className="input-container">
                            <i className="input-icon fas fa-lock"></i>
                            <input
                                type="password"
                                placeholder="Confirmar Contraseña"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="login-input"
                                required
                                disabled={loading}
                            />
                        </div>
                        <button 
                            type="submit" 
                            className="login-button mb-5" 
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="flex items-center justify-center">
                                    <i className="fas fa-spinner fa-spin mr-2"></i>
                                    Actualizando contraseña...
                                </span>
                            ) : (
                                'Restablecer contraseña'
                            )}
                        </button>
                        {success && !showAnimation && (
                            <p className="success-message">{success}</p>
                        )}
                        {error && (
                            <p className="error-message">{error}</p>
                        )}
                    </form>
                </div>
                <div className="triangle"></div>
            </div>
        </>
    );
};

export default ResetPassword;