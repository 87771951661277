import React from 'react';

const GearLoader = () => {
  return (
    <svg className="w-48 h-48" viewBox="-150 -150 300 300">
      <defs>
        <path id="gearTeeth" d="
          M -3,0 
          L -4,-12 
          L 0,-14
          L 4,-12 
          L 3,0 
          Z" />
      </defs>

      {/* Engranaje central (turquesa) */}
      <g>
        <circle cx="0" cy="0" r="40" fill="#40E0D0"/>
        <g>
          {[0, 21.18, 42.36, 63.54, 84.72, 105.9, 127.08, 148.26, 169.44, 190.62, 
            211.8, 232.98, 254.16, 275.34, 296.52, 317.7, 338.88].map((angle, index) => (
            <use
              key={`center-${index}`}
              href="#gearTeeth"
              transform={`rotate(${angle}) translate(40,0)`}
              fill="#40E0D0"
            />
          ))}
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="3s"
            repeatCount="indefinite"
            from="360 0 0"
            to="0 0 0"
            calcMode="linear"
          />
        </g>
      </g>

      {/* Grupo de engranajes orbitando */}
      <g>
        {/* Engranaje verde */}
        <g transform="translate(0, -80)">
          <circle cx="0" cy="0" r="25" fill="#7FFFD4"/>
          <g>
            {[0, 21.18, 42.36, 63.54, 84.72, 105.9, 127.08, 148.26, 169.44, 190.62,
              211.8, 232.98, 254.16, 275.34, 296.52, 317.7, 338.88].map((angle, index) => (
              <use
                key={`green-${index}`}
                href="#gearTeeth"
                transform={`rotate(${angle}) translate(25,0)`}
                fill="#7FFFD4"
              />
            ))}
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="2s"
              repeatCount="indefinite"
              from="0 0 0"
              to="-360 0 0"
              calcMode="linear"
            />
          </g>
        </g>

        {/* Engranaje amarillo */}
        <g transform="translate(69.28, 40)">
          <circle cx="0" cy="0" r="25" fill="#FFD700"/>
          <g>
            {[0, 21.18, 42.36, 63.54, 84.72, 105.9, 127.08, 148.26, 169.44, 190.62,
              211.8, 232.98, 254.16, 275.34, 296.52, 317.7, 338.88].map((angle, index) => (
              <use
                key={`yellow-${index}`}
                href="#gearTeeth"
                transform={`rotate(${angle}) translate(25,0)`}
                fill="#FFD700"
              />
            ))}
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="2s"
              repeatCount="indefinite"
              from="0 0 0"
              to="-360 0 0"
              calcMode="linear"
            />
          </g>
        </g>

        {/* Engranaje coral */}
        <g transform="translate(-69.28, 40)">
          <circle cx="0" cy="0" r="25" fill="#FF7F50"/>
          <g>
            {[0, 21.18, 42.36, 63.54, 84.72, 105.9, 127.08, 148.26, 169.44, 190.62,
              211.8, 232.98, 254.16, 275.34, 296.52, 317.7, 338.88].map((angle, index) => (
              <use
                key={`coral-${index}`}
                href="#gearTeeth"
                transform={`rotate(${angle}) translate(25,0)`}
                fill="#FF7F50"
              />
            ))}
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="2s"
              repeatCount="indefinite"
              from="0 0 0"
              to="-360 0 0"
              calcMode="linear"
            />
          </g>
        </g>

        {/* Animación de órbita para todo el grupo */}
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="3s"
          repeatCount="indefinite"
          from="0 0 0"
          to="360 0 0"
          calcMode="linear"
        />
      </g>
    </svg>
  );
};

export default GearLoader;