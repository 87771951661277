import React, { useEffect, useState } from 'react';
import logoImage from '../img/logo.png';

interface LogoAnimationProps {
  show: boolean;
  type?: 'login' | 'password-reset';
}

const LogoAnimation: React.FC<LogoAnimationProps> = ({ show, type = 'login' }) => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    title: '',
    gender: ''
  });

  useEffect(() => {
    if (show) {  // Solo cargar los datos cuando show es true
      const loadUserInfo = () => {
        const firstName = sessionStorage.getItem('firstName');
        const lastName = sessionStorage.getItem('lastName');
        const title = sessionStorage.getItem('title');
        const gender = sessionStorage.getItem('gender');
        
        if (firstName && lastName) {
          setUserInfo({
            name: `${firstName} ${lastName}`.trim(),
            title: title || '',
            gender: (gender || '').toUpperCase()
          });
        }
      };

      // Intentar cargar los datos inmediatamente
      loadUserInfo();

      // Si los datos no están disponibles inmediatamente, intentar nuevamente después de un breve retraso
      const timer = setTimeout(loadUserInfo, 100);

      return () => clearTimeout(timer);
    }
  }, [show]);  

  
  const getWelcomeMessage = (userInfo: { gender?: string; name?: string }) => {
    let welcome = 'Bienvenido';  // Valor por defecto
    
    if (userInfo.gender) {
      switch (userInfo.gender) {
        case 'F':
          welcome = 'Bienvenida';
          break;
        case 'M':
          welcome = 'Bienvenido';
          break;
        case 'O':
          welcome = 'Bienvenid@';  // O podrías usar 'Bienvenide' dependiendo de tu preferencia
          break;
        default:
          welcome = 'Bienvenido';
      }
    }
  
    if (userInfo.name) {
      return `Inicio de sesión exitoso, ¡${welcome} ${userInfo.name}!`;
    }
    
    return welcome;
  };

  return (
    <div className={`logo-animation-container ${show ? 'animate' : ''}`}>
      <div className="animation-content">
        <div className="logo-wrapper">
          <img 
            src={logoImage}
            alt="SIMAGI Logo"
            className="animated-logo"
          />
        </div>
        <div className="success-message">
          <div className="message-row">
            <h1>{getWelcomeMessage(userInfo)}</h1>
            <div className="checkmark-wrapper">
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoAnimation;