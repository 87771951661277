import React, { useState, useEffect, useRef, useMemo, useCallback, RefObject } from 'react';
import { motion, AnimatePresence, progress, Reorder } from 'framer-motion';
import { AlertCircle, AlertTriangle, Check, CheckCircle2, ChevronDown, ChevronRight, ChevronUp, CircleDot, Clock, FileQuestion, X, Building, Info, Calendar, Loader2, CheckCircle, XCircle } from 'lucide-react';
import { createPortal } from 'react-dom';
import { filter } from 'jszip';
import dateUtils from './dateUtils';
import CollapsibleContainer from './CollapsibleContainer';
import { debounce } from 'lodash'; // Asegúrate de importar lodash si no lo has hecho
import { group } from 'console';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../ui/button';
import { MdBusiness } from 'react-icons/md';


interface SubtaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  subtask: SubTask;
  onChangeTask: (task: Task) => void;
  onChangeSubtask: (updatedSubtask: SubTask) => void;
  onAddNewSubtask: (option: NewSubtaskOption) => void;
  backgroundColor: string;
  mainTask: Task;
  groupProgress?: number;
  processName?: string;
  subprocessName?: string;
  agrupadorName?: string;
  daysDelayed?: number; // Agregar esta prop
    forceTableRerender?: () => void;  // Agregamos esta línea

}


interface NewSubtaskOption {
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  name: string;
  resolutionType: string;
  responsable: User[] | string; 
  organismo: string;
}






interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
  processName: string | undefined;          // Nombre del proceso
  subprocessName: string | undefined;       // Nombre del subproceso
  agrupadorName: string | undefined;  
}


interface CommentData {
  id: number;          // ID de la tarea o subtarea
  comments: string;    // Comentarios en formato JSON string
  type: 'task' | 'subtask'; // Indica si es tarea principal o subtarea
  tarea_id?: number;   // ID de la tarea padre (solo para subtareas)
}



interface CommentResponse {
  success: boolean;
  comments?: string[];
  message?: string;
}

type ReminderType = 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed';

interface ReminderInfo {
  type: ReminderType | null;
  value: string | null;
}


interface ReminderData {
  task_id: number;
  reminder_type: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value: string | null;
  reminder_code: string | null;
  reminder_calculated_date: string | null;
  reminder_active: boolean;
  reminder_triggered: boolean;
}

interface TaskUpdateData {
  id: number;
  isClosed: boolean;
  progress: number;
  start: string;  // Campo agregado
  end: string;
  followUpDate: string;
  comments: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;
}

interface SubtaskUpdateData {
  id: number;
  tarea_id: number;
  nombre: string;
  tipo: string;
  responsible: User[] | string;
  organismo: string;
  progress: number;
  start: string;
  end: string;
  duracion: number;
  dependencias: string;
  enabled: boolean;
  resolucion_tipo?: string;
  orden: number;
  comments: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}




const calculateSemaphoreStatus = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  const today = dateUtils.getTodayLocal();

  // Convertir fechas de string a Date
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la fecha de inicio no ha llegado aún
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea está vencida
  if (today > endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular porcentaje transcurrido
  const totalTime = endDate.getTime() - startDate.getTime();
  const elapsedTime = today.getTime() - startDate.getTime();
  const progressPercentage = (elapsedTime / totalTime) * 100;

  // Determinar el estado del semáforo según los porcentajes
  if (progressPercentage >= 100) {
    return SemaphoreStatus.Red; // Vencida
  } else if (progressPercentage >= 80) {
    return SemaphoreStatus.Orange; // Muy cerca de vencer
  } else if (progressPercentage >= 50) {
    return SemaphoreStatus.Yellow; // Próxima a vencer
  }
  return SemaphoreStatus.Green; // En tiempo
};




const extractNumber = (name: string): number => {
  const match = name.match(/\d+$/);
  return match ? parseInt(match[0]) : 0;
};




  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  





  const SubtaskModal: React.FC<SubtaskModalProps> = ({
    isOpen,
    onClose,
    subtask,
    onChangeTask,
    onChangeSubtask,
    onAddNewSubtask,
    backgroundColor,
    mainTask,
    groupProgress,
    processName,
    subprocessName,
    agrupadorName,
    daysDelayed
  }) => {
    const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
    const [editedName, setEditedName] = useState(subtask.name);
    const [comments, setComments] = useState<string[]>([]);
    const [newComment, setNewComment] = useState('');
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [localStartDate, setLocalStartDate] = useState(subtask.start);
    const [localEndDate, setLocalEndDate] = useState(subtask.end);
    const [isLoading, setIsLoading] = useState(false);
    const commentsEndRef = useRef<HTMLDivElement>(null);
    const commentsContainerRef = useRef<HTMLDivElement>(null);
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    const [showResolutionTypeDialog, setShowResolutionTypeDialog] = useState(false);
    const [showNewSubtaskDialog, setShowNewSubtaskDialog] = useState(false);
    const [selectedResolutionType, setSelectedResolutionType] = useState('');
    const [selectedNewSubtaskType, setSelectedNewSubtaskType] = useState<NewSubtaskOption | null>(null);
    const formatDateDDMMYYYY = (dateInput: string | Date): string => {
      // Si es un string, parsearlo correctamente
      if (typeof dateInput === 'string') {
        // Asumiendo que el input viene en formato 'YYYY-MM-DD'
        const [year, month, day] = dateInput.split('-').map(Number);
        // Crear la fecha usando la zona horaria local
        return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
      }
      
      // Si es un objeto Date
      const day = String(dateInput.getDate()).padStart(2, '0');
      const month = String(dateInput.getMonth() + 1).padStart(2, '0');
      const year = dateInput.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const [subtaskToClose, setSubtaskToClose] = useState<SubTask | null>(null);

  
  
    // Función auxiliar para extraer el número de una observación o respuesta
  const extractNumber = (name: string): number => {
    const match = name.match(/\d+$/);
    return match ? parseInt(match[0]) : 0;
  };
  


  const getNextSubtaskOptions = (
    currentType: string,
    parentTask: Task,
    currentSubtask: SubTask
  ): NewSubtaskOption[] => {
    // Helper function to generate correct name
    const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
      if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
      
      const existingSubtasks = parentTask.subtasks || [];
      const currentNumber = extractNumber(currentSubtask.name);
      
      if (type === 'OBSERVACIÓN') {
        return `OBSERVACIÓN ${currentNumber + 1}`;
      } else {
        return `RESPUESTA ${currentNumber}`;
      }
    };
  
    switch (currentType) {
      case 'INGRESO':
        return [
          {
            type: 'OBSERVACIÓN',
            name: 'OBSERVACIÓN 1',
            resolutionType: '',
            responsable: [],
            organismo: mainTask.organismo || ''
          },
          {
            type: 'RESOLUCIÓN',
            name: generateName('RESOLUCIÓN'),
            resolutionType: '',
            responsable: [],
            organismo: mainTask.organismo || ''
          }
        ];
  
      case 'OBSERVACIÓN':
        const observationNumber = parseInt(subtask.name.split(' ')[1]) || 1;
        return [
          {
            type: 'RESPUESTA',
            name: `RESPUESTA ${observationNumber}`,
            resolutionType: '',
            responsable: mainTask.responsible || [],
            organismo: mainTask.organismo || ''
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: [],
            organismo: mainTask.organismo || ''
          }
        ];
  
      case 'RESPUESTA':
        const responseNumber = parseInt(subtask.name.split(' ')[1]) || 1;
        return [
          {
            type: 'OBSERVACIÓN',
            name: `OBSERVACIÓN ${responseNumber + 1}`,
            resolutionType: '',
            responsable: [],
            organismo: mainTask.organismo || ''
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: [],
            organismo: mainTask.organismo || ''
          }
        ];
  
      default:
        return [];
    }
  };
  

  const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);
  
  
    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      const updatedSubtask = { ...subtask, [name]: value };
       
      try {
        const subtaskData = {
          id: updatedSubtask.id,
          tarea_id: mainTask.id,
          nombre: name === 'name' ? value : updatedSubtask.name,
          responsable: name === 'responsible' ? value : updatedSubtask.responsible,
          tipo: name === 'type' ? value : updatedSubtask.type,
          isClosed: name === 'isClosed' ? value : updatedSubtask.isClosed,
          dependencias: name === 'dependsOn' ? value : JSON.stringify(updatedSubtask.dependsOn)
          // Incluir otros campos necesarios...
        };
  
        await updateSubtaskInDB(subtaskData);
        onChangeSubtask(updatedSubtask);
      } catch (error) {
        console.error('Error al actualizar campo:', error);
        alert('Error al actualizar el campo. Por favor, intente nuevamente.');
      }
    };
  
    const lightenColor = (color: string, amount: number = 0.2): string => {
      // Convertir el color hexadecimal a RGB
      let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
      
      // Aumentar los valores RGB para aclarar el color
      r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
      g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
      b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
      
      // Convertir de vuelta a hexadecimal
      return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };
    
  
    
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
          toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
          return;
        }
        handleSaveChanges();
      }
    };
  
  
    const modalVariants = {
      hidden: { opacity: 0, scale: 0.8 },
      visible: { 
        opacity: 1, 
        scale: 1,
        transition: { type: 'spring', stiffness: 500, damping: 25 }
      },
      exit: { 
        opacity: 0, 
        scale: 0.8,
        transition: { duration: 0.2 }
      }
    };
    
  
    const isEndDateValid = useMemo(() => {
      const startDate = dateUtils.parseLocalDate(localStartDate);
      const endDate = dateUtils.parseLocalDate(localEndDate);
      return endDate >= startDate;
    }, [localStartDate, localEndDate]);
    
  
  
    
    
    // Efectos para manejo de comentarios
    useEffect(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
    }, [subtask.comments, isOpen]);
  
    useEffect(() => {
      if (commentsEndRef.current) {
        commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [comments]);
  
    // Manejadores de eventos
    const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (subtask.isClosed) return;
  
      const parsedDate = dateUtils.parseLocalDate(value);
      const today = dateUtils.getTodayLocal();
  
      if (parsedDate < today) {
        toast.error('La fecha no puede ser anterior a hoy.');
        return;
      }
  
      if (name === 'start') {
        setLocalStartDate(value);
      } else if (name === 'end') {
        setLocalEndDate(value);
      }
    };
  

  
    
    const handleAddComment = async () => {
      if (!newComment.trim()) return;
  
      try {
        const timestamp = new Date().toLocaleString('es-CL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
  
        const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
        
        // Parsear comentarios existentes
        let currentComments = [];
        try {
          currentComments = subtask.comments ? JSON.parse(subtask.comments) : [];
        } catch (error) {
          console.error('Error parsing comments:', error);
          currentComments = [];
        }
  
        // Agregar nuevo comentario manteniendo los anteriores
        const updatedComments = Array.isArray(currentComments) 
          ? [...currentComments, commentWithTimestamp]
          : [commentWithTimestamp];
          
        const newCommentsString = JSON.stringify(updatedComments);
  
        // Actualizar subtarea
        const updatedSubtask = {
          ...subtask,
          id: subtask.id,
          name: subtask.name,
          tarea_id: mainTask.id,
          comments: newCommentsString
        };
  
        // Guardar en BD
        await updateSubtaskInDB(updatedSubtask);
  
        // Actualizar estados locales
        subtask.comments = newCommentsString;
        setComments(updatedComments);
        onChangeSubtask(updatedSubtask);
        setNewComment('');
  
        // Scroll al último comentario
        if (commentsContainerRef.current) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
  
      } catch (error) {
        console.error('Error al agregar comentario:', error);
        toast.error('Error al agregar el comentario');
      }
    };

  


// Modifica la función updateSubtaskInDB
const updateSubtaskInDB = async (subtaskData: any): Promise<void> => {
  try {

    // Process dependsOn array before sending
    const dependencies = Array.isArray(subtaskData.dependsOn) && subtaskData.dependsOn.length > 0
      ? subtaskData.dependsOn.map((dep: any) => dep.subtaskId)
      : [];
      console.log("subtaskData:", subtaskData);
     console.trace();
   
      const formattedSubtaskData = {
        action: 'update',
        subtask: {
          id: subtaskData.id,
          tarea_id: subtaskData.tarea_id,
          name: subtaskData.name || subtaskData.nombre || 'Sin Nombre', // Prioridad a 'name' o 'nombre'
          type: subtaskData.type || subtaskData.tipo || subtaskData.st_type || 'Sin Tipo', // Prioridad a 'type', 'tipo', o 'st_type'
          responsible: subtaskData.responsible,
          organism: subtaskData.organism || subtaskData.organismo,
          progress: subtaskData.progress,
          start: (subtaskData.start),
          end: (subtaskData.end),
          duration: subtaskData.duration,
          dependsOn: JSON.stringify(subtaskData.dependsOn),
          enabled: subtaskData.enabled,
          resolutionType: subtaskData.resolutionType || null,
          resolucion_tipo: subtaskData.resolutionType || null,
          orden: subtaskData.orden,
          comments: subtaskData.comments,
          isClosed: subtaskData.isClosed, 
          followUpDate: subtaskData.followUpDate,
          semaphoreStatus: subtaskData.semaphoreStatus,
        }
      };

      
 //   alert(JSON.stringify(formattedSubtaskData))
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedSubtaskData)
    });

   
    if (!response.ok) {
      throw new Error('Error updating subtask');
    }

    const result = await response.json();
    if (!result.success) {

      throw new Error(result.message || 'Error updating subtask');
    }

  } catch (error) {
    console.error('Error in updateSubtaskInDB:', error);
    //alert(JSON.stringify(subtaskData))
    throw error;
  }
};
  
    // Función auxiliar para crear subtareas en la BD
// Función para crear una nueva subtarea en la base de datos
 // Función auxiliar para crear subtareas en la BD
 const createSubtaskInDB = async (subtaskData: any) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'create',
        subtask: subtaskData
      }),
    });

    if (!response.ok) {
      throw new Error('Error al crear la subtarea');
    }

    return await response.json();
  } catch (error) {
    console.error('Error en createSubtaskInDB:', error);
    throw error;
  }
};

const handleResolutionTypeSelection = async (resolutionType: string) => {
  try {
    setIsLoading(true);
    const today = dateUtils.getTodayString();

    // Crear la nueva subtarea (si aplica)
    const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);
    const shouldCreateNextSubtask = nextOptions.length > 0;

    let newSubtask: SubTask | null = null;
    if (shouldCreateNextSubtask) {
      newSubtask = {
        id: mainTask.id,
        name: nextOptions[0].name,
        type: nextOptions[0].type,
        responsible: nextOptions[0].responsable,
        progress: 0,
        start: today,
        end: dateUtils.addDaysToDate(today, 7),
        duration: 1,
        organismo: subtask.organismo,
        enabled: true,
        orden: subtask.orden + 1,
        comments: '',
        followUpDate: '',
        followUp: false,
        semaphoreStatus: SemaphoreStatus.Green,
        isClosed: false,
        delayInDays: 0,
        dependsOn: subtask.dependsOn
      };
    }

    // Crear la tarea actualizada
    const updatedTask = {
      ...mainTask,
      subtasks: mainTask.subtasks?.map(st => 
        st.id === subtask.id 
          ? { 
              ...st, 
              isClosed: true, 
              progress: 100, 
              end: today,
              resolutionType: subtask.name !== 'RESOLUCIÓN' ? undefined : resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO',
              resolucion_tipo: subtask.name !== 'RESOLUCIÓN' ? undefined : resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO'
            }
          : st
      )
    };

    // Añadir nueva subtarea si existe
    if (newSubtask) {
      updatedTask.subtasks = updatedTask.subtasks?.concat(newSubtask);
    }

    console.log('Tarea actualizada:', updatedTask);
    if (mainTask.nombre === 'TRAMITACIÓN' && mainTask.subtasks) {
      const allSubtasksClosed = mainTask.subtasks.every(subtask => subtask.isClosed);
      if (allSubtasksClosed) {
        const updatedTask = {
          ...mainTask,
          isClosed: true,
          progress: 100,
          end: dateUtils.getTodayString()
        };
       
      }
    }
    // Actualizar en la base de datos
    await updateSubtaskInDB({
      ...subtask,
      id: subtask.id,
      tarea_id: mainTask.id,
      isClosed: true,
      progress: 100,
      end: today,
      resolutionType: resolutionType,
      resolucion_tipo: resolutionType
    });

    // Si hay nueva subtarea, crearla en la base de datos
    if (newSubtask) {
      await createSubtaskInDB({
        ...newSubtask,
        tarea_id: mainTask.id
      });
    }

    // Actualizar todo de una vez
    onChangeTask(updatedTask);

    // Cerrar diálogos
    setShowResolutionTypeDialog(false);
    setShowNewSubtaskDialog(false);
    onClose();

  } catch (error) {
    console.error('Error al cerrar resolución:', error);
    toast.error('Error al cerrar la resolución');
  } finally {
    setIsLoading(false);
  }
};
  
  
  const handleCloseSubtask = async () => {
    try {
      setIsLoading(true);
  
      if (subtask.type === 'RESOLUCIÓN') {
        setSelectedResolutionType('');
        setShowResolutionTypeDialog(true);
        return;
      }
  
      const today = dateUtils.getTodayString();
  
      // 1. Crear subtarea actualizada
      const updatedSubtaskData = {
        ...subtask,
        isClosed: true,
        tarea_id: mainTask.id,
        progress: 100,
        end: today,
        resolutionType: subtask.resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO' | undefined , // Añadir el tipo de resolución
        resolucion_tipo: subtask.resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO' | undefined,
   };
  
console.log('Estado de subtarea antes de cerrar:', subtask);
      // 2. Actualizar en la base de datos
      await updateSubtaskInDB(updatedSubtaskData);
  
      // 3. Guardar en el estado local
      setSubtaskToClose(updatedSubtaskData);
  
      // 4. Mostrar diálogo de nueva subtarea
      setShowNewSubtaskDialog(true);
  // En handleCloseSubtask
console.log('Estado de subtarea después de actualizar:', updatedSubtaskData);

    } catch (error) {
      console.error('Error al cerrar subtarea:', error);
      toast.error('Error al cerrar la subtarea');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleNewSubtaskSelection = async (option: NewSubtaskOption) => {
    try {
      setIsLoading(true);
      
      const formattedStartDate = dateUtils.getTodayString();
      const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7);
      
      // Primero, creamos la nueva subtarea
      const newSubtask: SubTask = {
        id: mainTask.id,
        name: option.name,
        type: option.type,
        responsible: option.responsable,
        progress: 0,
        start: formattedStartDate,
        end: formattedEndDate,
        duration: 1,
        organismo: subtask.organismo,
        enabled: true,
        orden: subtask.orden + 1,
        comments: '',
        followUpDate: '',
        followUp: false,
        semaphoreStatus: SemaphoreStatus.Green,
        isClosed: false,
        delayInDays: 0,
        dependsOn: subtask.dependsOn
      };
  
      // Crear la tarea actualizada con ambas subtareas
      const updatedTask = {
        ...mainTask,
        subtasks: mainTask.subtasks?.map(st => 
          st.id === subtask.id 
            ? { ...st, isClosed: true, progress: 100 }  // Asegurar que la subtarea original quede cerrada
            : st
        ).concat(newSubtask)
      };
  
      console.log('Subtarea original cerrada:', subtask);
      console.log('Nueva subtarea:', newSubtask);
      console.log('Tarea actualizada final:', updatedTask);
  
      // Actualizar todo de una vez
      onChangeTask(updatedTask);
      onAddNewSubtask(option);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al crear subtarea');
    } finally {
      setIsLoading(false);
      setShowNewSubtaskDialog(false);
      onClose();
    }
  };
  
    
    const handleOpenSubtask = async () => {
      try {
        setIsLoading(true);
        
        const startDate = dateUtils.parseLocalDate(subtask.start);
        const newEndDate = new Date(startDate);
        newEndDate.setDate(startDate.getDate() + (subtask.duration * 7));
        const formattedEndDate = dateUtils.formatLocalDate(newEndDate);
  
        const updatedSubtaskData = {
          ...subtask,
          tarea_id: mainTask.id,
          id: subtask.id,
          isClosed: false,
          progress: 0,
          end: formattedEndDate,
          fecha_termino: formattedEndDate
        };
  
        await updateSubtaskInDB(updatedSubtaskData);
        onChangeSubtask(updatedSubtaskData);
        
        onChangeTask({...mainTask});
        toast.success('Subtarea abierta exitosamente');
        onClose();
      } catch (error) {
        console.error('Error al abrir subtarea:', error);
        toast.error('Error al abrir la subtarea');
      } finally {
        setIsLoading(false);
      }
    };
  
    
    
  const contentVariants = {
    expanded: { 
      height: "auto",
      opacity: 1,
      transition: {
        height: {
          duration: 0.3,
          ease: [0.87, 0, 0.13, 1]
        },
        opacity: {
          duration: 0.25,
          ease: "easeInOut"
        }
      }
    },
    collapsed: { 
      height: 0,
      opacity: 0,
      transition: {
        height: {
          duration: 0.3,
          ease: [0.87, 0, 0.13, 1]
        },
        opacity: {
          duration: 0.25,
          ease: "easeInOut"
        }
      }
    }
  };
    
  
    const handleSaveChanges = async () => {
      try {
        setIsLoading(true);
  
        if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
          toast.error('La fecha de término no puede ser anterior a la fecha de inicio');
          return;
        }
  
        const updatedSubtask = {
          ...subtask,
          name: editedName,
          tarea_id: mainTask.id,
          start: localStartDate,
          end: localEndDate
        };
  
        await updateSubtaskInDB(updatedSubtask);
        onChangeSubtask(updatedSubtask);
        toast.success('Cambios guardados exitosamente');
        onClose();
      } catch (error) {
        console.error('Error al guardar cambios:', error);
        toast.error('Error al guardar los cambios');
      } finally {
        setIsLoading(false);
      }
    };
  
  
    return (
      <AnimatePresence>
      {isOpen && (
       <>
         <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 10002 }}>
         <Toaster
     position="top-right"
     toastOptions={{
       duration: 5000, // Duración más prolongada para mayor visibilidad
       style: {
     
         zIndex: 10002, // Asegúrate de que esté por encima de otros elementos
       },
       success: {
         style: {
           background: '#38A169', // Verde para mensajes de éxito
           color: '#FFFFFF',
         },
       },
       error: {
         style: {
           background: '#E53E3E', // Rojo para errores
           color: '#FFFFFF',
         },
       },
      
     }}
   />
             </div>
         <motion.div
           initial="hidden"
           animate="visible"
           exit="exit"
           variants={contentVariants}
           className="fixed inset-0 flex items-center justify-center  z-[10001] "
         >
             <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
             <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
             <motion.div 
               className="bg-white rounded-lg overflow-hidden shadow-xl"
               variants={contentVariants}
             >
        
   
               <div
                 className="bg-teal-600 p-3 text-white"
                
               >
                        <div className="relative">
     {/* Botón de cierre */}
     <button
       onClick={onClose}
       className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
     >
       <X size={20} />
     </button>
   </div>
       <h3 className="text-base font-semibold flex items-center space-x-2">
         <MdBusiness className="text-white w-5 h-5" /> 
         <span className="text-sm opacity-80 tracking-wide">
    {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName || ''}`}
  </span>
       </h3>
       <div className="flex mt-4 mb-3">
   
       <div className="flex items-center">
         <span className="text-lg font-bold text-white">{subtask.name}</span>
       </div>
     {/* Estado de la tarea */}
   <div className="ml-3 flex items-center">
   <div
     className={`px-3 py-1 rounded-full text-sm font-medium ${
       subtask.progress === 100 && subtask.isClosed
         ? `${backgroundColor} text-gray-800`
         : subtask.styles?.progressColor === '#EF4444' 
           ? 'bg-red-100 text-red-700'
           : subtask.styles?.progressColor === '#FB923C'
           ? 'bg-orange-100 text-orange-700'
           : subtask.styles?.progressColor === '#FDE047'
           ? 'bg-yellow-100 text-yellow-700'
           : subtask.styles?.progressColor === '#4ADE80'
           ? 'bg-green-100 text-green-700'
           : 'bg-gray-100 text-gray-700'
     }`}
     style={
       subtask.progress === 100 && subtask.isClosed
         ? {
             backgroundImage: `repeating-conic-gradient(
               ${lightenColor(backgroundColor, 80)} 0% 25%, 
               #ffffff 25% 50%
             )`,
             backgroundSize: '10px 10px',
           }
         : undefined
     }
   >
     {subtask.progress === 100 && subtask.isClosed
       ? 'Cerrada'
       : subtask.styles?.progressColor === '#EF4444'
       ? `Retrasada por ${daysDelayed} día(s)`
       : subtask.styles?.progressColor === '#FB923C'
       ? 'Próxima a vencer'
       : subtask.styles?.progressColor === '#FDE047'
       ? 'A tiempo, pero próxima'
       : subtask.styles?.progressColor === '#4ADE80'
       ? 'A tiempo'
       : 'No iniciada'}
   </div>
   </div>
   
       </div>
       <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
         <span className="flex items-center gap-1">
           <Calendar className="w-4 h-4" />
           {formatDateDDMMYYYY(subtask.start)}
         </span>
         <span className="flex items-center gap-1">
           <Clock className="w-4 h-4" />
           {formatDateDDMMYYYY(subtask.end)}
         </span>
   
       </div>
    
       </div>
     
     </motion.div>
   
   <div className="border-b">
             <nav className="flex">
               <button
                 onClick={() => setActiveTab('details')}
                 className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
               >
                 Detalles
               </button>
               <button
                 onClick={() => setActiveTab('followup')}
                 className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
               >
                 Seguimiento
               </button>
             </nav>
           </div>
   
           {activeTab === 'details' ? (
     <div className="p-4">
      
   
       {/* Grid de campos */}
       <div className="grid grid-cols-2 gap-3 mb-4">
         <div>
           <label className="block text-xs font-medium text-gray-700 mb-1">
             Nombre
           </label>
           <input
             type="text"
             value={editedName || ''}
             className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
             disabled={subtask.isClosed}
           />
         </div>
   
         <div></div>
   
         <div>
           <label className="block text-xs font-medium text-gray-700 mb-1">
             Fecha de Inicio
           </label>
           <input
             type="date"
             name="start"
             value={localStartDate}
             onChange={handleLocalDateChange}
             onKeyDown={handleKeyDown} 
             className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
             disabled={subtask.isClosed && subtask.progress === 100}
           />
         </div>
         <div>
           <label className="block text-xs font-medium text-gray-700 mb-1">
             Fecha de Término
           </label>
           <input
             type="date"
             name="end"
             value={localEndDate}
             onChange={handleLocalDateChange}
             onKeyDown={handleKeyDown}
             className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
             disabled={subtask.isClosed && subtask.progress === 100}
           />
         </div>
       </div>
   
       {/* Actualizar sección de comentarios */}
       <div className="comments-section">
         <div className="flex items-center justify-between mb-2">
           <h3 className="text-lg font-medium">Comentarios</h3>
           {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
         </div>
   
         <div
           ref={commentsContainerRef}
           className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
         >
   
   {subtask.comments ? (
     (() => {
       try {
         console.log('Contenido original de subtask.comments:', subtask.comments);
         
         // Parsear subtask.comments
         const parsedComments = JSON.parse(subtask.comments);
         console.log('Comentarios parseados:', parsedComments);
   
         // Validar que parsedComments sea un array con datos
         if (Array.isArray(parsedComments) && parsedComments.length > 0) {
           return parsedComments.map((comment, index) => {
             // Dividir y procesar cada comentario
             const [datePart, userAndContent] = comment.split('] ');
             const date = datePart.replace('[', '').trim();
             const [user, ...contentParts] = userAndContent.split(':');
             const content = contentParts.join(':').trim();
             const isCurrentUser = user === `${storedFirstName} ${storedLastName}`;
   
             return (
               <div
                 key={index}
                 className={`mb-3 last:mb-0 flex ${
                   isCurrentUser ? 'justify-start' : 'justify-end'
                 }`}
               >
                 <div
                   className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                     isCurrentUser
                       ? 'bg-green-100 text-green-900'
                       : 'bg-blue-100 text-blue-900'
                   }`}
                   style={{
                     maxWidth: 'calc(100% - 20px)',
                     wordBreak: 'break-word',
                   }}
                 >
                   <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                     <span className="font-medium">{user}</span>
                     <span className="text-gray-400">•</span>
                     <span className="text-gray-400">{date}</span>
                   </div>
                   <p className="text-sm whitespace-pre-wrap">{content}</p>
                 </div>
               </div>
             );
           });
         } else {
           return <p className="text-gray-500 italic">No hay comentarios.</p>;
         }
       } catch (error) {
         console.error('Error al procesar comentarios:', error);
         return (
           <p className="text-red-500 italic">Error al cargar comentarios.</p>
         );
       }
     })()
   ) : (
     <p className="text-gray-500 italic">No hay comentarios disponibles.</p>
   )}
   
   
         </div>
   
         <div className="mt-4 flex gap-2">
           <textarea
             value={newComment}
             onChange={(e) => setNewComment(e.target.value)}
             onKeyDown={async (e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (newComment.trim() && !subtask.isClosed && !isLoadingComments) {
                  await handleAddComment();
                  setNewComment('');
                }
              }
            }}
             placeholder="Escribir un comentario..."
             rows={2}
             className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
             disabled={subtask.isClosed}
             maxLength={250}
           />
           <button
             onClick={async () => {
               await handleAddComment();
               setNewComment(''); // Limpiar input después de agregar el comentario
             }}
             disabled={subtask.isClosed || !newComment.trim() || isLoadingComments}
             className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
           >
             Agregar
           </button>
         </div>
       </div>
        {/* Botones de acción */}
        <div className="flex justify-between mt-4 pt-2 border-t">
      <button
   type="button"
   className={`px-3 py-1.5 rounded text-sm font-medium ${
     subtask.progress === 100
       ? 'bg-green-600 hover:bg-green-700 text-white'
       : subtask.semaphoreStatus === SemaphoreStatus.Gray 
         ? 'bg-gray-400 text-white cursor-not-allowed' 
         : 'bg-red-600 hover:bg-red-700 text-white'
   }`}
   onClick={subtask.progress === 100 ? handleOpenSubtask : handleCloseSubtask}
   disabled={subtask.semaphoreStatus === SemaphoreStatus.Gray && subtask.progress === 0}
  >
   {subtask.progress === 100 ? "ABRIR SUBTAREA" : "CERRAR SUBTAREA"}
  </button>
                   <div className="relative">
                   <button
     type="button"
     onClick={() => {
       if (!isEndDateValid) {
         toast.error('La fecha de fin no puede ser anterior a la fecha de inicio.');
       } else {
         handleSaveChanges();
       }
     }}
     className={`px-3 py-1.5 text-white text-sm font-medium rounded ${
       isEndDateValid
         ? 'bg-blue-600 hover:bg-blue-700'
         : 'bg-gray-400 cursor-not-allowed'
     }`}
   >
     Guardar Cambios
   </button>
   </div>
                   </div>
     </div>
     
   ) :            
   <motion.div
   initial="hidden"
   animate="visible"
   exit="exit"
   variants={modalVariants}
   className="space-y-4 "
   >  { 
   //renderTrackingContent()
   }
         
   </motion.div>
   }
   </Dialog>
   
   
   
                  </motion.div>
                  
             </motion.div>
             
             </>
         )}
         {showResolutionTypeDialog && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
      <motion.div
        className="bg-white rounded-lg p-6 w-full max-w-md relative"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <button
          onClick={() => setShowResolutionTypeDialog(false)}
          className="absolute top-2 right-2 text-gray-300 hover:text-gray-400 z-10"
        >
          <X size={24} />
        </button>
  
        <h3 className="text-lg font-semibold text-gray-900 mb-4">
          Seleccionar tipo de resolución
        </h3>
        <div className="space-y-2">
          {resolutionTypes.map((type) => (
            <button
              key={type.value}
              onClick={() => handleResolutionTypeSelection(type.value)}
              className={`w-full flex items-center p-3 rounded-lg transition-all duration-200 ${type.bgColor} hover:opacity-90`}
            > 
              <div className={`mr-3 ${type.color}`}>{type.icon}</div>
              <span className={`font-medium ${type.color}`}>{type.label}</span>
            </button>
          ))}
        </div>
      </motion.div>
    </div>
  )}
  
           {/* Modal para seleccionar nueva subtarea */}
         <AnimatePresence>
          {showNewSubtaskDialog && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
              <motion.div
                className="bg-white rounded-lg p-6 w-full max-w-md"
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <h3 className="text-lg font-medium mb-4">Seleccionar siguiente subtarea</h3>
                <div className="space-y-4">
                {nextOptions.map((option) => (
  
                  <button
                    key={option.type}
                    className="w-full px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
                   onClick={() => handleNewSubtaskSelection(option)}
                  >
                      {option.name}
                  </button>
                              ))}
  
                  <button
                    className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                    onClick={() => setShowNewSubtaskDialog(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
       </AnimatePresence>
    );
  };
  

  interface ReminderValue {
    type: 'specific-date' | 'relative-time' | 'progress-based';
    value: string;
  }
  
  const parseReminderValue = (reminderString: string): ReminderValue | null => {
    if (!reminderString) return null;
  
    if (reminderString.startsWith('relative:')) {
      return {
        type: 'relative-time',
        value: reminderString.replace('relative:', '')
      };
    } else if (reminderString.startsWith('progress:')) {
      return {
        type: 'progress-based',
        value: reminderString.replace('progress:', '')
      };
    } else {
      return {
        type: 'specific-date',
        value: reminderString
      };
    }
  };


  const lightenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );
  };


  
  const TaskModal: React.FC<TaskModalProps> = ({ 
    isOpen, 
    onClose, 
    task, 
    onChangeTask, 
    backgroundColor,
    allTasks,
    agrupadorId,
    daysDelayed,
    groupProgress,
    processName,         // Prop: Nombre del proceso
    subprocessName,      // Prop: Nombre del subproceso
    agrupadorName,  
  }) => {
    const [comments, setComments] = useState<string>(task.comments || '');
    const [newComment, setNewComment] = useState('');
    const [showComments, setShowComments] = useState(true); // Set to true by default for better visibility
    const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || '');
    const [showReminderPicker, setShowReminderPicker] = useState(false);
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedTitle = sessionStorage.getItem('title');
    const storedLastName = sessionStorage.getItem('lastName');
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const commentsEndRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
    const [editedName, setEditedName] = useState(task.nombre);
    const storedUserId = sessionStorage.getItem('userId');
    const [localStartDate, setLocalStartDate] = useState<string>(task.start || '');
    const [localEndDate, setLocalEndDate] = useState<string>(task.end || '');
    const [isLoading, setIsLoading] = useState(false);
    const [reminderType, setReminderType] = useState<'date' | 'relative' | 'progress'>('progress');
    const [selectedDate, setSelectedDate] = useState('');
    const [timeValue, setTimeValue] = useState(1);
    const [timeUnit, setTimeUnit] = useState<'days' | 'weeks' | 'months'>('days');
    const [progressValue, setProgressValue] = useState(50);
    const [isLoadingReminder, setIsLoadingReminder] = useState(false);
  
  
      
    
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
          toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
          return;
        }
        handleSaveChanges();
      }
    };
  
  // Función para cargar los recordatorios de una tarea
  const loadTaskReminders = async (taskId: number): Promise<ReminderData | null> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_reminders.php?taskId=${taskId}`);
      if (!response.ok) {
        throw new Error('Error loading task reminders');
      }
      const data = await response.json();
      return data.reminder || null;
    } catch (error) {
      console.error('Error loading reminders for task:', taskId, error);
      return null;
    }
  };
  
  // Función para cargar los recordatorios de una subtarea
  const loadSubtaskReminders = async (taskId: number, subtaskId: number): Promise<ReminderData | null> => {
    try {
      const response = await fetch(
       `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_subtask_reminders.php?taskId=${taskId}&subtaskId=${subtaskId}`
      );
      if (!response.ok) {
        throw new Error('Error loading subtask reminders');
      }
      const data = await response.json();
      return data.reminder || null;
    } catch (error) {
      console.error('Error loading reminders for subtask:', subtaskId, error);
      return null;
    }
  };
  
  
  
    const formatDate = (date: string) => {
      if (!date) return '';
      const parsedDate = new Date(date);
      return parsedDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };
  
    
      // Handle task name change
      const handleNameChange = async () => {
        if (editedName.trim() === '') {
          toast.error('El nombre no puede estar vacío');
          return;
        }
      
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_name.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: task.id, name: editedName }),
          });
      
          if (!response.ok) {
            throw new Error('Error al actualizar el nombre de la tarea');
          }
      
          // Actualizar el estado local de la tarea
          onChangeTask({ ...task, nombre: editedName });
          toast.success('Nombre de la tarea actualizado');
        } catch (error) {
          console.error('Error al actualizar el nombre:', error);
          toast.error('Hubo un error al guardar el nombre. Por favor, inténtalo nuevamente.');
        }
      };
  
      const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'start') {
          setLocalStartDate(value);
        } else if (name === 'end') {
          setLocalEndDate(value);
        }
      };
  
      const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedName(e.target.value);
      };
  
      const handleDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
      
        // Actualiza el estado local
        const updatedTask = { ...task, [name]: value };
        onChangeTask(updatedTask); // Actualizar estado local en el componente padre
      
        try {
          // Llamada al backend para sincronizar los cambios
          const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_dates.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              taskId: task.id,
              start: updatedTask.start,
              end: updatedTask.end,
            }),
          });
      
          const result = await response.json();
      
          if (!response.ok || !result.success) {
            throw new Error(result.message || 'Error al actualizar las fechas');
          }
      
          console.log('Fechas actualizadas en el backend:', result.message);
        } catch (error) {
          console.error('Error al actualizar las fechas en el backend:', error);
          toast.error('Error al sincronizar las fechas con el servidor');
        }
      };
      
  
    const formatDateDDMMYYYY = (dateString: string) => {
      const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
    };
  
  
    const commentsContainerRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
    }, [task.comments, isOpen]); // Agrega isOpen como dependencia
  
    
    
    useEffect(() => {
      if (commentsEndRef.current) {
        commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [comments]);
    

  // Función para cargar comentarios de una tarea
  const loadTaskComments = async (taskId: number): Promise<string[]> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${taskId}`);
      
      if (!response.ok) {
        throw new Error('Error loading task comments');
      }
  
      const data: CommentResponse = await response.json();
      if (data.success && data.comments) {
        return data.comments;
      }
      return [];
    } catch (error) {
      console.error('Error loading task comments:', error);
      throw error;
    }
  };
  

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
  
    try {
      setIsLoadingComments(true);
      
      const timestamp = new Date().toLocaleString('es-CL', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
  
      const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
      
      let currentComments = [];
      try {
        currentComments = task.comments ? JSON.parse(task.comments) : [];
      } catch (error) {
        console.error('Error parsing existing comments:', error);
        currentComments = [];
      }
  
      const updatedComments = [...currentComments, commentWithTimestamp];
      const newCommentsString = JSON.stringify(updatedComments);
  
      // Create updated task object
      const updatedTask = {
        ...task,
        comments: newCommentsString
      };

      // Update task in database first
      await updateTaskInDatabase(updatedTask);
      
      // Then update local state
      onChangeTask(updatedTask);
      setNewComment('');

      // Update comments display
      setTimeout(() => {
        if (commentsContainerRef.current) {
          commentsContainerRef.current.scrollTo({
            top: commentsContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }, 100);
  
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Error al agregar el comentario');
    } finally {
      setIsLoadingComments(false);
    }
  };


  const loadComments = useCallback(async () => {
    if (!task.id) return;
    
    try {
      setIsLoadingComments(true);
      const comments = await loadTaskComments(task.id);
      onChangeTask({
        ...task, 
        start: task.start,
        end: task.end,
        comments: JSON.stringify(comments),
        isClosed: task.isClosed // Preservar explícitamente el estado
      });
    } catch (error) {
      console.error('Error loading comments:', error);
      toast.error('Error al cargar los comentarios');
    } finally {
      setIsLoadingComments(false);
    }
  }, [task.id, task.isClosed]); // Añadir task.isClosed a las dependencias
  

// Efecto para cargar comentarios cuando se abre el modal
useEffect(() => {
  if (isOpen) {
    loadComments();
  }
}, [isOpen, loadComments]);

// Efecto para scroll automático a nuevos comentarios
useEffect(() => {
  if (commentsEndRef.current) {
    commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [task.comments]);
  
  // Cargar comentarios al abrir el modal
  
  
    const handleTaskUpdate = async (task: Task, updatedData: Partial<Task>): Promise<void> => {
      try {
        // Actualizar la tarea principal
        const taskUpdateData: TaskUpdateData = {
          id: task.id,
          isClosed: updatedData.isClosed ?? task.isClosed,
          progress: updatedData.progress ?? task.progress,
          start: updatedData.start ?? task.start,
          end: updatedData.end ?? task.end,
          followUpDate: updatedData.followUpDate ?? task.followUpDate,
          comments: updatedData.comments ?? task.comments,
          semaphoreStatus: updatedData.semaphoreStatus ?? task.semaphoreStatus,
          delayInDays: updatedData.delayInDays
        };
    
    
        // Si hay subtareas actualizadas, procesarlas
        if (updatedData.subtasks && task.subtasks) {
          for (const subtask of updatedData.subtasks) {
            const subtaskUpdateData: SubtaskUpdateData = {
              id: subtask.id!,
              tarea_id: task.id,
              nombre: subtask.name,
              tipo: subtask.type,
              responsible: subtask.responsible,
              organismo: subtask.organismo,
              progress: subtask.progress,
              start: subtask.start,
              end: subtask.end,
              duracion: subtask.duration,
              dependencias: JSON.stringify(subtask.dependsOn),
              enabled: subtask.enabled,
              resolucion_tipo: subtask.resolutionType,
              orden: subtask.orden,
              comments: subtask.comments,
              isClosed: subtask.isClosed,
              followUpDate: subtask.followUpDate,
              semaphoreStatus: subtask.semaphoreStatus
            };
            if (task.subtasks) {
              for (const subtask of task.subtasks) {
                await updateSubtaskInDB(subtask);
              } 
            }
          }
        }
      } catch (error) {
        console.error('Error in handleTaskUpdate:', error);
        throw error;
      }
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      onChangeTask({ ...task, [name]: value });
    };
  
  
  
  // Función para calcular los días entre dos fechas
  const calculateDurationInDays = (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = end.getTime() - start.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  
  // Modificar la parte del renderizado del selector de progreso
  const renderProgressSelector = () => {
    const totalDays = calculateDurationInDays(task.start, task.end);
    
    return (
      <div className="space-y-4">
        <div className="bg-white rounded-lg">
          <h4 className="font-medium mb-2">Progreso basado en duración</h4>
          <p className="text-sm text-gray-600 mb-4">
            La tarea tiene una duración de {totalDays} días. 
            El recordatorio se activará cuando se alcance el porcentaje seleccionado del tiempo total.
          </p>
          
          <select
            value={progressValue}
            onChange={e => setProgressValue(parseInt(e.target.value))}
            className="w-full rounded-md border-gray-300"
            disabled={task.isClosed}
          >
            <option value={25}>25% del tiempo (Día {Math.ceil(totalDays * 0.25)})</option>
            <option value={50}>50% del tiempo (Día {Math.ceil(totalDays * 0.5)})</option>
            <option value={75}>75% del tiempo (Día {Math.ceil(totalDays * 0.75)})</option>
            <option value={90}>90% del tiempo (Día {Math.ceil(totalDays * 0.9)})</option>
          </select>
  
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
            <div className="flex">
              <Info className="h-5 w-5 text-blue-400 mr-2" />
              <p className="text-sm text-blue-700">
                El recordatorio se activará en el día {Math.ceil(totalDays * (progressValue / 100))} 
                de los {totalDays} días totales de la tarea.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  
  // Función para generar el reminder_code
  const generateReminderCode = (type: ReminderType, value: string): string => {
    switch (type) {
      case 'specific-date':
        return `f-${value}`;
      case 'relative-time':
        return `rt-${value}`;
      case 'progress-based':
        return `p-${value}`;
      case 'task-expired':
        return `exp-${value}`;
      case 'task-closed':
        return `closed-${value}`;
      default:
        return '';
    }
  };
  
  // Handler de guardado actualizado
  const handleReminderSave = async () => {
    try {
      setIsLoadingReminder(true);
      const currentUser = sessionStorage.getItem('userId');
      let reminder: {
        type: ReminderType;
        value: string;
        code: string;
      };
  
      switch (reminderType) {
        case 'date':
          reminder = {
            type: 'specific-date',
            value: selectedDate,
            code: generateReminderCode('specific-date', selectedDate)
          };
          break;
        case 'relative':
          const relativeValue = `${timeValue}${timeUnit === 'weeks' ? 'w' : 'd'}`;
          reminder = {
            type: 'relative-time',
            value: relativeValue,
            code: generateReminderCode('relative-time', relativeValue)
          };
          break;
        case 'progress':
          reminder = {
            type: 'progress-based',
            value: progressValue.toString(),
            code: generateReminderCode('progress-based', progressValue.toString())
          };
          break;
        default:
          throw new Error('Tipo de recordatorio no válido');
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_reminder.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          task_id: task.id,
          reminder_code: reminder.code,
          reminder_type: reminder.type,
          reminder_value: reminder.value,
          user_id: currentUser
        })
      });
  
      if (!response.ok) throw new Error('Error al guardar recordatorio');
  
      const result = await response.json();
      onChangeTask({
        ...task,
        reminder_code: reminder.code,
        reminder_type: reminder.type,
        reminder_value: reminder.value,
        reminder_calculated_date: result.reminder_calculated_date,
        reminder_active: true,
        reminder_triggered: false
      });
  
      toast.success('Recordatorio configurado exitosamente');
      setShowReminderPicker(false);
      onClose()
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al configurar recordatorio');
    } finally {
      setIsLoadingReminder(false);
    }
  };
  
  
    const renderTrackingContent = () => (
      <div className="p-6 space-y-6">
        <div className="bg-white p-4 rounded-lg border">
          <h4 className="font-medium mb-2">Configuración de Recordatorio</h4>
          
          <div className="flex gap-4 mb-6">
            {[
              { id: 'date', label: 'Fecha Específica', icon: Calendar },
              { id: 'relative', label: 'Tiempo Relativo', icon: Clock },
              { id: 'progress', label: 'Por Progreso', icon: AlertTriangle }
            ].map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setReminderType(id as typeof reminderType)}
                className={`flex-1 p-4 rounded-lg border-2 transition-all ${
                  reminderType === id 
                    ? 'border-teal-500 bg-teal-50 text-teal-700' 
                    : 'border-gray-200 hover:border-teal-200'
                }`}
                disabled={task.isClosed}
              >
                <Icon className="w-6 h-6 mx-auto mb-2" />
                <span className="text-sm font-medium block text-center">{label}</span>
              </button>
            ))}
          </div>
  
          {reminderType === 'date' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Seleccionar Fecha
              </label>
              <input
                type="date"
                value={selectedDate}
                onChange={e => setSelectedDate(e.target.value)}
                min={dateUtils.getTodayString()}
                className="w-full rounded-md border-gray-300"
                disabled={task.isClosed}
              />
            </div>
          )}
  
          {reminderType === 'relative' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Repetir Cada
              </label>
              <div className="flex gap-4">
                <input
                  type="number"
                  min="1"
                  value={timeValue}
                  onChange={e => setTimeValue(parseInt(e.target.value))}
                  className="w-24 rounded-md border-gray-300"
                  disabled={task.isClosed}
                />
                <select
                  value={timeUnit}
                  onChange={e => setTimeUnit(e.target.value as 'days' | 'weeks' | 'months')}
                  className="rounded-md border-gray-300"
                  disabled={task.isClosed}
                >
                  <option value="days">Días</option>
                  <option value="weeks">Semanas</option>
                  <option value="months">Meses</option>
                </select>
              </div>
            </div>
          )}
  
          {reminderType === 'progress' && (
            <div className="space-y-4">
              {renderProgressSelector ()}
            </div>
          )}
        </div>
  
        <div className="flex justify-end">
          <Button
            onClick={handleReminderSave}
            disabled={isLoadingReminder || task.isClosed}
            className="bg-teal-600 text-white hover:bg-teal-700"
          >
            {isLoadingReminder ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Guardando...
              </>
            ) : (
              'Guardar Recordatorio'
            )}
          </Button>
        </div>
      </div>
    );
  
  
  
    const parseExistingReminder = (code: string) => {
      if (code.startsWith('f')) {
        setReminderType('date');
        setSelectedDate(code.substring(1));
      } else if (code.startsWith('rt')) {
        setReminderType('relative');
        const [value, unit] = code.substring(2).split(':');
        setTimeValue(parseInt(value));
        setTimeUnit(unit as 'days' | 'weeks' | 'months');
      } else if (code.startsWith('p')) {
        setReminderType('progress');
        setProgressValue(parseInt(code.substring(1)));
      }
    };
  
    
  
  
  
  
  
  const parseReminderCode = (reminderCode: string | null): {
    type: string | null;
    value: string | null;
  } => {
    if (!reminderCode) return { type: null, value: null };
  
    if (reminderCode.startsWith('f-')) {
      return {
        type: 'specific-date',
        value: reminderCode.substring(2)
      };
    }
  
    if (reminderCode.startsWith('rt-')) {
      return {
        type: 'relative-time',
        value: reminderCode.substring(3)
      };
    }
  
    if (reminderCode.startsWith('p-')) {
      return {
        type: 'progress-based',
        value: reminderCode.substring(2)
      };
    }
  
    if (reminderCode.startsWith('exp-')) {
      return {
        type: 'task-expired',
        value: reminderCode.substring(4)
      };
    }
  
    if (reminderCode.startsWith('closed-')) {
      return {
        type: 'task-closed',
        value: reminderCode.substring(7)
      };
    }
  
    return { type: null, value: null };
  };
  
  // Función para verificar el estado del recordatorio
  const checkReminderStatus = (item: Task | SubTask): {
    isActive: boolean;
    isTriggered: boolean;
  } => {
    if (!item.reminder_code) return { isActive: false, isTriggered: false };
  
    const { type, value } = parseReminderCode(item.reminder_code);
    if (!type || !value) return { isActive: false, isTriggered: false };
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    switch (type) {
      case 'specific-date': {
        const reminderDate = new Date(value);
        reminderDate.setHours(0, 0, 0, 0);
        return {
          isActive: true,
          isTriggered: today >= reminderDate
        };
      }
  
      case 'relative-time': {
        const [amount, unit] = value.split(/(\d+)/).filter(Boolean);
        const reminderDate = new Date();
        if (unit === 'w') {
          reminderDate.setDate(reminderDate.getDate() + (parseInt(amount) * 7));
        } else {
          reminderDate.setDate(reminderDate.getDate() + parseInt(amount));
        }
        return {
          isActive: true,
          isTriggered: today >= reminderDate
        };
      }
  
      case 'progress-based': {
        const targetProgress = parseInt(value);
        return {
          isActive: true,
          isTriggered: (item.progress || 0) >= targetProgress
        };
      }
  
      case 'task-expired':
      case 'task-closed': {
        return {
          isActive: true,
          isTriggered: true
        };
      }
  
      default:
        return { isActive: false, isTriggered: false };
    }
  };
  
  const getReminderStatus = (item: Task | SubTask) => {
    // Si no hay recordatorio configurado
    if (!item.reminder_type || !item.reminder_value) {
      return {
        color: 'bg-gray-200',
        icon: null,
        tooltipText: 'Sin recordatorio'
      };
    }
  
    // Si el recordatorio está activo y se ha disparado
    if (item.reminder_active && item.reminder_triggered) {
      return {
        color: 'bg-red-500',
        icon: getReminderTypeIcon(item),
        tooltipText: getReminderTooltipText(item)
      };
    }
  
    // Si el recordatorio está activo pero no se ha disparado
    if (item.reminder_active && !item.reminder_triggered) {
      return {
        color: 'bg-yellow-500',
        icon: getReminderTypeIcon(item),
        tooltipText: getReminderTooltipText(item)
      };
    }
  
    // Si el recordatorio está inactivo
    return {
      color: 'bg-gray-200',
      icon: getReminderTypeIcon(item),
      tooltipText: 'Recordatorio inactivo'
    };
  };
  
  
  
  // Función para obtener el texto del tooltip
  const getReminderTooltipText = (item: Task | SubTask): string => {
    if (!item.reminder_type || !item.reminder_value) {
      return 'Sin recordatorio';
    }
  
    switch (item.reminder_type) {
      case 'specific-date':
        return `Recordatorio para: ${formatDate(item.reminder_calculated_date || '')}`;
      case 'relative-time': {
        const value = item.reminder_value;
        const unit = value.endsWith('w') ? 'semanas' : 'días';
        const amount = value.slice(0, -1);
        return `Recordatorio en ${amount} ${unit}`;
      }
      case 'progress-based':
        return `Recordar al ${item.reminder_value}% de progreso`;
      case 'task-expired':
        return 'Tarea vencida';
      case 'task-closed':
        return 'Tarea cerrada';
      default:
        return 'Sin recordatorio';
    }
  };
  
  
  
  // Función para obtener el ícono
  const getReminderTypeIcon = (item: Task | SubTask) => {
    if (!item.reminder_type) return null;
  
    switch (item.reminder_type) {
      case 'specific-date':
        return <Calendar className="w-4 h-4" />;
      case 'relative-time':
        return <Clock className="w-4 h-4" />;
      case 'progress-based':
        return <AlertTriangle className="w-4 h-4" />;
      case 'task-expired':
        return <AlertTriangle className="w-4 h-4" />;
      case 'task-closed':
        return <Check className="w-4 h-4" />;
      default:
        return null;
    }
  };
  
  
  
  
  
  
  
  
  
  
  
    // Función para actualizar comentarios de una tarea principal
  const updateTaskComments = async (taskData: CommentData): Promise<void> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_comments.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: taskData.id,
          comments: taskData.comments
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating task comments');
      }
  
      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message || 'Error updating task comments');
      }
    } catch (error) {
      console.error('Error in updateTaskComments:', error);
      throw error;
    }
  };
  
  
  
  
    const [updateTrigger, setUpdateTrigger] = useState(0);
  /*
    const handleCloseTask = async () => {
      if (task.semaphoreStatus === SemaphoreStatus.Gray) {
        alert("No se puede cerrar una tarea que aún no ha iniciado.");
        return;
      }
    
      // Crear fecha actual en zona horaria local
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
      const formattedDate = today.getFullYear() + '-' + 
        String(today.getMonth() + 1).padStart(2, '0') + '-' + 
        String(today.getDate()).padStart(2, '0');
    
      const updatedTask = { 
        ...task, 
        isClosed: true, 
        progress: 100, 
        end: formattedDate 
      };
      
      try {
        // Actualizar la tarea en la base de datos
        await updateTaskInDatabase(updatedTask);
        onChangeTask(updatedTask);
        setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
    
        // Calcular y actualizar el progreso del agrupador
        // await updateAgrupadorProgress(task.id);
        
        onChangeTask(updatedTask);
      } catch (error) {
        console.error('Error al cerrar la tarea:', error);
        alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
      }
    };
  */
  
  
  useEffect(() => {
    if (task.followUpDate) {
      parseExistingReminder(task.followUpDate);
    }
  }, [task.followUpDate]);
  

  
const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { duration: 0.2 }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.2 }
  }
};

// First, let's define the modalVariants
const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 500, damping: 25 }
  },
  exit: { 
    opacity: 0, 
    scale: 0.8,
    transition: { duration: 0.2 }
  }
};

const contentVariants = {
  expanded: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  },
  collapsed: { 
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }
};
  
  
  useEffect(() => {
    if (isOpen) {
 //     refreshTaskData();
    }
  }, [isOpen]);
  
  
    // Efecto para sincronizar el estado local cuando cambian las props
    useEffect(() => {
      if (isOpen) {
        // Actualizar el estado local con los datos más recientes
        setLocalTask(task);
        setLocalStartDate(task.start);
        setLocalEndDate(task.end);
        setEditedName(task.nombre);
      }
    }, [isOpen, task]);
    
  const [localTask, setLocalTask] = useState<Task>(task);
  
  
    // Función para cargar los datos más recientes de la tarea
    const refreshTaskData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${task.id}`);
        if (!response.ok) throw new Error('Error fetching task data');
        
        const updatedTaskData = await response.json();
        if (updatedTaskData.success) {
          const refreshedTask = {
            ...task,
            ...updatedTaskData.task,
            start: updatedTaskData.task.start,
            end: updatedTaskData.task.end
          };
          
          setLocalTask(refreshedTask);
          setLocalStartDate(refreshedTask.start);
          setLocalEndDate(refreshedTask.end);
          setEditedName(refreshedTask.nombre);
          onChangeTask(refreshedTask); // Actualizar el estado global
        }
      } catch (error) {
        console.error('Error refreshing task data:', error);
      }
    };
  
// Función para actualizar la tarea en la base de datos
const updateTaskInDatabase = async (taskData: Task): Promise<void> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: taskData.id,
        nombre: taskData.nombre,
        isClosed: taskData.isClosed,
        progress: taskData.progress,
        start: taskData.start,
        end: taskData.end,
        followUpDate: taskData.followUpDate,
        comments: taskData.comments,
        semaphoreStatus: taskData.semaphoreStatus,
        subtasks: taskData.subtasks
      }),
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task');
    }
  } catch (error) {
    console.error('Error in updateTaskInDatabase:', error);
    throw error;
  }
};

// Agregar estas funciones auxiliares
const getTaskDependencies = (task: Task, allTasks: Task[]): Task[] => {
  return allTasks.filter(t => {
    if (typeof t.dependencia === 'string') {
      const deps = t.dependencia.split(',').map(Number);
      return deps.includes(task.id);
    }
    return t.dependencia === task.id;
  });
};

const areAllDependenciesClosed = (task: Task, allTasks: Task[]): boolean => {
  if (!task.dependencia) return true;

  // Convertir la dependencia a número
  const dependencyOrder = typeof task.dependencia === 'string' 
    ? parseInt(task.dependencia) 
    : task.dependencia;
 
  // La tarea depende de la tarea cuyo (orden + 1) es igual a dependencyOrder
  const dependencyTask = allTasks.find(t => (t.orden + 1) === dependencyOrder);

  // Debug logs
  console.log('Checking dependencies for task:', task.nombre);
  console.log('Dependency order:', dependencyOrder);
  console.log('Found dependency task:', dependencyTask?.nombre);
  console.log('Dependency task closed?:', dependencyTask?.isClosed);

  // Si no encontramos la tarea dependiente, asumimos que no podemos proceder
  if (!dependencyTask) {
    console.log('No dependency task found');
    return false;
  }

  return dependencyTask.isClosed;
};



  // Función para calcular la nueva fecha de inicio para una tarea dependiente
  const calculateNewStartDate = (closedTask: Task): string => {
    const closedDate = dateUtils.parseLocalDate(closedTask.end);
    const nextDay = new Date(closedDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return dateUtils.formatLocalDate(nextDay);
  };

// Función para ajustar la fecha de término basada en la duración original
const adjustEndDate = (newStartDate: string, originalStartDate: string, originalEndDate: string): string => {
  const originalStart = dateUtils.parseLocalDate(originalStartDate);
  const originalEnd = dateUtils.parseLocalDate(originalEndDate);
  const duration = Math.ceil((originalEnd.getTime() - originalStart.getTime()) / (1000 * 60 * 60 * 24));
  
  const newStart = dateUtils.parseLocalDate(newStartDate);
  const newEnd = new Date(newStart);
  newEnd.setDate(newStart.getDate() + duration);
  
  return dateUtils.formatLocalDate(newEnd);
};

const getDependentTasks = (closedTask: Task, allTasks: Task[]): Task[] => {
  const closedTaskOrder = closedTask.orden;
  
  const dependentTasks = allTasks.filter(task => {
    const dependencyOrder = typeof task.dependencia === 'string' 
      ? parseInt(task.dependencia) 
      : task.dependencia;
    
    const isDependentTask = dependencyOrder === (closedTaskOrder + 1);
    console.log('Checking task:', {
      taskName: task.nombre,
      dependencyOrder,
      closedTaskOrder: closedTaskOrder + 1,
      isDependentTask
    });
    
    return isDependentTask;
  });

  console.log('Found dependent tasks:', dependentTasks.map(t => t.nombre));
  return dependentTasks;
};

// Función auxiliar para agregar días a una fecha
const updateTaskDates = (task: Task, newStartDate: string): { newStart: string, newEnd: string } => {
  const originalStart = dateUtils.parseLocalDate(task.start);
  const originalEnd = dateUtils.parseLocalDate(task.end);
  
  // Calcular la duración original en días
  const durationInDays = Math.ceil(
    (originalEnd.getTime() - originalStart.getTime()) / (1000 * 60 * 60 * 24)
  );

  const start = dateUtils.parseLocalDate(newStartDate);
  const end = new Date(start);
  // Mantener la duración original
  end.setDate(start.getDate() + durationInDays);

  return {
    newStart: dateUtils.formatLocalDate(start),
    newEnd: dateUtils.formatLocalDate(end)
  };
};

const handleCloseTask = async () => {
  try {
    const today = dateUtils.getTodayString();
    
    // 1. Actualizar la tarea que se está cerrando
    const updatedTask = {
      ...localTask,
      isClosed: true,
      progress: 100,
      end: today,
      comments: task.comments // Preserve comments

    };

    // 2. Actualizar en la base de datos
    await updateTaskInDatabase(updatedTask);

    // 3. Obtener lista actualizada de tareas
    const updatedAllTasks = allTasks.map(t => 
      t.id === updatedTask.id ? updatedTask : t
    );

    // 4. Encontrar tareas dependientes directas
    const dependentTasks = getDependentTasks(updatedTask, updatedAllTasks);
    const tasksToUpdate: Task[] = [];

    // 5. Procesar cada tarea dependiente
    for (const depTask of dependentTasks) {
      if (areAllDependenciesClosed(depTask, updatedAllTasks)) {
        // Calcular nueva fecha de inicio (día siguiente al cierre)
        const nextDay = dateUtils.addDaysToDate(today, 1);
        
        // Calcular nuevas fechas manteniendo la duración original
        const { newStart, newEnd } = updateTaskDates(depTask, nextDay);

        const updatedDepTask = {
          ...depTask,
          start: newStart,
          end: newEnd,
          semaphoreStatus: SemaphoreStatus.Green,
          enabled: true,
          comments: updatedTask.comments, 
        };

        if (updatedDepTask.nombre === 'TRAMITACIÓN' && updatedDepTask.subtasks) {
          updatedDepTask.subtasks = updatedDepTask.subtasks.map(subtask => ({
            ...subtask,
            start: newStart,
            end: newEnd
          }));
        }

        tasksToUpdate.push(updatedDepTask);
      }
    }

    // 6. Actualizar todas las tareas modificadas
    for (const taskToUpdate of tasksToUpdate) {
      await updateTaskInDatabase(taskToUpdate);
      onChangeTask(taskToUpdate);
    }

    // 7. Actualizar la tarea principal en la interfaz
    onChangeTask(updatedTask);
    onClose();

  } catch (error) {
    console.error('Error al cerrar la tarea:', error);
    toast.error('Error al cerrar la tarea');
    setLocalTask(task);
    setLocalEndDate(task.end);
  }
};


  
  const canCloseTramitacionTask = (task: Task): boolean => {
    if (task.nombre !== 'TRAMITACIÓN') return true;
    if (!task.subtasks || task.subtasks.length === 0) return true;
    
    return task.subtasks.every(subtask => subtask.isClosed);
  };
  
  // Función para actualizar las fechas de las subtareas
  /*const updateSubtasksDates = (subtasks: SubTask[]): SubTask[] => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
  
    return subtasks.map(subtask => ({
      ...subtask,
      end: subtask.isClosed ? subtask.end : formattedDate // Solo actualizar fechas de subtareas abiertas
    }));
  };
  */
  
  // Función para abrir una tarea
  const handleOpenTask = async () => {
    try {
      // Usar dateUtils para parsear la fecha de inicio
      const startDate = dateUtils.parseLocalDate(task.start);
      
      // Calcular la nueva fecha de término usando dateUtils
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + (task.duracion * 7));
      const formattedEndDate = dateUtils.formatLocalDate(newEndDate);
  
      const updatedTask = { 
        ...task, 
        isClosed: false,
        progress: 0,
        end: formattedEndDate
      };
  
      // Actualizar la tarea en la base de datos
      // Actualizar el estado local
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1);
      onChangeTask(updatedTask);
  
    } catch (error) {
      console.error('Error al abrir la tarea:', error);
      alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
    }
  };
  
    const handleResetFollowUpDate = () => {
      onChangeTask({ ...task, followUpDate: '' });
    };
  
  
  
    // Función para actualizar la subtarea en la base de datos
    const updateSubtaskInDB = async (subtaskData: any) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'update',
            subtask: subtaskData,
            start: subtaskData.start,
            end: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
            resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field
  
          })
        });
        console.log(JSON.stringify(subtaskData))
        if (!response.ok) {
          throw new Error('Error al actualizar la subtarea');
        }
  
        return await response.json();
      } catch (error) {
        console.error('Error en updateSubtaskInDB:', error);
        throw error;
      }
    };
  
  
  
    const handleSaveChanges = async () => {
      try {
        const updatedTask = {
          ...task,
          start: localStartDate,
          end: localEndDate,
          nombre: editedName,
          comments: task.comments, // Añadir comentarios
          followUpDate: task.followUpDate, // Añadir fecha de seguimiento 
          subtasks: task.subtasks, // Incluye subtareas
  
        };
       
        if (task.subtasks) {
          for (const subtask of task.subtasks) {
            await updateSubtaskInDB(subtask);
          } 
        }
  
        await updateTaskInDatabase(updatedTask);
        onChangeTask(updatedTask); // Actualizar el estado en el frontend
  
        onChangeTask(updatedTask);
        onClose();
      } catch (error) {
        console.error('Error al guardar los cambios:', error);
        alert('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
      }
    };
  
  
  
  const semaphoreStatus = calculateSemaphoreStatus(
    task.start,
    task.end,
    task.id,
    task.dependencia,
    task.isClosed,
    allTasks
  );
  
  
    const formatFollowUpDate = (date: string): string => {
      if (!date) return 'No establecida';
      const followUpDate = new Date(date);
      const today = new Date();
      const diffTime = followUpDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
      if (diffDays < 0) {
        return `Hace ${Math.abs(diffDays)} días`;
      } else if (diffDays === 0) {
        return 'Hoy';
      } else if (diffDays === 1) {
        return 'Mañana';
      } else {
        return `En ${diffDays} días`;
      }
    };
  
    return (
      <AnimatePresence>
     {isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={contentVariants}
          className="fixed inset-0 flex items-center justify-center  z-[10001] "
        >
            <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
            <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
            <motion.div 
              className="bg-white rounded-lg overflow-hidden shadow-xl"
              variants={contentVariants}
            >
       
  
              <div
                className="bg-teal-600 p-3 text-white"
               
              >
                       <div className="relative">
    {/* Botón de cierre */}
    <button
      onClick={onClose}
      className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
    >
      <X size={20} />
    </button>
  </div>
      <h3 className="text-base font-semibold flex items-center space-x-2">
        <MdBusiness className="text-white w-5 h-5" /> 
        <span className="text-sm opacity-80 tracking-wide">
          {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName?.toUpperCase()}`}
        </span>
      </h3>
      <div className="flex mt-4 mb-3">
  
      <div className="flex items-center">
        <span className="text-lg font-bold text-white">{task.nombre}</span>
      </div>
    {/* Estado de la tarea */}
  <div className="ml-3 flex items-center">
    <div
      className={`px-3 py-1 rounded-full text-sm font-medium ${
        task.progress === 100 && task.isClosed
          ? `${backgroundColor} text-gray-800` // Estilo para tareas cerradas
          : semaphoreStatus === SemaphoreStatus.Red
          ? 'bg-red-100 text-red-700'
          : semaphoreStatus === SemaphoreStatus.Orange
          ? 'bg-orange-100 text-orange-700'
          : semaphoreStatus === SemaphoreStatus.Yellow
          ? 'bg-yellow-100 text-yellow-700'
          : semaphoreStatus === SemaphoreStatus.Green
          ? 'bg-green-100 text-green-700'
          : 'bg-gray-100 text-gray-700'
      }`}
         style={
        task.progress === 100 && task.isClosed
          ? {
              backgroundImage: `repeating-conic-gradient(
                ${lightenColor(backgroundColor, 80)} 0% 25%, 
                #ffffff 25% 50%
              )`,
              backgroundSize: '10px 10px', // Tamaño del damero
            }
          : undefined
      }
    > 
   
      {task.progress === 100 && task.isClosed
        ? 'Cerrada'
        : semaphoreStatus === SemaphoreStatus.Red
        ? `Retrasada por ${daysDelayed} día(s)`
        : semaphoreStatus === SemaphoreStatus.Orange
        ? 'Próxima a vencer'
        : semaphoreStatus === SemaphoreStatus.Yellow
        ? 'A tiempo, pero próxima'
        : semaphoreStatus === SemaphoreStatus.Green
        ? 'A tiempo'
        : 'No iniciada'}
    </div>
  </div>
  
      </div>
      <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
        <span className="flex items-center gap-1">
          <Calendar className="w-4 h-4" />
          {formatDateDDMMYYYY(task.start)}
        </span>
        <span className="flex items-center gap-1">
          <Clock className="w-4 h-4" />
          {formatDateDDMMYYYY(task.end)}
        </span>
  
      </div>
   
      </div>
    
    </motion.div>
  
  <div className="border-b">
            <nav className="flex">
              <button
                onClick={() => setActiveTab('details')}
                className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
              >
                Detalles
              </button>
              <button
                onClick={() => setActiveTab('followup')}
                className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
              >
                Seguimiento
              </button>
            </nav>
          </div>
  
          {activeTab === 'details' ? (
    <div className="p-4">
     
  
      {/* Grid de campos */}
      <div className="grid grid-cols-2 gap-3 mb-4">
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Nombre
          </label>
          <input
            type="text"
            value={editedName || ''}
            onChange={handleLocalNameChange}
            className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={task.isClosed}
          />
        </div>
  
        <div></div>
  
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Fecha de Inicio
          </label>
          <input
            type="date"
            name="start"
            value={localStartDate}
            onChange={handleLocalDateChange}
            onKeyDown={handleKeyDown}
            className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={task.isClosed && task.progress === 100}
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Fecha de Término
          </label>
          <input
            type="date"
            name="end"
            value={localEndDate}
            onChange={handleLocalDateChange}
            onKeyDown={handleKeyDown}
            className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={task.isClosed && task.progress === 100}
          />
        </div>
      </div>
  
      {/* Actualizar sección de comentarios */}
      <div className="comments-section">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-medium">Comentarios</h3>
          {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
        </div>
  
        <div
          ref={commentsContainerRef}
          className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
          
        >
          {task.comments ? (
            (() => {
              try {
                const parsedComments = JSON.parse(task.comments);
                return Array.isArray(parsedComments) &&
                  parsedComments.length > 0 ? (
                  parsedComments.map((comment, index) => {
                    const [datePart, userAndContent] = comment.split('] ');
                    const date = datePart.replace('[', '').trim();
                    const [user, ...contentParts] = userAndContent.split(':');
                    const content = contentParts.join(':').trim();
                    const isCurrentUser =
                      user === `${storedFirstName} ${storedLastName}`;
                    return (
                      <div
                        key={index}
                        className={`mb-3 last:mb-0 flex ${
                          !isCurrentUser ? 'justify-end' : 'justify-start'
                        }`}
                      >
                        <div
                          className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                            isCurrentUser
                              ? 'bg-green-100 text-green-900'
                              : 'bg-blue-100 text-blue-900'
                          }`}
                          style={{
                            maxWidth: 'calc(100% - 20px)', // Asegura que el texto no exceda el ancho del contenedor principal
                            wordBreak: 'break-word', // Permite dividir palabras largas
                          }}
                        >
                          <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                            <span className="font-medium">{user}</span>
                            <span className="text-gray-400">•</span>
                            <span className="text-gray-400">{date}</span>
                          </div>
                          <p className="text-sm whitespace-pre-wrap">{content}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-gray-500 italic">No hay comentarios</p>
                );
              } catch (error) {
                return (
                  <p className="text-red-500">Error al cargar comentarios</p>
                );
              }
            })()
          ) : (
            <p className="text-gray-500 italic">Cargando comentarios...</p>
          )}
        </div>
  
        <div className="mt-4 flex gap-2">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyDown={async (e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (newComment.trim() && !task.isClosed && !isLoadingComments) {
                  await handleAddComment();
                  setNewComment('');
                }
              }
            }}
            placeholder="Escribir un comentario..."
            rows={2}
            className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
            disabled={task.isClosed}
            maxLength={250}
          />
          <button
            onClick={async () => {
              await handleAddComment();
              setNewComment(''); // Limpiar input después de agregar el comentario
            }}
            disabled={task.isClosed || !newComment.trim() || isLoadingComments}
            className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
          >
            Agregar
          </button>
        </div>
      </div>
       {/* Botones de acción */}
       <div className="flex justify-between mt-4 pt-2 border-t">
                <button
    type="button"
    className={`px-3 py-1.5 rounded text-sm font-medium ${
      task.isClosed
        ? 'bg-green-600 hover:bg-green-700 text-white'
        : task.semaphoreStatus === SemaphoreStatus.Gray || 
          (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0)
        ? 'bg-gray-400 text-white cursor-not-allowed'
        : 'bg-red-600 hover:bg-red-700 text-white'
    }`}
    onClick={task.isClosed ? handleOpenTask : handleCloseTask}
    disabled={
      !task.isClosed && 
      (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed || 
       (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0))
    }
  >
                    {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                  </button>
  
                  <button
                    type="button"
                    onClick={handleSaveChanges}
                    className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
                  >
                    Guardar Cambios
                  </button>
                </div>
    </div>
    
  ) :            
  <motion.div
  initial="hidden"
  animate="visible"
  exit="exit"
  variants={modalVariants}
  className="space-y-4 "
  >  { 
  renderTrackingContent()
  }
        
  </motion.div>
  }
  </Dialog>
  
  
  
                 </motion.div>
                 
            </motion.div>
            
        )}
      </AnimatePresence>
    );
  };
  





interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}


interface Task {
  id: number;
  globalId?: number; 
  nombre: string;
  description: string;
  responsible: User[] | string; 
  progress: number;
  start: string;
  organismo: string;
  duracion: number;
  end: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
    reminder_code?: string;
    reminder_type?: string;
    reminder_value?: string;
    reminder_calculated_date?: string;
    reminder_active?: true,
    reminder_triggered?: false
}



enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}




interface SubTask {
  id?: number;
  name: string;
  responsible: User[] | string; // Puede ser un array de Users o un string
  progress: number;
  start: string;
  end: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
  reminder_code?: string;
  reminder_type?: string;
  reminder_value?: string;
  reminder_calculated_date?: string;
  reminder_active?: boolean;
  reminder_triggered?: boolean;
  tarea_id?: number;
}

const resolutionTypes = [
  {
    value: 'APROBADO',
    label: 'Aprobado',
    icon: <Check className="h-5 w-5" />,
    color: 'text-green-700',
    bgColor: 'bg-green-100'
  },
  {
    value: 'RECHAZADO',
    label: 'Rechazado',
    icon: <X className="h-5 w-5" />,
    color: 'text-red-700',
    bgColor: 'bg-red-100'
  },
  {
    value: 'DESISTIMIENTO',
    label: 'Desistimiento',
    icon: <AlertTriangle className="h-5 w-5" />,
    color: 'text-orange-700',
    bgColor: 'bg-orange-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO POSITIVO',
    label: 'SILENCIO ADMINISTRATIVO POSITIVO',
    icon: <div className="flex items-center">
            <Check className="h-5 w-5 mr-1" />
            <span className="text-xs font-medium">SAP</span>
          </div>,
    color: 'text-green-700',
    bgColor: 'bg-green-50'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    label: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    icon: <div className="flex items-center">
            <X className="h-5 w-5 mr-1" />
            <span className="text-xs font-medium">SAN</span>
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  },
  {
    value: 'NO ADMITIDO',
    label: 'No Admitido',
    icon: <div className="flex items-center">
            <svg viewBox="0 0 24 24" className="h-5 w-5 mr-1 text-red-700">
              <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
              <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2"/>
            </svg>
            
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  }
];



interface Agrupador {
  id: number;
  nombre: string;
  start: string;
  end: string;
  progress: number;
  subproceso: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Task[];
  orden: number;
}
interface TaskItemProps {
  task: Task;
  status: 'inProgress' | 'pending' | 'warning' | 'success' | 'danger';
  onChangeTask: (updatedTask: Task) => void;
  onTaskClick: (task: Task) => void; // Nueva prop para manejar el clic
  color: string; // Add color prop
  processName?: string;
  subprocessName?: string;
  agrupadorName?: string;
}



const formattedResolutionType = (resolutionType: string) => {
  const resolutionLabel = resolutionTypes.find(rt => rt.value === resolutionType)?.label.toUpperCase();
 
  if (resolutionLabel === 'SILENCIO ADMINISTRATIVO POSITIVO') {
    return (
      <span className="flex items-center gap-1">
        <CheckCircle className="text-green-500" size={16} /> SAP
      </span>
    );
  }

  if (resolutionLabel === 'SILENCIO ADMINISTRATIVO NEGATIVO') {
    return (
      <span className="flex items-center gap-1">
        <XCircle className="text-red-500" size={16} /> SAN
      </span>
    );
  }

  return resolutionLabel;  // Para otros casos, simplemente retorna el label en mayúsculas.
};




const TaskItem: React.FC<TaskItemProps> = React.memo(({ 
  task, 
  status, 
  onChangeTask, 
  onTaskClick, 
  color,
  processName,
  subprocessName,
  agrupadorName
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeSubtask, setActiveSubtask] = useState<SubTask | null>(null);

  // Función auxiliar para convertir fechas de string a Date
  const getDateFromString = (dateStr: string): Date => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const calculateSemaphoreStatus = (
    start: string, 
    end: string, 
    isClosed: boolean,
    isSubtask: boolean = false,
    parentTask?: Task
  ): SemaphoreStatus => {
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    const today = dateUtils.getTodayLocal();
    const startDate = dateUtils.parseLocalDate(start);
    const endDate = dateUtils.parseLocalDate(end);
  
  
    // Si alguna fecha es inválida, devuelve `Gray` como predeterminado
    if (!startDate || !endDate) {
      return SemaphoreStatus.Gray;
    }
  
    // Para subtareas dentro de tareas como 'TRAMITACIÓN'
    if (parentTask) {
      if (parentTask.nombre === 'TRAMITACIÓN') {
        if (parentTask.semaphoreStatus === SemaphoreStatus.Gray || startDate > today) {
          return SemaphoreStatus.Gray;
        }
        
        const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  
        if (today > endDate) return SemaphoreStatus.Red;
        if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
        if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  
        return SemaphoreStatus.Green;
      }
    }
  
    // Para tareas principales
    if (today > endDate) {
      return SemaphoreStatus.Red;
    }
    if (startDate <= today) {
      const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
      if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  
      return SemaphoreStatus.Green;
    }
  
    return SemaphoreStatus.Gray;
  };

 

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0) {
      setIsExpanded(!isExpanded);
    }
  };

  // Procesar las subtareas con el estado del semáforo actualizado
  const processedSubtasks = useMemo(() => 
    task.subtasks?.map(subtask => ({
      ...subtask,
      semaphoreStatus: calculateSemaphoreStatus(
        subtask.start,
        subtask.end,
        subtask.isClosed,
        true,
        task
      )
    })) || [], [task, task.subtasks]
  );

  const getCheckerboardPattern = (color: string) => {
    const lightColor = lightenColor(color, 100);
    return {
      backgroundImage: `
        linear-gradient(45deg, ${lightColor} 25%, transparent 25%),
        linear-gradient(-45deg, ${lightColor} 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, ${lightColor} 75%),
        linear-gradient(-45deg, transparent 75%, ${lightColor} 75%)
      `,
      backgroundSize: '20px 20px',
      backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
      backgroundColor: 'white'
    };
  };

  const getSemaphoreConfig = () => {
    if (task.isClosed && task.progress === 100) {
      return {
        bg: 'relative',
        border: 'border-gray-300',
        text: 'text-gray-800',
        icon: <CheckCircle2 className="w-4 h-4 text-gray-600" />,
        label: 'Completada',
        style: () => ({
          backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
          backgroundSize: '10px 10px',
          backgroundClip: 'border-box',
          padding: '15px',
          overflow: 'hidden',
        }),
        closedTextBackground: 'bg-white',
      };
    }


    switch (task.semaphoreStatus) {
      case 'green':
        return {
          bg: 'bg-green-100 hover:bg-green-200',
          border: 'border-green-300',
          text: 'text-green-800',
          icon: <Clock className="w-4 h-4 text-green-600" />,
          label: 'En progreso',
        };
      case 'yellow':
        return {
          bg: 'bg-yellow-100 hover:bg-yellow-200',
          border: 'border-yellow-300',
          text: 'text-yellow-800',
          icon: <AlertTriangle className="w-4 h-4 text-yellow-600" />,
          label: 'Por vencer',
        };
      case 'orange':
        return {
          bg: 'bg-orange-100 hover:bg-orange-200',
          border: 'border-orange-300',
          text: 'text-orange-800',
          icon: <AlertTriangle className="w-4 h-4 text-orange-600" />,
          label: 'Próxima a vencer',
        };
      case 'red':
        return {
          bg: 'bg-red-100 hover:bg-red-200',
          border: 'border-red-300',
          text: 'text-red-800',
          icon: <AlertCircle className="w-4 h-4 text-red-600" />,
          label: 'Vencida',
        };
      case 'gray':
      default:
        return {
          bg: 'bg-gray-100 hover:bg-gray-200',
          border: 'border-gray-300',
          text: 'text-gray-800',
          icon: <CircleDot className="w-4 h-4 text-gray-600" />,
          label: 'Pendiente',
        };
    }
  };

  const getSubtaskSemaphoreConfig = (subtask: SubTask) => {
    if (subtask.isClosed && subtask.progress === 100) {
      return {
        bg: 'relative',
        border: 'border-gray-300',
        text: 'text-gray-800',
        icon: <CheckCircle2 className="w-4 h-4 text-gray-600" />,
        label: 'Completada',
        style: () => ({
          backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
          backgroundSize: '10px 10px',
          backgroundClip: 'border-box',
          padding: '15px',
          overflow: 'hidden',
        }),
        closedTextBackground: 'bg-white',
      };
    }
    switch (subtask.semaphoreStatus) {
      case 'green':
        return {
          bg: 'bg-green-100 hover:bg-green-200',
          border: 'border-green-300',
          text: 'text-green-800',
          icon: <Clock className="w-4 h-4 text-green-600" />,
          label: 'En progreso',
        };
      case 'yellow':
        return {
          bg: 'bg-yellow-100 hover:bg-yellow-200',
          border: 'border-yellow-300',
          text: 'text-yellow-800',
          icon: <AlertTriangle className="w-4 h-4 text-yellow-600" />,
          label: 'Por vencer',
        };
      case 'orange':
        return {
          bg: 'bg-orange-100 hover:bg-orange-200',
          border: 'border-orange-300',
          text: 'text-orange-800',
          icon: <AlertTriangle className="w-4 h-4 text-orange-600" />,
          label: 'Próxima a vencer',
        };
      case 'red':
        return {
          bg: 'bg-red-100 hover:bg-red-200',
          border: 'border-red-300',
          text: 'text-red-800',
          icon: <AlertCircle className="w-4 h-4 text-red-600" />,
          label: 'Vencida',
        };
      case 'gray':
      default:
        return {
          bg: 'bg-gray-100 hover:bg-gray-200',
          border: 'border-gray-300',
          text: 'text-gray-800',
          icon: <CircleDot className="w-4 h-4 text-gray-600" />,
          label: 'Pendiente',
        };
    }
  };
  

  const config = getSemaphoreConfig();





  // Add handler for subtask click
  const handleSubtaskClick = (subtask: SubTask, e: React.MouseEvent) => {
  //  e.stopPropagation();
    setActiveSubtask(subtask);
  };//churejas

  // Add handler for subtask changes
{/* En TaskItem */}
const handleChangeSubtask = (updatedSubtask: SubTask) => {
  if (!task.subtasks) return;

  // Actualizamos la tarea con la subtarea actualizada
  const updatedTask = {
    ...task,
    subtasks: task.subtasks.map(st => 
      st.id === updatedSubtask.id ? updatedSubtask : st
    )
  };

  // Propagamos el cambio hacia arriba
 // onChangeTask(updatedTask);
};

  // Add handler for new subtasks
  const handleAddNewSubtask = async (option: NewSubtaskOption) => {
    if (!task.subtasks) return;

    try {
        const todayString = dateUtils.getTodayString();
        const endDateString = dateUtils.addDaysToDate(todayString, 7); // Agregar 7 días

        const newSubtaskData = { 
            tarea_id: task.id,
            nombre: option.name,
            tipo: option.type,
            responsable: option.responsable,
            organismo: task.organismo,
            progreso: 0,
            fecha_inicio: todayString,
            fecha_termino: endDateString, // Ahora con 7 días más
            duracion: 1,
            enabled: true,
            orden: task.subtasks.length + 1
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'create',
                subtask: newSubtaskData
            })
        });

        if (!response.ok) throw new Error('Failed to create subtask');

        const result = await response.json();

        if (result.success) {
            const newSubtask: SubTask = {
                id: result.id,
                name: option.name,
                type: option.type,
                responsible: option.responsable,
                progress: 0,
                start: todayString,
                end: endDateString, // Ahora con la fecha de término correcta
                duration: 1,
                organismo: task.organismo,
                enabled: true,
                orden: task.subtasks.length + 1,
                comments: '',
                followUpDate: '',
                followUp: false,
                semaphoreStatus: SemaphoreStatus.Green,
                isClosed: false,
                delayInDays: 0,
                resolutionType: option.resolutionType as any
            };

            const updatedTask = {
                ...task,
                subtasks: [...task.subtasks, newSubtask]
            };

           // onChangeTask(updatedTask);
        }
    } catch (error) {
        console.error('Error creating subtask:', error);
    }
};

const getResolutionTypeStyle = (resolutionType?: string) => {
  switch (resolutionType) {
    case 'APROBADO':
      return 'bg-green-100 text-green-800 text-[10px]';  // Texto pequeño
    case 'RECHAZADO':
      return 'bg-red-100 text-red-800 text-[10px]';
    case 'DESISTIMIENTO':
      return 'bg-orange-100 text-orange-800 text-[10px]';
    case 'SILENCIO ADMINISTRATIVO POSITIVO':
      return 'bg-green-100 text-green-800 text-xs';
    case 'SILENCIO ADMINISTRATIVO NEGATIVO':
      return 'bg-red-100 text-red-800 text-xs';
    case 'NO ADMITIDO':
      return 'bg-red-100 text-red-800 text-xs';
    default:
      return 'bg-gray-100 text-gray-800 text-xs';
  }
};



return (
  <>
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className={`p-4 rounded-xl mb-3 rounded-lg border ${config.bg} ${config.border} transition-all duration-200 cursor-pointer group`}
      onClick={(e) => {
        e.preventDefault();
        onTaskClick(task);
      }}
      style={task.isClosed && config.style ? config.style() : {}}
    >
      <div className={`flex flex-col p-3 rounded-xl ${task.isClosed ? 'bg-white' : ''}`}>
        {/* Header row */}
        <div className="flex items-center">
          {task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0 && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className="mr-2 hover:bg-gray-200 rounded-full p-1 transition-colors"
            >
              {isExpanded ? 
                <ChevronDown size={30} className="text-gray-600" /> : 
                <ChevronRight size={30} className="text-gray-600" />
              }
            </button>
          )}
          <div className={`mr-2 ${config.text}`}>{config.icon}</div>
          <div className="flex-1">
            <div className={`font-medium ${config.text} text-sm`}>{task.nombre}</div>
          </div>
        </div>

        {/* Status and progress row */}
        <div className="flex items-center justify-between mt-2">
          <span className={`text-xs ${config.text} opacity-75`}>{config.label}</span>
          <span className={`text-xs ${config.text} opacity-75`}>{task.progress}%</span>
        </div>

        {/* Dates row */}
        <div className="flex items-center justify-between mt-2 text-xs ">
          <div className="flex items-center gap-4">
          <span className={`flex items-center gap-1 ${
      task.semaphoreStatus === 'green' ? 'text-green-700' :
      task.semaphoreStatus === 'yellow' ? 'text-yellow-700' :
      task.semaphoreStatus === 'orange' ? 'text-orange-700' :
      task.semaphoreStatus === 'red' ? 'text-red-700' :
      task.isClosed ? 'text-gray-500' : 'text-gray-500'
    }`}>
              <Calendar className="w-4 h-4" />
              {dateUtils.parseLocalDate(task.start).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }).replace(/\//g, '-')}
            </span>
            <span className={`flex items-center gap-1 ${
      task.semaphoreStatus === 'green' ? 'text-green-700' :
      task.semaphoreStatus === 'yellow' ? 'text-yellow-700' :
      task.semaphoreStatus === 'orange' ? 'text-orange-700' :
      task.semaphoreStatus === 'red' ? 'text-red-700' :
      task.isClosed ? 'text-gray-500' : 'text-gray-500'
    }`}>
              <Calendar className="w-4 h-4" />
              {dateUtils.parseLocalDate(task.end).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }).replace(/\//g, '-')}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
      <AnimatePresence>
        {isExpanded && processedSubtasks && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="ml-8 space-y-2"
          >
{processedSubtasks.map((subtask, index) => {
  const subtaskConfig = getSubtaskSemaphoreConfig(subtask);

  return (
    <motion.div
      key={subtask.id || index}
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -20, opacity: 0 }}
      className={`p-4 mb-3 rounded-lg border ${subtaskConfig.bg} ${subtaskConfig.border} transition-all duration-200 cursor-pointer group`}
      style={subtask.isClosed && subtaskConfig.style ? subtaskConfig.style() : {}}

    >
      <div 
        className={`p-3 rounded-xl ${subtaskConfig.text}`}
        style={{
          ...(subtask.isClosed && subtask.progress === 100
            ? {
                backgroundColor: 'white',
              }
            : {
                backgroundColor: '', // Fondo blanco para subtareas normales
              }),
        }}
        onClick={(e) => handleSubtaskClick(subtask, e)}
      >
        <div className="flex items-center px-2 space-x-2">
          <div className={`mr-2 ${subtaskConfig.text}`}>
            {subtaskConfig.icon}
          </div>
<div className="flex items-center space-x-2">
  <span className={`font-medium ${subtaskConfig.text} text-sm`}>
    {subtask.name}
  </span>
  {subtask.type === "RESOLUCIÓN" && subtask.resolutionType && (
  <span className={`px-1 py-0.5 rounded flex items-center gap-1 ${getResolutionTypeStyle(subtask.resolutionType)}`}>
   {formattedResolutionType(subtask.resolutionType)}

  </span>
)}
</div>
        </div>
        <div className="flex justify-between mt-1 px-5">
          <span className={`text-xs ${subtaskConfig.text} opacity-75`}>
            {subtaskConfig.label}
          </span>
          <span className={`text-xs ${subtaskConfig.text} opacity-75`}>
            {subtask.progress}%
          </span>
        </div>
        
        {/* Fechas de la subtarea */}
        <div className="flex items-center justify-between mt-2 text-xs px-2">
          <div className="flex items-center gap-4">
            <span className={`flex items-center gap-1 ${
              subtask.semaphoreStatus === 'green' ? 'text-green-700' :
              subtask.semaphoreStatus === 'yellow' ? 'text-yellow-700' :
              subtask.semaphoreStatus === 'orange' ? 'text-orange-700' :
              subtask.semaphoreStatus === 'red' ? 'text-red-700' :
              subtask.isClosed ? 'text-gray-500' : 'text-gray-500'
            }`}>
              <Calendar className="w-4 h-4" />
              {dateUtils.parseLocalDate(subtask.start).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }).replace(/\//g, '-')}
            </span>
            <span className={`flex items-center gap-1 ${
              subtask.semaphoreStatus === 'green' ? 'text-green-700' :
              subtask.semaphoreStatus === 'yellow' ? 'text-yellow-700' :
              subtask.semaphoreStatus === 'orange' ? 'text-orange-700' :
              subtask.semaphoreStatus === 'red' ? 'text-red-700' :
              subtask.isClosed ? 'text-gray-500' : 'text-gray-500'
            }`}>
              <Calendar className="w-4 h-4" />
              {dateUtils.parseLocalDate(subtask.end).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }).replace(/\//g, '-')}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
})}


          </motion.div>
        )}
      </AnimatePresence>
      {activeSubtask && createPortal(
       <SubtaskModal
       isOpen={activeSubtask !== null}
       onClose={() => setActiveSubtask(null)}

      subtask={activeSubtask}
      onChangeTask={onChangeTask}
   
      onChangeSubtask={handleChangeSubtask}

      onAddNewSubtask={handleAddNewSubtask}
      groupProgress={task.progress}

      backgroundColor={color}
      mainTask={task}
       processName={processName}
       subprocessName={subprocessName}
   
       agrupadorName={agrupadorName}
     />,   document.body

      )}
    </>
    
  );
 
});


const getTaskStatus = (task: Task): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
  if (task.isClosed) return 'success';
  if (task.progress > 0) return 'inProgress';
  return 'pending';
};


const convertSemaphoreStatus = (status: SemaphoreStatus): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
  switch (status) {
    case SemaphoreStatus.Green:
      return 'inProgress';
    case SemaphoreStatus.Yellow:
      return 'warning';
    case SemaphoreStatus.Orange:
      return 'warning';
    case SemaphoreStatus.Red:
      return 'danger';
    case SemaphoreStatus.Gray:
    default:
      return 'pending';
  }
};




interface TaskColumnProps {
  title: string;
  tasks: Task[];
  onChangeTask: (task: Task) => void;
  color: string;
  onTaskClick: (task: Task) => void; // Nueva prop

}





const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Proceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        Este proceso no contiene información, por favor revise la planificación.
      </p>
    </div>
  );
};




const PlanningPanel: React.FC<ProjectTimelineProps> = ({ 
  selectedProcess, 
  setSelectedProcess, 
  processes, 
  subprocessColor,
  onUpdateTask
}) => {
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [isProcessLoaded, setIsProcessLoaded] = useState(false);
  const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
  const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const previousProcessRef = useRef<string | null>(null);
  const [draggedColumnId, setDraggedColumnId] = useState<string | null>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [collapsedStates, setCollapsedStates] = useState<Record<string, boolean>>({});

  // 1. Funciones auxiliares corregidas
const calculateDurationInDays = (startDate: string, endDate: string): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
};

const formatDateToString = (date: Date): string => {
  return date.toISOString().split('T')[0];
};
const areAllDependenciesClosed = (task: Task, allTasks: Task[]): boolean => {
  if (!task.dependencia) return true;

  const dependencyOrder = typeof task.dependencia === 'string' 
    ? parseInt(task.dependencia) 
    : task.dependencia;

  // Buscar la tarea predecesora basándose en el orden
  const dependencyTask = allTasks.find(t => (t.orden + 1) === dependencyOrder);

  console.log('Checking dependencies for task:', {
    taskName: task.nombre,
    dependencyOrder,
    foundTask: dependencyTask?.nombre,
    isClosed: dependencyTask?.isClosed,
    progress: dependencyTask?.progress
  });

  return dependencyTask?.isClosed === true;
};
  const updateSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData,
          end: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
          resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field

        })
      });
    //  console.log(JSON.stringify(subtaskData))
      if (!response.ok) {
        throw new Error('Error al actualizar la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en updateSubtaskInDB:', error);
      throw error;
    }
  };

  // Función para crear una nueva subtarea en la base de datos
  const createSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'create',
          subtask: subtaskData
          
        })
      });
      

      if (!response.ok) {
        throw new Error('Error al crear la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en createSubtaskInDB:', error);
      throw error;
    }
  };


 
  const calculateSemaphoreStatus = (
    start: string,
    end: string,
    taskId: number,
    dependencia: string | number | null,
    isClosed: boolean,
    allTasks?: Task[],
    parentTask?: Task
  ): SemaphoreStatus => {
    // Si la tarea está cerrada, retornar gris
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    // Obtener fecha actual y establecer a medianoche
    const today = dateUtils.getTodayLocal();
    today.setHours(0, 0, 0, 0);
  
    // Convertir fechas de string a Date y establecer a medianoche
    const startDate = dateUtils.parseLocalDate(start);
    const endDate = dateUtils.parseLocalDate(end);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
  
    // Validación de fechas
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return SemaphoreStatus.Gray;
    }
  
    // Si la tarea aún no comienza
    if (startDate > today) {
      return SemaphoreStatus.Gray;
    }
  
    // Si ya pasó la fecha de fin o es el mismo día de fin
    if (today >= endDate) {
      return SemaphoreStatus.Red;
    }
  
    // Calcular duración total y días restantes
    const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
    const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;
  
    // Para debug
    console.log({
      taskId,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      today: today.toISOString(),
      totalDuration,
      daysRemaining,
      percentageCompleted
    });
  
    // Enfoque híbrido: usar días restantes para tareas cortas y porcentajes para largas
    if (totalDuration <= 5) {
      // Lógica para tareas cortas (5 días o menos)
      if (daysRemaining === 0) {
        return SemaphoreStatus.Red;     // Mismo día = Rojo
      } else if (daysRemaining === 1) {
        return SemaphoreStatus.Orange;  // Un día restante = Naranja
      } else if (daysRemaining <= 3) {
        return SemaphoreStatus.Yellow;  // 2-3 días restantes = Amarillo
      }
      return SemaphoreStatus.Green;     // Más de 3 días = Verde
    } else {
      // Lógica para tareas largas (más de 5 días)
      if (percentageCompleted >= 90) {
        return SemaphoreStatus.Red;     // 90% o más completado = Rojo
      } else if (percentageCompleted >= 75) {
        return SemaphoreStatus.Orange;  // 75-89% completado = Naranja
      } else if (percentageCompleted >= 50) {
        return SemaphoreStatus.Yellow;  // 50-74% completado = Amarillo
      }
      return SemaphoreStatus.Green;     // Menos del 50% completado = Verde
    }
  };
  const handleColumnDragStart = (agrupadorId: string) => {
    setDraggedColumnId(agrupadorId);
  };

  const handleColumnDragEnter = (targetAgrupadorId: string) => {
    if (draggedColumnId && draggedColumnId !== targetAgrupadorId) {
      const updatedAgrupadores = [...currentAgrupadores];
      const draggedIndex = updatedAgrupadores.findIndex(ag => ag.id.toString() === draggedColumnId);
      const targetIndex = updatedAgrupadores.findIndex(ag => ag.id.toString() === targetAgrupadorId);
      
      if (draggedIndex !== -1 && targetIndex !== -1) {
        // Intercambiar posiciones
        [updatedAgrupadores[draggedIndex], updatedAgrupadores[targetIndex]] = 
        [updatedAgrupadores[targetIndex], updatedAgrupadores[draggedIndex]];
        
        setCurrentAgrupadores(updatedAgrupadores);
      }
    }
  };

  const handleColumnDragEnd = () => {
    setDraggedColumnId(null);
  };

  const updateAgrupadorOrder = async (agrupadorId: number, newOrder: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_agrupador_order.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: agrupadorId,
          orden: newOrder
        }),
      });
      console.log(2)
      if (!response.ok) {
        throw new Error('Error al actualizar el orden');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error al actualizar el orden del agrupador:', error);
      throw error;
    }
  };

  
  
  const ColumnsContainer: React.FC<{
    agrupadores: Agrupador[];
    onReorder: (newOrder: Agrupador[]) => void;
    color: string;
    onTaskClick: (task: Task) => void;
    onChangeTask: (task: Task) => void;
    processName?: string;
    subprocessName?: string;
    agrupadorName?: string;
  }> = ({ agrupadores, onReorder, color, onTaskClick, onChangeTask, processName, subprocessName, agrupadorName }) => {
    const [orderedAgrupadores, setOrderedAgrupadores] = useState(

      [...agrupadores].sort((a, b) => a.orden - b.orden)
    );
  
    const previousOrderRef = useRef<Agrupador[]>(orderedAgrupadores);
    const previousColorRef = useRef(color);
    const [isExpanded, setIsExpanded] = useState(true);
    const containerRef = React.useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);

    const [collapsedStates, setCollapsedStates] = useState<Record<string, boolean>>({});
    const [contentHeights, setContentHeights] = useState<Record<string, number>>({});
    const contentRefs = useRef<Record<string, HTMLDivElement | null>>({});
  
    // Update heights when content changes
    const updateHeight = useCallback((id: string) => {
      const element = contentRefs.current[id];
      if (element) {
        setContentHeights(prev => ({
          ...prev,
          [id]: element.scrollHeight
        }));
      }
    }, []);
  
    // Update height on content change
    useEffect(() => {
      Object.keys(contentRefs.current).forEach(id => {
        updateHeight(id);
      });
    }, [orderedAgrupadores, updateHeight]);
  

    const contentVariants = {
      expanded: { 
        height: 'auto',
        opacity: 1,
        transition: {
          duration: 0.3,
          ease: "easeInOut"
        }
      },
      collapsed: { 
        height: 0,
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: "easeInOut"
        }
      }
    };

    const containerVariants = {
      expanded: { height: "auto", opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
      collapsed: { height: 0, opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } },
    };
    
    
    const headerVariants = {
      expanded: {
        backgroundColor: "#FFFFFF", // Cambia al color que quieras cuando está expandido
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        transition: { duration: 0.3, ease: "easeInOut" },
      },
      collapsed: {
        backgroundColor: "#F3F4F6", // Cambia al color que quieras cuando está colapsado
        boxShadow: "none",
        transition: { duration: 0.3, ease: "easeInOut" },
      },
    };
    

    // Función para actualizar la subtarea en la base de datos
  


    // Función para obtener el color del semáforo
    const getSemaphoreColor = (status: SemaphoreStatus): string => {
      switch (status) {
        case SemaphoreStatus.Green:
          return 'bg-green-100 hover:bg-green-200 border-green-300 text-green-800';
        case SemaphoreStatus.Yellow:
          return 'bg-yellow-100 hover:bg-yellow-200 border-yellow-300 text-yellow-800';
        case SemaphoreStatus.Orange:
          return 'bg-orange-100 hover:bg-orange-200 border-orange-300 text-orange-800';
        case SemaphoreStatus.Red:
          return 'bg-red-100 hover:bg-red-200 border-red-300 text-red-800';
        case SemaphoreStatus.Gray:
        default:
          return 'bg-gray-100 hover:bg-gray-200 border-gray-300 text-gray-800';
      }
    };
  
    const updateOrdersInDatabase = async (updatedAgrupadores: Agrupador[]) => {
      try {
        // Preparar los datos para enviar en un solo request
        const orderUpdates = updatedAgrupadores.map((agrupador, index) => ({
          id: agrupador.id,
          orden: index + 1
        }));
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_agrupador_order.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ updates: orderUpdates })
        });
       
        if (!response.ok) {
          throw new Error('Failed to update orders');
        }
  
        const result = await response.json();
        console.log('Órdenes actualizados en BD:', orderUpdates);
        
      } catch (error) {
        console.error('Error al actualizar los órdenes:', error);
      }
    };
  
    // Handler para el reordenamiento visual mientras se arrastra
    const handleReorder = (reorderedItems: Agrupador[]) => {
      setOrderedAgrupadores(reorderedItems);
      
      // Comparar el orden actual con el anterior
      const hasOrderChanged = JSON.stringify(reorderedItems.map(a => a.id)) !== 
                            JSON.stringify(previousOrderRef.current.map(a => a.id));
  
      if (hasOrderChanged) {
        // Actualizar el orden en la base de datos solo si ha cambiado
        const updatedAgrupadores = reorderedItems.map((agrupador, index) => ({
          ...agrupador,
          orden: index + 1
        }));
  
        updateOrdersInDatabase(updatedAgrupadores);
        onReorder(updatedAgrupadores);
        previousOrderRef.current = reorderedItems;
      }
    };
  
    // Handler para cuando se suelta el elemento
    const handleDragEnd = () => {
      const updatedAgrupadores = orderedAgrupadores.map((agrupador, index) => ({
        ...agrupador,
        orden: index + 1
      }));
  console.log(updatedAgrupadores)
      // Actualizar estado local con los nuevos órdenes
      setOrderedAgrupadores(updatedAgrupadores);
      onReorder(updatedAgrupadores);
      
      // Actualizar la base de datos
      updateOrdersInDatabase(updatedAgrupadores);
    };

    const lightenColor = (color: string, amount: number): string => {
      return '#' + color.replace(/^#/, '').replace(/../g, color => 
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      );
    };
  
    useEffect(() => {
      previousColorRef.current = color;
    }, [color]);
  

    React.useEffect(() => {
      if (containerRef.current) {
        const container = containerRef.current;
      }
    }, [agrupadores]);

    return (
      <Reorder.Group
      as="div"
      axis="x" 
      values={orderedAgrupadores}
      onReorder={handleReorder}
      className="flex gap-4 p-4 overflow-x-auto"
      layoutScroll
    >
         {orderedAgrupadores.map((agrupador) => (
      <Reorder.Item
        key={agrupador.id}
        value={agrupador}
        layoutId={`agrupador-${agrupador.id}`}
        className="list-none"
        initial={false} // Remove initial animation
        animate={{ scale: 1 }} // Simplify animation
        transition={{
          type: "tween",
          duration: 0.2
        }}
      >
    <motion.div
          className="w-80 shadow-xl rounded-xl border my-1 overflow-hidden"
          initial={false}
          animate={{
            backgroundColor: lightenColor(color, 80),
            borderColor: color,
          }}
          style={{
            borderWidth: '1px'
          }}
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          {/* Header */}
          <motion.div 
              className="p-4 cursor-pointer"
              onClick={() => {
                setCollapsedStates(prev => ({
                  ...prev,
                  [agrupador.id]: !prev[agrupador.id]
                }));
                updateHeight(agrupador.id.toString());
              }}
            >
        {/* Header */}
    
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-gray-700">
              {agrupador.nombre}
            </h3>
            <motion.div
      animate={{ 
        rotate: collapsedStates[agrupador.id] ? 0 : 180,
      }}
      transition={{
        type: "spring",
        stiffness: 200,
        damping: 15
      }}
    >
      <ChevronDown size={20} style={{ color }} />
    </motion.div>

          </div>
          <span className="text-sm text-gray-500 flex items-center gap-1">
            <Building size={14} />
            {agrupador.organismo}
          </span>
        </motion.div>
        {/* Contenido colapsable */}
        <motion.div
  animate={{
    height: collapsedStates[agrupador.id] ? 0 : "auto",
    opacity: collapsedStates[agrupador.id] ? 0 : 1,
  }}
  initial={false}
  transition={{
    height: {
      duration: 0.4,
      ease: [0.32, 0.72, 0, 1] // Custom easing curve
    },
    opacity: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }}
  className="overflow-hidden"
>
    
                <div className="px-4 pb-4 space-y-3">
                  {agrupador.tareas.map((task) => {
                    const semaphoreStatus = calculateSemaphoreStatus(
                      task.start,
                      task.end,
                      task.id,
                      task.dependencia,
                      task.isClosed,
                      orderedAgrupadores.flatMap(ag => ag.tareas)
                    );
                    
                    return (
                      <TaskItem
                        key={task.id}
                        task={{
                          ...task,
                          semaphoreStatus
                        }}
                        status={convertSemaphoreStatus(semaphoreStatus)}
                        onChangeTask={onChangeTask}
                        onTaskClick={onTaskClick}
                        color={color}
                        processName={processName}
                        subprocessName={subprocessName}
                        agrupadorName={agrupador.nombre}
                      />
                    );
                  })}
                </div>
              </motion.div>
        </motion.div>

          
      </Reorder.Item> 
    ))}
  </Reorder.Group>
  );
};
  
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };


  // Agregar estados para el modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);



// Agregar estas funciones dentro del componente PlanningPanel



// 2. Función para actualizar estado de tareas dependientes
const updateDependentTasksStatus = (closedTaskIndex: number) => {
  const getMaxEndDate = (dependencies: number[]): Date | null => {
    const dates = dependencies
      .map((depIndex) => {
        const predecessorTask = allTasks[depIndex - 1];
        if (predecessorTask) {
          return dateUtils.parseLocalDate(predecessorTask.end);
        }
        return null;
      })
      .filter(Boolean) as Date[];

    return dates.length > 0 ? new Date(Math.max(...dates.map((date) => date.getTime()))) : null;
  };

  const closedTaskPosition = closedTaskIndex + 1;

  allTasks.forEach((task) => {
    if (task.dependencia) {
      const dependencias =
        typeof task.dependencia === "string"
          ? task.dependencia.split(",").map(Number)
          : [task.dependencia];

      if (task.nombre === 'TRAMITACIÓN' && dependencias.includes(0)) {
        task.semaphoreStatus = SemaphoreStatus.Green;
        const todayString = dateUtils.getTodayString();
        task.start = todayString;
        task.end = todayString;
        return;
      }

      const allDependenciesClosed = dependencias.every((depPosition) => {
        const depTask = allTasks[depPosition - 1];
        return depTask && depTask.isClosed;
      });

      if (allDependenciesClosed) {
        const maxDependencyEndDate = getMaxEndDate(dependencias);

        if (maxDependencyEndDate) {
          task.semaphoreStatus = calculateSemaphoreStatus(
            task.start,
            task.end,
            task.id,
            task.dependencia,
            task.isClosed,
            allTasks
          );
        }
      }
    }
  });
};

// 3. Función para actualizar fechas de tareas dependientes
const updateDependentTaskStartDate = async () => {
  let tasksUpdated = true;
  let tasksToUpdate: Task[] = [];

  const getMaxEndDate = (dependencies: number[]): Date | null => {
    const dates = dependencies.map((depIndex: number) => {
      const predecessorTask = allTasks[depIndex - 1];
      if (predecessorTask) {
        const [year, month, day] = predecessorTask.end ? predecessorTask.end.split('-').map(Number) : [0, 0, 0];
        return new Date(year, month - 1, day);
      }
      return null;
    }).filter(Boolean) as Date[];

    return dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;
  };

  const handleTramitacionTask = (task: Task): void => {
    if (task.nombre === 'TRAMITACIÓN' && !task.isClosed) {
      const today = dateUtils.getTodayLocal();
   
      const endDate = dateUtils.parseLocalDate(task.start);
      endDate.setDate(endDate.getDate() + (task.duracion * 7));
      task.end = dateUtils.formatLocalDate(endDate);
      tasksToUpdate.push(task);

      if (task.subtasks) {
        task.subtasks.forEach(subtask => {
          if (!subtask.isClosed) {
            subtask.start = task.start;
            subtask.end = task.end;
          }
        });
      }
    }
  };

  while (tasksUpdated) {
    tasksUpdated = false;

    allTasks.forEach(task => {
      if (task.dependencia && !task.isClosed) {
        const dependencias = typeof task.dependencia === 'string'
          ? task.dependencia.split(',').map(Number)
          : [task.dependencia];

        const maxDependencyEndDate = getMaxEndDate(dependencias);

        if (maxDependencyEndDate) {
          const newStartDate = new Date(maxDependencyEndDate);
          
          const allDependenciesClosed = dependencias.every(depIndex => {
            const depTask = allTasks[depIndex - 1];
            return depTask && depTask.isClosed;
          });

          let maxDaysLate = 0;

          if (!allDependenciesClosed) {
            const today = dateUtils.getTodayLocal();
            today.setHours(0, 0, 0, 0);
          
            dependencias.forEach(depIndex => {
              const depTask = allTasks[depIndex - 1];
              if (depTask && !depTask.isClosed) {
                const taskEndDate = dateUtils.parseLocalDate(depTask.end);
                if (today > taskEndDate) {
                  const timeDiff = today.getTime() - taskEndDate.getTime();
                  maxDaysLate = Math.max(maxDaysLate, Math.ceil(timeDiff / (1000 * 60 * 60 * 24)));
                }
              }
            });
          
            newStartDate.setDate(newStartDate.getDate() + maxDaysLate + 1);
          }

          const formattedStartDate = dateUtils.formatLocalDate(newStartDate);

          if (task.start !== formattedStartDate) {
            task.start = formattedStartDate;

            if (task.nombre !== 'TRAMITACIÓN' || (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed)) {
              const newEndDate = new Date(newStartDate);
              newEndDate.setDate(newStartDate.getDate() + (task.duracion * 7));
              task.end = dateUtils.formatLocalDate(newEndDate);
            }

            tasksUpdated = true;
            tasksToUpdate.push(task);
            
            if (task.nombre === 'TRAMITACIÓN' && !task.isClosed) {
              handleTramitacionTask(task);
            }
           
          }
        }
      }
    });
  }

  // Guardar tareas actualizadas en la base de datos
  if (tasksToUpdate.length > 0) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_multiple_tasks.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tasksToUpdate.map(task => ({
          id: task.id,
          start: dateUtils.formatLocalDate(dateUtils.parseLocalDate(task.start)),
          end: dateUtils.formatLocalDate(dateUtils.parseLocalDate(task.end)),
          semaphoreStatus: task.semaphoreStatus,
          delayInDays: task.delayInDays || 0,
          subtasks: task.subtasks
        })))
      });

      if (!response.ok) {
        throw new Error('Failed to update tasks in database');
      }

      const result = await response.json();
      console.log('Tasks updated successfully:', result.message);
      
      // Actualizar el estado local después de la actualización exitosa
      setCurrentAgrupadores(prev => prev.map(agrupador => ({
        ...agrupador,
        tareas: agrupador.tareas.map(tarea => {
          const updatedTask = tasksToUpdate.find(t => t.id === tarea.id);
          return updatedTask || tarea;
        })
      })));

    } catch (error) {
      console.error('Error updating tasks in database:', error);
      toast.error('Error al actualizar las tareas');
    }
  }
};





// First, let's fix the useEffect hook to properly track dependencies
useEffect(() => {
  // Only run if there's a selected task
  if (selectedTask) {
    const updateDependencies = async () => {
      try {
        await updateDependentTaskStartDate();
        updateDependentTasksStatus(selectedTask.orden);
      } catch (error) {
        console.error('Error updating dependencies:', error);
        toast.error('Error updating dependent tasks');
      }
    };

    updateDependencies();
  }
}, [selectedTask, updateDependentTaskStartDate, updateDependentTasksStatus]);




  const updateDependentTasks = useCallback(async (task: Task) => {
    if (!task.isClosed) {
      console.log('Task not closed, skipping dependent tasks update');
      return;
    }
  
    const allTasks = currentAgrupadores.flatMap(ag => ag.tareas);
    console.log('All tasks:', allTasks.map(t => ({
      nombre: t.nombre,
      fecha_inicio: t.start,
      fecha_termino: t.end,
      orden: t.orden, 
      isClosed: true
    })));
  
    try {
      // Encontrar tareas que dependen de la tarea actual
      const dependentTasks = allTasks.filter(t => {
        const depValue = typeof t.dependencia === 'string' 
          ? parseInt(t.dependencia) 
          : t.dependencia;
        // La tarea depende de esta si su dependencia es igual al (orden + 1) de la tarea actual
        return depValue === (task.orden + 1);
      });
  
      console.log('Found dependent tasks:', dependentTasks.map(t => t.nombre));
  
      const tasksToUpdate = [];
  
      for (const depTask of dependentTasks) {
        // Verificar si todas las dependencias están cerradas
        if (areAllDependenciesClosed(depTask, allTasks)) {
          console.log('All dependencies closed for:', depTask.nombre);
          
          // ... resto del código de actualización ...
        } else {
          console.log('Dependencies not met for:', depTask.nombre);
        }
      }
      
      // ... resto del código ...
  
    } catch (error) {
      console.error('Error updating dependent tasks:', error);
      toast.error('Error al actualizar las tareas dependientes');
    }
  }, [currentAgrupadores]);


// 2. Función para actualizar tarea en la base de datos
const updateTaskInDatabase = async (taskData: Task): Promise<void> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: taskData.id,
        nombre: taskData.nombre,
        isClosed: taskData.isClosed,
        progress: taskData.progress,
        start: taskData.start,
        end: taskData.end,
        followUpDate: taskData.followUpDate,
        comments: taskData.comments,
        semaphoreStatus: taskData.semaphoreStatus,
        subtasks: taskData.subtasks
      }),
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task');
    }
  } catch (error) {
    console.error('Error in updateTaskInDatabase:', error);
    throw error;
  }
};





  // Agregar manejadores para el modal
  const handleTaskClick = (task: Task) => {
 
  

    // Configurar la tarea seleccionada
    setSelectedTask(task);
  
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };



  // Función para cargar los datos del subproceso seleccionado
  const loadSubprocessData = useCallback((subprocess: Subproceso) => {
    if (!subprocess || !allAgrupadores) return;
    
    const agrupadoresForSubprocess = allAgrupadores.filter(
      agrupador => agrupador.subproceso === subprocess.subproceso
    );
    setCurrentAgrupadores(agrupadoresForSubprocess);
  }, [allAgrupadores]);

  // Efecto para inicializar los datos cuando se cargan los subprocesos
  useEffect(() => {
    if (!filteredSubprocesos || !allAgrupadores) return;
    if (filteredSubprocesos.length === 0 || allAgrupadores.length === 0) return;
  
    try {
      // Intentar recuperar el subproceso guardado
      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      let targetIndex = 0;
      
      if (savedSubprocess) {
        const foundIndex = filteredSubprocesos.findIndex(sp => 
          sp && sp.subproceso && sp.subproceso === savedSubprocess
        );
        if (foundIndex !== -1) {
          targetIndex = foundIndex;
        }
      }
  
      // Validar que el subproceso objetivo existe
      const targetSubprocess = filteredSubprocesos[targetIndex];
      if (!targetSubprocess || !targetSubprocess.subproceso) {
        console.error('Subproceso objetivo no válido');
        return;
      }
  
      // Filtrar agrupadores válidos
      const agrupadoresForSubprocess = allAgrupadores.filter(
        agrupador => agrupador && agrupador.subproceso === targetSubprocess.subproceso
      );
  
      // Actualizar estados
      setActiveSubprocess(targetIndex);
      setCurrentAgrupadores(agrupadoresForSubprocess);
  
      // Guardar en localStorage solo si es válido
      if (targetSubprocess.subproceso) {
        localStorage.setItem('selectedSubprocess', targetSubprocess.subproceso);
      }
    } catch (error) {
      console.error('Error al inicializar subproceso:', error);
    }
  }, [filteredSubprocesos, allAgrupadores]);
  // Efecto para guardar el subproceso seleccionado


  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);

  const resetSubprocessState = () => {
    setActiveSubprocess(0);
    setCurrentAgrupadores([]);
    localStorage.removeItem('selectedSubprocessId');
  };


  const loadProcessData = async (processName: string) => {
    setIsLoading(true);
  
    if (processName !== previousProcessRef.current) {
      if (previousProcessRef.current !== null) {
        resetSubprocessState();
      }
    } else {
      setIsLoading(false);
      return;
    }
  
    if (processName === "") {
      resetState();
      setIsLoading(false);
      return;
    }
  
    const selectedProcess = processes.find(p => p.nombreProceso === processName);
    if (selectedProcess) {
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      setSelectedCodigoProyecto(selectedProcess.codigo);
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Datos recibidos:', JSON.stringify(data, null, 2));

        // Filtrar solo los subprocesos con agrupadores habilitados
        const fetchedSubprocesos: Subproceso[] = data.subprocesos
          .filter((sp: any) => sp.agrupadores.some((agr: any) => agr.enabled))
          .map((sp: any) => ({
            id: sp.id,
            subproceso: sp.nombre,
            color: sp.color || getDefaultColor(sp.id)
          }));
        setSubprocesos(fetchedSubprocesos);
  
        // Procesar los agrupadores
        const allAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: any, spIndex: number) =>
          subproceso.agrupadores
            .filter((agrupador: any) => agrupador.enabled)
            .map((agrupador: any, agrIndex: number) => ({
              id: agrupador.id || (spIndex * 1000 + agrIndex),
              nombre: agrupador.nombre,
              start: dateUtils.parseLocalDate(getEarliestStartDate(agrupador.tareas)),
              end: dateUtils.parseLocalDate(getLatestEndDate(agrupador.tareas)),      
              progress: calculateAverageProgress(agrupador.tareas),
              subproceso: subproceso.nombre,
              enabled: agrupador.enabled,
              descriptor: agrupador.descriptor,
              organismo: agrupador.organismo,
              tareas: agrupador.tareas.map((tarea: any) => ({
                ...tarea,
                nombre: tarea.nombre,
                responsable: tarea.responsable,
                progress: tarea.progreso,
                start: tarea.fecha_inicio,
                end: tarea.fecha_termino,
                duracion: tarea.duracion,
                organismo: tarea.organismo,
                enabled: tarea.enabled,
                isClosed: Boolean(tarea.isClosed),
                dependencia: tarea.dependencia,
                subtasks: tarea.subtasks ? tarea.subtasks.map((subtask: any) => ({
                  id: subtask.id,
                  name: subtask.nombre,
                  responsible: subtask.responsable,
                  progress: subtask.progress,
                  start: subtask.start,
                  end: subtask.end,
                  duration: subtask.duracion,
                  organismo: tarea.organismo,
                  enabled: subtask.enabled,
                  type: subtask.tipo,
                  resolutionType: subtask.resolucion_tipo,
                  orden: subtask.orden,
                  comments: subtask.comments || '',
                  followUpDate: subtask.followUpDate || '',
                  followUp: Boolean(subtask.followUp),
                  isClosed: Boolean(subtask.isClosed),
                  delayInDays: 0,
                  semaphoreStatus: SemaphoreStatus.Gray
                })) : []
              })),
              orden: agrupador.orden
            }))
        );
  
        //console.log(JSON.stringify(allAgrupadores))
        setAllAgrupadores(allAgrupadores);
  
        // Filtrar los subprocesos que tienen agrupadores
        const filteredSubs = fetchedSubprocesos.filter(subproceso => 
          allAgrupadores.some(agrupador => agrupador.subproceso === subproceso.subproceso)
        );
        setFilteredSubprocesos(filteredSubs);
  
        // Ahora el useEffect se encargará de inicializar los datos correctamente
        setIsProcessLoaded(true);
  
      } catch (error) {
        console.error('Error al cargar el proceso:', error);
        resetState();
      }
    }
    previousProcessRef.current = processName;
    setIsLoading(false);
  };
  
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  const getEarliestStartDate = (tareas: Task[]): string => {
    return tareas.reduce((earliest, tarea) => 
      tarea.start < earliest ? tarea.start : earliest
    , tareas[0]?.start || '');
  };

  const getLatestEndDate = (tareas: Task[]): string => {
    return tareas.reduce((latest, tarea) => 
      tarea.end > latest ? tarea.end : latest
    , tareas[0]?.end || '');
  };

  const calculateAverageProgress = (tareas: Task[]): number => {
    if (tareas.length === 0) return 0;
    const totalCompletedTasks = tareas.reduce((sum, tarea) => sum + (tarea.isClosed ? 1 : 0), 0);
    return Math.round((totalCompletedTasks / tareas.length) * 100);
  };

  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };
  
  const calculateDaysDelayed = (task: Task): number => {
    const endDate = new Date(task.end);
    const today = new Date();
    return Math.max(0, Math.floor((today.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)));
  };
  
  const calculateGroupProgress = (task: Task): number => {
    const agrupador = currentAgrupadores.find(a => a.tareas.some(t => t.id === task.id));
    return agrupador ? agrupador.progress : 0;
  };
  
  
  const allTasks = currentAgrupadores.flatMap(agrupador => agrupador.tareas);

  const daysDelayed = selectedTask ? calculateDaysDelayed(selectedTask) : 0;

  const inputColor = filteredSubprocesos[activeSubprocess]?.color || subprocessColor;
  const agrupadorId = allAgrupadores.find(agrupador => 
    agrupador.tareas.some(t => t.id === selectedTask?.id)
  )?.id || 0; // Devuelve 0 si no se encuentra el agrupador
  
  const groupProgress = selectedTask ? calculateGroupProgress(selectedTask) : 0;


const handleSubprocessChange = (index: number) => {
  const selectedSubprocess = filteredSubprocesos[index];
  if (!selectedSubprocess) return;

  // Obtener agrupadores para el subproceso seleccionado
  const agrupadoresForSubprocess = allAgrupadores.filter(
    agrupador => agrupador.subproceso === selectedSubprocess.subproceso
  );

  // Actualizar estados
  setActiveSubprocess(index);
  setCurrentAgrupadores(agrupadoresForSubprocess);

  // Guardar en localStorage
  localStorage.setItem('selectedSubprocess', selectedSubprocess.subproceso);
};

  // Mantiene las columnas originales pero ahora puede usar los datos de la BD
  const getTaskStatus = (tarea: Task): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
    if (tarea.isClosed) return 'success';
    if (tarea.progress > 0) return 'inProgress';
    return 'pending';
  };

  const tableVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      y: -50, 
      transition: { 
        duration: 0.3,
        when: "afterChildren",
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };
/*
  useEffect(() => {
    const checkAndCloseMainTasks = async () => {
      const tramitacionTasks = currentAgrupadores.flatMap(ag => 
        ag.tareas.filter(t => 
          t.nombre === 'TRAMITACIÓN' && 
          Array.isArray(t.subtasks) && // ✅ Verificamos que subtasks sea un array
          t.subtasks.length > 0 && 
          !t.isClosed
        )
      );
  
      for (const task of tramitacionTasks) {
        const allSubtasksClosed = task.subtasks?.every(subtask => subtask.isClosed) ?? false;
        
        if (allSubtasksClosed) {
          const todayString = dateUtils.getTodayString();
  
          const updatedTask = {
            ...task,
            isClosed: true,
            progress: 100,
            end: todayString
          };
  
          try {
            await updateTaskInDatabase(updatedTask);
            handleTaskChange(updatedTask);
            
          } catch (error) {
            console.error('Error closing TRAMITACIÓN task automatically:', error);
          }
        }
      }
    };
  
    checkAndCloseMainTasks();
  }, [currentAgrupadores, handleTaskChange]);*/
  
const handleTaskChange = useCallback(async (updatedTask: Task) => {
  try {
    const today = dateUtils.getTodayLocal();
    const taskEndDate = dateUtils.parseLocalDate(updatedTask.end);
    
    // Si la tarea ya pasó su fecha de finalización y aún está abierta, calcular retraso
    if (taskEndDate < today && !updatedTask.isClosed) {
      const delayInDays = Math.ceil((today.getTime() - taskEndDate.getTime()) / (1000 * 3600 * 24));
      updatedTask.delayInDays = delayInDays;
    }

    // Verificar si TRAMITACION debe cerrarse automáticamente
    if (updatedTask.nombre === 'TRAMITACIÓN' && !updatedTask.isClosed) {
      const allSubtasksClosed = Array.isArray(updatedTask.subtasks) && updatedTask.subtasks.length > 0
        ? updatedTask.subtasks.every(subtask => subtask.isClosed)
        : false;

      if (allSubtasksClosed) {
        const todayString = dateUtils.getTodayString();
        
        // Cerrar la tarea principal y todas sus subtareas
        if (updatedTask.subtasks) {
          updatedTask.subtasks = updatedTask.subtasks.map(subtask => ({
            ...subtask,
            isClosed: true,
            progress: 100,
            end: todayString
          }));
        }
        
        updatedTask = {
          ...updatedTask,
          isClosed: true,
          progress: 100,
          end: todayString
        };

        // Actualizar todas las tareas dependientes
        const allTasks = currentAgrupadores.flatMap(ag => ag.tareas);
        const dependentTasks = allTasks.filter(t => {
          const depValue = typeof t.dependencia === 'string' 
            ? parseInt(t.dependencia) 
            : t.dependencia;
          return depValue === (updatedTask.orden + 1);
        });

        for (const depTask of dependentTasks) {
          if (!depTask.isClosed) {
            const nextDay = dateUtils.addDaysToDate(todayString, 0);
            const endDate = dateUtils.addDaysToDate(nextDay, depTask.duracion * 7);
      
            const enabledTask: Task = {
              ...depTask,
              start: nextDay,
              end: endDate,
              semaphoreStatus: SemaphoreStatus.Green,
              enabled: true
            };

            // Si la tarea dependiente es TRAMITACIÓN, actualizar sus subtareas
            if (enabledTask.nombre === 'TRAMITACIÓN' && enabledTask.subtasks) {
              const subtaskDuration = Math.ceil(
                (dateUtils.parseLocalDate(endDate).getTime() - 
                 dateUtils.parseLocalDate(nextDay).getTime()) / 
                (1000 * 60 * 60 * 24 * enabledTask.subtasks.length)
              );

              enabledTask.subtasks = enabledTask.subtasks.map((subtask, index) => {
                const subtaskStart = dateUtils.addDaysToDate(nextDay, index * subtaskDuration);
                const subtaskEnd = dateUtils.addDaysToDate(subtaskStart, subtaskDuration);
                return {
                  ...subtask,
                  start: subtaskStart,
                  end: subtaskEnd,
                  enabled: true
                };
              });
             
            } 
            await updateTaskInDatabase(enabledTask);
            
            // Actualizar el estado local de las tareas dependientes
            setCurrentAgrupadores(prev => prev.map(ag => ({
              ...ag,
              tareas: ag.tareas.map(t => t.id === enabledTask.id ? enabledTask : t)
            })));
            setSelectedTask(enabledTask); 
          }
        
        }
   
        await updateTaskInDatabase(updatedTask);
      }
    }
 
    // Actualizar fechas de TRAMITACION cuando cambian las subtareas
    if (updatedTask.nombre === 'TRAMITACIÓN' && updatedTask.subtasks && updatedTask.subtasks.length > 0) {
      const latestSubtaskEnd = updatedTask.subtasks.reduce((latest, subtask) => {
        const subtaskEndDate = dateUtils.parseLocalDate(subtask.end);
        const currentLatest = dateUtils.parseLocalDate(latest);
        return subtaskEndDate > currentLatest ? subtask.end : latest;
      }, updatedTask.subtasks[0].end); // Comenzar desde la primera subtarea

      // Encontrar la fecha de inicio más temprana
      const earliestSubtaskStart = updatedTask.subtasks.reduce((earliest, subtask) => {
        const subtaskStartDate = dateUtils.parseLocalDate(subtask.start);
        const currentEarliest = dateUtils.parseLocalDate(earliest);
        return subtaskStartDate < currentEarliest ? subtask.start : earliest;
      }, updatedTask.subtasks[0].start);

      // Actualizar tanto la fecha de inicio como la de fin
      if (latestSubtaskEnd !== updatedTask.end || earliestSubtaskStart !== updatedTask.start) {
        updatedTask = {
          ...updatedTask,
          start: earliestSubtaskStart,
          end: latestSubtaskEnd
        };

        // Actualizar la tarea TRAMITACION en la base de datos
        await updateTaskInDatabase(updatedTask);
      }
  

    }

    const newAgrupadores = currentAgrupadores.map(agrupador => ({
      ...agrupador,
      tareas: agrupador.tareas.map(tarea => 
        tarea.id === updatedTask.id ? updatedTask : tarea
      )
    }));

    setCurrentAgrupadores(newAgrupadores);
    
    // Add this line to ensure selectedTask stays in sync
    setSelectedTask(updatedTask);

    if (onUpdateTask) {
      onUpdateTask(updatedTask);
    }

  } catch (error) {
    console.error('Error updating task:', error);
    toast.error('Error al actualizar la tarea');
    setCurrentAgrupadores(currentAgrupadores);
  }
}, [currentAgrupadores, onUpdateTask, updateTaskInDatabase, setSelectedTask]);
  const containerVariants = {
    expanded: {
      height: "auto",
      opacity: 1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    collapsed: {
      height: 0,
      opacity: 0,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white w-full rounded-lg p-3 mx-auto"
    >
      {isLoading ? (
        <div>Cargando...</div>
      ) : isProcessLoaded && filteredSubprocesos.length > 0 ? (
        <>
          <div className="flex mb-8 ml-8 mt-3">
            {filteredSubprocesos.map((subprocess, index) => (
              <SubprocessTab
                key={index}
                label={subprocess.subproceso}
                isActive={activeSubprocess === index}
                onClick={() => handleSubprocessChange(index)}
                color={subprocess.color}
                index={index}
                totalTabs={filteredSubprocesos.length}
                activeIndex={activeSubprocess}
              />
            ))}
          </div>
          
          {currentAgrupadores.length > 0 ? (
  <Reorder.Group
    as="div"
    axis="x"
    values={currentAgrupadores.map(ag => ag.id.toString())}
    onReorder={(newOrder) => {
      const reorderedAgrupadores = newOrder.map(id => 
        currentAgrupadores.find(ag => ag.id.toString() === id)!
      );
      setCurrentAgrupadores(reorderedAgrupadores);
    }}
  
    className="overflow-x-auto w-full"
  >
    
   <ColumnsContainer
      agrupadores={currentAgrupadores}
      onReorder={setCurrentAgrupadores}
      color={filteredSubprocesos[activeSubprocess]?.color || subprocessColor}
      onTaskClick={handleTaskClick}
      onChangeTask={handleTaskChange}
      processName={selectedNombreProyecto}  
      subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso}
      agrupadorName={currentAgrupadores[0]?.nombre}
    />
  </Reorder.Group>
) : (
  <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
)}
        </>
      ) : (
        <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
      )}
       {/* Agregar el modal */}
       {selectedTask && (
      <TaskModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal}        
        task={selectedTask}
        onChangeTask={handleTaskChange} // Pasamos la función aquí
        backgroundColor={inputColor}
        allTasks={allTasks}
        agrupadorId={selectedTask.id}
        daysDelayed={daysDelayed}
        groupProgress={groupProgress}
        processName={selectedNombreProyecto}
        subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso}
        agrupadorName={currentAgrupadores.find(ag => 
          ag.tareas.some(t => t.id === selectedTask.id)
        )?.nombre}
      />
    )}
    </motion.div>
  );
};

enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
  
}
  
  interface SubprocessTabProps {
    label: string;
    isActive: boolean;
    onClick: () => void;
    color: string;
    index: number;
    totalTabs: number;
    activeIndex: number;
  }
  
  const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
    label, 
    isActive, 
    onClick, 
    color, 
    index, 
    totalTabs
  }) => {
    const [scale, setScale] = useState(1);
    const activeScale = 1.185;
    const fixedOffset = 20;
  
    useEffect(() => {
      if (isActive) {
        setScale(activeScale);
      } else {
        setScale(1);
      }
    }, [isActive]);
  
    const buttonStyle: React.CSSProperties = {
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      padding: '0 30px 0 30px',
      fontSize: '14px',
      fontWeight: 'medium',
      color: isActive ? '#ffffff' : '#115E59',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      overflow: 'visible',
      transition: 'all 0.3s',
      marginRight: `-${fixedOffset}px`,
      zIndex: isActive ? totalTabs : index,
      whiteSpace: 'nowrap',
    };
  
    const shapeStyle: React.CSSProperties = {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: color,
      opacity: isActive ? 1 : 0.3,
      transition: 'opacity 0.3s',
      clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
    };
  
    return (
      <motion.button
        onClick={onClick}
        className="relative"
        animate={{ 
          scale,
          zIndex: isActive ? totalTabs : index,
        }}
        whileHover={{ scale: scale * 1.02 }}
        whileTap={{ scale: scale * 0.98 }}
        transition={{ duration: 0.2 }}
        style={buttonStyle}
      >
        <div style={shapeStyle} />
        <span className="relative z-10 px-2">{label}</span>
      </motion.button>
    );
  };
  
  interface ProjectTimelineProps {
    selectedProcess: string;
    setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
    processes: Process[];
    subprocessColor: string;
    onUpdateTask?: (updatedTask: Task) => void;

  }
  
  export default PlanningPanel;