import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import GearLoader from './GearLoader';

interface PublicRouteProps {
  children: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const token = localStorage.getItem('authToken');

        if (!token) {
          setTimeout(() => {
            setIsAuthenticated(false);
            setIsLoading(false);
          }, 300); // Simula carga de 3 segundos
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/verify_session.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error('Respuesta del servidor inválida');
        }

        const data = await response.json();

        setTimeout(() => {
          if (data.success) {
            if (data.token) {
              localStorage.setItem('authToken', data.token);
            }

            sessionStorage.setItem('userId', data.user.id);
            sessionStorage.setItem('firstName', data.user.firstname);
            sessionStorage.setItem('lastName', data.user.lastname);
            sessionStorage.setItem('email', data.user.email);
            sessionStorage.setItem('isAdmin', data.user.is_admin.toString());
            sessionStorage.setItem('gender', data.user.gender);
            sessionStorage.setItem('departamento', data.user.departamento);

            setIsAuthenticated(true);
          } else {
            throw new Error(data.message || 'Sesión inválida');
          }
          setIsLoading(false);
        }, 300); // Simula carga de 3 segundos
      } catch (error) {
        console.error('Error verificando autenticación:', error);
        setTimeout(() => {
          localStorage.removeItem('authToken');
          sessionStorage.clear();
          setIsAuthenticated(false);
          setIsLoading(false);
        }, 300);
      }
    };

    verifyAuth();
  }, [location.pathname]);

  if (isLoading) {
    console.log('PublicRoute: Loading state is true');
    return (
      <div className="flex justify-center items-center h-screen">
        <GearLoader />
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/ui" state={location.state} replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
