
import React, { useState, ChangeEvent } from 'react';
import { 
  PieChart, 
  Pie, 
  Cell, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip
} from 'recharts';


import { Card, CardHeader, CardContent, CardTitle } from '../ui/card';


// Type Definitions
type RiskLevel = 'BAJO' | 'MEDIO' | 'ALTO';
type RiskStatus = 'Abierto' | 'Cerrado';

interface RiskType {
  id: number;
  name: string;
  label: string;
  color: string;
}

interface Risk {
  id: number;
  type: string;
  riskIdentified: string;
  description: string;
  level: RiskLevel;
  strategy: string;
  normative: string;
  responsible: string;
  status: RiskStatus;
  updateDate: string;
  creationDate: string;
  resultStrategy?: string;
}

// Predefined Constants with Type Annotations
const RISK_TYPES: RiskType[] = [
  { id: 1, name: 'Urbanistico', label: 'Urbanístico', color: 'rgb(59, 130, 246)' },
  { id: 2, name: 'MedioAmbiente', label: 'M. Ambiente', color: 'rgb(34, 197, 94)' },
  { id: 3, name: 'Civil', label: 'Civil', color: 'rgb(249, 115, 22)' },
  { id: 4, name: 'Sectorial', label: 'Sectorial', color: 'rgb(168, 85, 247)' },
  { id: 5, name: 'Otro', label: 'Otro', color: 'rgb(107, 114, 128)' }
];

const RISK_LEVELS: RiskLevel[] = ['BAJO', 'MEDIO', 'ALTO'];
const STATUS_OPTIONS: RiskStatus[] = ['Abierto', 'Cerrado'];

// RiskGauge Component
const RiskGauge: React.FC<{ level: RiskLevel }> = ({ level }) => {  const needlePosition = {
    'BAJO': '20',
    'MEDIO': '80',
    'ALTO': '160'
  };

  return (
    <svg viewBox="0 0 200 120" className="w-32 h-32">
      <path d="M 20 100 A 80 80 0 0 1 180 100" fill="none" stroke="#e5e5e5" strokeWidth="20" strokeLinecap="round"/>
      <path d="M 20 100 A 80 80 0 0 1 60 40" fill="none" stroke="#22c55e" strokeWidth="20" strokeLinecap="round"/>
      <path d="M 60 40 A 80 80 0 0 1 100 28" fill="none" stroke="#facc15" strokeWidth="20" strokeLinecap="round"/>
      <path d="M 100 28 A 80 80 0 0 1 140 40" fill="none" stroke="#f97316" strokeWidth="20" strokeLinecap="round"/>
      <path d="M 140 40 A 80 80 0 0 1 180 100" fill="none" stroke="#ef4444" strokeWidth="20" strokeLinecap="round"/>
      <path d={`M 100 100 L ${needlePosition[level]} 60`} stroke="black" strokeWidth="4" strokeLinecap="round"/>
      <circle cx="100" cy="100" r="8" fill="white" stroke="black" strokeWidth="2" />
      <text x="100" y="85" textAnchor="middle" className="text-lg font-bold">{level}</text>
    </svg>
  );
};

interface RiskAccordionItemProps {
  risk: Risk;
  index: number;
  isEditing: boolean;
  onEdit: (id: number) => void;
}
// RiskAccordionItem Component
const RiskAccordionItem: React.FC<RiskAccordionItemProps> = ({ 
  risk, 
  index, 
  isEditing, 
  onEdit 
}) => {  const [isExpanded, setIsExpanded] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const riskType = RISK_TYPES.find(t => t.name === risk.type) || RISK_TYPES[4];

  return (
    <div 
      className="border rounded-lg shadow-sm overflow-hidden relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="p-4 border-t-4" style={{ borderColor: riskType.color }}>
        <div className="flex justify-between items-start">
          <div className="flex items-start space-x-4">
            <span className="w-6 h-6 flex items-center justify-center flex-shrink-0 mt-1 rounded-full text-white" 
                  style={{ backgroundColor: riskType.color }}>
              {index + 1}
            </span>
            <div>
              <h3 className="text-lg font-semibold" style={{ color: riskType.color }}>
                {riskType.label} - {risk.riskIdentified}
              </h3>
              <div className="text-sm text-gray-600 mt-1">
                <p><span className="font-medium">Responsable:</span> {risk.responsible}</p>
                <p><span className="font-medium">Fecha de Creación:</span> {new Date(risk.creationDate).toLocaleDateString()}</p>
                <p><span className="font-medium">Fecha de Revisión:</span> {new Date(risk.updateDate).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <RiskGauge level={risk.level} />
            <span className={`px-2 py-1 text-sm rounded ${risk.status === 'Abierto' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {risk.status}
            </span>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            >
              <svg
                className={`w-6 h-6 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="p-4 border-t">
          <div className="grid grid-cols-4 gap-4">
            <div className="space-y-2">
              <h4 className="font-medium text-gray-700 text-lg border-b pb-2">Descripción</h4>
              <div className="text-gray-600 whitespace-pre-line">{risk.description}</div>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium text-gray-700 text-lg border-b pb-2">Estrategia</h4>
              <div className="text-gray-600 whitespace-pre-line">{risk.strategy}</div>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium text-gray-700 text-lg border-b pb-2">Respaldo normativo</h4>
              <div className="text-gray-600 whitespace-pre-line">{risk.normative}</div>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium text-gray-700 text-lg border-b pb-2">Resultado Estrategia</h4>
              <div className="text-gray-600">
                {risk.status === 'Cerrado' ? risk.resultStrategy || 'Sin resultado registrado' : 'Pendiente de cierre'}
              </div>
            </div>
          </div>
        </div>
      )}
      
      {isHovered && !isEditing && (
        <button
          onClick={() => onEdit(risk.id)}
          className="absolute right-4 top-4 text-blue-500 hover:text-blue-600 px-3 py-1 text-sm border rounded hover:bg-blue-50 transition-colors"
          style={{ borderColor: riskType.color, color: riskType.color }}
        >
          Editar
        </button>
      )}
    </div>
  );
};

interface RiskFormProps {
  initialData?: Risk;  // Changed from Partial<Risk>
  onSave: (risk: Risk) => void;
  onCancel: () => void;
}

// RiskForm Component
const RiskForm: React.FC<RiskFormProps> = ({ 
  initialData, 
  onSave, 
  onCancel 
}) => {  

  const initialRiskData: Risk = {
    id: Date.now(),
    type: '',
    riskIdentified: '',
    description: '',
    level: 'BAJO',
    strategy: '',
    normative: '',
    responsible: '',
    status: 'Abierto',
    updateDate: new Date().toISOString().split('T')[0],
    creationDate: new Date().toISOString().split('T')[0],
    resultStrategy: ''
  };

  
  const [formData, setFormData] = useState<Risk>(initialData || initialRiskData);


  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const isFieldInvalid = (fieldName: keyof Risk): boolean => {
    if (fieldName === 'resultStrategy') {
      return formData.status === 'Cerrado' && 
             (!formData[fieldName] || 
              (typeof formData[fieldName] === 'string' && !formData[fieldName]!.trim()));
    }
  
    const value = formData[fieldName as keyof Risk];
    return !value || 
           (typeof value === 'string' && !value.trim());
  };

  const getFieldClassName = (baseClassName: string, fieldName: keyof Risk): string => {
    if (fieldName === 'resultStrategy' && formData.status !== 'Cerrado') {
      return baseClassName;
    }
    return `${baseClassName} ${isFieldInvalid(fieldName) ? 'border-red-500 bg-red-50' : ''}`;
  };

  const handleSave = () => {
    const fieldsToValidate: (keyof Risk)[] = ['type', 'riskIdentified', 'description', 'strategy', 'normative', 'responsible'];
    if (formData.status === 'Cerrado') {
      fieldsToValidate.push('resultStrategy');
    }
    
    const hasInvalidFields = fieldsToValidate.some(field => isFieldInvalid(field));
    if (hasInvalidFields) {
      alert('Por favor complete todos los campos requeridos');
      return;
    }
    
    onSave(formData);
  };


  return (
    <div className="border rounded-lg p-6 bg-white">
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className={getFieldClassName('w-full p-2 border rounded-md', 'type')}
            required
          >
            <option value="">Seleccione tipo</option>
            {RISK_TYPES.map(type => (
              <option key={type.id} value={type.name}>{type.label}</option>
            ))}
          </select>
          <input
            type="text"
            name="riskIdentified"
            value={formData.riskIdentified}
            onChange={handleChange}
            className={getFieldClassName('w-full p-2 border rounded-md', 'riskIdentified')}
            placeholder="Riesgo Identificado"
            required
          />
        </div>

        <div className="grid grid-cols-3 gap-4">
          <input
            type="text"
            name="responsible"
            value={formData.responsible}
            onChange={handleChange}
            className={getFieldClassName('w-full p-2 border rounded-md', 'responsible')}
            placeholder="Responsable"
            required
          />
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label className="block text-sm text-gray-600">Fecha de Revisión</label>
              <input
                type="date"
                name="updateDate"
                value={formData.updateDate}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600">Fecha de Creación</label>
              <input
                type="date"
                name="creationDate"
                value={formData.creationDate}
                className="w-full p-2 border rounded-md bg-gray-50"
                disabled
              />
            </div>
          </div>
          <div className="flex gap-4">
            <select
              name="level"
              value={formData.level}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
            >
              {RISK_LEVELS.map(level => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
            >
              {STATUS_OPTIONS.map(status => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Descripción</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className={getFieldClassName('w-full p-2 border rounded-md h-40', 'description')}
              required
            />
            {isFieldInvalid('description') && (
              <span className="text-red-500 text-sm mt-1">Este campo es requerido</span>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Estrategia</label>
            <textarea
              name="strategy"
              value={formData.strategy}
              onChange={handleChange}
              className={getFieldClassName('w-full p-2 border rounded-md h-40', 'strategy')}
              required
            />
            {isFieldInvalid('strategy') && (
              <span className="text-red-500 text-sm mt-1">Este campo es requerido</span>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Respaldo normativo</label>
            <textarea
              name="normative"
              value={formData.normative}
              onChange={handleChange}
              className={getFieldClassName('w-full p-2 border rounded-md h-40', 'normative')}
              required
            />
            {isFieldInvalid('normative') && (
              <span className="text-red-500 text-sm mt-1">Este campo es requerido</span>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Resultado Estrategia {formData.status === 'Cerrado' && <span className="text-red-500">*</span>}
            </label>
            <textarea
              name="resultStrategy"
              value={formData.resultStrategy}
              onChange={handleChange}
              className={getFieldClassName('w-full p-2 border rounded-md h-40', 'resultStrategy')}
              placeholder={formData.status === 'Cerrado' 
                ? "Debe registrar el resultado para cerrar el riesgo"
                : "El resultado será requerido al cerrar el riesgo"}
              required={formData.status === 'Cerrado'}
            />
            {isFieldInvalid('resultStrategy') && formData.status === 'Cerrado' && (
              <span className="text-red-500 text-sm mt-1">Este campo es requerido para cerrar el riesgo</span>
            )}
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancelar
          </button>
          <button
          type="button"
          onClick={handleSave}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          {initialData ? 'Actualizar' : 'Crear'} Riesgo
        </button>
        </div>
      </div>
    </div>
  );
};


interface RiskSummaryProps {
  risks: Risk[];
  onFilterChange: (filterType: string | null) => void;
  activeFilter: string | null;
}

const RiskSummary: React.FC<RiskSummaryProps> = ({ 
  risks, 
  onFilterChange, 
  activeFilter 
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const openRisks = risks.filter(r => r.status === 'Abierto').length;
  const closedRisks = risks.filter(r => r.status === 'Cerrado').length;
  const total = openRisks + closedRisks;
  
  const pieData = [
    { name: 'Abiertos', value: openRisks, percentage: (openRisks/total * 100).toFixed(0) },
    { name: 'Cerrado', value: closedRisks, percentage: (closedRisks/total * 100).toFixed(0) }
  ];

  const risksByType = RISK_TYPES.map(type => ({
    name: type.label,
    Abiertos: risks.filter(r => r.type === type.name && r.status === 'Abierto').length,
    Cerrados: risks.filter(r => r.type === type.name && r.status === 'Cerrado').length,
    color: type.color
  }));

  return (
    <Card className="mb-8">
      <CardHeader className="bg-gray-50 hover:bg-gray-100 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex items-center justify-between w-full">
          <CardTitle className="text-xl text-red-600">Resumen Matriz de Riesgo</CardTitle>
          <svg className={`w-6 h-6 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </CardHeader>
      {isExpanded && (
        <CardContent className="p-6">
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-4 text-center">Resumen de Riesgos</h3>
              <div className="flex justify-center">
                <PieChart width={300} height={200}>
                  <Pie
                    data={pieData}
                    cx={150}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={0}
                    dataKey="value"
                    label={({ name, percentage }) => (
                      <text 
                        x={name === 'Abiertos' ? 220 : 80} 
                        y={name === 'Abiertos' ? 70 : 160} 
                        textAnchor="middle" 
                        fill={name === 'Abiertos' ? '#3B82F6' : '#F97316'}
                        className="text-sm"
                      >
                        {`${name} ${percentage}%`}
                      </text>
                    )}
                    labelLine={false}
                  >
                    <Cell fill="#3B82F6" />
                    <Cell fill="#F97316" />
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-4 text-center">Clasificación tipo Riesgo</h3>
              <BarChart width={500} height={200} data={risksByType} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  interval={0}
                  tick={({ x, y, payload }) => {
                    const riskType = RISK_TYPES.find(t => t.label === payload.value);
                    return (
                      <text 
                        x={x} 
                        y={y}
                        dy={20}
                        dx={-10}
                        fill={riskType?.color}
                        textAnchor="end"
                        transform={`rotate(-45, ${x}, ${y})`}
                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                      >
                        {payload.value}
                      </text>
                    );
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const riskType = RISK_TYPES.find(t => t.label === label);
                    return (
                      <div className="bg-white p-2 border rounded shadow">
                        <p className="font-bold" style={{ color: riskType?.color }}>{label}</p>
                        <p className="flex items-center gap-2" style={{ color: riskType?.color }}>
                          <span className="w-3 h-3" style={{ backgroundColor: riskType?.color }}></span>
                          Abiertos: {payload[0].value} riesgos
                        </p>
                        <p className="flex items-center gap-2" style={{ color: riskType?.color }}>
                          <span className="w-3 h-3 border" style={{ borderColor: riskType?.color }}></span>
                          Cerrados: {payload[1].value} riesgos
                        </p>
                      </div>
                    );
                  }
                  return null;
                }} />
                <Bar dataKey="Abiertos" stackId="a">
                  {risksByType.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
                <Bar dataKey="Cerrados" stackId="a">
                  {risksByType.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill="white" stroke={entry.color} strokeWidth={2} />
                  ))}
                </Bar>
              </BarChart>
            </div>
          </div>
          
          {/* Filtros de tipo de riesgo */}
          <div className="mt-6 flex flex-wrap gap-4 justify-center border-t pt-4">
            {RISK_TYPES.map(type => (
              <button
                key={type.id}
                onClick={() => onFilterChange(type.name === activeFilter ? null : type.name)}
                className={`flex items-center gap-2 px-3 py-1 rounded transition-colors ${
                  type.name === activeFilter ? 'ring-2' : 'hover:bg-gray-100'
                }`}
                style={{ 
                  borderColor: type.color,
                  color: type.color,
                  backgroundColor: type.name === activeFilter ? `${type.color}20` : 'transparent'
                }}
              >
                <div className="w-4 h-4" style={{ backgroundColor: type.color }}></div>
                <span className="text-sm font-medium">{type.label}</span>
              </button>
            ))}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

const RiskDashboard = () => {
  const [risks, setRisks] = useState<Risk[]>([{
    id: 1,
    type: 'Urbanistico',
    riskIdentified: 'Superficie predial inferior a IPT',
    description: '1. El proyecto debe contar con la autorización conforme al Art. 55° L.G.U.C.',
    level: 'BAJO' as RiskLevel,  // Type assertion
    strategy: '1. Requerir informe favorable de la Seremi MINVU.',
    normative: 'Ley 19.537 Copropiedad Inmobiliaria',
    responsible: 'Juan Pérez',
    status: 'Abierto' as RiskStatus,  // Type assertion
    updateDate: '2025-02-11',
    creationDate: '2025-02-01',
    resultStrategy: ''
  }]);


  const [showAddForm, setShowAddForm] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const filteredRisks = activeFilter 
    ? risks.filter(risk => risk.type === activeFilter)
    : risks;

  const handleAddRisk = () => setShowAddForm(true);
  const handleCancelAdd = () => setShowAddForm(false);
  const handleEdit = (riskId: number) => {
    setEditingId(riskId);
  };
  const handleCancelEdit = () => setEditingId(null);

const handleFilterChange = (filterType: string | null) => {
  setActiveFilter(filterType);
};

  const handleSaveNewRisk = (newRisk: Risk) => {
    const riskWithId: Risk = {
      ...newRisk,
      id: Date.now(),
      creationDate: new Date().toISOString().split('T')[0]
    };
    setRisks(prevRisks => [...prevRisks, riskWithId]);
    setShowAddForm(false);
  };
  
  const handleSaveEdit = (editedRisk: Risk) => {
    setRisks(prevRisks => 
      prevRisks.map(risk => risk.id === editedRisk.id ? editedRisk : risk)
    );
    setEditingId(null);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <RiskSummary 
        risks={risks}
        onFilterChange={handleFilterChange}
        activeFilter={activeFilter}
      />
      
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Matriz de Riesgos</h2>
        <button
          onClick={handleAddRisk}
          className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          Añadir Riesgo
        </button>
      </div>
      
      <div className="space-y-4">
        {filteredRisks.map((risk, index) => (
          editingId === risk.id ? (
            <RiskForm
              key={risk.id}
              initialData={risk}
              onSave={handleSaveEdit}
              onCancel={handleCancelEdit}
            />
          ) : (
            <RiskAccordionItem
              key={risk.id}
              risk={risk}
              index={index}
              isEditing={false}
              onEdit={handleEdit}
            />
          )
        ))}
        {showAddForm && (
          <RiskForm
            onSave={handleSaveNewRisk}
            onCancel={handleCancelAdd}
          />
        )}
      </div>
    </div>
  );
};

export default RiskDashboard;