// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SimagiNewsComponent from './pages/SimagiNewsComponent';
import Login from './pages/Login';
import SimagiInteractivePreview from './pages/SimagiInterface';
import ResetPassword from './pages/ResetPassword';
import RequestPassword from './pages/RequestPasswordReset';
import Register from './pages/Register';
import PrivateRoute from './components/PrivateRoute'; // Importa el componente de ruta privada
import PublicRoute from './components/PublicRoute'; // Importa el componente de ruta pública
import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <Router> {/* Asegúrate de que solo haya un Router en toda la aplicación */}
<Toaster
  position="top-right"
  reverseOrder={false}

/>

      <Routes>
        <Route path="/" element={<SimagiNewsComponent />} />
        <Route path="/mvp" element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
       
        <Route path="/ui" element={
          <PrivateRoute>
            <SimagiInteractivePreview />
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
};

export default App;