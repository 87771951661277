import { AlertCircle, AlertTriangle, ArrowRight, Check, ChevronDown, ChevronsDown, ChevronsUp, ChevronUp, Clock, FileQuestion, FileText, X } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';
import dateUtils from './dateUtils';
import { motion, AnimatePresence } from 'framer-motion';


import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}

interface Task {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  descriptor: string;
  duracion: number;
  organismo: string;
  dependencia: string;
  enabled: boolean;
  isClosed: boolean;
  subtasks?: SubTask[];
}

interface SubTask {
  id: number;
  nombre: string;
  responsible: string;
  progress: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  organismo: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  isClosed: boolean;
  delayInDays: number;
}

interface DataItem {
  name: string;
  agrupador: string;
  tiempoTranscurrido: string;
  plazoTitular: number;
  plazoServicio: number;
  fechaInicio: string;
  fechaTermino: string;
  organismo: string; // Añadido organismo
  task?: Task;
}


interface TableRowProps {
  item: DataItem;
  isExpanded: boolean;
  onToggleExpand: () => void;
  colors: {
    primary: string;
    secondary: string;
  };
}

interface CollapsibleHeaderProps {
  title: string;
  color: string;
  isOpen: boolean;
  onToggle: () => void;
}

interface CollapsibleSectionProps {
  title: string;
  data: DataItem[];
  onTitleChange: (newTitle: string) => void;
}

interface DataChartProps {
  data: DataItem[];
  title: string;
  onTitleChange: (newTitle: string) => void;
}

interface RegistrationProtocolProps {
  selectedProcess: string;
  processes: Process[];
}


const NoTramitacionState = () => (
  <div className="flex flex-col items-center justify-center p-8 bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl border border-gray-200 shadow-sm">
    <AlertCircle className="w-14 h-14 text-amber-500 mb-4" />
    <div className="text-center">
      <h4 className="text-xl font-semibold text-gray-900 mb-2">
        Sin datos de tramitación
      </h4>
      <p className="text-sm text-gray-600">
        No se encontró información de tramitación para este agrupador.
      </p>
    </div>
  </div>
);

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center p-10 bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl border-2 border-dashed border-gray-200">
    <FileQuestion className="w-20 h-20 text-gray-400 mb-6" />
    <div className="text-center">
      <h3 className="text-2xl font-bold text-gray-900 mb-3">
        No hay datos disponibles
      </h3>
      <p className="text-gray-600 max-w-md">
        No se encontraron registros para mostrar en este momento.
      </p>
    </div>
  </div> 
);

const getDefaultColor = (id: number): string => {
  const colors = ['#5DADE2', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
  return colors[id % colors.length];
};

const formatDate = (dateString: string): string => {
  if (!dateString) return 'No disponible';
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  if (isNaN(date.getTime())) return 'No disponible';
  
  const formattedDay = date.getDate().toString().padStart(2, '0');
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  const formattedYear = date.getFullYear();
  
  return `${formattedDay}-${formattedMonth}-${formattedYear}`;
};

const calculateDaysElapsed = (startDate: string, endDate: string, isClosed: boolean): string => {
  if (!startDate) return 'N/A';
  
  const start = dateUtils.parseLocalDate(startDate);
  const today = dateUtils.getTodayLocal();

  // Si la fecha de inicio es futura
  if (start > today) {
    return 'En espera';
  }

  // Determinar la fecha de fin según si está cerrada o no
  let end;
  if (isClosed) {
    // Si está cerrada, usar la fecha de término proporcionada
    if (!endDate) return 'N/A';
    end = dateUtils.parseLocalDate(endDate);
  } else {
    // Si no está cerrada, usar la fecha actual
    end = today;
  }
  
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  
  if (isNaN(start.getTime()) || isNaN(end.getTime())) return 'N/A';
  
  const diffTime = end.getTime() - start.getTime();
  const diffDays = Math.max(1, Math.ceil(diffTime / (1000 * 60 * 60 * 24))); // Asegurar un mínimo de 1 día
  
  return `${diffDays} día${diffDays > 1 ? 's' : ''}`;
};

const calculateDaysBetween = (
  startDate: string,
  endDate: string,
  isClosed: boolean
): number => {
  if (!startDate) return 0;

  const start = dateUtils.parseLocalDate(startDate);
  let end;
  
  if (isClosed) {
    // Si está cerrada, usar la fecha de término proporcionada
    if (!endDate) return 0;
    end = dateUtils.parseLocalDate(endDate);
  } else {
    // Si no está cerrada, usar la fecha actual
    end = new Date();
  }

  if (isNaN(start.getTime()) || isNaN(end.getTime())) return 0;

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  const diffTime = end.getTime() - start.getTime();
  return Math.max(1, Math.ceil(diffTime / (1000 * 60 * 60 * 24))); // Asegurar un mínimo de 1 día
};

const isLightColor = (color: string): boolean => {
  try {
    // Convertir el color a RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    
    // Calcular la luminosidad (algoritmo YIQ)
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return yiq >= 180;
  } catch (e) {
    // Si hay algún error en el formato del color, asumimos que es oscuro
    return false;
  }
};



const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({
  title,
  color,
  isOpen,
  onToggle,
}) => {
  const isLight = isLightColor(color);
  
  return (
    <div 
      style={{ backgroundColor: color }}
      className="p-4 flex justify-between items-center cursor-pointer rounded-t-xl hover:brightness-95 transition-all duration-200"
      onClick={onToggle}
    >
      <h3 className={`font-bold text-lg ${isLight ? 'text-gray-800' : 'text-white'}`}>
        {title}
      </h3>
      {isOpen ? (
        <ChevronUp className={`w-6 h-6 ${isLight ? 'text-gray-600' : 'text-white'}`} />
      ) : (
        <ChevronDown className={`w-6 h-6 ${isLight ? 'text-gray-600' : 'text-white'}`} />
      )}
    </div>
  );
};

const getResponsible = (subtask: SubTask, task: Task, agrupadorOrganismo: string | User[]): string => {
  const organismo = Array.isArray(agrupadorOrganismo) 
    ? agrupadorOrganismo.map(user => user.firstname || "Sin responsable asignado").join(", ") 
    : agrupadorOrganismo;

  if (subtask.tipo === 'OBSERVACIÓN' || 
      (subtask.tipo === 'RESOLUCIÓN' && subtask.resolutionType !== 'DESISTIMIENTO')) {
    return organismo;
  }

  return typeof task.responsible === 'string' 
    ? task.responsible 
    : Array.isArray(task.responsible)
    ? task.responsible.map(user => user.firstname || "Sin responsable asignado").join(", ")
    : "Sin responsable asignado";
};



const ExpandableDetails: React.FC<ExpandableDetailsProps> = ({ task, agrupadorOrganismo, onClose }) => {
  return (
    <tr className="contents">
      <td colSpan={5} className="p-0">
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="border-t bg-gray-50 p-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center space-x-2">
                <Clock className="w-5 h-5 text-gray-500" />
                <h3 className="text-lg font-semibold text-gray-800">
                  Detalles de Tramitación
                </h3>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-200 rounded-lg transition-colors duration-200"
              >
                <X className="w-5 h-5 text-gray-500" />
              </button>
            </div>
            <div className="space-y-4 max-h-[500px] overflow-y-auto pr-2">
              <AnimatePresence>
                {task.subtasks?.sort((a, b) => a.orden - b.orden).map((subtask) => (
                  <motion.div
                    key={subtask.id}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    <SubtaskCard 
                      subtask={subtask} 
                      task={task}
                      agrupadorOrganismo={agrupadorOrganismo}
                    />
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </td>
    </tr>
  );
};

const TableRow: React.FC<TableRowProps> = ({ item, isExpanded, onToggleExpand, colors }) => {
  const hasResolucionCerrada = item.task?.subtasks?.some(
    st => st.tipo === 'RESOLUCIÓN' && st.isClosed
  );

  const today = new Date().toLocaleDateString('es-CL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  const createDameroStyle = (baseColor: string) => {
    if (!hasResolucionCerrada) return { backgroundColor: baseColor };
    
    return {
      backgroundImage: `
        repeating-linear-gradient(
          45deg,
          ${baseColor},
          ${baseColor} 2px,
          white 2px,
          white 4px
        )
      `
    };
  };

  const textColorClass = hasResolucionCerrada ? 'text-gray-500' : 'text-gray-900';

  return (
    <tr className={`border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150 ${textColorClass}`}>
      <td className="py-2 px-3">
        <div className="flex items-center space-x-1">
          <span 
            title={`Fecha de inicio: ${item.fechaInicio}\nFecha de término: ${item.fechaTermino}`} 
            className="cursor-help bg-gray-100 px-2 py-0.5 rounded-full text-sm font-medium whitespace-nowrap"
          >
            {item.tiempoTranscurrido}
          </span>
        </div>
      </td>
      <td className="py-2 px-3 font-medium whitespace-nowrap">{item.agrupador}</td>
      <td className="py-2 px-3 whitespace-nowrap">{item.fechaInicio}</td>
      <td className="py-2 px-3 whitespace-nowrap">{today}</td>
      <td className="py-2 px-3 whitespace-nowrap">{item.fechaTermino || 'En curso'}</td>
      <td className="py-2 px-3">
        <div className="flex items-center space-x-2 whitespace-nowrap">
          <div className="w-16 bg-gray-200 rounded-sm h-3">
            <div 
              className="rounded-sm h-3 transition-all duration-200" 
              style={{
                width: `${item.plazoTitular}%`,
                ...createDameroStyle(colors.primary)
              }}
            />
          </div>
          <span className="text-sm font-medium min-w-[2.5rem]">{item.plazoTitular}%</span>
        </div>
      </td>
      <td className="py-2 px-3">
        <div className="flex items-center space-x-2 whitespace-nowrap">
          <div className="w-16 bg-gray-200 rounded-sm h-3">
            <div 
              className="rounded-sm h-3 transition-all duration-200" 
              style={{
                width: `${item.plazoServicio}%`,
                ...createDameroStyle(colors.secondary)
              }}
            />
          </div>
          <span className="text-sm font-medium min-w-[2.5rem]">{item.plazoServicio}%</span>
        </div>
      </td>
    </tr>
  );
};

const DataDisplay: React.FC<{ 
  data: DataItem[], 
  color: string
}> = ({ data, color }) => {
  const [expandedId, setExpandedId] = useState<string | null>(null);

  // Función para generar colores contrastantes
  const generateContrastColors = (baseColor: string): { primary: string, secondary: string } => {
    const hex = baseColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    
    // Calcular HSL para mejor manipulación del color
    const toHSL = (r: number, g: number, b: number) => {
      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h = 0, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }
      return [h * 360, s * 100, l * 100];
    };

    const [h, s, l] = toHSL(r, g, b);
    
    // Generar colores contrastantes manteniendo el tono pero ajustando saturación y luminosidad
    return {
      primary: `hsl(${h}, ${Math.min(100, s + 20)}%, ${Math.max(25, l - 15)}%)`,
      secondary: `hsl(${h}, ${Math.min(100, s + 10)}%, ${Math.min(85, l + 25)}%)`
    };
  };

  const { primary, secondary } = generateContrastColors(color);

  const validData = data.filter(item => {
    const hasSubtasks = item.task?.subtasks && Array.isArray(item.task.subtasks) && item.task.subtasks.length > 0;
    return hasSubtasks && item.tiempoTranscurrido !== 'En espera';
  });

  if (validData.length === 0) return null;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="overflow-x-auto bg-white rounded-xl shadow-sm">
  <table className="w-full text-sm">
    <thead>
      <tr className="bg-gray-50 border-b border-gray-200">
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Tiempo</th>
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Agrupador</th>
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Fecha Inicio</th>
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Fecha Actual</th>
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Fecha Término</th>
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Plazo Titular</th>
        <th className="py-3 px-4 text-left font-semibold text-gray-700">Plazo Servicio</th>
      </tr>
    </thead>
    <tbody>
      {validData.map((item, index) => {
        const itemId = `${item.agrupador}-${index}`;
        return (
          <React.Fragment key={itemId}>
            <TableRow 
              item={item}
              isExpanded={expandedId === itemId}
              onToggleExpand={() => setExpandedId(expandedId === itemId ? null : itemId)}
              colors={{ primary, secondary }}
            />
            <AnimatePresence>
              {expandedId === itemId && item.task && (
                <ExpandableDetails
                  task={item.task}
                  agrupadorOrganismo={item.organismo}
                  onClose={() => setExpandedId(null)}
                />
              )}
            </AnimatePresence>
          </React.Fragment>
        );
      })}
    </tbody>
  </table>
</div>
      
        <div className="h-[300px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <defs>
              {validData.map((item, index) => {
                const hasResolucionCerrada = item.task?.subtasks?.some(
                  st => st.tipo === 'RESOLUCIÓN' && st.isClosed
                );
                
                if (hasResolucionCerrada) {
                  return (
                    <React.Fragment key={index}>
                      <pattern
                        id={`checkerTitular-${index}`}
                        patternUnits="userSpaceOnUse"
                        width="6"
                        height="6"
                      >
                        <rect width="6" height="6" fill={primary} />
                        <rect width="3" height="3" fill="white" />
                        <rect x="3" y="3" width="3" height="3" fill="white" />
                      </pattern>
                      <pattern
                        id={`checkerServicio-${index}`}
                        patternUnits="userSpaceOnUse"
                        width="6"
                        height="6"
                      >
                        <rect width="6" height="6" fill={secondary} />
                        <rect width="3" height="3" fill="white" />
                        <rect x="3" y="3" width="3" height="3" fill="white" />
                      </pattern>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" domain={[0, 100]} />
            <YAxis 
              dataKey="name" 
              type="category" 
              width={150}
              tick={{ fontSize: 12 }}
            />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar
              dataKey="plazoTitular"
              stackId="a"
              name="Plazo Titular (%)"
              fill={primary}
            >
              {validData.map((item, index) => {
                const hasResolucionCerrada = item.task?.subtasks?.some(
                  st => st.tipo === 'RESOLUCIÓN' && st.isClosed
                );
                return (
                  <Cell
                    key={`titular-${index}`}
                    fill={hasResolucionCerrada ? `url(#checkerTitular-${index})` : primary}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="plazoServicio"
              stackId="a"
              name="Plazo Servicio (%)"
              fill={secondary}
            >
              {validData.map((item, index) => {
                const hasResolucionCerrada = item.task?.subtasks?.some(
                  st => st.tipo === 'RESOLUCIÓN' && st.isClosed
                );
                return (
                  <Cell
                    key={`servicio-${index}`}
                    fill={hasResolucionCerrada ? `url(#checkerServicio-${index})` : secondary}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        </div>
      </div>
  );
};



interface ExpandableDetailsProps {
  task: Task;
  agrupadorOrganismo: string; // Añadida esta prop

  onClose: () => void;
}


interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}


interface Task {
  id: number;
  name: string;
  responsible: User[]; // Cambiar de string a User[]
  progress: number;
  start: string;
  end: string;
  descriptor: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId?: number }[];
  enabled: boolean; 
  isTramitacion?: boolean;
  subtasks?: SubTask[];
  hidden?: boolean;
  orden: number;
  isClosed: boolean;
}



// Actualizar la interfaz SubTask
interface SubTask {
  id: number;
  name: string;
  responsable: User[];
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean; 
  hidden?: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  isClosed: boolean;
}



const getStatusColor = (isClosed: boolean, delayInDays: number): string => {
  if (isClosed) return 'bg-green-100 text-green-800';
  if (delayInDays > 0) return 'bg-red-100 text-red-800';
  return 'bg-yellow-100 text-yellow-800';
};

const getTypeIcon = (tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS') => {
  switch (tipo) {
    case 'INGRESO':
      return <FileText className="w-5 h-5 text-blue-600" />;
    case 'OBSERVACIÓN':
      return <AlertTriangle className="w-5 h-5 text-amber-600" />;
    case 'RESPUESTA':
      return <ArrowRight className="w-5 h-5 text-purple-600" />;
    case 'RESOLUCIÓN':
      return <Check className="w-5 h-5 text-green-600" />;
    default:
      return <Clock className="w-5 h-5 text-gray-600" />;
  }
};


interface SubtaskCardProps {
  subtask: SubTask;
  task: Task;
  agrupadorOrganismo: string;
}

const SubtaskCard: React.FC<SubtaskCardProps> = ({ subtask, task, agrupadorOrganismo }) => {

  const statusColor = getStatusColor(subtask.isClosed, subtask.delayInDays);
  
  return (
    <div className="bg-white rounded-lg shadow-sm border p-4 space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          {getTypeIcon(subtask.tipo)}
          <h3 className="font-medium text-gray-900">{subtask.nombre}</h3>
        </div>
        <span 
          className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${statusColor}`}
          title="Estado actual de la subtarea"
        >
          {subtask.isClosed ? 'Completado' : subtask.delayInDays > 0 ? `${subtask.delayInDays} días de retraso` : 'En proceso'}
        </span>
      </div>

      <div className="grid grid-cols-2 gap-4 text-sm">
      <div>
          <p className="text-gray-500">Responsable</p>
          <p className="font-medium">{getResponsible(subtask, task, agrupadorOrganismo)}</p>
        </div>
        <div>
          <p className="text-gray-500">Tipo</p>
          <p className="font-medium">{subtask.tipo}</p>
        </div>
        <div>
          <p className="text-gray-500">Fecha Inicio</p>
          <p className="font-medium">{new Date(subtask.fecha_inicio).toLocaleDateString()}</p>
        </div>
        <div>
          <p className="text-gray-500">Fecha Término</p>
          <p className="font-medium">
            {subtask.fecha_termino ? new Date(subtask.fecha_termino).toLocaleDateString() : 'En curso'}
          </p>
        </div>
      </div>

      {subtask.comments && (
        <div className="mt-3 pt-3 border-t">
          <p className="text-sm text-gray-500">Comentarios</p>
          <p className="text-sm mt-1">{subtask.comments}</p>
        </div>
      )}
    </div>
  );
};





const RegistrationProtocol: React.FC<RegistrationProtocolProps> = ({
  selectedProcess,
  processes
}) => {
  const [subprocesosData, setSubprocesosData] = useState<{
    subproceso: Subproceso;
    agrupadores: DataItem[];
    isOpen: boolean;
  }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allExpanded, setAllExpanded] = useState(true);

  const calculateTimePercentages = (tramitacionTask: Task | undefined): { 
    plazoTitular: number, 
    plazoServicio: number 
  } => {
     const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (!tramitacionTask?.subtasks) {
      return { plazoTitular: 0, plazoServicio: 0 };
    }

    if (new Date(tramitacionTask.fecha_inicio) > today) {
      return { plazoTitular: 0, plazoServicio: 0 };
    }
    
    console.log("Tareas de tramitación:", tramitacionTask.subtasks);
  
   
    const calculateTotalTimeForSubtasks = (
      subtasks: SubTask[] | undefined,
      tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN',
      today: Date
    ): number => {
      if (!subtasks) return 0;
  
      return subtasks
        .filter(subtask => {
          if (tipo === 'RESOLUCIÓN' && subtask.tipo === 'RESOLUCIÓN') {
            return true;
          }
          return subtask.tipo === tipo;
        })
        .reduce((total, subtask) => {
          const startDate = dateUtils.parseLocalDate(subtask.fecha_inicio);
          const endDate = subtask.isClosed ? dateUtils.parseLocalDate(subtask.fecha_termino) : today;
  
          const daysBetween = calculateDaysBetween(
            dateUtils.formatLocalDate(startDate),
            dateUtils.formatLocalDate(endDate), subtask.isClosed
          );
  
          console.log(`Días para ${subtask.tipo} (${subtask.nombre}): ${daysBetween} días`);
  
          // Asegura al menos 1 día contado incluso si las fechas son iguales
          return total + (daysBetween === 0 ? 1 : daysBetween);
        }, 0);
    };
  
    const tiempoIngreso = calculateTotalTimeForSubtasks(tramitacionTask.subtasks, 'INGRESO', today);
    const tiempoObservaciones = calculateTotalTimeForSubtasks(tramitacionTask.subtasks, 'OBSERVACIÓN', today);
    const tiempoRespuestas = calculateTotalTimeForSubtasks(tramitacionTask.subtasks, 'RESPUESTA', today);
    
    console.log("Tiempo de INGRESO:", tiempoIngreso);
    console.log("Tiempo de OBSERVACIÓN:", tiempoObservaciones);
    console.log("Tiempo de RESPUESTA:", tiempoRespuestas);
  
    const tiempoResolucionTitular = tramitacionTask.subtasks
      .filter(subtask => subtask.tipo === 'RESOLUCIÓN' && subtask.resolutionType === 'DESISTIMIENTO')
      .reduce((total, subtask) => {
        const endDate = subtask.isClosed ? dateUtils.parseLocalDate(subtask.fecha_termino) : today;
        const daysBetween = calculateDaysBetween(
          dateUtils.formatLocalDate(dateUtils.parseLocalDate(subtask.fecha_inicio)),
          dateUtils.formatLocalDate(endDate), subtask.isClosed
        );
        
        console.log(`Días para RESOLUCIÓN Titular (${subtask.nombre}): ${daysBetween} días`);
        
        return total + (daysBetween === 0 ? 1 : daysBetween);
      }, 0);
  
    const tiempoResolucionServicio = tramitacionTask.subtasks
      .filter(subtask => subtask.tipo === 'RESOLUCIÓN' && subtask.resolutionType !== 'DESISTIMIENTO')
      .reduce((total, subtask) => {
        const endDate = subtask.isClosed ? dateUtils.parseLocalDate(subtask.fecha_termino) : today;
        const daysBetween = calculateDaysBetween(
          dateUtils.formatLocalDate(dateUtils.parseLocalDate(subtask.fecha_inicio)),
          dateUtils.formatLocalDate(endDate), subtask.isClosed
        );
        
        console.log(`Días para RESOLUCIÓN Servicio (${subtask.nombre}): ${daysBetween} días`);
        
        return total + (daysBetween === 0 ? 1 : daysBetween);
      }, 0);
  
    const tiempoTotal = tiempoIngreso + tiempoObservaciones + tiempoRespuestas + tiempoResolucionTitular + tiempoResolucionServicio;
  
    console.log("Tiempo Total:", tiempoTotal);
    console.log("Tiempo Titular (Ingreso + Respuestas + Resolución Titular):", tiempoIngreso + tiempoRespuestas + tiempoResolucionTitular);
    console.log("Tiempo Servicio (Observaciones + Resolución Servicio):", tiempoObservaciones + tiempoResolucionServicio);
  
    if (tiempoTotal === 0) {
      return { plazoTitular: 0, plazoServicio: 0 };
    }
  
    return {
      plazoTitular: Math.round(((tiempoIngreso + tiempoRespuestas + tiempoResolucionTitular) / tiempoTotal) * 100),
      plazoServicio: Math.round(((tiempoObservaciones + tiempoResolucionServicio) / tiempoTotal) * 100)
    };
  };

  
  const loadProcessData = async (processName: string) => {
    if (!processName || !processes) {
      setSubprocesosData([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    try {
      const selectedProcess = processes.find(p => p.nombreProceso === processName);
      if (!selectedProcess?.codigo) {
        setSubprocesosData([]);
        setIsLoading(false);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      if (!data?.subprocesos || !Array.isArray(data.subprocesos)) {
        setSubprocesosData([]);
        setIsLoading(false);
        return;
      }
      const processedData = data.subprocesos
      .filter((sp: any) => sp && Array.isArray(sp.agrupadores))
      .map((sp: any) => {
        const agrupadoresValidos = sp.agrupadores
          .filter((agr: any) => {
            if (!agr || !agr.enabled || agr.descriptor !== "PERMISOLOGÍA" || !Array.isArray(agr.tareas)) {
              return false;
            }
            
            const tramitacionTask = agr.tareas?.find((tarea: Task) => 
              tarea?.nombre === "TRAMITACIÓN"
            );

            // Verificación segura de subtareas
            const hasSubtasks = tramitacionTask?.subtasks && 
                              Array.isArray(tramitacionTask.subtasks) && 
                              tramitacionTask.subtasks.length > 0;
            
            if (!hasSubtasks) return false;

            // Verificación de subtareas activas
            const hasActiveSubtasks = tramitacionTask.subtasks.some((subtask: SubTask) => 
              new Date(subtask.fecha_inicio) <= new Date()
            );

            return hasActiveSubtasks;
          })
          .map((agrupador: any) => {
            const tramitacionTask = agrupador.tareas?.find((tarea: Task) => 
              tarea?.nombre === "TRAMITACIÓN"
            );

            if (!tramitacionTask) return null;

            const { plazoTitular, plazoServicio } = calculateTimePercentages(tramitacionTask);

            return {
              name: agrupador.nombre || '',
              agrupador: agrupador.nombre || '',
              tiempoTranscurrido: calculateDaysElapsed(
                tramitacionTask.fecha_inicio || '',
                tramitacionTask.fecha_termino || '',
                !!tramitacionTask.isClosed
              ),
              fechaInicio: formatDate(tramitacionTask.fecha_inicio),
              fechaTermino: formatDate(tramitacionTask.fecha_termino),
              plazoTitular,
              plazoServicio,
              task: tramitacionTask,
              organismo: agrupador.organismo || ''
            };
          })
          .filter(Boolean);

        // Solo retornar subprocesos que tengan agrupadores válidos
        return agrupadoresValidos.length > 0 ? {
          subproceso: {
            id: sp.id || 0,
            subproceso: sp.nombre || '',
            color: sp.color || getDefaultColor(sp.id || 0),
          },
          agrupadores: agrupadoresValidos,
          isOpen: true
        } : null;
      })
      .filter(Boolean);

    setSubprocesosData(processedData);


    } catch (error) {
      console.error('Error al cargar el proceso:', error);
      setSubprocesosData([]);
    } finally {
      setIsLoading(false);
    }
  };


  const toggleSubproceso = (index: number) => {
    setSubprocesosData(prevData => 
      prevData.map((item, i) => 
        i === index ? { ...item, isOpen: !item.isOpen } : item
      )
    );
    setAllExpanded(false);
  };
  
  const toggleAll = () => {
    const newExpandedState = !allExpanded;
    setAllExpanded(newExpandedState);
    setSubprocesosData(prevData =>
      prevData.map(item => ({
        ...item,
        isOpen: newExpandedState
      }))
    );
  };

  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);
  

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg">
    {isLoading ? (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-10 w-10 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    ) : !subprocesosData || subprocesosData.length === 0 ? (
      <EmptyState />
    ) : (
      <>
        <div className="flex justify-end mb-6">
          <button
            onClick={toggleAll}
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200 text-gray-700 font-medium"
          >
            {allExpanded ? (
              <>
                <ChevronsUp className="w-5 h-5" />
                <span>Colapsar todo</span>
              </>
            ) : (
              <>
                <ChevronsDown className="w-5 h-5" />
                <span>Expandir todo</span>
              </>
            )}
          </button>
        </div>
  
        <div className="space-y-6">
          {subprocesosData.map((spData, index) => (
            <div key={index} className="border rounded-xl overflow-hidden shadow-sm">
              <CollapsibleHeader
                title={spData.subproceso.subproceso}
                color={spData.subproceso.color}
                isOpen={spData.isOpen}
                onToggle={() => toggleSubproceso(index)}
              />
              <div
                className={`transition-all duration-300 ease-in-out ${
                  spData.isOpen ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0'
                } overflow-hidden`}
              >
                <div className="p-4">
                  {spData.agrupadores.every(item => item.tiempoTranscurrido === 'En espera') ? (
                    <div className="flex flex-col items-center justify-center p-6 bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl border border-gray-200 shadow-sm">
                      <AlertCircle className="w-14 h-14 text-blue-500 mb-4" />
                      <div className="text-center">
                        <h4 className="text-xl font-semibold text-gray-900 mb-2">
                          Tareas pendientes
                        </h4>
                        <p className="text-sm text-gray-600 mb-2">
                          Todas las tareas están programadas para fechas futuras.
                        </p>
                        {spData.agrupadores.length > 0 && (() => {
                          const validDates = spData.agrupadores
                            .map(item => dateUtils.parseLocalDate(item.fechaInicio))
                            .filter(date => !isNaN(date.getTime()));
  
                          if (validDates.length === 0) return null;
  
                          const nextDate = validDates.sort(
                            (a, b) => a.getTime() - b.getTime()
                          )[0];
  
                          console.log('Próxima fecha válida:', nextDate.toISOString());
  
                          return (
                            <p className="text-sm text-gray-500">
                              {`Las tareas comenzarán a partir del ${nextDate.toLocaleDateString('es-CL', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}`}
                            </p>
                          );
                        })()}
                      </div>
                    </div>
                  ) : (
                    <DataDisplay 
                    data={spData.agrupadores} 
                    color={spData.subproceso.color} // Pasando el color del subproceso
                  />                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    )}
  </div>
  
  );
};

export default RegistrationProtocol;