import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Register: React.FC = () => {
  const [username, setUsername] = useState('');
  const [firstname, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState(''); // Nuevo estado para género
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [token, setToken] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const generateUsername = (name: string, lastname: string) => {
    const [firstName] = name.split(' ');
    const LastName = lastname.split(' ')[0];
    const isCompound = lastname.includes(' ');
    const usernamePrefix = firstName.charAt(0).toLowerCase();
    const usernameSuffix = isCompound ? lastname.replace(/ /g, '').toLowerCase() : LastName.toLowerCase();
    return `${usernamePrefix}${usernameSuffix}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    const isAdminParam = urlParams.get('is_admin') === 'true';
    const emailParam = urlParams.get('email');

    if (tokenParam) {
      setToken(tokenParam);
      setIsAdmin(isAdminParam);
    }

    if (emailParam) {
      setEmail(emailParam);
    }

    if (firstname && lastname) {
      setUsername(generateUsername(firstname, lastname));
    }
  }, [firstname, lastname]);

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      setSuccess('');
      return;
    }

    if (!gender) {
      setError('Por favor selecciona un género.');
      setSuccess('');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/register.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          username, 
          firstname, 
          lastname, 
          email, 
          password, 
          token, 
          is_admin: isAdmin,
          gender: gender === 'other' ? 'O' : gender.charAt(0).toUpperCase() // Convertir a M, F, u O
        }),
      });

      const responseText = await response.text();
      console.log('Respuesta del servidor:', responseText);

      if (responseText.trim() === '') {
        setError('La respuesta del servidor está vacía.');
        setSuccess('');
        return;
      }

      try {
        const data = JSON.parse(responseText);

        if (data.success) {
          setSuccess(data.message || 'Registro exitoso.');
          setError('');
          sessionStorage.setItem('firstName', data.firstname);
          sessionStorage.setItem('isAdmin', data.is_admin.toString());
          if (data.token) {
            localStorage.setItem('authToken', data.token);
          }
          setTimeout(() => navigate('/ui'), 2000);
        } else {
          setError(data.error || 'Error al registrar el usuario.');
          setSuccess('');
        }
      } catch (jsonError) {
        console.error('Error al parsear JSON:', jsonError);
        setError('Error en la respuesta del servidor: ' + responseText);
        setSuccess('');
      }

    } catch (error) {
      console.error('Register failed:', error);
      setError(`Ocurrió un error durante el registro. ${error}`);
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-logo"></div>
        <form onSubmit={handleRegister}>
          <div className="input-container">
            <i className="input-icon fas fa-at"></i>
            <input
              type="text"
              placeholder="Usuario (automático)"
              value={username}
              readOnly
              className="bg-gray-100 cursor-default login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Nombre"
              value={firstname}
              onChange={(e) => setName(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Apellido"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-venus-mars"></i>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="login-input"
              style={{ paddingLeft: '2.5rem' }}
            >
              <option value="">Seleccionar género</option>
              <option value="male">Masculino</option>
              <option value="female">Femenino</option>
              <option value="other">Otro</option>
            </select>
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-envelope"></i>
            <input
              type="email"
              placeholder="Correo electrónico"
              value={email}
              readOnly
              className="login-input bg-gray-100 cursor-default"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Confirmar contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <input
              type="checkbox"
              id="is_admin"
              checked={isAdmin}
              readOnly
              className="cursor-default"
            />
            <label htmlFor="is_admin" className="ml-2">Administrador</label>
          </div>
          <button type="submit" className='submit-button mb-5' disabled={loading}>
            {loading ? 'Registrando...' : 'Registrarse'}
          </button>
          {success && <p className="success-message">{success}</p>}
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
      <div className="triangle"></div>
    </div>
  );
};

export default Register;