import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

interface RequestPasswordResetProps {
    onClose: () => void;
}

const RequestPasswordReset: React.FC<RequestPasswordResetProps> = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<'success' | 'error' | ''>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        setMessageType('');

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/php/pswd/request_reset.php`, { email });

            if (response.data.success) {
                setMessage('Se ha enviado un enlace para restablecer la contraseña. Por favor, revise su correo electrónico');
                setMessageType('success');
                setTimeout(() => {
                    onClose();
                }, 3000);
            } else {
                setMessage(`Error: ${response.data.error}`);
                setMessageType('error');
            }
        } catch (error) {
            setMessage('Error al enviar el correo de restablecimiento.');
            setMessageType('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
            <button 
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-800" 
                onClick={onClose}
            >
                <i className="fas fa-xmark w-5 h-5"></i>
            </button>

            <div className="text-center mb-6">
                <h2 className="text-xl font-bold mb-2">¿Olvidaste tu contraseña?</h2>
                <p className="text-gray-600">
                    Ingresa tu correo electrónico para recuperar el acceso a Simagi.
                </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Correo electrónico"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                        required
                    />
                </div>

                {message && (
                    <div className={`text-sm ${messageType === 'success' ? 'text-green-600' : 'text-red-600'}`}>
                        {message}
                    </div>
                )}

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-teal-500 text-white py-2 px-4 rounded-md hover:bg-teal-600 transition-colors duration-200 disabled:opacity-50"
                >
                    {loading ? (
                        <span className="flex items-center justify-center">
                            <i className="fas fa-spinner fa-spin mr-2"></i>
                            Enviando...
                        </span>
                    ) : (
                        'Enviar Enlace de Restablecimiento'
                    )}
                </button>
            </form>
        </div>
    );
};

export default RequestPasswordReset;