import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { UserPlus, Loader2 } from "lucide-react";

const InviteUser = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [userAuthorized, setUserAuthorized] = useState(false);

    useEffect(() => {
        const adminStatus = sessionStorage.getItem('isAdmin');
        if (adminStatus === '1') {
            setUserAuthorized(true);
            setIsAdmin(true);
        } else {
            setUserAuthorized(false);
        }
    }, []);

    const handleInvite = async () => {
        setMessage('');
        setError('');
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/send_invitation.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name, is_admin: isAdmin }),
            });

            const data = await response.json();

            if (data.success) {
                setMessage('Invitación enviada exitosamente.');
                setName('');
                setEmail('');
                setIsAdmin(false);
            } else {
                setError(data.error);
            }
        } catch (error) {
            setError(`Error en la solicitud. ${error}`);
        } finally {
            setLoading(false);
        }
    };

    const toggleAdmin = () => {
        setIsAdmin(!isAdmin);
    };

    if (!userAuthorized) {
        return (
            <div className="p-4">
                <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 text-base">
                    No tienes permiso para ver esta página.
                </div>
            </div>
        );
    }

    return (
        <div className="p-4">
            <Card className="max-w-4xl mx-auto bg-white shadow-md">
                <CardHeader className="pb-4 border-b border-gray-200 bg-gray-50">
                    <CardTitle className="flex items-center gap-2 text-xl font-semibold text-gray-800">
                        <UserPlus className="w-6 h-6 text-[#47D7D7]" />
                        Invitar Usuario
                    </CardTitle>
                    <p className="text-base text-gray-600 mt-3 leading-relaxed text-justify">
                        Complete el siguiente formulario para enviar una invitación a un nuevo usuario. 
                        Ingrese el nombre completo y una dirección de correo electrónico válida. 
                        Si el usuario requiere privilegios de administrador, active la opción correspondiente. 
                        La invitación será enviada automáticamente al correo especificado.
                    </p>
                </CardHeader>
                <CardContent className="pt-5 pb-6">
                    <div className="space-y-5 max-w-3xl mx-auto">
                        <div className="space-y-2">
                            <label 
                                htmlFor="name" 
                                className="block text-base font-medium text-gray-700"
                            >
                                Nombre
                            </label>
                            <Input
                                id="name"
                                type="text"
                                placeholder="Ingrese el nombre del invitado"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full border-gray-300 text-base"
                            />
                        </div>

                        <div className="space-y-2">
                            <label 
                                htmlFor="email" 
                                className="block text-base font-medium text-gray-700"
                            >
                                Correo electrónico
                            </label>
                            <Input
                                id="email"
                                type="email"
                                placeholder="Ingrese el correo electrónico"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full border-gray-300 text-base"
                            />
                        </div>

                        <div 
                            className="flex items-center justify-between py-3.5 px-4 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors border border-gray-200"
                            onClick={toggleAdmin}
                        >
                            <span 
                                className="text-base font-medium text-gray-700 cursor-pointer select-none"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleAdmin();
                                }}
                            >
                                Modo Administrador
                            </span>
                            <div className="relative inline-block w-12 align-middle select-none">
                                <input
                                    type="checkbox"
                                    id="admin-mode"
                                    checked={isAdmin}
                                    onChange={toggleAdmin}
                                    className="peer sr-only"
                                />
                                <div className="w-12 h-7 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:bg-[#2BAFAF] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all border-2 border-transparent"></div>
                            </div>
                        </div>

                        <div className="pt-2">
                            <Button 
                                className="w-full bg-[#2BAFAF] hover:bg-[#229292] text-white font-medium py-3 text-base"
                                onClick={handleInvite} 
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                                        Enviando invitación...
                                    </>
                                ) : (
                                    'Enviar Invitación'
                                )}
                            </Button>
                        </div>

                        {(message || error) && (
                            <div className="mt-4">
                                {message && (
                                    <div className="p-4 bg-green-50 border border-green-300 rounded-lg text-green-800 text-base">
                                        {message}
                                    </div>
                                )}

                                {error && (
                                    <div className="p-4 bg-red-50 border border-red-300 rounded-lg text-red-800 text-base">
                                        Error: {error}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default InviteUser;