import React, { useState, useEffect, useRef, useCallback, useMemo, useLayoutEffect } from 'react';
import { Calendar, ChevronDown, ChevronUp, Minimize, Maximize, FileX, Plus, FileQuestion, X, Upload, Layers, ChevronRight, Clock, Check, AlertTriangle, Bell, Loader2, UserIcon, Users, Search, MessageSquare, Info, XCircle, CheckCircle } from 'lucide-react';
import { CommonComponentProps } from '../../types/common';
import { motion, AnimatePresence, progress } from 'framer-motion';
import { filter } from 'jszip';
import dateUtils from './dateUtils';
import { debounce } from 'lodash'; // Asegúrate de importar lodash si no lo has hecho
import { group } from 'console';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../ui/button';
import { MdBusiness } from 'react-icons/md';


const calculateLatestEndDate = (subtasks: SubTask[]): string => {
  if (!subtasks || subtasks.length === 0) {
    return dateUtils.getTodayString();
  }

  // Encuentra la fecha más lejana entre todas las subtareas
  const latestDate = subtasks.reduce((latest, subtask) => {
    const subtaskEndDate = dateUtils.parseLocalDate(subtask.end);
    return subtaskEndDate > latest ? subtaskEndDate : latest;
  }, dateUtils.parseLocalDate(subtasks[0].end));

  return dateUtils.formatLocalDate(latestDate);
};



function handleDependenciesAndDates(tasks: Task[], delayedTaskIndex: number): { updatedTasks: Task[], delayInDays: number } {
  if (delayedTaskIndex < 0 || delayedTaskIndex >= tasks.length) {
    console.log(`Índice de tarea inválido: ${delayedTaskIndex}`);
    return { updatedTasks: tasks, delayInDays: 0 };
  }

  const delayedTask = tasks[delayedTaskIndex];
  const delayInDays = delayedTask.delayInDays || 0; // Usamos el delayInDays ya calculado

  console.log(`La tarea ${delayedTask.description} (Posición: ${delayedTaskIndex + 1}) está retrasada por ${delayInDays} día(s).`);

  const updatedTasks = [...tasks];

  function updateDates(taskPosition: number, delay: number) {
    for (let i = taskPosition + 1; i < updatedTasks.length; i++) {
      const task = updatedTasks[i];
      const dependencies = typeof task.dependencia === 'string' 
        ? task.dependencia.split(',').map(Number)
        : task.dependencia ? [Number(task.dependencia)] : [];
  
      if (dependencies.includes(taskPosition + 1)) {
        // Adjust start and end dates using dateUtils
        const newStartDate = dateUtils.addDaysToDate(task.start, delay);
        const newEndDate = dateUtils.addDaysToDate(task.end, delay);
  
        updatedTasks[i] = {
          ...task,
          start: newStartDate,
          end: newEndDate,
        };
  
        console.log(`Actualizando fechas para la tarea ${task.description} (Posición: ${i + 1}):`);
        console.log(`  Nueva fecha de inicio: ${updatedTasks[i].start}`);
        console.log(`  Nueva fecha de fin: ${updatedTasks[i].end}`);
  
        // Recursively adjust dates for dependent tasks
        updateDates(i, delay);
      }
    }
  }
  
  if (delayInDays > 0) {
    updateDates(delayedTaskIndex, delayInDays);
  }
  
  return { updatedTasks, delayInDays };
  

  if (delayInDays > 0) {
    updateDates(delayedTaskIndex, delayInDays);
  }

  return { updatedTasks, delayInDays };
}


function analyzeDependencies(tasks: Task[], delayedTaskIndex: number): void {
  if (delayedTaskIndex < 0 || delayedTaskIndex >= tasks.length) {
    console.log(`Índice de tarea inválido: ${delayedTaskIndex}`);
    return;
  }

  const delayedTask = tasks[delayedTaskIndex];
  console.log(`Analizando dependencias para la tarea retrasada: ${delayedTask.description} (Posición: ${delayedTaskIndex + 1})`);

  const affectedTasks: { task: Task; position: number }[] = [];

  function findDependentTasks(taskPosition: number, depth: number = 0) {
    console.log(`${' '.repeat(depth * 2)}Buscando dependencias para tarea en posición: ${taskPosition + 1}`);
    
    tasks.forEach((task, index) => {
      const dependencies = typeof task.dependencia === 'string' 
        ? task.dependencia.split(',').map(Number)
        : task.dependencia ? [Number(task.dependencia)] : [];
      
      if (dependencies.includes(taskPosition + 1)) { // +1 porque las posiciones en la UI empiezan en 1
        if (!affectedTasks.some(t => t.position === index)) {
          console.log(`${' '.repeat((depth + 1) * 2)}Tarea afectada: ${task.description} (Posición: ${index + 1})`);
          affectedTasks.push({ task, position: index });
          findDependentTasks(index, depth + 1); // Buscar dependencias indirectas
        }
      }
    });
  }

  findDependentTasks(delayedTaskIndex);

  console.log(`La tarea ${delayedTask.description} (Posición: ${delayedTaskIndex + 1}) está retrasada.`);
  console.log('Tareas afectadas por el retraso:');
  if (affectedTasks.length === 0) {
    console.log('No se encontraron tareas afectadas.');
  } else {
    affectedTasks.forEach(({ task, position }) => {
      const dependencyType = task.dependencia === `${delayedTaskIndex + 1}` ? 'directamente' : 'indirectamente';
      console.log(`- Tarea ${task.description} (Posición: ${position + 1}) depende ${dependencyType} de la tarea retrasada.`);
    });
  }
}

interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}



const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.185;
  const fixedOffset = 20; // Offset fijo para todas las pestañas



  
  useEffect(() => {
    if (isActive) {
      
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
      //  zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div   style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => (
  <input
    type="date"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full border rounded px-2 py-1 text-sm"
  />
);
interface NoPlantillaMessageProps {
  navigateToSubComponent: (main: string, sub: string) => void;
}

const NoPlantillaMessage: React.FC<NoPlantillaMessageProps> = ({ navigateToSubComponent }) => {
  const handleEditarPlantillas = () => {
    navigateToSubComponent('ADMINISTRACIÓN', 'Mis plantillas');
  };

  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileX size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Planificación no disponible</h3>
      <p className="text-teal-600 text-center mb-6">Parece que aún no se ha asignado una plantilla para este proceso.</p>
      <button
        onClick={handleEditarPlantillas}
        className="flex items-center bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 transition-all duration-300 transform hover:scale-105 active:scale-95"
      >
        <Plus size={20} className="mr-2" />
        Asignar nueva Plantilla
      </button>
    </div>
  );
};


const NoAgrupadoresMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      <Layers size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No hay información disponible</h3>
      <p className="text-teal-600 text-center mb-6">Este proceso no contiene información. Por favor revise la planificación.</p>
    </div>
  );
};


interface SmoothCollapseProps {
  isOpen: boolean;
  children: React.ReactNode;
}

const SmoothCollapse: React.FC<SmoothCollapseProps> = ({ isOpen, children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | string>(0);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setMaxHeight(contentHeight); // Usar scrollHeight para la expansión
    } else if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setMaxHeight(contentHeight); // Ajustar al scrollHeight antes de colapsar
      setTimeout(() => setMaxHeight(0), 10); // Luego de un pequeño delay, colapsar
    }
  }, [isOpen, children]);

  const handleAnimationComplete = () => {
    if (isOpen && contentRef.current) {
      setMaxHeight('none'); // Ajustar dinámicamente después de la expansión
    }
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        className="overflow-hidden"
        initial={false}
        animate={{ 
          maxHeight,
          marginBottom: maxHeight !== 0 ? '1.5rem' : 0,
          boxShadow: maxHeight !== 0 ? '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)' : 'none'
        }}
        transition={{
          maxHeight: {
            type: "spring",
            stiffness: 100, // Aumentar la rigidez para acelerar la animación
            damping: 20,    // Amortiguación más ajustada para suavidad
            duration: isOpen ? 0.3 : 0.4, // Expansión más rápida (0.3s), colapso ligeramente más lento (0.4s)
          },
          marginBottom: { duration: 0.2 },
          marginTop: { duration: 0.2 },
          boxShadow: { duration: 0.2 }
        }}
        onAnimationComplete={handleAnimationComplete}
      >
        <div ref={contentRef}>
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};






const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow-md">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Subproceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        El subproceso "{subprocessName}" no tiene agrupadores ni tareas definidas.
      </p>
    
    </div>
  );
};



const NoProcesoSeleccionadoMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No se ha seleccionado ningún proceso</h3>
      <p className="text-teal-600 text-center mb-6">Por favor, seleccione un proceso para comenzar.</p>
    </div>
  );
};


interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}

interface Task {
  id: number;
  globalId?: number; 
  description: string;
  name: string;
  responsible: User[]; 
  progress: number;
  start: string;
  organismo: string;
  duracion: number;
  end: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
    reminder_code?: string;
    reminder_type?: string;
    reminder_value?: string;
    reminder_calculated_date?: string;
    reminder_active?: true,
    reminder_triggered?: false
  
}


interface SubTask {
  id?: number;
  name: string;
  responsible: User[] | string; // Puede ser un array de Users o un string
  progress: number;
  start: string;
  end: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
  reminder_code?: string;
  reminder_type?: string;
  reminder_value?: string;
  reminder_calculated_date?: string;
  reminder_active?: boolean;
  reminder_triggered?: boolean;
  tarea_id?: number;
}



enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray",
  
  
}
interface Group {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: Task[];
  enabled: boolean; // Nueva propiedad
  descriptor: string; 
  organismo: string;

}


interface ProjectSection {
  title: string;
  sectionType: string;
  processingBody: string;
  isCollapsed: boolean;
  tasks: Task[];
  onChangeSectionType: () => void;
  onChangeProcessingBody: () => void;
  onChangeTask: () => void;
  onToggleCollapse: () => void;
}

interface TaskRowProps {
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  rowColor: string;
  inputColor: string;
  allTasks: Task[];
  isLoading: boolean;
  subprocessColor: string;
  index: number;
  groupProgress: number;
  calculateSemaphoreStatus: SemaphoreCalculator;
  processName: string | undefined;         // Prop: Nombre del proceso
  subprocessName: string | undefined;      // Prop: Nombre del subproceso
  agrupadorName: string | undefined;     
  columnWidths: {
    id: string;
    description: string;
    responsible: string;
    dates: string;
    dependencies: string;
    followUp: string;
    status: string;
  };
}






// Actualizamos la función para verificar el estado de seguimiento
const isFollowUpPending = (date: string): boolean => {
  if (!date) return false;
  const today = new Date();
  const followUpDate = new Date(date);
  return followUpDate >= today;
};


interface ReminderValue {
  type: 'specific-date' | 'relative-time' | 'progress-based';
  value: string;
}

const parseReminderValue = (reminderString: string): ReminderValue | null => {
  if (!reminderString) return null;

  if (reminderString.startsWith('relative:')) {
    return {
      type: 'relative-time',
      value: reminderString.replace('relative:', '')
    };
  } else if (reminderString.startsWith('progress:')) {
    return {
      type: 'progress-based',
      value: reminderString.replace('progress:', '')
    };
  } else {
    return {
      type: 'specific-date',
      value: reminderString
    };
  }
};

type SemaphoreCalculator = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
) => SemaphoreStatus;
interface User {
  id: number;
  firstname: string;
  lastname: string;
}

interface UserSelectorIconProps {
  selectedUsers: User[]; // Lista de usuarios seleccionados
  enabled?: boolean; // Por defecto, `true`
  isClosed?: boolean; // Por defecto, `false`
  subprocessColor: string; // Color del subproceso
  currentUserId?: string | null; // ID del usuario actual, puede ser null si no está disponible
  onClick?: () => void; // Función opcional para manejar el clic
}


const UserSelectorIcon: React.FC<UserSelectorIconProps> = ({
  selectedUsers,
  enabled = true,
  isClosed = false,
  subprocessColor,
  currentUserId = sessionStorage.getItem('userId'),
  onClick
}) => {
  const darkenColor = (color: string, percentage: number): string => {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percentage);
    const R = (num >> 16) - amt;
    const G = ((num >> 8) & 0x00ff) - amt;
    const B = (num & 0x0000ff) - amt;
    return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1)}`;
  };
  const getIconColor = (): string => {
    if (isClosed) {
      return '#8EA6A6'; // Very light gray for closed tasks
    }
  
    if (!selectedUsers || selectedUsers.length === 0) {
      return '#A9A9A9'; // Gray for no users
    }
    
    const isCurrentUserResponsible = selectedUsers.some(
      user => user.id.toString() === currentUserId
    );
    
    if (isCurrentUserResponsible) {
      return '#23C25F'; // Green if current user is responsible
    }
    
    return '#4B5563'; // Normal gray if has users but current user isn't one
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (enabled && onClick) {
      onClick();
    }
  };

  const tooltipText = selectedUsers.length > 0
    ? `Responsables: ${selectedUsers.map(u => `${u.firstname} ${u.lastname}`).join(', ')}`
    : 'Sin responsables asignados';

  return (
    <div 
      onClick={handleClick}
      className={`cursor-pointer flex items-center ${enabled ? 'hover:opacity-80' : 'opacity-50 cursor-not-allowed'}`}
      title={tooltipText}
    >
      <UserIcon 
        className="w-5 h-5 mr-2 transition-colors duration-200"
        style={{ color: getIconColor() }}
      />
    </div>
  );
};




interface UserSelectorModalProps {
  isOpen: boolean; // Controla si el modal está abierto
  onClose: () => void; // Callback para cerrar el modal
  users: User[]; // Lista de todos los usuarios disponibles
  selectedUsers: User[]; // Usuarios seleccionados actualmente
  onUsersChange: (users: User[]) => void; // Callback para actualizar los usuarios seleccionados
  enabled?: boolean; // Indica si el modal está habilitado
  taskId: number; // ID de la tarea asociada
  groupId: number; // ID del grupo asociado
  isClosed?: boolean; // Indica si la tarea está cerrada
  subprocessColor?: string; // Color del subproceso asociado
}

const UserSelectorModal: React.FC<UserSelectorModalProps> = ({
  isOpen, // Controlado externamente
  onClose, // Callback para cerrar el modal
  users: initialUsers = [],
  selectedUsers: initialSelectedUsers = [],
  onUsersChange,
  enabled = true,
  taskId,
  groupId,
  isClosed = false,
  subprocessColor,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localSelectedUsers, setLocalSelectedUsers] = useState<User[]>(initialSelectedUsers);
  const [allUsers, setAllUsers] = useState<User[]>(initialUsers);
  const isNewTask = taskId < 0;
  const currentUserId = sessionStorage.getItem('userId');

  // Efecto para cargar usuarios cuando el modal se abre
  useEffect(() => {
    if (isOpen) {
      loadAllUsers();
      loadAssignedUsers();
    }
  }, [isOpen]);

  // Cargar todos los usuarios
  const loadAllUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setAllUsers(data);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Error al cargar la lista de usuarios');
    } finally {
      setIsLoading(false);
    }
  };

  // Cargar usuarios asignados
  const loadAssignedUsers = async () => {
    if (isNewTask || initialSelectedUsers.length > 0) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
       `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_users.php?taskId=${taskId}&groupId=${groupId}`
      );

      if (!response.ok) {
        throw new Error('Error al cargar los usuarios asignados');
      }

      const data = await response.json();
      if (data.success && Array.isArray(data.users)) {
        setLocalSelectedUsers(data.users);
        onUsersChange(data.users);
      }
    } catch (error) {
      console.error('Error loading assigned users:', error);
      toast.error('Error al cargar los usuarios asignados');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);

      if (isNewTask) {
        onUsersChange(localSelectedUsers);
        onClose();
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taskId,
          groupId,
          userIds: localSelectedUsers.map(user => user.id),
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Error al guardar los responsables');
      }

      toast.success('Responsables actualizados exitosamente');
      onUsersChange(localSelectedUsers);
      onClose();
    } catch (error) {
      console.error('Error saving users:', error);
      toast.error(error instanceof Error ? error.message : 'Error al guardar los responsables');
    } finally {
      setIsSaving(false);
    }
  };

  const filteredUsers = allUsers.filter(user => {
    const searchString = `${user.firstname} ${user.lastname} ${user.email} ${user.departamento}`.toLowerCase();
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term => searchString.includes(term));
  });

  return(
    <AnimatePresence>
    {isOpen && (
      <motion.div 
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70]"
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <motion.div 
          className="bg-white rounded-lg w-full max-w-2xl overflow-hidden"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
            <div className="flex justify-between items-center">
              <DialogTitle>
                <span className="text-xl font-semibold text-white">Seleccionar Responsables</span>
              </DialogTitle>
              <button
                onClick={onClose}
                className="text-white/80 hover:text-white transition-colors"
              >
                <X size={24} />
              </button>
            </div>
            <p className="text-teal-100 mt-2 text-sm">
              {localSelectedUsers.length} usuario(s) seleccionado(s)
            </p>
          </div>

          <motion.div
            className="p-6"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
          >
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="w-8 h-8 animate-spin text-teal-500" />
                <span className="ml-2">Cargando usuarios...</span>
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Buscar por nombre, email o departamento..."
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  </div>
                </div>

                <div className="bg-teal-50 p-4 rounded-lg border border-teal-100 mb-4">
                  <h3 className="text-sm font-medium text-teal-800 mb-2">Usuarios Seleccionados</h3>
                  <div className="flex flex-wrap gap-2">
                    {localSelectedUsers.map((user) => (
                      <div
                        key={user.id}
                        className="inline-flex items-center gap-2 bg-white text-teal-700 px-3 py-1 rounded-full text-sm border border-teal-200 hover:bg-teal-50 transition-colors"
                      >
                        <UserIcon className="w-4 h-4" />
                        <span>{`${user.firstname} ${user.lastname}`}</span>
                        <button
                          onClick={() => setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id))}
                          className="ml-1 text-teal-400 hover:text-teal-600"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    {localSelectedUsers.length === 0 && (
                      <p className="text-sm text-teal-600 italic">No hay usuarios seleccionados</p>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    {filteredUsers.length} usuario(s) encontrado(s)
                  </h3>
                  <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto overflow-x-hidden">
                    {filteredUsers.map((user) => (
                      <motion.button
                        key={user.id}
                        onClick={() => {
                          const isSelected = localSelectedUsers.some(u => u.id === user.id);
                          if (isSelected) {
                            setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id));
                          } else {
                            setLocalSelectedUsers(prev => [...prev, user]);
                          }
                        }}
                        className={`flex items-center justify-between w-full p-3 rounded-lg border transition-all ${
                          localSelectedUsers.some(u => u.id === user.id)
                            ? 'bg-teal-50 border-teal-200 text-teal-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50'
                        }`}
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center">
                            <UserIcon className="w-4 h-4 text-teal-600" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="font-medium">{`${user.firstname} ${user.lastname}`}</span>
                            <span className="text-sm text-gray-500">{user.email}</span>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{user.departamento}</span>
                      </motion.button>
                    ))}
                  </div>
                </div>

                <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
                  <Button onClick={onClose} variant="outline">
                    Cancelar
                  </Button>
                  <Button 
                    onClick={handleSave} 
                    className="bg-teal-500 text-white hover:bg-teal-600"
                  >
                    {isSaving ? (
                      <>
                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                        Guardando...
                      </>
                    ) : (
                      'Confirmar Selección'
                    )}
                  </Button>
                </div>
              </>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);
};
  

const darkenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
};


const rowMotionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 30,
      mass: 1
    }
  },
  hover: {
    scale: 1.01,
    transition: { duration: 0.2 }
  },
  tap: {
    scale: 0.99,
    transition: { duration: 0.1 }
  }
};



const TaskRow: React.FC<TaskRowProps> = ({ task, onChangeTask, rowColor, groupProgress, calculateSemaphoreStatus,  inputColor, allTasks, index, isLoading, subprocessColor, columnWidths,  processName, subprocessName, agrupadorName   }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeSubtask, setActiveSubtask] = useState<SubTask | null>(null);
  const [showReminderPicker, setShowReminderPicker] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedSubtask, setSelectedSubtask] = useState<SubTask | null>(null);

  const [height, setHeight] = useState(0);
  const [isSubtaskModalOpen, setIsSubtaskModalOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedOrganismo, setEditedOrganismo] = useState(task.organismo);
  const currentUserId = sessionStorage.getItem('userId');

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

   // Add organismo edit handlers
   const handleOrganismoEdit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_organismo.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          taskId: task.id,
          organismo: editedOrganismo
        })
      });

      if (!response.ok) throw new Error('Failed to update organismo');
      
      onChangeTask({
        ...task,
        organismo: editedOrganismo
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating organismo:', error);
      toast.error('Error al actualizar el organismo');
    }
  };



  


  const handleDateBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (task.isClosed) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const [year, month, day] = value.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      alert('La fecha no puede ser anterior a hoy.');
      return;
    }

    onChangeTask({ ...task, [name]: value });
  };

  const handleDependencyBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newDependency = value.trim();
    
    // Validar que sea un número o una lista de números separados por comas
    if (newDependency === '' || /^(\d+)(,\d+)*$/.test(newDependency)) {
      onChangeTask({ ...task, dependencia: newDependency || null });
    } else {
      // Si el valor no es válido, restaurar el valor anterior
      e.target.value = task.dependencia?.toString() || '';
      alert('Por favor ingrese números válidos separados por comas');
    }
  };



  const handleFollowUpClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent row click event
    setShowReminderPicker(true);
};

const isFollowUpPending = (reminderString: string): boolean => {
  if (!reminderString) return false;

  const reminder = parseReminderValue(reminderString);
  if (!reminder) return false;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  switch (reminder.type) {
    case 'specific-date': {
      const followUpDate = new Date(reminder.value);
      followUpDate.setHours(0, 0, 0, 0);
      return followUpDate >= today;
    }
    case 'relative-time': {
      const [amount, unit] = reminder.value.split(':');
      const futureDate = new Date(today);
      if (unit === 'weeks') {
        futureDate.setDate(today.getDate() + (parseInt(amount) * 7));
      } else {
        futureDate.setDate(today.getDate() + parseInt(amount));
      }
      return true; // Siempre pendiente para recordatorios relativos
    }
    case 'progress-based': {
      const targetProgress = parseInt(reminder.value);
      return groupProgress < targetProgress;
    }
    default:
      return false;
  }
};

const getReminderIcon = (reminderString: string) => {
  if (!reminderString) return null;

  const reminder = parseReminderValue(reminderString);
  if (!reminder) return null;

  switch (reminder.type) {
    case 'specific-date':
      return <Calendar className="w-4 h-4" />;
    case 'relative-time':
      return <Clock className="w-4 h-4" />;
    case 'progress-based':
      return <AlertTriangle className="w-4 h-4" />;
    default:
      return null;
  }
};

const renderFollowUpStatus = (item: Task | SubTask) => {
  const reminderStatus = getReminderStatus(item);
  const isSubtask = !('subtasks' in item);

  return (
    <div 
      className="relative group"
      onClick={(e) => {
        e.stopPropagation();
        if (!item.isClosed) {
          setShowReminderPicker(true);
        }
      }}
    >
      <div 
        className={`
          w-6 h-6 rounded-full ${reminderStatus.color} 
          cursor-pointer hover:opacity-80 transition-all duration-200
          flex items-center justify-center
          ${item.isClosed ? 'opacity-50 cursor-not-allowed' : ''}
        `}
      >
        {reminderStatus.icon && (
          <div className={`text-white ${item.reminder_triggered ? 'animate-pulse' : ''}`}>
            {reminderStatus.icon}
          </div>
        )}
      </div>

      {/* Tooltip */}
      <div className={`
        absolute ${isSubtask ? 'right-full top-1/2 -translate-y-1/2 mr-2' : 'bottom-full left-1/2 -translate-x-1/2 mb-1'}
        px-2 py-1 bg-gray-800 text-white text-xs rounded 
        opacity-0 group-hover:opacity-100 transition-opacity duration-200 
        whitespace-nowrap z-50 pointer-events-none
        ${isSubtask ? 
          'before:content-[\'\'] before:absolute before:left-full before:top-1/2 before:-translate-y-1/2 before:border-4 before:border-transparent before:border-l-gray-800' : 
          'before:content-[\'\'] before:absolute before:top-full before:left-1/2 before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gray-800'
        }
      `}>
        {reminderStatus.tooltipText}
        {item.reminder_calculated_date && (
          <div className="text-xs text-gray-300">
            Fecha calculada: {formatDate(item.reminder_calculated_date)}
          </div>
        )}
      </div>
    </div>
  );
};













// Función para obtener el estado del recordatorio
const getReminderStatus = (item: Task | SubTask) => {
  // Si no hay recordatorio configurado
  if (!item.reminder_type || !item.reminder_value) {
    return {
      color: 'bg-gray-200',
      icon: null,
      tooltipText: 'Sin recordatorio'
    };
  }

  // Si el recordatorio está activo y se ha disparado
  if (item.reminder_active && item.reminder_triggered) {
    return {
      color: 'bg-red-500',
      icon: getReminderTypeIcon(item),
      tooltipText: getReminderTooltipText(item) 
    };
  }

  // Si el recordatorio está activo pero no se ha disparado
  if (item.reminder_active && !item.reminder_triggered) {
    return {
      color: 'bg-yellow-500',
      icon: getReminderTypeIcon(item),
      tooltipText: getReminderTooltipText(item)
    };
  }

  // Si el recordatorio está inactivo
  return {
    color: 'bg-gray-200',
    icon: getReminderTypeIcon(item),
    tooltipText: 'Recordatorio inactivo'
  };
};



const getReminderTypeIcon = (item: Task | SubTask) => {
  if (!item.reminder_type) return null;

  switch (item.reminder_type) {
    case 'specific-date':
      return <Calendar className="w-4 h-4" />;
    case 'relative-time':
      return <Clock className="w-4 h-4" />;
    case 'progress-based':
      return <AlertTriangle className="w-4 h-4" />;
    case 'task-expired':
      return <AlertTriangle className="w-4 h-4" />;
    case 'task-closed':
      return <Check className="w-4 h-4" />;
    default:
      return null;
  }
};

// Función para obtener el texto del tooltip
const getReminderTooltipText = (item: Task | SubTask): string => {
  if (!item.reminder_type || !item.reminder_value) {
    return 'Sin recordatorio';
  }

  switch (item.reminder_type) {
    case 'specific-date':
      return `Recordatorio para: ${formatDate(item.reminder_calculated_date || '')}`;
    case 'relative-time': {
      const value = item.reminder_value;
      const unit = value.endsWith('w') ? 'semanas' : 'días';
      const amount = value.slice(0, -1);
      return `Recordatorio en ${amount} ${unit}`;
    }
    case 'progress-based':
      return `Recordar al ${item.reminder_value}% de progreso`;
    case 'task-expired':
      return 'Tarea vencida';
    case 'task-closed':
      return 'Tarea cerrada';
    default:
      return 'Sin recordatorio';
  }
};



const getResponsableIconColor = (task: Task, currentUserId: string | null): string => {
  if (!task.responsible || task.responsible.length === 0) {
    return '#E5E7EB'; // Light gray for no responsables
  }
  
  const isCurrentUserResponsible = task.responsible.some(
    user => user.id.toString() === currentUserId
  );
  
  if (isCurrentUserResponsible) {
    return '#10B981'; // Green if current user is responsible
  }
  
  return '#4B5563'; // Dark gray if has responsables but current user isn't one
};


const getResolutionTypeStyle = (resolutionType?: string) => {
  switch (resolutionType) {
    case 'APROBADO':
      return 'bg-green-100 text-green-800 text-[10px]';  // Texto pequeño
    case 'RECHAZADO':
      return 'bg-red-100 text-red-800 text-[10px]';
    case 'DESISTIMIENTO':
      return 'bg-orange-100 text-orange-800 text-[10px]';
    case 'SILENCIO ADMINISTRATIVO POSITIVO':
      return 'bg-green-100 text-green-800 text-xs';
    case 'SILENCIO ADMINISTRATIVO NEGATIVO':
      return 'bg-red-100 text-red-800 text-xs';
    case 'NO ADMITIDO':
      return 'bg-red-100 text-red-800 text-xs';
    default:
      return 'bg-gray-100 text-gray-800 text-xs';
  }
};


const formattedResolutionType = (resolutionType: string) => {
  const resolutionLabel = resolutionTypes.find(rt => rt.value === resolutionType)?.label.toUpperCase();
 
  if (resolutionLabel === 'SILENCIO ADMINISTRATIVO POSITIVO') {
    return (
      <span className="flex items-center gap-1">
        <CheckCircle className="text-green-500" size={16} /> SAP
      </span>
    );
  }

  if (resolutionLabel === 'SILENCIO ADMINISTRATIVO NEGATIVO') {
    return (
      <span className="flex items-center gap-1">
        <XCircle className="text-red-500" size={16} /> SAN
      </span>
    );
  }

  return resolutionLabel;  // Para otros casos, simplemente retorna el label en mayúsculas.
};



  const getResolutionBadge = (subtask: SubTask) => {
    if (subtask.name === 'RESOLUCIÓN' && subtask.resolutionType) {
      const getResolutionStyle = () => {
        switch (subtask.resolutionType) {
          case 'APROBADO':
            return 'bg-green-100 text-green-800';
          case 'RECHAZADO':
            return 'bg-red-100 text-red-800';
          case 'DESISTIMIENTO':
            return 'bg-orange-100 text-orange-800';
          case 'SILENCIO ADMINISTRATIVO POSITIVO':
            return 'bg-green-100 text-green-800';
          case 'SILENCIO ADMINISTRATIVO NEGATIVO':
            return 'bg-red-100 text-red-800';
          case 'NO ADMITIDO':
            return 'bg-red-100 text-red-800';
          default:
            return 'bg-gray-100 text-gray-800';
        }
      };
  
      return (
        <span className={`ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${getResolutionStyle()}`}>
         {subtask.type === "RESOLUCIÓN" && subtask.resolutionType && (
  <span className={`px-1 py-0.5 rounded flex items-center gap-1 ${getResolutionTypeStyle(subtask.resolutionType)}`}>
   {formattedResolutionType(subtask.resolutionType)}

  </span>
)}
        </span>
      );
    }
    return null;
  };
  useEffect(() => {
    if (isExpanded && contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(contentHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  const rowMotionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 30,
        mass: 1
      }
    },
    hover: {
      scale: 1.01,
      transition: { duration: 0.2 }
    },
    tap: {
      scale: 0.99,
      transition: { duration: 0.1 }
    }
  };

const containerVariants = {
  open: { 
    minHeight: height, // Para la altura mínima
    opacity: 1,
    transition: { 
      minHeight: { type: "spring", stiffness: 100, damping: 20, duration: 0.5 }, // Suavizado para la expansión
      opacity: { duration: 0.5, ease: "easeInOut" } // Suavizado para la opacidad
    }
  },
  collapsed: { 
    minHeight: 0, // Para la altura mínima cuando está colapsado
    opacity: 0,
    transition: { 
      minHeight: { type: "spring", stiffness: 100, damping: 20, duration: 0.5 }, 
      opacity: { duration: 0.5, ease: "easeInOut" } // Suavizado para la opacidad cuando colapsa
    }
  }
};



  const handleSubtaskClick = (subtask: SubTask) => {

    setActiveSubtask(subtask);
  };

  const updateSubtaskInDatabase = async (subtaskData: SubtaskUpdateData): Promise<void> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating subtask');
      }
  
      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message || 'Error updating subtask');
      }
    } catch (error) {
      console.error('Error in updateSubtaskInDatabase:', error);
      throw error;
    }
  };

// Modificar el handleChangeSubtask en SubtaskModal para manejar correctamente los cambios de followUp
const handleChangeSubtask = async (updatedSubtask: SubTask) => {
  try {
    
    // Crear una copia profunda de las subtareas actuales
    let updatedSubtasks = task.subtasks ? 
      task.subtasks.map(st => ({...st})) : 
      [];
    
    // Encontrar el índice de la subtarea que estamos actualizando
    const subtaskIndex = updatedSubtasks.findIndex(st => st.id === updatedSubtask.id);
    
    if (subtaskIndex !== -1) {
      // Mantener los datos originales de la subtarea
      const originalSubtask = updatedSubtasks[subtaskIndex];
      
      // Actualizar solo la subtarea específica manteniendo sus propiedades originales
      updatedSubtasks[subtaskIndex] = {
        ...originalSubtask,           // Mantener propiedades originales
        ...updatedSubtask,           // Aplicar actualizaciones
        name: originalSubtask.name,   // Mantener nombre original
        type: originalSubtask.type, // Mantener tipo original
        orden: originalSubtask.orden,  // Mantener orden original
        isClosed: originalSubtask.isClosed // Mantener estado de cierre original
      };

      // Actualizar la tarea principal
      const updatedTask = {
        ...task,
        subtasks: updatedSubtasks
      };

      // Preparar datos para la base de datos
      const subtaskData = {
        id: originalSubtask.id,
        tarea_id: task.id,
        name: originalSubtask.name,
        type: originalSubtask.type,
        start: updatedSubtask.start || originalSubtask.start,
        end: updatedSubtask.end || originalSubtask.end,
        isClosed: originalSubtask.isClosed, // Mantener estado de cierre original
        progress: updatedSubtask.progress,
        resolutionType: originalSubtask.resolutionType, // Mantener tipo de resolución original
        orden: originalSubtask.orden,
        comments: updatedSubtask.comments,
        followUpDate: updatedSubtask.followUpDate,
        semaphoreStatus: updatedSubtask.semaphoreStatus
      };

   
 
      // Actualizar el estado
      onChangeTask(updatedTask);
      onChangeTask(updatedTask);

      toast.success('Cambios guardados correctamente');
    } else {
      toast.error('No se encontró la subtarea para actualizar');
    }
  } catch (error) {
    console.error('Error en handleChangeSubtask:', error);
    toast.error('Error al guardar los cambios');
  } finally {
  }
};

const handleSubtaskSelection = async (currentSubtask: SubTask, option: NewSubtaskOption) => {
  try {
    // Actualizar la subtarea actual como cerrada
    const updatedCurrentSubtask = {
      ...currentSubtask,
      isClosed: true,
      end: dateUtils.getTodayString(),
      progress: 100,
      semaphoreStatus: SemaphoreStatus.Gray
    };
  
    // Encontrar el máximo orden actual
    const maxOrden = Math.max(...(task.subtasks?.map(st => st.orden) || [0]));

    // Crear la nueva subtarea con orden incrementado
    const formattedStartDate = dateUtils.getTodayString(); // Fecha de inicio: hoy
    const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7); // Fecha de término: una semana después de hoy

    const newSubtask: SubTask = {
      name: option.name,
      type: option.type,
      responsible: Array.isArray(option.responsable) ? option.responsable : [],
      progress: 0,
      start: formattedStartDate, // Fecha de inicio en zona horaria local
      end: formattedEndDate,     // Fecha de término en zona horaria local
      duration: 1,
      organismo: currentSubtask.organismo,
      enabled: true,
      orden: maxOrden + 1, // Asignar el siguiente orden disponible
      comments: '',
      followUpDate: '',
      followUp: false,
      semaphoreStatus: SemaphoreStatus.Green,
      isClosed: false, 
      delayInDays: 0,
      resolutionType: option.resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' |'OTRO' | undefined
    };
    
    try {
      // Crear una nueva lista de subtareas
      let updatedSubtasks = task.subtasks ? [...task.subtasks] : [];
      
      // Actualizar la subtarea actual
      const currentSubtaskIndex = updatedSubtasks.findIndex(st => st.name === currentSubtask.name);
      if (currentSubtaskIndex !== -1) {
        updatedSubtasks[currentSubtaskIndex] = updatedCurrentSubtask;
      }

      // Agregar la nueva subtarea
      updatedSubtasks.push(newSubtask);
      
      // Reordenar las subtareas
      updatedSubtasks.sort((a, b) => {
        if (a.type === 'INGRESO') return -1;
        if (b.type === 'INGRESO') return 1;
        return a.orden - b.orden;
      });

      // Obtener la fecha máxima de las subtareas
      const maxEndDate = updatedSubtasks.reduce((maxDate, subtask) => {
        const subtaskEndDate = dateUtils.parseLocalDate(subtask.end);
        return subtaskEndDate > maxDate ? subtaskEndDate : maxDate;
      }, new Date(0));

      // Formatear la fecha máxima para la tarea principal
      const formattedMaxEndDate = dateUtils.formatLocalDate(maxEndDate);

      // Crear la tarea actualizada
      const updatedTask = {
        ...task,
        end: formattedMaxEndDate, // Fecha de término de la tarea principal según la fecha máxima de las subtareas
        subtasks: updatedSubtasks
      };

      // Actualizar en la UI
      onChangeTask(updatedTask);
      onChangeTask(updatedTask);
    } catch (error) {
      console.error('Error updating subtasks:', error);
      alert('Error updating subtasks. Please try again.');
    }
  } catch (error) {
    console.error('Error in handleSubtaskSelection:', error);
    alert('Error processing subtask selection. Please try again.');
  }
};




  const darkerColor = darkenColor(subprocessColor, 30); // 30 es la cantidad de oscurecimiento, ajusta según sea necesario

  
const lightenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => 
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
  );
};

// Uso
const lighterColor = lightenColor(subprocessColor, 115);
  
  const subtaskColumnWidths = {
    id: columnWidths.id, // Usando el mismo ancho que la columna ID principal
    description: columnWidths.description, // Mismo ancho que la descripción principal
    responsible: columnWidths.responsible, // Mismo ancho que el responsable principal
    start: columnWidths.dates, // Mismo ancho que las fechas principales
    end: columnWidths.dates, // Mismo ancho que las fechas principales
    dependencies: columnWidths.dependencies, // Mismo ancho que las dependencias principales
    followUp: columnWidths.followUp, // Mismo ancho que el seguimiento principal
    status: columnWidths.status // Mismo ancho que el estado principal
  };

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleRowClick = () => {
    setIsModalOpen(true);

  };
  const calculateDaysDelayed = (endDate: string): number => {
    const today = new Date();
    
    // Crear la fecha manualmente en la zona horaria local
    
    const [year, month, day] = endDate? endDate.split('-').map(Number) : [0, 0 ,0];
    const end = new Date(year, month - 1, day);  // Meses en JavaScript van de 0 a 11
  
    // Ajustar ambos a la medianoche para comparar solo las fechas
    today.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
  
    const diffTime = today.getTime() - end.getTime();
   // alert(`${end}-${endDate}`);  // Mostrar la fecha ajustada y la original
  
    return Math.max(0, Math.ceil(diffTime / (1000 * 3600 * 24)));
  };
  
  const daysDelayed = calculateDaysDelayed(task.end);


  //ACA LO DE CAMBIAR LA FECHA DE LAS TAREAS RETRASADAS
   
  useEffect(() => {
   updateDependentTaskStartDate();
   updateDependentTasksStatus(index);
  }, [task]); // Se ejecuta cada vez que la tarea cambia

  
  const updateDependentTasksStatus = (closedTaskIndex: number) => {
    const getMaxEndDate = (dependencies: number[]): Date | null => {
      const dates = dependencies
        .map((depIndex) => {
          const predecessorTask = allTasks[depIndex - 1];
          if (predecessorTask) {
            // Usar dateUtils para parsear la fecha en zona horaria local
            return dateUtils.parseLocalDate(predecessorTask.end);
          }
          return null;
        })
        .filter(Boolean) as Date[];
  
      return dates.length > 0 ? new Date(Math.max(...dates.map((date) => date.getTime()))) : null;
    };
  
    const closedTaskPosition = closedTaskIndex + 1;
  
    allTasks.forEach((task) => {
      if (task.dependencia) {
        const dependencias =
          typeof task.dependencia === "string"
            ? task.dependencia.split(",").map(Number)
            : [task.dependencia];
  
        // Verificar si es una tarea TRAMITACIÓN con dependencia igual a 0
        if (task.name === 'TRAMITACIÓN' && dependencias.includes(0)) {
          // Si la dependencia es 0, marcar como verde automáticamente
          task.semaphoreStatus = SemaphoreStatus.Green;
  
          // Establecer fecha de inicio y fin a hoy en zona horaria local
          const todayString = dateUtils.getTodayString();
          task.start = todayString;
          task.end = todayString;
  
          console.log('TRAMITACIÓN con dependencia igual a 0, comenzando en verde');
          return; // Pasar a la siguiente tarea
        }
  
        // Verificar si todas las dependencias están cerradas
        const allDependenciesClosed = dependencias.every((depPosition) => {
          const depTask = allTasks[depPosition - 1];
          return depTask && depTask.isClosed;
        });
  
        if (allDependenciesClosed) {
          const maxDependencyEndDate = getMaxEndDate(dependencias);
  
          if (maxDependencyEndDate) {
            // Actualizar estado del semáforo
            task.semaphoreStatus = calculateSemaphoreStatus(
              task.start,
              task.end,
              task.id,
              task.dependencia,
              task.isClosed,
              allTasks
            );
  
            // El código comentado de TRAMITACIÓN también se puede actualizar así:
            /*if (task.name === 'TRAMITACIÓN') {
              const todayString = dateUtils.getTodayString();
  
              if (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed) {
                // Si está en gris, mantener fecha según duración
                const startDate = dateUtils.parseLocalDate(task.start);
                const calculatedEndDate = new Date(startDate);
                calculatedEndDate.setDate(startDate.getDate() + (task.duracion * 7));
                const formattedEndDate = dateUtils.formatLocalDate(calculatedEndDate);
                
                task.end = formattedEndDate;
                 
                // Actualizar fechas de subtareas
                if (task.subtasks) {
                  task.subtasks.forEach(subtask => {
                    if (!subtask.isClosed) {
                      subtask.end = formattedEndDate;
                      subtask.semaphoreStatus = calculateSemaphoreStatus(
                        subtask.start,
                        subtask.end,
                        subtask.id ?? -1,
                        null,
                        subtask.isClosed,
                        allTasks,
                        task
                      );
                    }
                  });
                }
              } else {
                // Si no está en gris, la fecha final es hoy
                task.end = todayString;
                
                // Actualizar fechas de subtareas
                if (task.subtasks) {
                  task.subtasks.forEach(subtask => {
                    if (!subtask.isClosed) {
                      subtask.end = todayString;
                      subtask.semaphoreStatus = calculateSemaphoreStatus(
                        subtask.start,
                        subtask.end,
                        subtask.id ?? -1,
                        null,
                        subtask.isClosed,
                        allTasks,
                        task
                      );
                    }
                  });
                }
              }
            }*/
          }
        }
      }
    });
  };
  
/*
  // Función para calcular el estado del semáforo
  const calculateSemaphoreStatus = (
    start: string, 
    end: string, 
    taskId: number, 
    dependencia: string | number | null, 
    isClosed: boolean,
    allTasks?: Task[] | SubTask[]
  ): SemaphoreStatus => {
    const chileTimeZone = 'America/Santiago';
  
    // Function to check if all dependencies are closed, considering local IDs
    const areDependenciesClosed = (): boolean => {
      if (!dependencia || !allTasks) return true;
      
      // Parse dependencies as local IDs (1, 2, 3, etc.)
      const dependencies = typeof dependencia === 'string' 
        ? dependencia.split(',').map(Number)
        : [Number(dependencia)];
  
      // Verify if all local dependencies are closed
      return dependencies.every(depId => {
        const depTask = allTasks[depId - 1]; // Assuming local ID = index + 1
        console.log(`Checking local task dependency: Task ID = ${taskId}, Dependency = ${depId}, Is Closed = ${depTask?.isClosed}`);
        return depTask && depTask.isClosed;
      });
    };
  
    // If the task is already closed, mark it as green
    if (isClosed) {
      return SemaphoreStatus.Green;
    }
  
    // If dependencies are not closed, the task cannot start
    if (!areDependenciesClosed()) {
      return SemaphoreStatus.Gray;
    }
  
   
    // Fetch the current date in Chile timezone
    const now = new Date(new Intl.DateTimeFormat('en-US', { 
      timeZone: chileTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date()));
  
    // Parse start and end dates in Chile timezone
    const startDate = new Date(new Intl.DateTimeFormat('en-US', {
      timeZone: chileTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date(start)));
  
    const endDate = new Date(new Intl.DateTimeFormat('en-US', {
      timeZone: chileTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date(end)));
  
    const remainingTime = endDate.getTime() - now.getTime();
  
    if (now > endDate) {
      return SemaphoreStatus.Red; // Overdue
    } else if (remainingTime <= 2 * 24 * 60 * 60 * 1000) { // Less than 2 days
      return SemaphoreStatus.Orange; // About to expire
    } else if (remainingTime <= 5 * 24 * 60 * 60 * 1000) { // Less than 5 days
      return SemaphoreStatus.Yellow; // Near expiration
    } else if (now >= startDate) {
      return SemaphoreStatus.Green; // Started on time
    } else {
      return SemaphoreStatus.Gray; // Not started yet but within schedule
    }
  };
  
*/
  
  // Función para ajustar las fechas de las tareas dependientes de forma recursiva
  const updateDependentTaskStartDate = async () => {
    let tasksUpdated = true;
    let tasksToUpdate: Task[] = [];
  
    const getMaxEndDate = (dependencies: number[]): Date | null => {
      const dates = dependencies.map((depIndex: number) => {
        const predecessorTask = allTasks[depIndex - 1];
        if (predecessorTask) {
          const [year, month, day] = predecessorTask.end?predecessorTask.end.split('-').map(Number) : [0, 0 ,0];
          return new Date(year, month - 1, day);
        }
        return null;
      }).filter(Boolean) as Date[];
  
      return dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;
    };
  
    const handleTramitacionTask = (task: Task): void => {
      if (task.name === 'TRAMITACIÓN' && !task.isClosed) {
        const today = dateUtils.getTodayLocal();
        const formattedToday = dateUtils.formatLocalDate(today);
  
          // If semaphore is Gray, maintain original end date
          const endDate = dateUtils.parseLocalDate(task.start);
          endDate.setDate(endDate.getDate() + (task.duracion * 7));
          task.end = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
          tasksToUpdate.push(task);
  
          // Update subtasks dates to match parent task dates
          if (task.subtasks) {
            task.subtasks.forEach(subtask => {
              if (!subtask.isClosed) {
                subtask.start = task.start;
                subtask.end = task.end;
              }
            });
          }
        
      }
    };
  
    while (tasksUpdated) {
      tasksUpdated = false;
  
      allTasks.forEach(task => {
        // First handle TRAMITACIÓN specific logic
       // handleTramitacionTask(task);
  
        // Then handle dependencies if they exist
        if (task.dependencia && !task.isClosed) {
          const dependencias = typeof task.dependencia === 'string'
            ? task.dependencia.split(',').map(Number)
            : [task.dependencia];
  
          const maxDependencyEndDate = getMaxEndDate(dependencias);
  
          if (maxDependencyEndDate) {
            const newStartDate = new Date(maxDependencyEndDate);
            
            // Verify if all dependencies are closed
            const allDependenciesClosed = dependencias.every(depIndex => {
              const depTask = allTasks[depIndex - 1];
              return depTask && depTask.isClosed;
            });
  
            let maxDaysLate = 0;
  
            if (!allDependenciesClosed) {
              const today = dateUtils.getTodayLocal();
              today.setHours(0, 0, 0, 0);
            
              dependencias.forEach(depIndex => {
                const depTask = allTasks[depIndex - 1];
                if (depTask && !depTask.isClosed) {
                  const [year, month, day] = depTask.end?depTask.end.split('-').map(Number) : [0, 0 ,0];
                  const taskEndDate = new Date(year, month - 1, day);
                  taskEndDate.setHours(0, 0, 0, 0);
            
                  if (today > taskEndDate) {
                    const timeDiff = today.getTime() - taskEndDate.getTime();
                    const daysLate = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                    maxDaysLate = Math.max(maxDaysLate, daysLate);
                  }
                }
              });
            
              newStartDate.setHours(0, 0, 0, 0);
              newStartDate.setDate(newStartDate.getDate() + maxDaysLate + 1);
            }
  
            const formattedStartDate = `${newStartDate.getFullYear()}-${String(newStartDate.getMonth() + 1).padStart(2, '0')}-${String(newStartDate.getDate()).padStart(2, '0')}`;
  
            if (task.start !== formattedStartDate) {
              task.start = formattedStartDate;
  
              if (task.name !== 'TRAMITACIÓN' || task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed) {
                const newEndDate = new Date(newStartDate);
                newEndDate.setDate(newStartDate.getDate() + (task.duracion * 7));
                task.end = `${newEndDate.getFullYear()}-${String(newEndDate.getMonth() + 1).padStart(2, '0')}-${String(newEndDate.getDate()).padStart(2, '0')}`;
              }
  
              tasksUpdated = true;
              tasksToUpdate.push(task);
              
              if (task.name === 'TRAMITACIÓN' && !task.isClosed) {
                handleTramitacionTask(task);
              }
            }
          }
        }
      });
    }
  
    // Save all updated tasks to database
    if (tasksToUpdate.length > 0) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_multiple_tasks.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(tasksToUpdate.map(task => ({
            id: task.id,
            start: task.start,
            end: task.end,
            semaphoreStatus: task.semaphoreStatus,
            delayInDays: task.delayInDays || 0,
            subtasks: task.subtasks,

          })))
        });
        if (task.subtasks) {
          task.subtasks.forEach(subtask => {
            if (!subtask.isClosed) {
              subtask.start = task.start;
              subtask.end = task.end;
            }
          });
        }
  
        if (!response.ok) {
          throw new Error('Failed to update tasks in database');
        }
  
        const result = await response.json();
        console.log('Tasks updated successfully:', result.message);
      } catch (error) {
        console.error('Error updating tasks in database:', error);
      }
    }
  
    console.log('Tasks updated:', tasksToUpdate);
  };

  
const updateTasksInDatabase = async (tasks: Task[]) => {
  try {
    const tasksToUpdate = tasks.map(task => ({
      id: task.id,
      start: task.start,
      end: task.end,
      semaphoreStatus: task.semaphoreStatus,
      delayInDays: task.delayInDays || 0
    }));

    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_multiple_tasks.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tasksToUpdate),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to update tasks');
    }

    const result = await response.json();
    console.log(result.message);
  } catch (error) {
    console.error('Error updating tasks:', error);
  }
};

  



  const checkDependencies = (closedTaskGlobalId: number) => {
    // Encontrar la tarea cerrada
    const closedTaskIndex = allTasks.findIndex(task => task.id === closedTaskGlobalId);
    if (closedTaskIndex === -1) {
      console.error(`No se encontró ninguna tarea con el ID global ${closedTaskGlobalId}`);
      return;
    }
  
    const closedTask = allTasks[closedTaskIndex];
    const closedTaskPosition = closedTaskIndex + 1; // Posición basada en 1
  
    console.log(`Verificando dependencias para la tarea "${closedTask.name}" (posición ${closedTaskPosition})`);
  
    // Buscar tareas dependientes
    const dependentTasks = allTasks.filter(task => {
      if (typeof task.dependencia === 'string') {
        // Si la dependencia es una cadena (ej: "1,2,3"), la dividimos en un array de números
        const dependencias = task.dependencia.split(',').map(Number);
        // Verificar si la posición cerrada está en alguna de esas dependencias
        return dependencias.includes(closedTaskPosition);
      } else if (typeof task.dependencia === 'number') {
        // Si la dependencia es un número, compararla directamente
        return task.dependencia === closedTaskPosition;
      }
      return false;
    });
  
    if (dependentTasks.length > 0) {
      const dependentTasksInfo = dependentTasks.map(task => {
        const taskPosition = allTasks.indexOf(task) + 1; // Posición basada en 1
        return `- Tarea "${task.name}" en la posición ${taskPosition}`;
      }).join('\n');
  
    //  alert(`Las siguientes tareas dependen de la tarea "${closedTask.name}" (posición ${closedTaskPosition}):\n\n${dependentTasksInfo}`);
    } else {
    //  alert(`Ninguna tarea depende de la tarea "${closedTask.name}" (posición ${closedTaskPosition})`);
    }
  };
  

  const getSemaphoreStyle = (item: Task | SubTask) => {
    // Determine if we're dealing with a subtask
    const isSubtask = 'type' in item;
    
    // Get the appropriate color based on status
    const getStatusColor = () => {
      if (item.progress === 100) {
        return {
          backgroundImage: `repeating-conic-gradient(${subprocessColor} 0% 25%, #fff 0% 50%)`,
          backgroundSize: '10px 10px',
          border: `1px solid ${subprocessColor}`,
          borderRadius: '50%',
        };
      }

      const semaphoreColor = {
        [SemaphoreStatus.Red]: '#EF4444',
        [SemaphoreStatus.Orange]: '#FB923C',
        [SemaphoreStatus.Yellow]: '#FDE047',
        [SemaphoreStatus.Green]: '#4ADE80',
        [SemaphoreStatus.Gray]: '#E5E7EB'
      }[item.semaphoreStatus];

      return {
        backgroundColor: semaphoreColor,
        border: `1px solid ${semaphoreColor}`,
        borderRadius: '50%',
      };
    };

    return getStatusColor();
  };
/*
  const isFollowUpPending = () => {
    if (!task.followUpDate) return false;
    
    // Establecer fecha actual a medianoche en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Convertir la fecha de seguimiento a objeto Date en zona horaria local
    const [year, month, day] = task.followUpDate.split('-').map(Number);
    const followUpDate = new Date(year, month - 1, day);  // Ajuste del mes (0-11)
    followUpDate.setHours(0, 0, 0, 0);
    
    return followUpDate >= today;
  };
  */


  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString?dateString.split('-').map(Number) : [0, 0 ,0];
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };

  const formatDependencies = (dependencyId: string | number | null): string => {
    if (dependencyId === null) {
      return '';
    }
    return dependencyId.toString();
  };

  const [disableAnimations, setDisableAnimations] = useState(false);


  const handleUserIconClick = () => {
    setIsUserModalOpen(true); // Abrir el modal
  };

  const handleCloseModal = () => {
    setIsUserModalOpen(false);

    setIsModalOpen(false);
    setActiveSubtask(null);

  };
  
  return (
    <>
     <motion.tr
  variants={disableAnimations ? {} : rowMotionVariants}
  initial="hidden"
  animate="visible"
  whileHover="hover"
  whileTap="tap"
  style={{
    position: 'relative'
  }}
  onMouseEnter={() => {
    if (task.subtasks && task.subtasks.length > 0) {
      setDisableAnimations(true); // Deshabilita animaciones si hay subtareas
    }
    setIsHovered(true);
  }}
    onMouseLeave={() => !disableAnimations && setIsHovered(false)}
  onClick={(e) => {
    // Asegúrate de que el clic no siempre desactive las animaciones
    e.stopPropagation();
    if (!isModalOpen && !isUserModalOpen) {
      setDisableAnimations(false); // No deshabilites animaciones si no se abre nada
    }
  }}
  >

        <td className={`px-2 py-2 whitespace-nowrap text-sm ${columnWidths.id}`}>
        {task.subtasks && task.subtasks.length > 0 && (
            <button onClick={toggleExpand} className="ml-1">
              {isExpanded ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
            </button>
          )}
          <span className="ml-1">{index + 1}</span>
          
        </td>
        <td 
          className={`px-2 py-2 text-sm ${columnWidths.description}`}
          onClick={handleRowClick}
          style={{ cursor: 'pointer' }}
        >
          {task.name}
        </td>
         <td className={`px-2 py-2 text-center ${columnWidths.responsible}`}>
          <div className="flex justify-center">
            <UserSelectorIcon
              selectedUsers={Array.isArray(task.responsible) ? task.responsible : []}
              enabled={!task.isClosed}
              isClosed={task.isClosed}
              subprocessColor={subprocessColor}
              onClick={handleUserIconClick} // Manejar clic para abrir modal
            />
          </div>
        </td>
        <td className={`px-2 py-2 text-sm ${columnWidths.dates}`}>{formatDate(task.start)}</td>
        <td className={`px-2 py-2 text-sm ${columnWidths.dates}`}>
  {formatDate(task.end)}
  {daysDelayed > 0 && !task.isClosed && (
    <span className="ml-1 text-red-500 flex text-xs">
      ({`+${daysDelayed} días`})
    </span>
  )}
</td>
        <td className={`px-2 py-2 text-center text-sm ${columnWidths.dependencies}`}>{formatDependencies(task.dependencia)}</td>
        <td className={`px-2 py-2 text-center ${columnWidths.followUp}`}>
          {renderFollowUpStatus(task)}
        </td>
        <td className={`px-2 py-2 text-center ${columnWidths.status}`}>
          <div className="w-6 h-6 rounded-full mx-auto" style={getSemaphoreStyle(task)}></div>
        </td>
      </motion.tr>

     
      {/* Renderizado condicional del UserSelectorModal */}
    
        <UserSelectorModal
        isOpen={isUserModalOpen} // Controla si el modal está abierto
        onClose={handleCloseModal} // Llamado cuando el modal debe cerrarse
        
          users={users} // Pasa aquí tu lista de usuarios
          selectedUsers={Array.isArray(task.responsible) ? task.responsible : []}
          onUsersChange={(newUsers: User[]) => {
            onChangeTask({ ...task, responsible: newUsers });
            setDisableAnimations(false);
          }}
          
          enabled={!task.isClosed}
          taskId={task.id}
          groupId={index + 1}
          isClosed={task.isClosed}
          subprocessColor={subprocessColor}
        />

      <AnimatePresence>
  {isExpanded && task.subtasks && (
    <tr>
      <td colSpan={8} className="p-0">
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{
            type: "spring",
            damping: 30,
            stiffness: 300 
          }} 
          style={{ overflow: 'hidden' }}
        >
          <div className="w-full bg-white">
            <table className="w-full table-fixed">
              <tbody>
                {task.subtasks.map((subtask: SubTask, subIndex: number) => (
                  <tr 
                    key={subtask.id} 
                    className="border-b cursor-pointer hover:bg-gray-50"
                    style={{ backgroundColor: lightenColor(subprocessColor, 185) }}
                    onClick={() => handleSubtaskClick(subtask)}
                  >
                    <td className={`px-2 py-2 whitespace-nowrap text-sm ${subtaskColumnWidths.id}`}>
                      <span className="text-gray-500 ml-6">└─</span>
                      <span className="ml-1">{`${index + 1}.${subIndex + 1}`}</span>
                    </td>
                    <td className={`ml-1 px-2 py-2 text-sm ${subtaskColumnWidths.description} text-left`}>
                    <div className="flex items-center"> 
                              <span className='ml-2'>{subtask.name}</span>
                              {getResolutionBadge(subtask)}
                            </div>
                    </td>
                    <td className={`py-2 px-3 text-sm ${subtaskColumnWidths.responsible} text-center`}>
  {(() => {
    if (subtask.type === 'OBSERVACIÓN' || 
        (subtask.type === 'RESOLUCIÓN' && subtask.resolutionType && subtask.resolutionType !== 'DESISTIMIENTO')) {
      return <span className="text-sm text-gray-600 italic">{task.organismo || 'Sin organismo'}</span>;
    } else if (subtask.type === 'INGRESO' || 
              subtask.type === 'RESPUESTA' || 
              (subtask.type === 'RESOLUCIÓN' && subtask.resolutionType === 'DESISTIMIENTO')) {
      
    }
    return null;
  })()}
</td>
                    <td className={`py-2 px-3 text-sm ${subtaskColumnWidths.start} text-left`}>
                      {dateUtils.parseLocalDate(subtask.start).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }).replace(/\//g, '-')}
                    </td>
                    <td className={`py-2 px-3 text-sm ${subtaskColumnWidths.end} text-left`}>
                      {dateUtils.parseLocalDate(subtask.end).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }).replace(/\//g, '-')}
                    </td>

                    <td className={`py-2 text-center text-sm ${subtaskColumnWidths.dependencies} text-left`}>
                     {subtask.name==='INGRESO'?'':subtask.orden}
                     {/*subtask.dependsOn.map(dep => dep.subtaskId).join(', ')*/}
                    </td>
                    <td className={`px-2 py-2 text-center ${columnWidths.followUp}`}>
                      {renderFollowUpStatus(subtask)}
                    </td>
                    <td className={`px-2 py-2 text-center ${subtaskColumnWidths.status}`}>
                      <div className="w-6 h-6 rounded-full mx-auto" style={getSemaphoreStyle(subtask)} />
                    </td>
                  </tr>
                  
                ))}
              </tbody>
            </table>
          </div>
        </motion.div>
      </td>
   
      
    </tr>
  )}
</AnimatePresence>
<AnimatePresence>
        {showReminderPicker && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={(e) => {
            if (e.target === e.currentTarget) setShowReminderPicker(false);
          }}>
            <ReminderPicker
              currentDate={task.followUpDate}
              onDateChange={(reminderDate) => {
                onChangeTask({ 
                  ...task, 
                  followUpDate: reminderDate 
                });
                setShowReminderPicker(false);
              }}
              onClose={() => setShowReminderPicker(false)}
              groupProgress={groupProgress}
              isDisabled={task.isClosed}
            />
          </div>
        )}
      </AnimatePresence>
      <TaskModal 
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
          setDisableAnimations(false); // Restablecer aquí también
        }}        
        task={task}
        onChangeTask={onChangeTask}
        backgroundColor={inputColor}
        allTasks={allTasks}
        agrupadorId={task.id}
        daysDelayed={daysDelayed}
        groupProgress={groupProgress}
        processName={processName} // Pasando el nombre del proceso
        subprocessName={subprocessName} // Pasando el nombre del subproceso
        agrupadorName={agrupadorName}

      />
       {activeSubtask && (
         <SubtaskModal
         isOpen={activeSubtask !== null}
         onClose={() => {
          setActiveSubtask(null);
          setDisableAnimations(false);
        }}  
        subtask={activeSubtask}
        onChangeTask={onChangeTask}
     
        onChangeSubtask={handleChangeSubtask}

        onAddNewSubtask={(option) => handleSubtaskSelection(activeSubtask, option)}
        groupProgress={groupProgress}

        backgroundColor={inputColor}
        mainTask={task}
         processName={processName}
         subprocessName={subprocessName}
     
         agrupadorName={agrupadorName}
       />
      )}
    </>
  );
};



interface NewSubtaskOption {
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  name: string;
  resolutionType: string;
  responsable: User[];
  organismo: string;
}







const resolutionTypes = [
  {
    value: 'APROBADO',
    label: 'Aprobado',
    icon: <Check className="h-5 w-5" />,
    color: 'text-green-700',
    bgColor: 'bg-green-100'
  },
  {
    value: 'RECHAZADO',
    label: 'Rechazado',
    icon: <X className="h-5 w-5" />,
    color: 'text-red-700',
    bgColor: 'bg-red-100'
  },
  {
    value: 'DESISTIMIENTO',
    label: 'Desistimiento',
    icon: <AlertTriangle className="h-5 w-5" />,
    color: 'text-orange-700',
    bgColor: 'bg-orange-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO POSITIVO',
    label: 'SILENCIO ADMINISTRATIVO POSITIVO',
    icon: <div className="flex items-center">
            <CheckCircle className="h-5 w-5 mr-1" />
            <span className="text-xs font-medium">SAP</span>
          </div>,
    color: 'text-green-700',
    bgColor: 'bg-green-50'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    label: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    icon: <div className="flex items-center">
            <XCircle className="h-5 w-5 mr-1" />
            <span className="text-xs font-medium">SAN</span>
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  },
  {
    value: 'NO ADMITIDO',
    label: 'No Admitido',
    icon: <div className="flex items-center">
            <svg viewBox="0 0 24 24" className="h-5 w-5 mr-1 text-red-700">
              <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
              <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2"/>
            </svg>
            
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  }
];

// Actualizamos SubtaskModal para incluir la funcionalidad de fecha de seguimiento
interface SubtaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  subtask: SubTask;
  onChangeTask: (task: Task) => void;
  onChangeSubtask: (updatedSubtask: SubTask) => void;
  onAddNewSubtask: (option: NewSubtaskOption) => void;
  backgroundColor: string;
  mainTask: Task;
  groupProgress?: number;
  processName?: string;
  subprocessName?: string;
  agrupadorName?: string;
  daysDelayed?: number; // Agregar esta prop
    forceTableRerender?: () => void;  // Agregamos esta línea

}


interface ResolutionTypeOption {
  value: string;
  label: string;
  icon: JSX.Element;
  color: string;
  bgColor: string;
}



const SubtaskModal: React.FC<SubtaskModalProps> = ({
  isOpen,
  onClose,
  subtask,
  onChangeTask,
  onChangeSubtask,
  onAddNewSubtask,
  backgroundColor,
  mainTask,
  groupProgress,
  processName,
  subprocessName,
  agrupadorName,
  daysDelayed
}) => {
  const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
  const [editedName, setEditedName] = useState(subtask.name);
  const [comments, setComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [localStartDate, setLocalStartDate] = useState(subtask.start);
  const [localEndDate, setLocalEndDate] = useState(subtask.end);
  const [isLoading, setIsLoading] = useState(false);
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedLastName = sessionStorage.getItem('lastName');
  const [showResolutionTypeDialog, setShowResolutionTypeDialog] = useState(false);
  const [showNewSubtaskDialog, setShowNewSubtaskDialog] = useState(false);
  const [selectedResolutionType, setSelectedResolutionType] = useState('');
  const [selectedNewSubtaskType, setSelectedNewSubtaskType] = useState<NewSubtaskOption | null>(null);
  const formatDateDDMMYYYY = (dateInput: string | Date): string => {
    // Si es un string, parsearlo correctamente
    if (typeof dateInput === 'string') {
      // Asumiendo que el input viene en formato 'YYYY-MM-DD'
      const [year, month, day] = dateInput.split('-').map(Number);
      // Crear la fecha usando la zona horaria local
      return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
    }
    
    // Si es un objeto Date
    const day = String(dateInput.getDate()).padStart(2, '0');
    const month = String(dateInput.getMonth() + 1).padStart(2, '0');
    const year = dateInput.getFullYear();
    return `${day}/${month}/${year}`;
  };


  // Función auxiliar para extraer el número de una observación o respuesta
const extractNumber = (name: string): number => {
  const match = name.match(/\d+$/);
  return match ? parseInt(match[0]) : 0;
};


const getNextSubtaskOptions = (
  currentType: string,
  parentTask: Task,
  currentSubtask: SubTask
): NewSubtaskOption[] => {
  // Helper function to generate correct name
  const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
    if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
    
    const existingSubtasks = parentTask.subtasks || [];
    const currentNumber = extractNumber(currentSubtask.name);
    
    if (type === 'OBSERVACIÓN') {
      return `OBSERVACIÓN ${currentNumber + 1}`;
    } else {
      return `RESPUESTA ${currentNumber}`;
    }
  };

  switch (currentType) {
    case 'INGRESO':
      return [
        {
          type: 'OBSERVACIÓN',
          name: 'OBSERVACIÓN 1',
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        },
        {
          type: 'RESOLUCIÓN',
          name: generateName('RESOLUCIÓN'),
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        }
      ];

    case 'OBSERVACIÓN':
      const observationNumber = parseInt(subtask.name.split(' ')[1]) || 1;
      return [
        {
          type: 'RESPUESTA',
          name: `RESPUESTA ${observationNumber}`,
          resolutionType: '',
          responsable: mainTask.responsible || [],
          organismo: mainTask.organismo || ''
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        }
      ];

    case 'RESPUESTA':
      const responseNumber = parseInt(subtask.name.split(' ')[1]) || 1;
      return [
        {
          type: 'OBSERVACIÓN',
          name: `OBSERVACIÓN ${responseNumber + 1}`,
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        }
      ];

    default:
      return [];
  }
};


  const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);

  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  

  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveChanges();
    }
  };


  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  

  const isEndDateValid = useMemo(() => {
    const startDate = dateUtils.parseLocalDate(localStartDate);
    const endDate = dateUtils.parseLocalDate(localEndDate);
    return endDate >= startDate;
  }, [localStartDate, localEndDate]);
  


  
  
  // Efectos para manejo de comentarios
  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [subtask.comments, isOpen]);

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);

  // Manejadores de eventos
  const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (subtask.isClosed) return;

    const parsedDate = dateUtils.parseLocalDate(value);
    const today = dateUtils.getTodayLocal();

    if (parsedDate < today) {
      toast.error('La fecha no puede ser anterior a hoy.');
      return;
    }

    if (name === 'start') {
      setLocalStartDate(value);
    } else if (name === 'end') {
      setLocalEndDate(value);
    }
  };



    
    const handleAddComment = async () => {
      if (!newComment.trim()) return;
  
      try {
        const timestamp = new Date().toLocaleString('es-CL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
  
        const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
        
        // Parsear comentarios existentes
        let currentComments = [];
        try {
          currentComments = subtask.comments ? JSON.parse(subtask.comments) : [];
        } catch (error) {
          console.error('Error parsing comments:', error);
          currentComments = [];
        }
  
        // Agregar nuevo comentario manteniendo los anteriores
        const updatedComments = Array.isArray(currentComments) 
          ? [...currentComments, commentWithTimestamp]
          : [commentWithTimestamp];
          
        const newCommentsString = JSON.stringify(updatedComments);
  
        // Actualizar subtarea
        const updatedSubtask = {
          ...subtask,
          id: subtask.id,
          name: subtask.name,
          tarea_id: mainTask.id,
          comments: newCommentsString
        };
  
        // Guardar en BD
        await updateSubtaskInDB(updatedSubtask);
  
        // Actualizar estados locales
        subtask.comments = newCommentsString;
        setComments(updatedComments);
        onChangeSubtask(updatedSubtask);
        setNewComment('');
  
        // Scroll al último comentario
        if (commentsContainerRef.current) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
  
      } catch (error) {
        console.error('Error al agregar comentario:', error);
        toast.error('Error al agregar el comentario');
      }
    };

// Modifica la función updateSubtaskInDB
const   updateSubtaskInDB = async (subtaskData: any): Promise<void> => {
  try {

    // Process dependsOn array before sending
    const dependencies = Array.isArray(subtaskData.dependsOn) && subtaskData.dependsOn.length > 0
      ? subtaskData.dependsOn.map((dep: any) => dep.subtaskId)
      : [];
      console.log("subtaskData:", subtaskData);
     console.trace();
   
      const formattedSubtaskData = {
        action: 'update',
        subtask: {
          id: subtaskData.id, 
          tarea_id: mainTask.id,
          name: subtaskData.name || subtaskData.nombre || 'Sin Nombre', // Prioridad a 'name' o 'nombre'
          type: subtaskData.type || subtaskData.tipo || subtaskData.type || 'Sin Tipo', // Prioridad a 'type', 'tipo', o 'type'
          responsible: subtaskData.responsible,
          organism: subtaskData.organism || subtaskData.organismo,
          progress: subtaskData.progress,
          start: dateUtils.parseAndFormatReadableYYYY(subtaskData.start),
          end: dateUtils.parseAndFormatReadableYYYY(subtaskData.end),
          duration: subtaskData.duration,
          dependsOn: JSON.stringify(subtaskData.dependsOn),
          enabled: subtaskData.enabled,
          resolutionType: subtaskData.resolutionType || null,
          resolucion_tipo: subtaskData.resolutionType || null,
          orden: subtaskData.orden,
          comments: subtaskData.comments,
          isClosed: subtaskData.isClosed, 
          followUpDate: subtaskData.followUpDate,
          semaphoreStatus: subtaskData.semaphoreStatus,
        }
      };

      
 //   alert(JSON.stringify(formattedSubtaskData))
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedSubtaskData)
    });

   
    if (!response.ok) {
      throw new Error('Error updating subtask');
    }

    const result = await response.json();
    if (!result.success) {

      throw new Error(result.message || 'Error updating subtask');
    }

  } catch (error) {
    console.error('Error in updateSubtaskInDB:', error);
    //alert(JSON.stringify(subtaskData))
    throw error;
  }
};
  
  // Función auxiliar para crear subtareas en la BD
const createSubtaskInDB = async (subtaskData: any) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'create',
        subtask: subtaskData
      }),
    });

    if (!response.ok) {
      throw new Error('Error al crear la subtarea');
    }

    return await response.json();
  } catch (error) {
    console.error('Error en createSubtaskInDB:', error);
    throw error;
  }
};

const handleResolutionTypeSelection = async (resolutionType: string) => {
  try {
    setIsLoading(true);
    const today = dateUtils.getTodayString();

    // Crear la nueva subtarea (si aplica)
    const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);
    const shouldCreateNextSubtask = nextOptions.length > 0;

    let newSubtask: SubTask | null = null;
    if (shouldCreateNextSubtask) {
      newSubtask = {
        id: mainTask.id,
        name: nextOptions[0].name,
        type: nextOptions[0].type,
        responsible: nextOptions[0].responsable,
        progress: 0,
        start: today,
        end: dateUtils.addDaysToDate(today, 7),
        duration: 1,
        organismo: subtask.organismo,
        enabled: true,
        orden: subtask.orden + 1,
        comments: '',
        followUpDate: '',
        followUp: false,
        semaphoreStatus: SemaphoreStatus.Green,
        isClosed: false,
        delayInDays: 0,
        dependsOn: subtask.dependsOn
      };
    }

    // Crear la tarea actualizada
    const updatedTask = {
      ...mainTask,
      subtasks: mainTask.subtasks?.map(st => 
        st.id === subtask.id 
          ? { 
              ...st, 
              isClosed: true, 
              progress: 100, 
              end: today,
              resolutionType: subtask.name !== 'RESOLUCIÓN' ? undefined : resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO',
              resolucion_tipo: subtask.name !== 'RESOLUCIÓN' ? undefined : resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO'
            }
          : st
      )
    };

    // Añadir nueva subtarea si existe
    if (newSubtask) {
      updatedTask.subtasks = updatedTask.subtasks?.concat(newSubtask);
    }

    console.log('Tarea actualizada:', updatedTask);
    if (mainTask.name === 'TRAMITACIÓN' && mainTask.subtasks) {
      const allSubtasksClosed = mainTask.subtasks.every(subtask => subtask.isClosed);
      if (allSubtasksClosed) {
        const updatedTask = {
          ...mainTask,
          isClosed: true,
          progress: 100,
          end: dateUtils.getTodayString()
        };
       
      }
    }
    // Actualizar en la base de datos
    await updateSubtaskInDB({
      ...subtask,
      id: subtask.id,
      tarea_id: mainTask.id,
      isClosed: true,
      progress: 100,
      end: today,
      resolutionType: resolutionType,
      resolucion_tipo: resolutionType
    });

    // Si hay nueva subtarea, crearla en la base de datos
    if (newSubtask) {
      await createSubtaskInDB({
        ...newSubtask,
        tarea_id: mainTask.id
      });
    }

    // Actualizar todo de una vez
    onChangeTask(updatedTask);

    // Cerrar diálogos
    setShowResolutionTypeDialog(false);
    setShowNewSubtaskDialog(false);
    onClose();

  } catch (error) {
    console.error('Error al cerrar resolución:', error);
    toast.error('Error al cerrar la resolución');
  } finally {
    setIsLoading(false);
  }
};


const handleCloseSubtask = async () => {
  try {
    setIsLoading(true);

    if (
      mainTask.name === 'TRAMITACION' &&
      mainTask.semaphoreStatus === SemaphoreStatus.Gray
    ) {
      toast.error('No se puede cerrar una subtarea cuando TRAMITACIÓN no ha iniciado');
      return;
    }
    if (subtask.type === 'RESOLUCIÓN') {
      setSelectedResolutionType('');
      setShowResolutionTypeDialog(true);
    } else {

    const today = dateUtils.getTodayString();
    const todayDate = dateUtils.getTodayLocal();
    //alert(`${today}   ${todayDate}`)
    const updatedSubtaskData: SubTask = {
      ...subtask, 
      id: subtask.id, 
      name: subtask.name,
      type: subtask.type,
      organismo: subtask.organismo,
      start: subtask.start,
      end: today,
      tarea_id: mainTask.id,
      isClosed: true,
      comments: subtask.comments, 
      followUpDate: subtask.followUpDate,
      semaphoreStatus: subtask.semaphoreStatus,
      progress: 100,
      duration: subtask.duration,
      orden: subtask.orden,
      enabled: true,
    }; 

    await updateSubtaskInDB(updatedSubtaskData);

    // Si es una subtarea de tipo RESOLUCIÓN, cerrar TRAMITACIÓN
   

      setShowNewSubtaskDialog(true);
    }
    
  } catch (error) {
    console.error('Error:', error);
    toast.error('Error al cerrar la subtarea');
  } finally {
    setIsLoading(false);
  }
};


  const handleNewSubtaskSelection = async (option: NewSubtaskOption) => {
    try {
      setIsLoading(true);
      const formattedStartDate = dateUtils.getTodayString();
      const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7);

      const newSubtaskData = {
        tarea_id: mainTask.id,
        nombre: option.name,
        tipo: option.type,
        responsable: option.responsable,
        organismo: subtask.organismo,
        progreso: 0,
        fecha_inicio: formattedStartDate,
        fecha_termino: formattedEndDate,
        duracion: 1,
        isClosed: false,
        dependencias: JSON.stringify([{
          groupId: mainTask.id,
          taskId: mainTask.id,
          subtaskId: subtask.id
        }]),
        enabled: true,
        orden: subtask.orden + 1,
              semaphoreStatus: SemaphoreStatus.Green // Iniciar en estado verde

      };

      const result = await createSubtaskInDB(newSubtaskData);
    //  alert(JSON.stringify(newSubtaskData))
      if (result.success) {
          onAddNewSubtask(option);
     //   onChangeSubtask({ ...subtask, ...newSubtaskData });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al crear nueva subtarea');
    } finally {
      setIsLoading(false);
      setShowNewSubtaskDialog(false);
      onClose();
    }
  };


  
  const handleOpenSubtask = async () => {
    try {
      setIsLoading(true);
      
      const startDate = dateUtils.parseLocalDate(subtask.start);
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + (subtask.duration * 7));
      const formattedEndDate = dateUtils.formatLocalDate(newEndDate);

      const updatedSubtaskData = {
        ...subtask,
        tarea_id: mainTask.id,
        id: subtask.id,
        isClosed: false,
        progress: 0,
        end: formattedEndDate,
        fecha_termino: formattedEndDate
      };

      await updateSubtaskInDB(updatedSubtaskData);
      onChangeSubtask(updatedSubtaskData);
      
      onChangeTask({...mainTask});
      toast.success('Subtarea abierta exitosamente');
      onClose();
    } catch (error) {
      console.error('Error al abrir subtarea:', error);
      toast.error('Error al abrir la subtarea');
    } finally {
      setIsLoading(false);
    }
  };

  
  
const contentVariants = {
  expanded: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  },
  collapsed: { 
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }
};
  

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);

      if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
        toast.error('La fecha de término no puede ser anterior a la fecha de inicio');
        return;
      }

      const updatedSubtask = {
        ...subtask,
        nombre: subtask.name,
        tarea_id: mainTask.id,
        start: localStartDate,
        end: localEndDate,
        isClosed: subtask.isClosed,
        progress: subtask.progress,
        
      };

      await updateSubtaskInDB(updatedSubtask);
      onChangeSubtask(updatedSubtask);
      toast.success('Cambios guardados exitosamente');
      onClose();
    } catch (error) {
      console.error('Error al guardar cambios:', error);
      toast.error('Error al guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <AnimatePresence>
    {isOpen && (
     <>
       <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 10002 }}>
       <Toaster
   position="top-right"
   toastOptions={{
     duration: 5000, // Duración más prolongada para mayor visibilidad
     style: {
   
       zIndex: 10002, // Asegúrate de que esté por encima de otros elementos
     },
     success: {
       style: {
         background: '#38A169', // Verde para mensajes de éxito
         color: '#FFFFFF',
       },
     },
     error: {
       style: {
         background: '#E53E3E', // Rojo para errores
         color: '#FFFFFF',
       },
     },
    
   }}
 />
           </div>
       <motion.div
         initial="hidden"
         animate="visible"
         exit="exit"
         variants={contentVariants}
         className="fixed inset-0 flex items-center justify-center  z-[10001] "
       >
           <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
           <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
           <motion.div 
             className="bg-white rounded-lg overflow-hidden shadow-xl"
             variants={contentVariants}
           >
      
 
             <div
               className="bg-teal-600 p-3 text-white"
              
             >
                      <div className="relative">
   {/* Botón de cierre */}
   <button
     onClick={onClose}
     className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
   >
     <X size={20} />
   </button>
 </div>
     <h3 className="text-base font-semibold flex items-center space-x-2">
       <MdBusiness className="text-white w-5 h-5" /> 
       <span className="text-sm opacity-80 tracking-wide">
  {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName || ''}`}
</span>
     </h3>
     <div className="flex mt-4 mb-3">
 
     <div className="flex items-center">
       <span className="text-lg font-bold text-white">{subtask.name}</span>
     </div>
   {/* Estado de la tarea */}
 <div className="ml-3 flex items-center">
 <div
   className={`px-3 py-1 rounded-full text-sm font-medium ${
     subtask.progress === 100 && subtask.isClosed
       ? `${backgroundColor} text-gray-800`
       : subtask.styles?.progressColor === '#EF4444' 
         ? 'bg-red-100 text-red-700'
         : subtask.styles?.progressColor === '#FB923C'
         ? 'bg-orange-100 text-orange-700'
         : subtask.styles?.progressColor === '#FDE047'
         ? 'bg-yellow-100 text-yellow-700'
         : subtask.styles?.progressColor === '#4ADE80'
         ? 'bg-green-100 text-green-700'
         : 'bg-gray-100 text-gray-700'
   }`}
   style={
     subtask.progress === 100 && subtask.isClosed
       ? {
           backgroundImage: `repeating-conic-gradient(
             ${lightenColor(backgroundColor, 80)} 0% 25%, 
             #ffffff 25% 50%
           )`,
           backgroundSize: '10px 10px',
         }
       : undefined
   }
 >
   {subtask.progress === 100 && subtask.isClosed
     ? 'Cerrada'
     : subtask.styles?.progressColor === '#EF4444'
     ? `Retrasada por ${daysDelayed} día(s)`
     : subtask.styles?.progressColor === '#FB923C'
     ? 'Próxima a vencer'
     : subtask.styles?.progressColor === '#FDE047'
     ? 'A tiempo, pero próxima'
     : subtask.styles?.progressColor === '#4ADE80'
     ? 'A tiempo'
     : 'No iniciada'}
 </div>
 </div>
 
     </div>
     <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
       <span className="flex items-center gap-1">
         <Calendar className="w-4 h-4" />
         {formatDateDDMMYYYY(subtask.start)}
       </span>
       <span className="flex items-center gap-1">
         <Calendar className="w-4 h-4" />
         {formatDateDDMMYYYY(subtask.end)}
       </span>
 
     </div>
  
     </div>
   
   </motion.div>
 
 <div className="border-b">
           <nav className="flex">
             <button
               onClick={() => setActiveTab('details')}
               className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
             >
               Detalles
             </button>
             <button
               onClick={() => setActiveTab('followup')}
               className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
             >
               Seguimiento
             </button>
           </nav>
         </div>
 
         {activeTab === 'details' ? (
   <div className="p-4">
    
 
     {/* Grid de campos */}
     <div className="grid grid-cols-2 gap-3 mb-4">
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Nombre
         </label>
         <input
           type="text"
           value={editedName || ''}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed}
         />
       </div>
 
       <div></div>
 
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Fecha de Inicio
         </label>
         <input
           type="date"
           name="start"
           value={localStartDate}
           onChange={handleLocalDateChange}
           onKeyDown={handleKeyDown}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed && subtask.progress === 100}
         />
       </div>
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Fecha de Término
         </label>
         <input
           type="date"
           name="end"
           value={localEndDate}
           onChange={handleLocalDateChange}
           onKeyDown={handleKeyDown}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed && subtask.progress === 100}
         />
       </div>
     </div>
 
     {/* Actualizar sección de comentarios */}
     <div className="comments-section">
       <div className="flex items-center justify-between mb-2">
         <h3 className="text-lg font-medium">Comentarios</h3>
         {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
       </div>
 
       <div
         ref={commentsContainerRef}
         className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
       >
 
 {subtask.comments ? (
   (() => {
     try {
       console.log('Contenido original de subtask.comments:', subtask.comments);
       
       // Parsear subtask.comments
       const parsedComments = JSON.parse(subtask.comments);
       console.log('Comentarios parseados:', parsedComments);
 
       // Validar que parsedComments sea un array con datos
       if (Array.isArray(parsedComments) && parsedComments.length > 0) {
         return parsedComments.map((comment, index) => {
           // Dividir y procesar cada comentario
           const [datePart, userAndContent] = comment.split('] ');
           const date = datePart.replace('[', '').trim();
           const [user, ...contentParts] = userAndContent.split(':');
           const content = contentParts.join(':').trim();
           const isCurrentUser = user === `${storedFirstName} ${storedLastName}`;
 
           return (
             <div
               key={index}
               className={`mb-3 last:mb-0 flex ${
                 isCurrentUser ? 'justify-start' : 'justify-end'
               }`}
             >
               <div
                 className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                   isCurrentUser
                     ? 'bg-green-100 text-green-900'
                     : 'bg-blue-100 text-blue-900'
                 }`}
                 style={{
                   maxWidth: 'calc(100% - 20px)',
                   wordBreak: 'break-word',
                 }}
               >
                 <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                   <span className="font-medium">{user}</span>
                   <span className="text-gray-400">•</span>
                   <span className="text-gray-400">{date}</span>
                 </div>
                 <p className="text-sm whitespace-pre-wrap">{content}</p>
               </div>
             </div>
           );
         });
       } else {
         return <p className="text-gray-500 italic">No hay comentarios.</p>;
       }
     } catch (error) {
       console.error('Error al procesar comentarios:', error);
       return (
         <p className="text-red-500 italic">Error al cargar comentarios.</p>
       );
     }
   })()
 ) : (
   <p className="text-gray-500 italic">No hay comentarios disponibles.</p>
 )}
 
 
       </div>
 
       <div className="mt-4 flex gap-2">
         <textarea
           value={newComment}
           onChange={(e) => setNewComment(e.target.value)}
           onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (newComment.trim() && !subtask.isClosed && !isLoadingComments) {
                await handleAddComment();
                setNewComment('');
              }
            }
          }}
           placeholder="Escribir un comentario..."
           rows={2}
           className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
           disabled={subtask.isClosed}
           maxLength={250}
         />
         <button
           onClick={async () => {
             await handleAddComment();
             setNewComment(''); // Limpiar input después de agregar el comentario
           }}
           disabled={subtask.isClosed || !newComment.trim() || isLoadingComments}
           className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
         >
           Agregar
         </button>
       </div>
     </div>
      {/* Botones de acción */}
      <div className="flex justify-between mt-4 pt-2 border-t">
    <button
 type="button"
 className={`px-3 py-1.5 rounded text-sm font-medium ${
   subtask.progress === 100
     ? 'bg-green-600 hover:bg-green-700 text-white'
     : subtask.semaphoreStatus === SemaphoreStatus.Gray 
       ? 'bg-gray-400 text-white cursor-not-allowed' 
       : 'bg-red-600 hover:bg-red-700 text-white'
 }`}
 onClick={subtask.progress === 100 ? handleOpenSubtask : handleCloseSubtask}
 disabled={subtask.semaphoreStatus === SemaphoreStatus.Gray && subtask.progress === 0}
>
 {subtask.progress === 100 ? "ABRIR SUBTAREA" : "CERRAR SUBTAREA"}
</button>
                 <div className="relative">
                 <button
   type="button"
   onClick={() => {
     if (!isEndDateValid) {
       toast.error('La fecha de fin no puede ser anterior a la fecha de inicio.');
     } else {
       handleSaveChanges();
     }
   }}
   className={`px-3 py-1.5 text-white text-sm font-medium rounded ${
     isEndDateValid
       ? 'bg-blue-600 hover:bg-blue-700'
       : 'bg-gray-400 cursor-not-allowed'
   }`}
 >
   Guardar Cambios
 </button>
 </div>
                 </div>
   </div>
   
 ) :            
 <motion.div
 initial="hidden"
 animate="visible"
 exit="exit"
 variants={modalVariants}
 className="space-y-4 "
 >  { 
 //renderTrackingContent()
 }
       
 </motion.div>
 }
 </Dialog>
 
 
 
                </motion.div>
                
           </motion.div>
           
           </>
       )}
       {showResolutionTypeDialog && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
    <motion.div
      className="bg-white rounded-lg p-6 w-full max-w-md relative"
      variants={modalVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <button
        onClick={() => setShowResolutionTypeDialog(false)}
        className="absolute top-2 right-2 text-gray-300 hover:text-gray-400 z-10"
      >
        <X size={24} />
      </button>

      <h3 className="text-lg font-semibold text-gray-900 mb-4">
        Seleccionar tipo de resolución
      </h3>
      <div className="space-y-2">
        {resolutionTypes.map((type) => (
          <button
            key={type.value}
            onClick={() => handleResolutionTypeSelection(type.value)}
            className={`w-full flex items-center p-3 rounded-lg transition-all duration-200 ${type.bgColor} hover:opacity-90`}
          > 
            <div className={`mr-3 ${type.color}`}>{type.icon}</div>
            <span className={`font-medium ${type.color}`}>{type.label}</span>
          </button>
        ))}
      </div>
    </motion.div>
  </div>
)}

         {/* Modal para seleccionar nueva subtarea */}
       <AnimatePresence>
        {showNewSubtaskDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
            <motion.div
              className="bg-white rounded-lg p-6 w-full max-w-md"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h3 className="text-lg font-medium mb-4">Seleccionar siguiente subtarea</h3>
              <div className="space-y-4">
              {nextOptions.map((option) => (

                <button
                  key={option.type}
                  className="w-full px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
                 onClick={() => handleNewSubtaskSelection(option)}
                >
                    {option.name}
                </button>
                            ))}

                <button
                  className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                  onClick={() => setShowNewSubtaskDialog(false)}
                >
                  Cancelar
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
     </AnimatePresence>
  );
};


interface ReminderPickerProps {
  currentDate: string;
  onDateChange: (date: string) => void;
  onClose: () => void;
  groupProgress: number;
  isDisabled?: boolean;
}


const ReminderPicker: React.FC<ReminderPickerProps> = ({ 
  currentDate, 
  onDateChange, 
  onClose,
  groupProgress,
  isDisabled = false
}) => {
  const [reminderType, setReminderType] = useState('specific-date');
  const [selectedDate, setSelectedDate] = useState(currentDate || '');
  const [timeValue, setTimeValue] = useState(1);
  const [timeUnit, setTimeUnit] = useState('days');
  const [progressThreshold, setProgressThreshold] = useState(50);
  
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { type: "spring", damping: 25, stiffness: 500 }
    },
    exit: { 
      opacity: 0, 
      y: 20,
      transition: { duration: 0.2 }
    }
  };

  const handleApply = () => {
    let reminderDate = '';
    
    switch (reminderType) {
      case 'specific-date':
        reminderDate = selectedDate;
        break;
      case 'relative-time':
        const today = new Date();
        const futureDate = new Date(today);
        if (timeUnit === 'weeks') {
          futureDate.setDate(today.getDate() + (timeValue * 7));
        } else {
          futureDate.setDate(today.getDate() + timeValue);
        }
        reminderDate = futureDate.toISOString().split('T')[0];
        break;
      case 'progress-based':
        reminderDate = `progress:${progressThreshold}`;
        break;
    }

    onDateChange(reminderDate);
  };

  const isValidDate = (date: string) => {
    if (!date) return true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(date);
    return selectedDate >= today;
  };

  return (
    <motion.div
      className="bg-white rounded-lg shadow-xl w-full max-w-md"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/* Header */}
      <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-teal-600 text-white rounded-t-lg">
        <div className="flex items-center space-x-2">
          <Bell size={20} />
          <h3 className="text-lg font-semibold">Configurar Recordatorio</h3>
        </div>
        <button 
          onClick={onClose}
          className="text-white hover:text-gray-200 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      {/* Content */}
      <div className="p-6 space-y-6">
        {/* Reminder Type Selector */}
        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700">
            Tipo de Recordatorio
          </label>
          <div className="grid grid-cols-3 gap-4">
            {[
              { id: 'specific-date', icon: Calendar, label: 'Fecha Específica' },
              { id: 'relative-time', icon: Clock, label: 'Tiempo Relativo' },
              { id: 'progress-based', icon: AlertTriangle, label: 'Por Progreso' }
            ].map(({ id, icon: Icon, label }) => (
              <button
                key={id}
                onClick={() => setReminderType(id)}
                className={`p-4 rounded-lg border-2 transition-all ${
                  reminderType === id 
                    ? 'border-teal-500 bg-teal-50 text-teal-700' 
                    : 'border-gray-200 hover:border-teal-200'
                }`}
                disabled={isDisabled}
              >
                <Icon className="w-6 h-6 mx-auto mb-2" />
                <span className="text-sm font-medium block text-center">{label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Dynamic Input Section */}
        <AnimatePresence mode="wait">
          <motion.div
            key={reminderType}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="space-y-4"
          >
            {reminderType === 'specific-date' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Seleccionar Fecha
                </label>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  min={dateUtils.getTodayString()}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                  disabled={isDisabled}
                />
                {!isValidDate(selectedDate) && (
                  <p className="mt-2 text-sm text-red-600">
                    La fecha no puede ser anterior a hoy
                  </p>
                )}
              </div>
            )}

            {reminderType === 'relative-time' && (
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tiempo
                  </label>
                  <input
                    type="number"
                    min="1"
                    value={timeValue}
                    onChange={(e) => setTimeValue(parseInt(e.target.value))}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                    disabled={isDisabled}
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Unidad
                  </label>
                  <select
                    value={timeUnit}
                    onChange={(e) => setTimeUnit(e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                    disabled={isDisabled}
                  >
                    <option value="days">Días</option>
                    <option value="weeks">Semanas</option>
                  </select>
                </div>
              </div>
            )}

            {reminderType === 'progress-based' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Umbral de Progreso
                </label>
                <select
                  value={progressThreshold}
                  onChange={(e) => setProgressThreshold(parseInt(e.target.value))}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                  disabled={isDisabled}
                >
                  <option value={25}>25% completado</option>
                  <option value={50}>50% completado</option>
                  <option value={75}>75% completado</option>
                  <option value={90}>90% completado</option>
                </select>
                <div className="mt-4 bg-gray-50 rounded-lg p-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium text-gray-700">
                      Progreso Actual
                    </span>
                    <span className="text-sm font-bold text-teal-600">
                      {groupProgress}%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-teal-600 h-2.5 rounded-full transition-all duration-500"
                      style={{ width: `${groupProgress}%` }}
                    />
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        {/* Footer */}
        <div className="flex justify-end space-x-3 pt-4 border-t border-gray-200">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md border border-gray-300"
            disabled={isDisabled}
          >
            Cancelar
          </button>
          <button
            onClick={handleApply}
            className="px-4 py-2 text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 rounded-md shadow-sm disabled:opacity-50"
            disabled={isDisabled || !isValidDate(selectedDate)}
          >
            Aplicar
          </button>
        </div>
      </div>
    </motion.div>
  );
};










interface SemaphoreResult {
  status: SemaphoreStatus;
  daysDelayed: number;
}

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
  processName: string | undefined;          // Nombre del proceso
  subprocessName: string | undefined;       // Nombre del subproceso
  agrupadorName: string | undefined;  
}

interface TaskUpdateData {
  id: number;
  tarea_id?: number;
  isClosed: boolean;
  progress: number;
  start: string;
  end: string;
  followUpDate: string;
  comments: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;
}

interface SubtaskUpdateData {
  id: number;
  tarea_id: number;
  nombre: string;
  tipo: string;
  responsible: User[] | string;
  organismo: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  dependencias: string;
  enabled: boolean;
  resolucion_tipo?: string;
  orden: number;
  comments: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
}

interface CommentData {
  id: number;          // ID de la tarea o subtarea
  comments: string;    // Comentarios en formato JSON string
  type: 'task' | 'subtask'; // Indica si es tarea principal o subtarea
  tarea_id?: number;   // ID de la tarea padre (solo para subtareas)
}



interface CommentResponse {
  success: boolean;
  comments?: string[];
  message?: string;
}

type ReminderType = 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed';

interface ReminderInfo {
  type: ReminderType | null;
  value: string | null;
}


interface ReminderData {
  task_id: number;
  reminder_type: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value: string | null;
  reminder_code: string | null;
  reminder_calculated_date: string | null;
  reminder_active: boolean;
  reminder_triggered: boolean;
}
const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  task, 
  onChangeTask, 
  backgroundColor,
  allTasks,
  agrupadorId,
  daysDelayed,
  groupProgress,
  processName,         // Prop: Nombre del proceso
  subprocessName,      // Prop: Nombre del subproceso
  agrupadorName,  
}) => {
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(true); // Set to true by default for better visibility
  const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || '');
  const [showReminderPicker, setShowReminderPicker] = useState(false);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedLastName = sessionStorage.getItem('lastName');
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
  const [editedName, setEditedName] = useState(task.name);
  const storedUserId = sessionStorage.getItem('userId');
  const [localStartDate, setLocalStartDate] = useState<string>(task.start || '');
  const [localEndDate, setLocalEndDate] = useState<string>(task.end || '');
  const [isLoading, setIsLoading] = useState(false);
  const [reminderType, setReminderType] = useState<'date' | 'relative' | 'progress'>('progress');
  const [selectedDate, setSelectedDate] = useState('');
  const [timeValue, setTimeValue] = useState(1);
  const [timeUnit, setTimeUnit] = useState<'days' | 'weeks' | 'months'>('days');
  const [progressValue, setProgressValue] = useState(50);
  const [isLoadingReminder, setIsLoadingReminder] = useState(false);
  const [modalKey, setModalKey] = useState(0);
   
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = useState<string>(task.comments || '');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isCommentsInitialized, setIsCommentsInitialized] = useState(false);

  
  useEffect(() => {
    if (isOpen) {
      setLocalStartDate(task.start);
      setLocalEndDate(task.end);
      setEditedName(task.name);
      // Asegurarnos de mantener el estado del semáforo
      setLocalTask({
        ...task,
        semaphoreStatus: task.semaphoreStatus
      });
    }
  }, [isOpen, task]);




 
// Función para cargar los recordatorios de una tarea
const loadTaskReminders = async (taskId: number): Promise<ReminderData | null> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_reminders.php?taskId=${taskId}`);
    if (!response.ok) {
      throw new Error('Error loading task reminders');
    }
    const data = await response.json();
    return data.reminder || null;
  } catch (error) {
    console.error('Error loading reminders for task:', taskId, error);
    return null;
  }
};

// Función para cargar los recordatorios de una subtarea
const loadSubtaskReminders = async (taskId: number, subtaskId: number): Promise<ReminderData | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_subtask_reminders.php?taskId=${taskId}&subtaskId=${subtaskId}`
    );
    if (!response.ok) {
      throw new Error('Error loading subtask reminders');
    }
    const data = await response.json();
    return data.reminder || null;
  } catch (error) {
    console.error('Error loading reminders for subtask:', subtaskId, error);
    return null;
  }
};



  const formatDate = (date: string) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    return parsedDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  };

  
    // Handle task name change
    const handleNameChange = async () => {
      if (editedName.trim() === '') {
        toast.error('El nombre no puede estar vacío');
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_name.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: task.id, name: editedName }),
        });
    
        if (!response.ok) {
          throw new Error('Error al actualizar el nombre de la tarea');
        }
    
        // Actualizar el estado local de la tarea
        onChangeTask({ ...task, name: editedName });
        toast.success('Nombre de la tarea actualizado');
      } catch (error) {
        console.error('Error al actualizar el nombre:', error);
        toast.error('Hubo un error al guardar el nombre. Por favor, inténtalo nuevamente.');
      }
    };

    const handleLocalDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      
      // First update local state for immediate UI feedback
      if (name === 'start') {
        setLocalStartDate(value);
      } else if (name === 'end') {
        setLocalEndDate(value);
      }
    
      try {
        // Sync with backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_dates.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            taskId: task.id,
            start: name === 'start' ? value : localStartDate,
            end: name === 'end' ? value : localEndDate
          })
        });
    
        if (!response.ok) {
          throw new Error('Error updating dates');
        }
    
        // Update parent state only after successful backend update
        const updatedTask = {
          ...task,
          start: name === 'start' ? value : localStartDate,
          end: name === 'end' ? value : localEndDate
        };
        
     //  onChangeTask(updatedTask);
        setLocalTask(updatedTask);
    
      } catch (error) {
        // Revert local state on error
        if (name === 'start') {
          setLocalStartDate(task.start);
        } else {
          setLocalEndDate(task.end);
        }
        toast.error('Error al actualizar las fechas');
      }
    };

    const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedName(e.target.value);
    };

    const handleDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (task.isClosed) return;
  
      const { name, value } = e.target;
      const parsedDate = dateUtils.parseLocalDate(value);
      const today = dateUtils.getTodayLocal();
    
      // Actualizar estado local
      if (name === 'start') {
        setLocalStartDate(value);
      } else if (name === 'end') {
        setLocalEndDate(value);
      }

     
    /*
      // Crear objeto actualizado
      const updatedTask = { 
        ...task, 
        [name]: value 
      };  // Update handlers to use localTask
      setLocalTask(prev => ({...prev, [name]: value}));

    
      try {
        // Llamada al backend
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_dates.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            taskId: task.id,
            start: updatedTask.start,
            end: updatedTask.end,
          }),
        });
    
        if (!response.ok) {
          throw new Error('Error updating dates');
        }
    
        // Actualizar estado padre
      //  onChangeTask(updatedTask);
        setLocalTask(updatedTask); // Actualizar estado local
      } catch (error) {
        console.error('Error:', error);
        toast.error('Error al actualizar las fechas');
      }
        */
    };

  const formatDateDDMMYYYY = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };



  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [task.comments, isOpen]); // Agrega isOpen como dependencia

  
  
  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);
  


  // Función para cargar comentarios de una tarea
const loadTaskComments = async (taskId: number): Promise<string[]> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${taskId}`);
    
    if (!response.ok) {
      throw new Error('Error loading task comments');
    }

    const data: CommentResponse = await response.json();
    if (data.success && data.comments) {
      return data.comments;
    }
    return [];
  } catch (error) {
    console.error('Error loading task comments:', error);
    throw error;
  }
};


const loadComments = useCallback(async () => {
  if (!task.id || isCommentsInitialized) return;
  
  try {
    setIsLoadingComments(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${task.id}`);
    
    if (!response.ok) {
      throw new Error('Error loading task comments');
    }

    const data = await response.json();
    if (data.success && data.comments) {
      onChangeTask({
        ...task,
        comments: JSON.stringify(data.comments)
      });
    }
    setIsCommentsInitialized(true);
  } catch (error) {
    console.error('Error loading comments:', error);
    toast.error('Error al cargar los comentarios');
  } finally {
    setIsLoadingComments(false);
  }
}, [task.id, isCommentsInitialized]);


  const handleTaskUpdate = async (task: Task, updatedData: Partial<Task>): Promise<void> => {
    try {
      // Actualizar la tarea principal
      const taskUpdateData: TaskUpdateData = {
        id: task.id,
        isClosed: updatedData.isClosed ?? task.isClosed,
        progress: updatedData.progress ?? task.progress,
        start: updatedData.start ?? task.start,
        end: updatedData.end ?? task.end,
        followUpDate: updatedData.followUpDate ?? task.followUpDate,
        comments: updatedData.comments ?? task.comments,
        semaphoreStatus: updatedData.semaphoreStatus ?? task.semaphoreStatus,
        delayInDays: updatedData.delayInDays
      };
  
      await updateTaskInDatabase(taskUpdateData);
  
      // Si hay subtareas actualizadas, procesarlas
      if (updatedData.subtasks && task.subtasks) {
        for (const subtask of updatedData.subtasks) {
          const subtaskUpdateData: SubtaskUpdateData = {
            id: subtask.id!,
            tarea_id: task.id,
            nombre: subtask.name,
            tipo: subtask.type,
            responsible: subtask.responsible,
            organismo: subtask.organismo,
            progreso: subtask.progress,
            fecha_inicio: subtask.start,
            fecha_termino: subtask.end,
            duracion: subtask.duration,
            dependencias: JSON.stringify(subtask.dependsOn),
            enabled: subtask.enabled,
            resolucion_tipo: subtask.resolutionType,
            orden: subtask.orden,
            comments: subtask.comments,
            isClosed: subtask.isClosed,
            followUpDate: subtask.followUpDate,
            semaphoreStatus: subtask.semaphoreStatus
          };
          if (task.subtasks) {
            for (const subtask of task.subtasks) {
              await updateSubtaskInDB(subtask);
            } 
          }
        }
      }
    } catch (error) {
      console.error('Error in handleTaskUpdate:', error);
      throw error;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };



// Función para calcular los días entre dos fechas
const calculateDurationInDays = (startDate: string, endDate: string): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = end.getTime() - start.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Modificar la parte del renderizado del selector de progreso
const renderProgressSelector = () => {
  const totalDays = calculateDurationInDays(task.start, task.end);
  
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg">
        <h4 className="font-medium mb-2">Progreso basado en duración</h4>
        <p className="text-sm text-gray-600 mb-4">
          La tarea tiene una duración de {totalDays} días. 
          El recordatorio se activará cuando se alcance el porcentaje seleccionado del tiempo total.
        </p>
        
        <select
          value={progressValue}
          onChange={e => setProgressValue(parseInt(e.target.value))}
          className="w-full rounded-md border-gray-300"
          disabled={task.isClosed}
        >
          <option value={25}>25% del tiempo (Día {Math.ceil(totalDays * 0.25)})</option>
          <option value={50}>50% del tiempo (Día {Math.ceil(totalDays * 0.5)})</option>
          <option value={75}>75% del tiempo (Día {Math.ceil(totalDays * 0.75)})</option>
          <option value={90}>90% del tiempo (Día {Math.ceil(totalDays * 0.9)})</option>
        </select>

        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
          <div className="flex">
            <Info className="h-5 w-5 text-blue-400 mr-2" />
            <p className="text-sm text-blue-700">
              El recordatorio se activará en el día {Math.ceil(totalDays * (progressValue / 100))} 
              de los {totalDays} días totales de la tarea.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


// Función para generar el reminder_code
const generateReminderCode = (type: ReminderType, value: string): string => {
  switch (type) {
    case 'specific-date':
      return `f-${value}`;
    case 'relative-time':
      return `rt-${value}`;
    case 'progress-based':
      return `p-${value}`;
    case 'task-expired':
      return `exp-${value}`;
    case 'task-closed':
      return `closed-${value}`;
    default:
      return '';
  }
};

// Handler de guardado actualizado
const handleReminderSave = async () => {
  try {
    setIsLoadingReminder(true);
    const currentUser = sessionStorage.getItem('userId');
    let reminder: {
      type: ReminderType;
      value: string;
      code: string;
    };

    switch (reminderType) {
      case 'date':
        reminder = {
          type: 'specific-date',
          value: selectedDate,
          code: generateReminderCode('specific-date', selectedDate)
        };
        break;
      case 'relative':
        const relativeValue = `${timeValue}${timeUnit === 'weeks' ? 'w' : 'd'}`;
        reminder = {
          type: 'relative-time',
          value: relativeValue,
          code: generateReminderCode('relative-time', relativeValue)
        };
        break;
      case 'progress':
        reminder = {
          type: 'progress-based',
          value: progressValue.toString(),
          code: generateReminderCode('progress-based', progressValue.toString())
        };
        break;
      default:
        throw new Error('Tipo de recordatorio no válido');
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_reminder.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        task_id: task.id,
        reminder_code: reminder.code,
        reminder_type: reminder.type,
        reminder_value: reminder.value,
        user_id: currentUser
      })
    });

    if (!response.ok) throw new Error('Error al guardar recordatorio');

    const result = await response.json();
    onChangeTask({
      ...task,
      reminder_code: reminder.code,
      reminder_type: reminder.type,
      reminder_value: reminder.value,
      reminder_calculated_date: result.reminder_calculated_date,
      reminder_active: true,
      reminder_triggered: false
    });

    toast.success('Recordatorio configurado exitosamente');
    setShowReminderPicker(false);
    onClose()
  } catch (error) {
    console.error('Error:', error);
    toast.error('Error al configurar recordatorio');
  } finally {
    setIsLoadingReminder(false);
  }
};


  const renderTrackingContent = () => (
    <div className="p-6 space-y-6">
      <div className="bg-white p-4 rounded-lg border">
        <h4 className="font-medium mb-2">Configuración de Recordatorio</h4>
        
        <div className="flex gap-4 mb-6">
          {[
            { id: 'date', label: 'Fecha Específica', icon: Calendar },
            { id: 'relative', label: 'Tiempo Relativo', icon: Clock },
            { id: 'progress', label: 'Por Progreso', icon: AlertTriangle }
          ].map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setReminderType(id as typeof reminderType)}
              className={`flex-1 p-4 rounded-lg border-2 transition-all ${
                reminderType === id 
                  ? 'border-teal-500 bg-teal-50 text-teal-700' 
                  : 'border-gray-200 hover:border-teal-200'
              }`}
              disabled={task.isClosed}
            >
              <Icon className="w-6 h-6 mx-auto mb-2" />
              <span className="text-sm font-medium block text-center">{label}</span>
            </button>
          ))}
        </div>

        {reminderType === 'date' && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Seleccionar Fecha
            </label>
            <input
              type="date"
              value={selectedDate}
              onChange={e => setSelectedDate(e.target.value)}
              min={dateUtils.getTodayString()}
              className="w-full rounded-md border-gray-300"
              disabled={task.isClosed}
            />
          </div>
        )}

        {reminderType === 'relative' && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Repetir Cada
            </label>
            <div className="flex gap-4">
              <input
                type="number"
                min="1"
                value={timeValue}
                onChange={e => setTimeValue(parseInt(e.target.value))}
                className="w-24 rounded-md border-gray-300"
                disabled={task.isClosed}
              />
              <select
                value={timeUnit}
                onChange={e => setTimeUnit(e.target.value as 'days' | 'weeks' | 'months')}
                className="rounded-md border-gray-300"
                disabled={task.isClosed}
              >
                <option value="days">Días</option>
                <option value="weeks">Semanas</option>
                <option value="months">Meses</option>
              </select>
            </div>
          </div>
        )}

        {reminderType === 'progress' && (
          <div className="space-y-4">
            {renderProgressSelector ()}
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <Button
          onClick={handleReminderSave}
          disabled={isLoadingReminder || task.isClosed}
          className="bg-teal-600 text-white hover:bg-teal-700"
        >
          {isLoadingReminder ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Guardando...
            </>
          ) : (
            'Guardar Recordatorio'
          )}
        </Button>
      </div>
    </div>
  );



  const parseExistingReminder = (code: string) => {
    if (code.startsWith('f')) {
      setReminderType('date');
      setSelectedDate(code.substring(1));
    } else if (code.startsWith('rt')) {
      setReminderType('relative');
      const [value, unit] = code.substring(2).split(':');
      setTimeValue(parseInt(value));
      setTimeUnit(unit as 'days' | 'weeks' | 'months');
    } else if (code.startsWith('p')) {
      setReminderType('progress');
      setProgressValue(parseInt(code.substring(1)));
    }
  };








const parseReminderCode = (reminderCode: string | null): {
  type: string | null;
  value: string | null;
} => {
  if (!reminderCode) return { type: null, value: null };

  if (reminderCode.startsWith('f-')) {
    return {
      type: 'specific-date',
      value: reminderCode.substring(2)
    };
  }

  if (reminderCode.startsWith('rt-')) {
    return {
      type: 'relative-time',
      value: reminderCode.substring(3)
    };
  }

  if (reminderCode.startsWith('p-')) {
    return {
      type: 'progress-based',
      value: reminderCode.substring(2)
    };
  }

  if (reminderCode.startsWith('exp-')) {
    return {
      type: 'task-expired',
      value: reminderCode.substring(4)
    };
  }

  if (reminderCode.startsWith('closed-')) {
    return {
      type: 'task-closed',
      value: reminderCode.substring(7)
    };
  }

  return { type: null, value: null };
};

// Función para verificar el estado del recordatorio
const checkReminderStatus = (item: Task | SubTask): {
  isActive: boolean;
  isTriggered: boolean;
} => {
  if (!item.reminder_code) return { isActive: false, isTriggered: false };

  const { type, value } = parseReminderCode(item.reminder_code);
  if (!type || !value) return { isActive: false, isTriggered: false };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  switch (type) {
    case 'specific-date': {
      const reminderDate = new Date(value);
      reminderDate.setHours(0, 0, 0, 0);
      return {
        isActive: true,
        isTriggered: today >= reminderDate
      };
    }

    case 'relative-time': {
      const [amount, unit] = value.split(/(\d+)/).filter(Boolean);
      const reminderDate = new Date();
      if (unit === 'w') {
        reminderDate.setDate(reminderDate.getDate() + (parseInt(amount) * 7));
      } else {
        reminderDate.setDate(reminderDate.getDate() + parseInt(amount));
      }
      return {
        isActive: true,
        isTriggered: today >= reminderDate
      };
    }

    case 'progress-based': {
      const targetProgress = parseInt(value);
      return {
        isActive: true,
        isTriggered: (item.progress || 0) >= targetProgress
      };
    }

    case 'task-expired':
    case 'task-closed': {
      return {
        isActive: true,
        isTriggered: true
      };
    }

    default:
      return { isActive: false, isTriggered: false };
  }
};

const getReminderStatus = (item: Task | SubTask) => {
  // Si no hay recordatorio configurado
  if (!item.reminder_type || !item.reminder_value) {
    return {
      color: 'bg-gray-200',
      icon: null,
      tooltipText: 'Sin recordatorio'
    };
  }

  // Si el recordatorio está activo y se ha disparado
  if (item.reminder_active && item.reminder_triggered) {
    return {
      color: 'bg-red-500',
      icon: getReminderTypeIcon(item),
      tooltipText: getReminderTooltipText(item)
    };
  }

  // Si el recordatorio está activo pero no se ha disparado
  if (item.reminder_active && !item.reminder_triggered) {
    return {
      color: 'bg-yellow-500',
      icon: getReminderTypeIcon(item),
      tooltipText: getReminderTooltipText(item)
    };
  }

  // Si el recordatorio está inactivo
  return {
    color: 'bg-gray-200',
    icon: getReminderTypeIcon(item),
    tooltipText: 'Recordatorio inactivo'
  };
};



// Función para obtener el texto del tooltip
const getReminderTooltipText = (item: Task | SubTask): string => {
  if (!item.reminder_type || !item.reminder_value) {
    return 'Sin recordatorio';
  }

  switch (item.reminder_type) {
    case 'specific-date':
      return `Recordatorio para: ${formatDate(item.reminder_calculated_date || '')}`;
    case 'relative-time': {
      const value = item.reminder_value;
      const unit = value.endsWith('w') ? 'semanas' : 'días';
      const amount = value.slice(0, -1);
      return `Recordatorio en ${amount} ${unit}`;
    }
    case 'progress-based':
      return `Recordar al ${item.reminder_value}% de progreso`;
    case 'task-expired':
      return 'Tarea vencida';
    case 'task-closed':
      return 'Tarea cerrada';
    default:
      return 'Sin recordatorio';
  }
};



// Función para obtener el ícono
const getReminderTypeIcon = (item: Task | SubTask) => {
  if (!item.reminder_type) return null;

  switch (item.reminder_type) {
    case 'specific-date':
      return <Calendar className="w-4 h-4" />;
    case 'relative-time':
      return <Clock className="w-4 h-4" />;
    case 'progress-based':
      return <AlertTriangle className="w-4 h-4" />;
    case 'task-expired':
      return <AlertTriangle className="w-4 h-4" />;
    case 'task-closed':
      return <Check className="w-4 h-4" />;
    default:
      return null;
  }
};

















  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      setIsLoadingComments(true);
      
      // Create timestamp in local timezone
      const timestamp = new Date().toLocaleString('es-CL', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });

      const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
      
      // Parse existing comments or create new array
      let currentComments = [];
      try {
        currentComments = task.comments ? JSON.parse(task.comments) : [];
      } catch (error) {
        console.error('Error parsing existing comments:', error);
        currentComments = [];
      }

      // Add new comment to array
      const updatedComments = [...currentComments, commentWithTimestamp];
      const newCommentsString = JSON.stringify(updatedComments);

      // First update the UI immediately
      const updatedTask = {
        ...task,
        comments: newCommentsString
      };
      onChangeTask(updatedTask);

      // Then update the database
     

    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Error al agregar el comentario. Por favor, intente nuevamente.');
      
      // Optionally reload comments from server to ensure consistency
      await loadComments();
    } finally {
      setIsLoadingComments(false);
    }
  };


  const [updateTrigger, setUpdateTrigger] = useState(0);
/*
  const handleCloseTask = async () => {
    if (task.semaphoreStatus === SemaphoreStatus.Gray) {
      alert("No se puede cerrar una tarea que aún no ha iniciado.");
      return;
    }
  
    // Crear fecha actual en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
    const formattedDate = today.getFullYear() + '-' + 
      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
      String(today.getDate()).padStart(2, '0');
  
    const updatedTask = { 
      ...task, 
      isClosed: true, 
      progress: 100, 
      end: formattedDate 
    };
    
    try {
      // Actualizar la tarea en la base de datos
      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
  
      // Calcular y actualizar el progreso del agrupador
      // await updateAgrupadorProgress(task.id);
      
      onChangeTask(updatedTask);
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
    }
  };
*/


useEffect(() => {
  if (isOpen) {
    loadComments();
  } else {
    setIsCommentsInitialized(false);
  }
}, [isOpen, loadComments]);

useEffect(() => {
  if (commentsEndRef.current) {
    commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [comments]);


useEffect(() => {
  if (task.followUpDate) {
    parseExistingReminder(task.followUpDate);
  }
}, [task.followUpDate]);


useEffect(() => {
  const checkAndCloseMainTask = async () => {
    if (
      task.name === 'TRAMITACIÓN' && 
      task.subtasks && 
      task.subtasks.length > 0 && 
      !task.isClosed
    ) {
      const allSubtasksClosed = task.subtasks.every(subtask => subtask.isClosed);
      
      if (allSubtasksClosed) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const formattedDate = today.getFullYear() + '-' + 
          String(today.getMonth() + 1).padStart(2, '0') + '-' + 
          String(today.getDate()).padStart(2, '0');

        const updatedTask = { 
          ...task, 
          isClosed: true, 
          progress: 100, 
          end: formattedDate 
        };
        
        try {
          
          await updateTaskInDatabase(updatedTask);
          onChangeTask(updatedTask);
          setUpdateTrigger(prev => prev + 1);
        } catch (error) {
          console.error('Error al cerrar la tarea automáticamente:', error);
        }
      }
    }
  };

  checkAndCloseMainTask();
}, [task.subtasks]);



useEffect(() => {
  if (isOpen) {
    refreshTaskData();
  }
}, [isOpen]);




const [localTask, setLocalTask] = useState<Task>(task);


  // Función para cargar los datos más recientes de la tarea
  const refreshTaskData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task.php?taskId=${task.id}`);
      if (!response.ok) throw new Error('Error fetching task data');
      
      const updatedTaskData = await response.json();
      if (updatedTaskData.success) {
        const refreshedTask = {
          ...task,
          ...updatedTaskData.task,
          start: updatedTaskData.task.fecha_inicio,
          end: updatedTaskData.task.fecha_termino
        };
        
        setLocalTask(refreshedTask);
        setLocalStartDate(refreshedTask.start);
        setLocalEndDate(refreshedTask.end);
        setEditedName(refreshedTask.name);
        onChangeTask(refreshedTask); // Actualizar el estado global
      }
    } catch (error) {
      console.error('Error refreshing task data:', error);
    }
  };


const handleCloseTask = async () => {
  try {
    // Validaciones...
    const today = dateUtils.getTodayString();
    
    const updatedTask = {
      ...localTask,
      isClosed: true,
      progress: 100,
      end: today,
      comments: task.comments // Preserve comments

    };

    // Actualizar estado local inmediatamente
    setLocalTask(updatedTask);
    setLocalEndDate(today);

    // Actualizar en la base de datos
    await updateTaskInDatabase({
      ...updatedTask,
      id: updatedTask.id,
      isClosed: true,
      progress: 100,
      start: updatedTask.start,
      end: today,
      followUpDate: updatedTask.followUpDate,
      semaphoreStatus: updatedTask.semaphoreStatus,
      comments: updatedTask.comments, 

    });

    // Actualizar las tareas dependientes...
    
    // Actualizar el estado global
    onChangeTask(updatedTask);
    onChangeTask(updatedTask);
    onClose();
  } catch (error) {
    console.error('Error al cerrar la tarea:', error);
    toast.error('Error al cerrar la tarea');
    // Revertir cambios locales en caso de error
    setLocalTask(task);
    setLocalEndDate(task.end);
  }
};


const canCloseTramitacionTask = (task: Task): boolean => {
  if (task.name !== 'TRAMITACIÓN') return true;
  if (!task.subtasks || task.subtasks.length === 0) return true;
  
  return task.subtasks.every(subtask => subtask.isClosed);
};

// Función para actualizar las fechas de las subtareas
/*const updateSubtasksDates = (subtasks: SubTask[]): SubTask[] => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  return subtasks.map(subtask => ({
    ...subtask,
    end: subtask.isClosed ? subtask.end : formattedDate // Solo actualizar fechas de subtareas abiertas
  }));
};
*/

// Función para abrir una tarea
const handleOpenTask = async () => {
  try {
    // Usar dateUtils para parsear la fecha de inicio
    const startDate = dateUtils.parseLocalDate(task.start);
    
    // Calcular la nueva fecha de término usando dateUtils
    const newEndDate = new Date(startDate);
    newEndDate.setDate(startDate.getDate() + (task.duracion * 7));
    const formattedEndDate = dateUtils.formatLocalDate(newEndDate);

    const updatedTask = { 
      ...task, 
      isClosed: false,
      progress: 0,
      end: formattedEndDate
    };

    // Actualizar la tarea en la base de datos
    await updateTaskInDatabase(updatedTask);
    // Actualizar el estado local
    onChangeTask(updatedTask);
    setUpdateTrigger(prev => prev + 1);
    onChangeTask(updatedTask);

  } catch (error) {
    console.error('Error al abrir la tarea:', error);
    alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
  }
};

  const handleResetFollowUpDate = () => {
    onChangeTask({ ...task, followUpDate: '' });
  };



  // Función para actualizar la subtarea en la base de datos
  const updateSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData,
          fecha_inicio: subtaskData.start,
          fecha_termino: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
          resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field

        })
      });
      console.log(JSON.stringify(subtaskData))
      if (!response.ok) {
        throw new Error('Error al actualizar la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en updateSubtaskInDB:', error);
      throw error;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {

    if (e.key === 'Enter') {
      e.preventDefault();
      if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
        toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
        return;
      }
      handleSaveChanges();
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedTask = {
        ...task,
        start: localStartDate,
        end: localEndDate, 
        name: editedName,
        comments: task.comments, // Añadir comentarios
        followUpDate: task.followUpDate, // Añadir fecha de seguimiento 
        subtasks: task.subtasks, // Incluye subtareas
        semaphoreStatus: calculateSemaphoreStatus(
          localStartDate, 
          localEndDate,
          task.id,
          task.dependencia,
          task.isClosed,
          allTasks
        )
      };
     
      await updateTaskInDatabase(updatedTask); // Función que guardará los datos en la base de datos
     
      if (task.subtasks) {
        for (const subtask of task.subtasks) {
          await updateSubtaskInDB(subtask);
        } 
      }

     
      onChangeTask(updatedTask); // Actualizar el estado en el fron tend

      onChangeTask(updatedTask);
      //alert(JSON.stringify(updatedTask))
      onClose();
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
    }
  };




// Función para actualizar una tarea principal
const updateTaskInDatabase = async (taskData: TaskUpdateData): Promise<void> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskData),
      
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task');
    }
  } catch (error) {
    console.error('Error in updateTaskInDatabase:', error);
    throw error;
  }
};




const calculateSemaphoreStatus = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  // Obtener fecha actual y establecer a medianoche
  const today = dateUtils.getTodayLocal();
  today.setHours(0, 0, 0, 0);

  // Convertir fechas de string a Date y establecer a medianoche
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea aún no comienza
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si ya pasó la fecha de fin o es el mismo día de fin
  if (today >= endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular duración total y días restantes
  const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
  const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;

  // Para debug
  console.log({
    taskId,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    today: today.toISOString(),
    totalDuration,
    daysRemaining,
    percentageCompleted
  });

  // Enfoque híbrido: usar días restantes para tareas cortas y porcentajes para largas
  if (totalDuration <= 5) {
    // Lógica para tareas cortas (5 días o menos)
    if (daysRemaining === 0) {
      return SemaphoreStatus.Red;     // Mismo día = Rojo
    } else if (daysRemaining === 1) {
      return SemaphoreStatus.Orange;  // Un día restante = Naranja
    } else if (daysRemaining <= 3) {
      return SemaphoreStatus.Yellow;  // 2-3 días restantes = Amarillo
    }
    return SemaphoreStatus.Green;     // Más de 3 días = Verde
  } else {
    // Lógica para tareas largas (más de 5 días)
    if (percentageCompleted >= 90) {
      return SemaphoreStatus.Red;     // 90% o más completado = Rojo
    } else if (percentageCompleted >= 75) {
      return SemaphoreStatus.Orange;  // 75-89% completado = Naranja
    } else if (percentageCompleted >= 50) {
      return SemaphoreStatus.Yellow;  // 50-74% completado = Amarillo
    }
    return SemaphoreStatus.Green;     // Menos del 50% completado = Verde
  }
};

const semaphoreStatus = calculateSemaphoreStatus(
  task.start,
  task.end,
  task.id,
  task.dependencia,
  task.isClosed,
  allTasks
);


  const formatFollowUpDate = (date: string): string => {
    if (!date) return 'No establecida';
    const followUpDate = new Date(date);
    const today = new Date();
    const diffTime = followUpDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return `Hace ${Math.abs(diffDays)} días`;
    } else if (diffDays === 0) {
      return 'Hoy';
    } else if (diffDays === 1) {
      return 'Mañana';
    } else {
      return `En ${diffDays} días`;
    }
  };

/*
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      // Simula la carga de datos (reemplaza esto con tu lógica real)
      setTimeout(() => {
        setIsLoading(false);
      }, 350); // Simula un retraso de 2 segundos
    }
  }, [isOpen]);
  
*/
  return (
    <AnimatePresence>
   {isOpen && (
     
         
         isLoading ? (
          <div className="fixed inset-0 flex justify-center items-center z-[10002]">
              <Loader2 className="animate-spin w-8 h-8 text-gray-500" />
            </div>
          ) : (

            <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            key={modalKey}
            variants={contentVariants}
            className="fixed inset-0 flex items-center justify-center  z-[10002] "
          >
          
            <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
         
         
          <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
          <motion.div 
            className="bg-white rounded-lg overflow-hidden shadow-xl"
            variants={contentVariants}
          >
     

            <div
              className="bg-teal-600 p-3 text-white"
             
            >
                     <div className="relative">
  {/* Botón de cierre */}
  <button
    onClick={onClose}
    className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
  >
    <X size={20} />
  </button>
</div>
    <h3 className="text-base font-semibold flex items-center space-x-2">
      <MdBusiness className="text-white w-5 h-5" /> 
      <span className="text-sm opacity-80 tracking-wide">
        {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName?.toUpperCase()}`}
      </span>
    </h3>
    <div className="flex mt-4 mb-3">

    <div className="flex items-center">
      <span className="text-lg font-bold text-white">{task.name}</span>
    </div>
  {/* Estado de la tarea */}
<div className="ml-3 flex items-center">
  <div
    className={`px-3 py-1 rounded-full text-sm font-medium ${
      task.progress === 100 && task.isClosed
        ? `${backgroundColor} text-gray-800` // Estilo para tareas cerradas
        : semaphoreStatus === SemaphoreStatus.Red
        ? 'bg-red-100 text-red-700'
        : semaphoreStatus === SemaphoreStatus.Orange
        ? 'bg-orange-100 text-orange-700'
        : semaphoreStatus === SemaphoreStatus.Yellow
        ? 'bg-yellow-100 text-yellow-700'
        : semaphoreStatus === SemaphoreStatus.Green
        ? 'bg-green-100 text-green-700'
        : 'bg-gray-100 text-gray-700'
    }`}
       style={
      task.progress === 100 && task.isClosed
        ? {
            backgroundImage: `repeating-conic-gradient(
              ${lightenColor(backgroundColor, 100)} 0% 25%, 
              #ffffff 25% 50%
            )`,
            backgroundSize: '10px 10px', // Tamaño del damero
          }
        : undefined
    }
  > 
 
    {task.progress === 100 && task.isClosed
      ? 'Cerrada'
      : semaphoreStatus === SemaphoreStatus.Red
      ? `Retrasada por ${daysDelayed} día(s)`
      : semaphoreStatus === SemaphoreStatus.Orange
      ? 'Próxima a vencer'
      : semaphoreStatus === SemaphoreStatus.Yellow
      ? 'A tiempo, pero próxima'
      : semaphoreStatus === SemaphoreStatus.Green
      ? 'A tiempo'
      : 'No iniciada'}
  </div>
</div>

    </div>
    <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
      <span className="flex items-center gap-1">
        <Calendar className="w-4 h-4" />
        {formatDateDDMMYYYY(task.start)}
      </span>
      <span className="flex items-center gap-1">
        <Clock className="w-4 h-4" />
        {formatDateDDMMYYYY(task.end)}
      </span>

    </div>
 
    </div>
  
  </motion.div>

<div className="border-b">
          <nav className="flex">
            <button
              onClick={() => setActiveTab('details')}
              className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
            >
              Detalles
            </button>
            <button
              onClick={() => setActiveTab('followup')}
              className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
            >
              Seguimiento
            </button>
          </nav>
        </div>

        {activeTab === 'details' ? (
  <div className="p-4">
   

    {/* Grid de campos */}
    <div className="grid grid-cols-2 gap-3 mb-4">
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Nombre
        </label>
        <input
          type="text"
          value={editedName || ''}
          onChange={handleLocalNameChange}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed}
        />
      </div>

      <div></div>

      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Fecha de Inicio
        </label>
        <input
          type="date"
          name="start"
          value={localStartDate}
          onChange={handleDateChange}
          onKeyDown={handleKeyDown}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed && task.progress === 100}
        />
      </div>
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Fecha de Término
        </label>
        <input
          type="date"
          name="end"
          value={localEndDate}
          onChange={handleDateChange}
          onKeyDown={handleKeyDown}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed && task.progress === 100}
        />
      </div>
    </div>

    {/* Actualizar sección de comentarios */}
    <div className="comments-section">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-medium">Comentarios</h3>
        {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
      </div>

      <div
        ref={commentsContainerRef}
        className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
      >
        {task.comments ? (
          (() => {
            try {
              const parsedComments = JSON.parse(task.comments);
              return Array.isArray(parsedComments) &&
                parsedComments.length > 0 ? (
                parsedComments.map((comment, index) => {
                  const [datePart, userAndContent] = comment.split('] ');
                  const date = datePart.replace('[', '').trim();
                  const [user, ...contentParts] = userAndContent.split(':');
                  const content = contentParts.join(':').trim();
                  const isCurrentUser =
                    user === `${storedFirstName} ${storedLastName}`;
                
                  return (
                    <div
                      key={index}
                      className={`mb-3 last:mb-0 flex ${
                        !isCurrentUser ? 'justify-end' : 'justify-start'
                      }`}
                    >
                      <div
                        className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                          isCurrentUser
                            ? 'bg-green-100 text-green-900'
                            : 'bg-blue-100 text-blue-900'
                        }`}
                        style={{
                          maxWidth: 'calc(100% - 20px)', // Asegura que el texto no exceda el ancho del contenedor principal
                          wordBreak: 'break-word', // Permite dividir palabras largas
                        }}
                      >
                        <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                          <span className="font-medium">{user}</span>
                          <span className="text-gray-400">•</span>
                          <span className="text-gray-400">{date}</span>
                        </div>
                        <p className="text-sm whitespace-pre-wrap">{content}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-500 italic">No hay comentarios</p>
              );
            } catch (error) {
              return (
                <p className="text-red-500">Error al cargar comentarios</p>
              );
            }
          })()
        ) : (
          <p className="text-gray-500 italic">No hay comentarios</p>
        )}
      </div>

      <div className="mt-4 flex gap-2">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (newComment.trim() && !task.isClosed && !isLoadingComments) {
                await handleAddComment();
                setNewComment('');
              }
            }
          }}
          placeholder="Escribir un comentario..."
          rows={2}
          className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
          disabled={task.isClosed}
          maxLength={250}
        />
        <button
          onClick={async () => {
            await handleAddComment();
            setNewComment(''); // Limpiar input después de agregar el comentario
          }}
          disabled={task.isClosed || !newComment.trim() || isLoadingComments}
          className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
        >
          Agregar
        </button>
      </div>
    </div>
     {/* Botones de acción */}
     <div className="flex justify-between mt-4 pt-2 border-t">
              <button
  type="button"
  className={`px-3 py-1.5 rounded text-sm font-medium ${
    task.isClosed
      ? 'bg-green-600 hover:bg-green-700 text-white'
      : task.semaphoreStatus === SemaphoreStatus.Gray || 
        (task.name === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0)
      ? 'bg-gray-400 text-white cursor-not-allowed'
      : 'bg-red-600 hover:bg-red-700 text-white'
  }`}
  onClick={task.isClosed ? handleOpenTask : handleCloseTask}
  disabled={
    !task.isClosed && 
    (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed || 
     (task.name === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0))
  }
>
                  {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                </button>

                <button
                      type="button"
                      onClick={() => {
                        if (
                          new Date(localEndDate) < new Date(localStartDate)
                        ) {
                          alert(
                            "La fecha de término no puede ser anterior a la fecha de inicio."
                          );
                          return;
                        }
                        handleSaveChanges();
                      }}
                      className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
                    >
                      Guardar Cambios
                    </button>
              </div>
  </div>
  
) :            
<motion.div
initial="hidden"
animate="visible"
exit="exit"
variants={modalVariants}
className="space-y-4 "
>  { 
renderTrackingContent()
}
      
</motion.div>
}
</Dialog>

</motion.div>    

</motion.div> 
)
             
          
)}
    </AnimatePresence>
  );
};

interface SectionHeaderProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  backgroundColor: string;
  progress: number;
  agrupadorName: string;
  subprocessName: string;
  agrupadorId: number; // Agregamos esta prop
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  isCollapsed, 
  onToggleCollapse,
  backgroundColor,
  progress,
  agrupadorName, 
  subprocessName,
  agrupadorId
}) => {
  const darkenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
  };
  

  const darkerColor = darkenColor(backgroundColor, 30); // 30 es la cantidad de oscurecimiento, ajusta según sea necesario
  const [isEditingProcessingBody, setIsEditingProcessingBody] = useState(false);
 
  const [localProcessingBody, setLocalProcessingBody] = useState(processingBody); // Estado local sincronizado
  
  useEffect(() => {
    setLocalProcessingBody(processingBody);
    
  }, [processingBody]);




  const handleSaveProcessingBody = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_agrupador_organismo.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          agrupadorId: agrupadorId, // Necesitarás añadir esta prop al componente
          subprocessName,
          organismo: localProcessingBody
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update organismo');
      }
  
      const result = await response.json();
      console.log(result.message);
      setIsEditingProcessingBody(false);
      onChangeProcessingBody(localProcessingBody);
      toast.success('Organismo actualizado exitosamente');
    } catch (error) {
      console.error('Error updating organismo:', error);
      toast.error('Error al actualizar el organismo');
    }
  };
  
  const handleBlur = () => {
    setIsEditingProcessingBody(false);
    if (localProcessingBody !== processingBody) {
      onChangeProcessingBody(localProcessingBody); // Notificar cambio
    }
  };


  return(
  <div style={{ backgroundColor }} className="text-white px-4 py-2">
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center">
        <button onClick={onToggleCollapse} className="mr-2">
          {isCollapsed ? <ChevronRight size={20} /> : <ChevronDown size={20} />}
        </button>
        <h3 className="text-sm font-bold">{title}</h3>
      </div>
     

      <div className="relative inline-flex items-center">
      <span 
        className="text-white px-2 py-1 rounded text-sm cursor-default"
        style={{ backgroundColor: darkerColor }}
      >
        {sectionType}
      </span>
      
    </div>
    </div>
    {!isCollapsed && (
      <div className="flex items-center">
        <span className="text-sm mr-2">
          {sectionType === "PERMISOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}
        </span>
      {/*  <input
          type="text"
          value={processingBody}
          onChange={(e) => onChangeProcessingBody(e.target.value)}
          className=" text-white px-2 py-1 text-white rounded text-sm flex-grow"
          style={{ backgroundColor: darkerColor }}

          placeholder={sectionType === "PERMISOLOGÍA" ? "Ingrese organismo" : "Ingrese gestor"}
        />*/}
         <div className="relative inline-flex items-center">
         <span
  className="text-white px-2 py-1 rounded text-sm cursor-default"
  style={{ backgroundColor: darkerColor }}
>
 {isEditingProcessingBody ? (
          <input
            type="text"
            value={localProcessingBody}
            onChange={(e) => setLocalProcessingBody(e.target.value)}
            onBlur={handleSaveProcessingBody}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSaveProcessingBody();
            }}
            autoFocus
            className="bg-transparent text-white border-b border-white focus:ring-0 outline-none px-2 py-1 w-full"
            style={{ backgroundColor: darkerColor }}
          />
        ) : (
          <span
            className="text-white px-2 py-1 rounded text-sm cursor-pointer"
            style={{ backgroundColor: darkerColor }}
            onClick={() => setIsEditingProcessingBody(true)}
          >
            {localProcessingBody || 'Haz clic para editar'}
          </span>
        )}
</span>

      
    </div>
       {  /*<div className="flex items-center ml-10">
            <div className="mr-4 text-sm "><b>Progreso: {progress.toFixed(0)}%</b></div>
            <div className="w-32 bg-white rounded-full h-2.5">
              <div
                className="bg-green-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>*/}
      </div>
    )}
  </div>
);
};

interface ProjectSectionProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  tasks: Task[];
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  onChangeTask: (taskIndex: number, updatedTask: Task) => void;
  isCollapsed: boolean;
  groupProgress: number;
  subprocessColor: string;
  onToggleCollapse: () => void;
  calculateSemaphoreStatus: SemaphoreCalculator;
  // Nuevas props para la jerarquía
  processName?: string;
  agrupadorName?: string;
  subprocessName?: string;
  agrupadorId: number; // Añadimos esta propiedad

}



const lightenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => 
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
  );
};
const ProjectSection: React.FC<ProjectSectionProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  tasks, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  onChangeTask, 
  isCollapsed, 
  subprocessColor,
  onToggleCollapse,
  groupProgress,
  calculateSemaphoreStatus,
  processName,
  agrupadorName,
  subprocessName,
  agrupadorId
}) =>{
  
  const [progress, setProgress] = useState(0);
  const [shouldLoadClosedStatus, setShouldLoadClosedStatus] = useState(true);
  const [cachedClosedStatus, setCachedClosedStatus] = useState<{[key: number]: boolean}>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const updateTasksAndSubtaskStatus = () => {
      let hasChanges = false;
  
      const updatedTasks = tasks.map(task => {
        // 1. Primero calculamos los nuevos estados para comparar
        const newSubtasks = task.subtasks?.map(subtask => {
          const newStatus = calculateSemaphoreStatus(
            subtask.start,
            subtask.end,
            subtask.id ?? -1,
            null,
            subtask.isClosed,
            tasks,
            task
          );
  
          // Verificar si hay cambios en subtarea
          if (subtask.semaphoreStatus !== newStatus) {
            hasChanges = true;
            return { ...subtask, semaphoreStatus: newStatus };
          }
          return subtask;
        });
  
        // 2. Calcular nuevo estado para la tarea principal
        const newStatus = calculateSemaphoreStatus(
          task.start,
          task.end,
          task.id,
          task.dependencia,
          task.isClosed,
          tasks
        );
  
        // 3. Verificación especial para TRAMITACIÓN
        let shouldUpdate = false;
        if (task.name === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0) {
          const allSubtasksClosed = task.subtasks.every(subtask => subtask.isClosed);
          const anySubtaskOpen = task.subtasks.some(subtask => !subtask.isClosed);
        
          
          if (anySubtaskOpen && task.isClosed) {
            hasChanges = true;
            shouldUpdate = true;
          }
        }
  
        // 4. Verificar cambios en el estado principal
        if (task.semaphoreStatus !== newStatus || shouldUpdate) {
          hasChanges = true;
          return {
            ...task,
            semaphoreStatus: newStatus,
            subtasks: newSubtasks,
            isClosed: shouldUpdate ? false : task.isClosed,
            progress: shouldUpdate ? 0 : task.progress
          };
        }
  
        // Solo actualizar subtasks si hubo cambios
        if (JSON.stringify(task.subtasks) !== JSON.stringify(newSubtasks)) {
          hasChanges = true;
          return { ...task, subtasks: newSubtasks };
        }
  
        return task;
      });
  
      // Solo actualizar si realmente hubo cambios
      if (hasChanges) {
        updatedTasks.forEach((updatedTask, taskIndex) => {
          if (JSON.stringify(updatedTask) !== JSON.stringify(tasks[taskIndex])) {
            onChangeTask(taskIndex, updatedTask);
          }
        });
      }
    };
  
    updateTasksAndSubtaskStatus();
  }, [tasks, calculateSemaphoreStatus]);
    
  const columnWidths = {
    id: 'w-16',
    description: 'w-1/6',
    responsible: 'w-1/6',
    dates: 'w-1/6',
    dependencies: 'w-1/6',
    followUp: 'w-24',
    status: 'w-24'
  };

  
 


/*
  const calculateProgress = useMemo(() => {
    return () => {
      const totalTasks = tasks.length;
      const completedTasks = tasks.filter(task => task.isClosed).length;
      return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
    };
  }, [tasks]);

    const debouncedUpdateProgress = useCallback(
    debounce((newProgress: number) => {
      setProgress(newProgress);
      updateProgressInDatabase(newProgress);
    }, 300),
    []
  );

  useEffect(() => {
    const newProgress = calculateProgress();
    debouncedUpdateProgress(newProgress);
  }, [calculateProgress, debouncedUpdateProgress]);



*/
// Llamada para obtener el estado de las tareas cerradas en una sola solicitud

 
  /*
  const updateProgressInDatabase = async (newProgress: number) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_agrupador_progress.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agrupadorId: title,
          progress: Math.round(newProgress * 100) / 100
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error('Error updating agrupador progress:', error);
      // Aquí podrías mostrar un mensaje de error al usuario si lo deseas
    }
  };
*/

/*
  useEffect(() => {
    const newProgress = calculateProgress();
    debouncedUpdateProgress(newProgress);
  
  }, [calculateProgress, debouncedUpdateProgress]);

*/




  const handleTaskChange = (taskIndex: number, updatedTask: Task) => {
    onChangeTask(taskIndex, updatedTask);
   //const newProgress = calculateProgress();
   // setProgress(newProgress);
    //debouncedUpdateProgress(newProgress);
  };
 
/*
  const calculateSemaphoreStatus = (task: Task, allTasks: Task[]): SemaphoreStatus => {
    const chileTimeZone = 'America/Santiago';
    const now = new Date(new Intl.DateTimeFormat('en-US', { 
      timeZone: chileTimeZone,
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit'
    }).format(new Date()));
  
    const startDate = new Date(task.start);
    const endDate = new Date(task.end);
  
    // Verificar si alguna tarea predecesora está abierta
    const hasPredecessorOpen = task.dependsOn.some(dep => {
   
      const predecessorTask = allTasks.find(t => t.id === dep.taskId);
      return predecessorTask && !predecessorTask.isClosed;
    });
  
    if (hasPredecessorOpen) {
      return SemaphoreStatus.Gray; // Tarea no puede iniciar si una predecesora está abierta
    }
  
    if (task.isClosed) {
      return SemaphoreStatus.Green; // Tarea cerrada
    }
  
    if (now > endDate) {
      // Actualizar la fecha de fin si la tarea está retrasada
      task.end = now.toISOString().split('T')[0];
      return SemaphoreStatus.Red; // Vencida
    } else if (now >= startDate) {
      const totalDuration = endDate.getTime() - startDate.getTime();
      const remainingTime = endDate.getTime() - now.getTime();
      
      if (remainingTime <= 2 * 24 * 60 * 60 * 1000) { // 2 días o menos
        return SemaphoreStatus.Orange; // Casi por vencer
      } else if (remainingTime <= 5 * 24 * 60 * 60 * 1000) { // 5 días o menos
        return SemaphoreStatus.Green; // Por vencer
      } else {
        return SemaphoreStatus.Green; // Iniciada (a tiempo)
      }
    } else {
      return SemaphoreStatus.Gray; // No iniciada, pero dentro del plazo
    }
  };

  const updateTaskStatus = useCallback((task: Task): Task => {
    const updatedTask = { ...task };
    const newStatus = calculateSemaphoreStatus(updatedTask, tasks);
    
    if (updatedTask.semaphoreStatus !== newStatus) {
      updatedTask.semaphoreStatus = newStatus;
    }
    
    // Actualizar la fecha de fin si la tarea está retrasada
    const now = new Date();
    const endDate = new Date(updatedTask.end);
    if (now > endDate && !updatedTask.isClosed) {
      updatedTask.end = now.toISOString().split('T')[0];
    }
    
    return updatedTask;
  }, [tasks, calculateSemaphoreStatus]);*/
/*
  useEffect(() => {
    const updatedTasks = tasks.map(updateTaskStatus);
    if (JSON.stringify(updatedTasks) !== JSON.stringify(tasks)) {
      updatedTasks.forEach((task, index) => {
        if (JSON.stringify(task) !== JSON.stringify(tasks[index])) {
          onChangeTask(index, task);
        }
      });
    }
  }, [tasks, updateTaskStatus, onChangeTask]);*/

const darkenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
};

const darkerColor = darkenColor(subprocessColor, 20);


const lightenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => 
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
  );
};

// Uso
const lighterColor = lightenColor(subprocessColor, 115);
// Filtrar solo las tareas habilitadas
const enabledTasks = tasks.filter(task => task.enabled);
if (enabledTasks.length === 0) {
  return null;
}
return (
  <div className="mb-6 mt-2 shadow-lg">
    <SectionHeader
      title={title}
      sectionType={sectionType}
      processingBody={processingBody}
      onChangeSectionType={onChangeSectionType}
      onChangeProcessingBody={onChangeProcessingBody}
      isCollapsed={isCollapsed}
      onToggleCollapse={onToggleCollapse}
      backgroundColor={subprocessColor} 
      progress={progress}
      agrupadorName={agrupadorName || ''}
      subprocessName={subprocessName || ''}
      agrupadorId={agrupadorId} // Pasa el ID al SectionHeader

    />
  <SmoothCollapse isOpen={!isCollapsed}>
        {enabledTasks.length > 0 ? (
          <div className="overflow-x-hidden bg-white">
            <table className="w-full table-fixed">
              <thead>
              <tr style={{ backgroundColor: lighterColor }}>
                  <th className={`px-2 py-3 text-left text-xs font-medium uppercase tracking-wider ${columnWidths.id}`}>ID</th>
                  <th className={`px-2 py-3 text-left text-xs font-medium uppercase tracking-wider ${columnWidths.description}`}>Tarea</th>
                  <th className={`px-2 py-3 text-center text-xs font-medium uppercase tracking-wider ${columnWidths.responsible}`}>Responsable</th>
                  <th className={`px-2 py-3 text-left text-xs font-medium uppercase tracking-wider ${columnWidths.dates}`}>Inicio</th>
                  <th className={`px-2 py-3 text-left text-xs font-medium uppercase tracking-wider ${columnWidths.dates}`}>Término</th>
                  <th className={`px-2 py-3 text-center text-xs font-medium uppercase tracking-wider ${columnWidths.dependencies}`}>Dependencias</th>
                  <th className={`px-2 py-3 text-center text-xs font-medium uppercase tracking-wider ${columnWidths.followUp}`}>Seguimiento</th>
                  <th className={`px-2 py-3 text-center text-xs font-medium uppercase tracking-wider ${columnWidths.status}`}>Estado</th>
                </tr>
              </thead>
              <tbody>
                {enabledTasks.map((task, index) => (
                  <TaskRow
                    key={task.id}
                    index={index}
                    task={task}
                    onChangeTask={(updatedTask) => onChangeTask(index, updatedTask)}
                    rowColor={index % 2 === 0 ? `${subprocessColor}10` : 'white'} 
                    inputColor={darkerColor}
                    allTasks={enabledTasks}
                    isLoading={isLoading}
                    subprocessColor={subprocessColor}
                    columnWidths={columnWidths}
                    processName={processName} // Pasando el nombre del proceso
                    subprocessName={subprocessName} // Pasando el nombre del subproceso
                    agrupadorName={agrupadorName} 
                    groupProgress={groupProgress} // Función que calcule el progreso actual
                    calculateSemaphoreStatus={calculateSemaphoreStatus} // Pasar la función


                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="p-4 text-center text-gray-500">
            No hay tareas habilitadas en este agrupador.
          </div>
        )}
      </SmoothCollapse>
  </div>
);
};

enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

interface Process {
  id: number;
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
  plantilla: string; // Nombre de la plantilla asociada al proceso

}


interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string; // This will be a JSON string
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Plantilla {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}


interface Props extends Partial<CommonComponentProps> {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}


interface Subproceso {
  id: number;
  nombre: string;
  agrupadores: Agrupador[];
  subproceso: string;
  color?: string;
}

interface Agrupador {
  id: number;
  nombre: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Tarea[];
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
}

interface Tarea {
  id: number;
  nombre: string;
  description: string;
  responsable: User[]; 
  progreso: number;
  fecha_inicio: string;
  organismo: string;
  duracion: number;
  fecha_termino: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
  reminder_type?: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value?: string | null;
  reminder_calculated_date?: string | null;
  reminder_active?: boolean;
  reminder_code?: string; // Añadimos el campo reminder_code como opcional

  reminder_triggered?: boolean;
}

interface ProcessData {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}

interface TasksState {
  name: string;
  groups: Group[];
}

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { duration: 0.2 }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.2 }
  }
};

// First, let's define the modalVariants
const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 500, damping: 25 }
  },
  exit: { 
    opacity: 0, 
    scale: 0.8,
    transition: { duration: 0.2 }
  }
};

const contentVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25
    }
  },
  exit: { 
    opacity: 0, 
    x: -20,
    transition: { duration: 0.2 }
  }
};

const tabVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 500, damping: 30 }
  }
};

const buttonVariants = {
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
  initial: { scale: 1 }
};




const EngineeringProjectTimeline: React.FC<Props> = ({ 
  selectedProcess,
  setSelectedProcess,
  processes,
  navigateToSubComponent,
  ...props
}) => {

const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
const [projectSections, setProjectSections] = useState<ProjectSectionProps[]>([]);
const [allCollapsed, setAllCollapsed] = useState<boolean>(false);
const [hasPlantilla, setHasPlantilla] = useState<boolean>(true);
const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
const [isLoading, setIsLoading] = useState(false);
const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});

    const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const previousProcessRef = useRef<string | null>(null);

  const [initialUpdateDone, setInitialUpdateDone] = useState(false);
  const [labelMessage, setLabelMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const [users, setUsers] = useState<User[]>([]);

  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setLabelMessage({ type, message });
    setTimeout(() => {
      setLabelMessage(null);
    }, duration);
  };


  


  const calculateSemaphoreStatus = (
    start: string,
    end: string,
    taskId: number,
    dependencia: string | number | null,
    isClosed: boolean,
    allTasks?: Task[],
    parentTask?: Task
  ): SemaphoreStatus => {
    // Si la tarea está cerrada, retornar gris
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    // Obtener fecha actual y establecer a medianoche
    const today = dateUtils.getTodayLocal();
    today.setHours(0, 0, 0, 0);
  
    // Convertir fechas de string a Date y establecer a medianoche
    const startDate = dateUtils.parseLocalDate(start);
    const endDate = dateUtils.parseLocalDate(end);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
  
    // Validación de fechas
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return SemaphoreStatus.Gray;
    }
  
    // Si la tarea aún no comienza
    if (startDate > today) {
      return SemaphoreStatus.Gray;
    }
  
    // Si ya pasó la fecha de fin o es el mismo día de fin
    if (today >= endDate) {
      return SemaphoreStatus.Red;
    }
  
    // Calcular duración total y días restantes
    const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
    const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;
  
    // Para debug
    console.log({
      taskId,
      start: startDate.toISOString(), 
      end: endDate.toISOString(),
      today: today.toISOString(),
      totalDuration,
      daysRemaining,
      percentageCompleted
    });
  
    // Enfoque híbrido: usar días restantes para tareas cortas y porcentajes para largas
    if (totalDuration <= 5) {
      // Lógica para tareas cortas (5 días o menos)
      if (daysRemaining === 0) {
        return SemaphoreStatus.Red;     // Mismo día = Rojo
      } else if (daysRemaining === 1) {
        return SemaphoreStatus.Orange;  // Un día restante = Naranja
      } else if (daysRemaining <= 3) {
        return SemaphoreStatus.Yellow;  // 2-3 días restantes = Amarillo
      }
      return SemaphoreStatus.Green;     // Más de 3 días = Verde
    } else {
      // Lógica para tareas largas (más de 5 días)
      if (percentageCompleted >= 90) {
        return SemaphoreStatus.Red;     // 90% o más completado = Rojo
      } else if (percentageCompleted >= 75) {
        return SemaphoreStatus.Orange;  // 75-89% completado = Naranja
      } else if (percentageCompleted >= 50) {
        return SemaphoreStatus.Yellow;  // 50-74% completado = Amarillo
      }
      return SemaphoreStatus.Green;     // Menos del 50% completado = Verde
    }
  };


  // Add function to fetch users
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showLabelMessage('error', 'Error al cargar la lista de usuarios');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  
  const calculateAverageProgress = (tasks: Task[]): number => {
    if (!tasks || tasks.length === 0) return 0;

    // Sumar el progreso de todas las tareas
    const totalProgress = tasks.reduce((sum, task) => {
        // Asignar 100 si está cerrada (isClosed es true), 0 si está abierta (isClosed es false)
        const progress = task.isClosed ? 100 : 0;
        return sum + progress;
    }, 0);
    
    // Calcular el promedio de progreso
    return Math.round(totalProgress / tasks.length);
};


useLayoutEffect(() => {
  // Esto se ejecutará sincrónicamente después de todas las mutaciones del DOM
}, [projectSections]);





const calculateDaysDelayed = useCallback((endDate: string, startDate: string): number => {
  const end = dateUtils.parseLocalDate(endDate);
  const start = dateUtils.parseLocalDate(startDate);
  const diffTime = end.getTime() - start.getTime();
  return Math.max(0, Math.ceil(diffTime / (1000 * 3600 * 24))); // Convert milliseconds to days
}, []);










const resetSubprocessState = () => {
  setActiveSubprocess(0);
  setCurrentAgrupadores([]);
  localStorage.removeItem('selectedSubprocess');
};

const loadProcessData = async (processName: string) => {
  setIsLoading(true);
  
  if (processName === "") {
    resetState();
    setIsLoading(false);
    return;
  }

  // Check if the process has actually changed
  if (processName !== previousProcessRef.current) {
    if (previousProcessRef.current !== null) {
      // Reset subprocess state when switching between processes
      resetSubprocessState();
    }
  } else {
    setIsLoading(false);
    return; // Exit early if the process hasn't changed
  }

  const selectedProcessObj = processes.find(p => p.nombreProceso === processName);
  if (selectedProcessObj) {
  //  alert(`El proceso seleccionado ha cambiado a: ${selectedProcessObj.nombreProceso}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${selectedProcessObj.codigo}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: ProcessData = await response.json();
      console.log('Datos recibidos:', JSON.stringify(data, null, 2));

      // Sección 1: Información básica del proceso
      setSelectedNombreProyecto(data.nombre);
      setSelectedCodigoProyecto(data.proceso);

      // Sección 2: Subprocesos
      const fetchedSubprocesos: Subproceso[] = data.subprocesos.map((sp: any) => ({
        id: sp.id,
        nombre: sp.nombre,
        subproceso: sp.nombre,
        color: sp.color || getDefaultColor(sp.id),
        agrupadores: (sp.agrupadores || []).filter((agr: Agrupador) => agr.enabled)
      })).filter(sp => sp.agrupadores.length > 0);
      setSubprocesos(fetchedSubprocesos);

      // Sección 3: Agrupadores (todos)
      const allAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: Subproceso) =>
        subproceso.agrupadores.map((agrupador: Agrupador) => ({
          ...agrupador,
          subproceso: subproceso.nombre
        }))
      );
      setAllAgrupadores(allAgrupadores);

      // Sección 4: Filtrado de subprocesos
      setFilteredSubprocesos(fetchedSubprocesos);


      // Sección 5: Colores de subprocesos
      const newSubprocessColors: {[key: string]: string} = {};
      fetchedSubprocesos.forEach(sp => {
        newSubprocessColors[sp.subproceso] = sp.color || "";
      });
      setSubprocessColors(newSubprocessColors);

      setIsProcessLoaded(true);
      setHasPlantilla(fetchedSubprocesos.length > 0);
      previousProcessRef.current = processName;
      // No cargaremos los ProjectSections aquí, lo haremos en un efecto separado
    } catch (error) {
      console.error('Error loading process data:', error);
      setHasPlantilla(false);
      setIsProcessLoaded(false);
    } finally {
      setIsLoading(false);
    }
  }
};


const adjustDependentTasks = (tasks: Task[], taskId: number, daysToAdjust: number): Task[] => {
  const updatedTasks = [...tasks];
  const adjustTask = (id: number) => {
    const taskIndex = updatedTasks.findIndex(t => t.id === id);
    if (taskIndex === -1) return;

    const task = updatedTasks[taskIndex];
    const newStartDate = dateUtils.addDaysToDate(task.start, daysToAdjust);
    const newEndDate = dateUtils.addDaysToDate(task.end, daysToAdjust);

    updatedTasks[taskIndex] = {
      ...task,
      start: newStartDate,
      end: newEndDate
    };

    // Ajustar tareas dependientes de esta tarea
    updatedTasks.forEach(t => {
      if (t.dependencia === task.id) {
        adjustTask(t.id);
      }
    });
  };

  adjustTask(taskId);
  return updatedTasks;
};







const updateDependentTasks = useCallback((tasks: Task[]): Task[] => {
  const updatedTasks = [...tasks];
  tasks.forEach(task => {
    if (task.dependencia !== null) {
      const parentTask = updatedTasks.find(t => t.id === task.dependencia);
      if (parentTask) {
        const parentEndDate = new Date(parentTask.end);
        const taskStartDate = new Date(task.start);
        if (parentEndDate > taskStartDate) {
          const daysToAdjust = Math.ceil((parentEndDate.getTime() - taskStartDate.getTime()) / (1000 * 3600 * 24));
          adjustDependentTasks(updatedTasks, task.id, daysToAdjust);
        } else if (parentTask.isClosed && parentEndDate < taskStartDate) {
          const daysToAdjust = Math.ceil((taskStartDate.getTime() - parentEndDate.getTime()) / (1000 * 3600 * 24));
          adjustDependentTasks(updatedTasks, task.id, -daysToAdjust);
        }
      }
    }
  });
  return updatedTasks;
}, [adjustDependentTasks]);

// Añade este efecto para cargar los datos del primer subproceso
useEffect(() => {
  if (isProcessLoaded && filteredSubprocesos.length > 0) {
    // Intentamos obtener el subproceso guardado en localStorage
    const savedSubprocess = localStorage.getItem('selectedSubprocess');
    
    if (savedSubprocess) {
      // Buscar si el subproceso guardado existe en la lista
      const subprocessIndex = filteredSubprocesos.findIndex(sp => sp.nombre === savedSubprocess);
      if (subprocessIndex !== -1) {
        // Si existe en la lista, lo cargamos
        loadSubprocessData(savedSubprocess, filteredSubprocesos[subprocessIndex].color || getDefaultColor(filteredSubprocesos[subprocessIndex].id));
        setActiveSubprocess(subprocessIndex);
      } else {
        // Si no está en la lista, cargar el primer subproceso
        const firstSubprocess = filteredSubprocesos[0];
        loadSubprocessData(firstSubprocess.nombre, firstSubprocess.color || getDefaultColor(firstSubprocess.id));
        setActiveSubprocess(0);
      }
    } else {
      // Si no hay subproceso guardado en localStorage, cargar el primero de la lista
      const firstSubprocess = filteredSubprocesos[0];
      loadSubprocessData(firstSubprocess.nombre, firstSubprocess.color || getDefaultColor(firstSubprocess.id));
      setActiveSubprocess(0);
    }
  }
}, [isProcessLoaded, filteredSubprocesos]);



const parseDependencies = (dependencias: any, groupId: number, taskId: number): { groupId: number; taskId: number; subtaskId: number }[] => {
  if (!dependencias) return [];
  if (typeof dependencias === 'string') {
    try {
      const parsed = JSON.parse(dependencias);
      if (Array.isArray(parsed)) {
        return parsed.map(id => ({ groupId, taskId, subtaskId: id }));
      }
    } catch (e) {
      console.error('Error parsing dependencies:', e);
    }
  }
  if (Array.isArray(dependencias)) {
    return dependencias.map(id => ({ groupId, taskId, subtaskId: id }));
  }
  console.warn('Unexpected dependencies format:', dependencias);
  return [];
};
const loadSubprocessData = useCallback((subprocessName: string, subprocessColor: string) => {
  const subprocessAgrupadores = allAgrupadores.filter(ag => ag.subproceso === subprocessName);
  
  const projectSections: ProjectSectionProps[] = subprocessAgrupadores.map((agrupador: Agrupador) => {
    // Primero creamos el array de tareas base
    const tasksArray: Task[] = agrupador.tareas.map((tarea: Tarea): Task => ({
      id: tarea.id,
      name: tarea.nombre,
      description: tarea.nombre,
      responsible: Array.isArray(tarea.responsable) 
      ? tarea.responsable 
      : typeof tarea.responsable === 'string'
      ? [{ 
          id: 0,
          username: '',
          firstname: tarea.responsable,
          lastname: '',
          email: '',
          is_admin: false,
          departamento: '',
          is_active: true
        }]
      : [],
      progress: tarea.progreso,
      start: tarea.fecha_inicio,
      end: tarea.fecha_termino,
      descriptor: tarea.descriptor as 'GESTIÓN' | 'PERMISOLOGÍA',
      organismo: tarea.organismo,
      dependsOn: (tarea.dependencia && typeof tarea.dependencia === 'string') 
        ? tarea.dependencia.split(',').map(dep => ({ groupId: 0, taskId: Number(dep) }))
        : (tarea.dependencia 
          ? [{ groupId: 0, taskId: Number(tarea.dependencia) }] 
          : []),
      duracion: tarea.duracion,
      enabled: tarea.enabled,
      status: TaskStatus.None,
      followUp: tarea.followUp,
      followUpDate: tarea.followUpDate || '',
      comments: tarea.comments,
      semaphoreStatus: SemaphoreStatus.Gray,
      dependencia: tarea.dependencia,
      isClosed: Boolean(tarea.isClosed),
      orden: tarea.orden,
      subtasks: [],
      // Agregar campos de recordatorios directamente de la tarea
   
    }));

    // Ahora procesamos las tareas y sus subtareas
    const processedTasks = tasksArray.map((task, index) => {
      // Calculamos el estado del semáforo de la tarea principal
      const taskWithStatus = {
        ...task,
        semaphoreStatus: calculateSemaphoreStatus(
          task.start,
          task.end,
          task.id,
          task.dependencia,
          task.isClosed,
          tasksArray
        )
      };

      // Procesamos las subtareas si existen
      const originalTarea = agrupador.tareas[index];
      if (originalTarea.subtasks) {
        taskWithStatus.subtasks = originalTarea.subtasks.map((subtask: any): SubTask => ({
          id: subtask.id,
          name: subtask.nombre,
          responsible: subtask.tipo==='OBSERVACIÓN'?originalTarea.organismo:subtask.responsable,
          progress: subtask.isClosed ? 100 : (subtask.progreso || 0),
          start: subtask.fecha_inicio,
          end: subtask.fecha_termino,
          duration: subtask.duracion || 0,
          organismo: originalTarea.organismo || '',
          dependsOn: parseDependencies(subtask.dependencias, subtask.orden, originalTarea.id),
          enabled: subtask.enabled !== undefined ? subtask.enabled : true,
          type: subtask.tipo,
          resolutionType: subtask.resolucion_tipo,
          orden: subtask.orden || 0,
          followUpDate: subtask.follow_up_date || '',
          followUp: subtask.followUp || false,
          comments: subtask.comments || '',
          isClosed: Boolean(subtask.isClosed),
          delayInDays: calculateDaysDelayed(subtask.fecha_termino, subtask.fecha_inicio),
          semaphoreStatus: Boolean(subtask.isClosed) 
            ? SemaphoreStatus.Gray 
            : calculateSemaphoreStatus(
                subtask.fecha_inicio,
                subtask.fecha_termino,
                subtask.id,
                null,
                Boolean(subtask.isClosed),
                tasksArray,
                taskWithStatus
              ),
          // Agregar campos de recordatorios directamente de la subtarea
          reminder_type: subtask.reminder_type || null,
          reminder_value: subtask.reminder_value || null,
          reminder_calculated_date: subtask.reminder_calculated_date || null,
          reminder_active: Boolean(subtask.reminder_active),
          reminder_triggered: Boolean(subtask.reminder_triggered)
        })).sort((a: SubTask, b: SubTask) => a.orden - b.orden);
      }

      taskWithStatus.delayInDays = calculateDaysDelayed(task.end, task.start);
      return taskWithStatus;
    });

    return {
      title: agrupador.nombre,
      sectionType: agrupador.descriptor.toUpperCase() as 'GESTIÓN' | 'PERMISOLOGÍA',
      processingBody: agrupador.organismo,
      isCollapsed: false,
      tasks: processedTasks,
      onChangeSectionType: () => {},
      onChangeProcessingBody: () => {},
      onChangeTask: () => {},
      onToggleCollapse: () => {},
      subprocessColor: subprocessColor,
      groupProgress: calculateAverageProgress(processedTasks),
      calculateSemaphoreStatus,
      agrupadorId: agrupador.id, // Asegúrate de que esto exista en tu interfaz Agrupador

    };
  });
  
  setProjectSections(projectSections);
}, [allAgrupadores, calculateSemaphoreStatus, calculateDaysDelayed, parseDependencies]);

const subprocessName = filteredSubprocesos[activeSubprocess]?.nombre || '';


// Modifica handleSubprocessChange para usar loadSubprocessData
const handleSubprocessChange = (index: number) => {
  setActiveSubprocess(index);
  const selectedSubprocess = filteredSubprocesos[index];

  if (selectedSubprocess) {
    const subprocesoName = selectedSubprocess.nombre;
    const color = selectedSubprocess.color || getDefaultColor(selectedSubprocess.id);
    localStorage.setItem('selectedSubprocess', subprocesoName);

    loadSubprocessData(subprocesoName, color);
  } else {
    console.error('Subproceso no encontrado');
    setProjectSections([]);
  }
};



const handleChangeSectionType = (sectionIndex: number, newType: 'GESTIÓN' | 'PERMISOLOGÍA') => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    sectionType: newType
  };
  setProjectSections(updatedSections);
};

const handleChangeProcessingBody = (sectionIndex: number, newBody: string, subprocessName: string) => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    processingBody: newBody
  };
  setProjectSections(updatedSections);
};


// Función auxiliar para calcular la fecha final según la duración
const calculateEndDateBasedOnDuration = (startDate: Date, durationInWeeks: number): string => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + (durationInWeeks * 7));
  return dateUtils.formatLocalDate(endDate);
};


// Función para actualizar las fechas de TRAMITACIÓN

// Función para actualizar las fechas de TRAMITACIÓN
/*
const updateTramitacionDates = (task: Task): Task => {
  if (task.name !== 'TRAMITACIÓN') {
    return task;
  }

  const todayString = dateUtils.getTodayString();
  const startDate = dateUtils.parseLocalDate(task.start);
  
  // Si la tarea no está en verde, mantener la fecha final según la duración
  if (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed) {
    const calculatedEndDate = calculateEndDateBasedOnDuration(startDate, task.duracion);
    return {
      ...task,
      end: calculatedEndDate.toString(),
      subtasks: task.subtasks?.map(subtask => ({
        ...subtask,
        end: calculatedEndDate.toString() 
      }))
    };
  }

  // Si está en verde, la fecha final es hoy
  return {
    ...task,
    end: todayString,
    subtasks: task.subtasks?.map(subtask => ({
      ...subtask,
      end: todayString
    }))
  };
};
*/



//DESDE ACA

/*useEffect(() => {
  const updateInterval = setInterval(() => {
    setProjectSections(prevSections => {
      const newSections = prevSections.map(section => ({
        ...section,
        tasks: section.tasks.map(task => 
          task.name === 'TRAMITACIÓN' ? updateTramitacionDates(task) : task
        )
      }));
      return newSections;
    });
  }, 60000); // Actualizar cada minuto

  return () => clearInterval(updateInterval);
}, []);
*/


const handleChangeTask = useCallback(async (sectionIndex: number, taskIndex: number, updatedTask: Task) => {
  try {
    // Primero actualizamos el estado local
    setProjectSections(prevSections => {
      const newSections = [...prevSections];
      const sectionTasks = [...newSections[sectionIndex].tasks];
      
      // Calcular días de retraso
      const today = dateUtils.getTodayLocal();
      const taskEndDate = dateUtils.parseLocalDate(updatedTask.end);
      
      if (taskEndDate < today && !updatedTask.isClosed) {
        const delayInDays = Math.ceil((today.getTime() - taskEndDate.getTime()) / (1000 * 3600 * 24));
        updatedTask.delayInDays = delayInDays;
      }
     
      sectionTasks[taskIndex] = updatedTask;
      newSections[sectionIndex] = {
        ...newSections[sectionIndex],
        tasks: sectionTasks
      };
      return newSections;
    });

    // Actualizar la tarea principal en la base de datos
    const taskData = {
      id: updatedTask.id,
      name: updatedTask.name,
      isClosed: updatedTask.isClosed,
      progress: updatedTask.progress,
      start: updatedTask.start,
      end: updatedTask.end,
      followUpDate: updatedTask.followUpDate,
      comments: updatedTask.comments,
      semaphoreStatus: updatedTask.semaphoreStatus,
      subtasks: updatedTask.subtasks,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskData),
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    // Si la tarea tiene subtareas y alguna fue actualizada, actualizarlas en la BD
    if (updatedTask.subtasks && updatedTask.subtasks.length > 0) {
      for (const subtask of updatedTask.subtasks) {
        const subtaskData = {
          id: subtask.id,
          tarea_id: updatedTask.id,
          name: subtask.name,
          type: subtask.type,
          responsible: subtask.responsible,
          organismo: subtask.organismo,
          progreso: subtask.progress,
          start: subtask.start,
          end: subtask.end,
          duration: subtask.duration,
          dependencias: JSON.stringify(subtask.dependsOn),
          enabled: subtask.enabled,
          resolucion_tipo: subtask.resolutionType,
          orden: subtask.orden, 
          comments: subtask.comments,
          isClosed: subtask.isClosed,
          followUpDate: subtask.followUpDate,
          semaphoreStatus: subtask.semaphoreStatus
        };

        const subtaskResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'update',
            subtask: subtaskData
          }),
        });

        if (!subtaskResponse.ok) {
          throw new Error('Error updating subtask');
        }
      }
    }


  } catch (error) {
    console.error('Error en handleChangeTask:', error);
    alert('Error al actualizar la tarea y sus subtareas. Por favor, intente nuevamente.');
  }
}, []);


// Helper function to check for circular dependencies
const hasCircularDependency = (
  tasks: Task[], 
  currentTaskId: number, 
  dependencyChain = new Set<number>()
): boolean => {
  if (dependencyChain.has(currentTaskId)) {
    return true;
  }

  const currentTask = tasks.find(task => task.id === currentTaskId);
  if (!currentTask) {
    return false;
  }

  const dependencies = typeof currentTask.dependencia === 'string'
    ? currentTask.dependencia.split(',').map(Number)
    : currentTask.dependencia ? [Number(currentTask.dependencia)] : [];

  const newChain = new Set(dependencyChain);
  newChain.add(currentTaskId);

  return dependencies.some(depId => 
    hasCircularDependency(tasks, depId, newChain)
  );
};

/*
const adjustTaskDates = useCallback((tasks: Task[]): Task[] => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Asegurarnos de que sea la medianoche en la zona horaria local
  const adjustedTasks = [...tasks];

  const adjustTask = (taskPosition: number) => {
    if (taskPosition < 0 || taskPosition >= adjustedTasks.length) return;

    const task = adjustedTasks[taskPosition];
    const taskEndDate = new Date(task.end);

    // Ajustamos la hora de la fecha de la tarea a medianoche
    taskEndDate.setHours(0, 0, 0, 0);

    // Verificamos si la fecha de término de la tarea ya pasó (excluyendo el día de hoy)
    if (taskEndDate < today && !task.isClosed) {
      // Cálculo de los días de retraso basado en la diferencia entre hoy y la fecha de término de la tarea
      const delayInDays = Math.ceil((today.getTime() - taskEndDate.getTime()) / (1000 * 3600 * 24));

      // Calcular la nueva fecha sumando el retraso + 1 día
      const newAdjustedDate = new Date(taskEndDate);
      newAdjustedDate.setDate(newAdjustedDate.getDate() + delayInDays + 1); // Sumar días de retraso + 1

      // Mostrar un alert con la fecha de término original, los días de retraso y la nueva fecha ajustada
   //   alert(`La tarea "${task.description}" (Posición: ${taskPosition + 1}) con fecha de término original ${task.end} tiene ${delayInDays} días de retraso.\nNueva fecha ajustada: ${newAdjustedDate.toISOString().split('T')[0]}`);

      // Solo actualizamos si el retraso ha cambiado
      if (task.delayInDays !== delayInDays) {
        adjustedTasks[taskPosition] = {
          ...task,
          delayInDays: delayInDays, // Actualizar días de retraso para esta tarea
          semaphoreStatus: calculateSemaphoreStatus(
            task.start,
            task.end,
            task.id,
            task.dependencia,
            task.isClosed,
            adjustedTasks
          )
        };

        // Ajustar las tareas dependientes
        adjustedTasks.forEach((dependentTask, dependentTaskPosition) => {
          // Las dependencias están basadas en las posiciones, no en los IDs
          const dependencies = typeof dependentTask.dependencia === 'string'
            ? dependentTask.dependencia.split(',').map(Number)
            : dependentTask.dependencia ? [Number(dependentTask.dependencia)] : [];

          // Verificar si la tarea dependiente tiene una dependencia directa de la tarea retrasada (basado en la posición)
          if (dependencies.includes(taskPosition + 1) && !dependentTask.isClosed) {
            // Mostrar un alert con la tarea dependiente directa
         //   alert(`La tarea dependiente directa de la tarea en posición ${taskPosition + 1} es "${dependentTask.description}" (Posición: ${dependentTaskPosition + 1}).`);

            // Usar la nueva fecha ajustada como la nueva fecha de inicio para la tarea dependiente
            const newStartDate = new Date(newAdjustedDate); // Aquí usamos la fecha ajustada de la tarea retrasada
            const currentEndDate = new Date(dependentTask.end);

            // Ajustar la nueva fecha de fin de la tarea dependiente en función de su duración
            const duration = currentEndDate.getTime() - new Date(dependentTask.start).getTime();
            const newEndDate = new Date(newStartDate.getTime() + duration);

            // Actualizar la tarea dependiente con las nuevas fechas
            adjustedTasks[dependentTaskPosition] = {
              ...dependentTask,
              start: newStartDate.toISOString().split('T')[0],
              end: newEndDate.toISOString().split('T')[0],
              delayInDays: Math.ceil((newStartDate.getTime() - new Date(dependentTask.start).getTime()) / (1000 * 3600 * 24)),
              semaphoreStatus: calculateSemaphoreStatus(
                newStartDate.toISOString().split('T')[0],
                newEndDate.toISOString().split('T')[0],
                dependentTask.id,
                dependentTask.dependencia,
                dependentTask.isClosed,
                adjustedTasks
              )
            };

            // Recursivamente ajustar las tareas que dependen de esta
            adjustTask(dependentTaskPosition);
          }
        });
      }
    }
  };

  // Identificar las tareas retrasadas y ajustar sus dependientes
  adjustedTasks.forEach((task, index) => {
    if (!task.isClosed) {
      const taskEndDate = new Date(task.end);
      taskEndDate.setHours(0, 0, 0, 0); // Asegurarnos de que sea medianoche en la zona horaria local
      if (taskEndDate < today) {
        adjustTask(index); // Ajustamos la tarea retrasada por posición
      }
    }
  });

  return adjustedTasks;
}, [calculateSemaphoreStatus]);
*/




// Update the updateTasksInDatabase function to handle batch updates more efficiently
const updateTasksInDatabase = async (tasks: Task[]) => {
  try {
    // Remove duplicates by ID
    const uniqueTasks = Array.from(
      new Map(tasks.map(task => [task.id, task])).values()
    );

    const tasksToUpdate = uniqueTasks.map(task => ({
      id: task.id,
      start: task.start,
      end: task.end,
      semaphoreStatus: task.semaphoreStatus,
      delayInDays: task.delayInDays || 0,
      isClosed: task.isClosed
    }));

    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_multiple_tasks.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tasksToUpdate),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const result = await response.json();
    console.log('Tasks updated successfully:', result.message);
  } catch (error) {
    console.error('Error updating tasks:', error);
  }
};
/*

useEffect(() => {
  
  if (!initialUpdateDone && projectSections.length > 0) {
    const updatedSections = projectSections.map(section => ({
      ...section,
      tasks: adjustTaskDates(section.tasks)
    }));

    // Actualizar el estado con las secciones ajustadas
 //   setProjectSections(updatedSections);

    // Recopilar todas las tareas actualizadas
    const allUpdatedTasks = updatedSections.flatMap(section => section.tasks);

    // Actualizar las tareas en la base de datos
    updateTasksInDatabase(allUpdatedTasks);

    // Marcar que la actualización inicial se ha completado
    setInitialUpdateDone(true);
  }
}, [projectSections, adjustTaskDates, initialUpdateDone]);
*/


  const handleToggleCollapse = (sectionIndex: number) => {
    const updatedSections = [...projectSections];
    updatedSections[sectionIndex].isCollapsed = !updatedSections[sectionIndex].isCollapsed;
    setProjectSections(updatedSections);
  };

  const handleToggleAllCollapse = () => {
    const newCollapsedState = !allCollapsed;
    setAllCollapsed(newCollapsedState);
    const updatedSections = projectSections.map(section => ({
      ...section,
      isCollapsed: newCollapsedState
    }));
    setProjectSections(updatedSections);
  };
  

  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };



  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    } else {
      resetState();
    }
  }, [selectedProcess]);


  if (!selectedProcess) {
    return <NoProcesoSeleccionadoMessage />;
  }
 



/*

  useEffect(() => {
    setSelectedCodigoProyecto('');
      setSubprocesos([]);
  
  
      const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);
  
  */




  
  // Función auxiliar para generar un color por defecto si no se proporciona uno
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  /*
  const handleProcessChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    alert(selectedValue);
    if (selectedValue === "") {
      // "Asignar proceso" was selected
      setSelectedCodigoProyecto('');
      setSubprocesos([]);
      setIsProcessLoaded(false)
      setProjectSections([]);
    } else {
      setSelectedProcess?.(selectedValue); // Use optional chaining
      const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
      if (selectedProcess) {
        
        setSelectedCodigoProyecto(selectedProcess.codigo);
        
        // Cargar subprocesos
        fetchSubprocesos(selectedProcess.codigo);
        setIsProcessLoaded(true);

         // Fetch plantilla name
         const plantillaNombre = await fetchPlantillaNombre(selectedProcess.codigo);
         if (plantillaNombre) {
          await fetchPlantilla(plantillaNombre); 
          setIsProcessLoaded(true);


         } else {
           console.error('No se pudo obtener el nombre de la plantilla');
           setIsProcessLoaded(false);
         }

      }
      
    }
  };
*/



const handleNavigateToEditPlantillas = () => {
  if (navigateToSubComponent) {
    navigateToSubComponent('ADMINISTRACIÓN', 'Mis plantillas');
  } else {
    console.error('navigateToSubComponent is not defined');
  }
};
const sectionVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      type: "spring",
      stiffness: 100,
      damping: 15,
      mass: 0.3
    }
  },
  exit: { 
    opacity: 0, 
    y: -10, 
    transition: { 
      duration: 0.2
    }
  }
};

// 1. Animación de deslizamiento lateral
const slideVariants = {
  hidden: { x: '-100%' },
  visible: { 
    x: 0,
    transition: { type: 'spring', stiffness: 120, damping: 20 }
  },
  exit: { x: '100%' }
};

// 2. Animación de fundido con escala
const fadeScaleVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.3 }
  },
  exit: { opacity: 0, scale: 1.2 }
};

// 3. Animación de aparición desde el centro
const expandVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 200, damping: 20 }
  },
  exit: { opacity: 0, scale: 0 }
};

// 4. Animación de rotación y escala
const rotateScaleVariants = {
  hidden: { rotate: -180, scale: 0 },
  visible: { 
    rotate: 0, 
    scale: 1,
    transition: { type: 'spring', stiffness: 150, damping: 20 }
  },
  exit: { rotate: 180, scale: 0 }
};

// 5. Animación de rebote
const bounceVariants = {
  hidden: { y: '-100%', opacity: 0 },
  visible: { 
    y: 0,
    opacity: 1,
    transition: { 
      type: 'spring',
      stiffness: 300,
      damping: 25,
      mass: 0.5
    }
  },
  exit: { y: '100%', opacity: 0 }
};
return (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="w-full bg-white rounded-lg p-3 mx-auto"
  >
    {isProcessLoaded && (
      <AnimatePresence mode="wait">
        <motion.div
          key="plantilla-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="sticky top-0 z-10 bg-white">
            <div className="p-2 mx-auto">
              {filteredSubprocesos.length > 0 ? (
                <>
                  {/* Render SubprocessTabs si hay subprocesos */}
                  <span className="font-bold">Subprocesos:</span>
                  <div className="flex justify-between items-center border-b border-none py-2">
                    <div className="flex flex-wrap mt-1 items-center flex-grow">
                      {filteredSubprocesos.map((subprocess, index) => (
                        <SubprocessTab
                          key={index}
                          label={subprocess.nombre} 
                          isActive={activeSubprocess === index}
                          onClick={() => handleSubprocessChange(index)}
                          color={subprocess.color || ''}
                          index={index}
                          totalTabs={filteredSubprocesos.length}
                          activeIndex={activeSubprocess}
                        />
                      ))}
                    </div>
                    <motion.button
                      onClick={handleToggleAllCollapse}
                      className="ml-4 px-3 py-2 flex items-center text-sm border border-black border-2 transition-colors duration-200 h-[38px]"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {allCollapsed ? <Maximize size={20} className="mr-2" /> : <Minimize size={20} className="mr-2" />}
                      <span className="whitespace-nowrap">
                        {allCollapsed ? "Expandir Todo" : "Colapsar Todo"}
                      </span>
                    </motion.button>
                  </div>

                  {/* Render ProjectSections si hay agrupadores */}
                  {projectSections.length > 0 ? (
                    <motion.div
                      className="pr-2 pl-2 mx-auto"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2 }}
                    >
                      <AnimatePresence mode="wait">
                        {projectSections.map((section, index) => (
                          <motion.div
                            key={section.title}
                            variants={sectionVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            transition={{ delay: index * 0.03 }}
                            layout
                          >
                            <ProjectSection
                              title={section.title}
                              sectionType={section.sectionType as 'GESTIÓN' | 'PERMISOLOGÍA'}
                              processingBody={section.processingBody}
                              tasks={section.tasks}
                              processName={selectedNombreProyecto}
                              subprocessName={subprocessName}
                              agrupadorName={section.title}
                              onChangeSectionType={(newType: 'GESTIÓN' | 'PERMISOLOGÍA') => handleChangeSectionType(index, newType)}
                              onChangeProcessingBody={(newBody) => handleChangeProcessingBody(index, newBody, subprocessName)}
                              onChangeTask={(taskIndex, updatedTask) => handleChangeTask(index, taskIndex, updatedTask)}
                              isCollapsed={section.isCollapsed}
                              onToggleCollapse={() => handleToggleCollapse(index)}
                              subprocessColor={section.subprocessColor}
                              groupProgress={calculateAverageProgress(section.tasks)} 
                              calculateSemaphoreStatus={calculateSemaphoreStatus} // Pasar la función
                              agrupadorId={section.agrupadorId} // Asegúrate de que esto se pase

                            />
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </motion.div>
                  ) : (
                    <div className="flex justify-center items-center w-full">
                      <span>No hay datos disponibles para este proceso</span> {/* Mensaje cuando no hay datos */}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center w-full">
                 <NoAgrupadoresMessage/> {/* Mensaje cuando no hay agrupadores */}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    )}
  </motion.div>
);
};

export default EngineeringProjectTimeline;
