import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, Minimize, Maximize, FileX, Plus, FileQuestion, X, Upload, Layers } from 'lucide-react';
import { CommonComponentProps } from '../../types/common';
import { motion, AnimatePresence, color } from 'framer-motion';
import GanttChartView from './ganttChartView';
import { group } from 'console';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import toast, { Toaster } from 'react-hot-toast';
import dateUtils from './dateUtils';


interface UpdateTaskEvent {
  taskId: string;
  groupId?: string;
  updates: {
    name?: string;
    start?: Date;
    end?: Date;
    progress?: number;
    isClosed?: boolean;
    comments?: string;
    reminder_code?: string;
    reminder_type?: string;
    reminder_value?: string;
    subtasks?: SubTask[];
  };
}


interface Task {
  id: number;
  description: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  duracion?: number;
  color?: string;
  followUp: boolean;
  status: TaskStatus;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
  dependencia: string | number | null;
  name: string;
  delayInDays?: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean;
  isClosed: boolean; // Add this line
  comments?: string;
subtasks: SubTask[];
  
}



interface ProcessData {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}
interface Agrupador {
  id: string;
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
  tareas: Tarea[]; // Añadimos esta línea
  enabled: boolean; // Add this line

}

interface Tarea {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  descriptor: string;
  duracion: number;
  organismo: string;
  dependencia: number | null;
  enabled: boolean;
  isClosed: boolean;
  subtasks: SubTask[];
}

interface SubTask {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean; 
  isClosed: boolean; // Add this line
  comments: string;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
  hidden?: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SA+' | 'SA-' | 'OTRO';
  orden: number;
}


interface TasksState {
  name: string;
  groups: Group[];
}
interface TaskInput {
  id: number;
  name: string;
  description: string;
  followUp: boolean;
  status: TaskStatus;
  responsible: string;
  progress: number;
  descriptor: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string;
  isClosed?: boolean; // Añadimos esta propiedad

}
interface Group {
  id: number;
  nombre: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: TaskInput[];
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organismo: string;
  enabled: boolean;
  color: string;
  tareas: {
    id: number;
    nombre: string;
    responsable: string;
    progreso: number;
    fecha_inicio: string;
    fecha_termino: string;
    color: string;
    descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
    organismo: string;
    enabled: boolean;
    seguimiento: string;
    dependencia: number | null;
    fechaSeguimiento: string;
  }[];
}

interface TasksState {
  name: string;
  groups: Group[];
}



interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}



const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.185;
  const fixedOffset = 20; // Offset fijo para todas las pestañas

  useEffect(() => {
    if (isActive) {
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
        zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => (
  <input
    type="date"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full border rounded px-2 py-1 text-sm"
  />
);


interface NoPlantillaMessageProps {
  navigateToSubComponent: (main: string, sub: string) => void;
}

const NoPlantillaMessage: React.FC<NoPlantillaMessageProps> = ({ navigateToSubComponent }) => {
  const handleEditarPlantillas = () => {
    navigateToSubComponent('ADMINISTRACIÓN', 'Editar plantillas');
  };

  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileX size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No existe planificación para este proceso</h3>
      <p className="text-teal-600 text-center mb-6">
  Al parecer aún no se ha establecido una planificación para este proceso.<br />
  Por favor diríjase a Mis Plantillas y asocie una plantilla a un proceso para iniciar la planificación.
</p>

      <button
        onClick={handleEditarPlantillas}
        className="flex items-center bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 transition-all duration-300 transform hover:scale-105 active:scale-95"
      >
        <Plus size={20} className="mr-2" />
        Asignar nueva Plantilla
      </button>
    </div>
  );
};


interface SmoothCollapseProps {
  isOpen: boolean;
  children: React.ReactNode;
}



const SmoothCollapse: React.FC<SmoothCollapseProps> = ({ isOpen, children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | string>(0);

  useEffect(() => {
    if (isOpen) {
      const contentHeight = contentRef.current?.scrollHeight;
      setHeight(contentHeight || 'auto');
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const containerVariants = {
    open: { 
      height: height,
      opacity: 1,
      transition: { 
        height: { type: "spring", stiffness: 100, damping: 20, duration: 0.3 },
        opacity: { duration: 0.2 }
      }
    },
    collapsed: { 
      height: 0,
      opacity: 0,
      transition: { 
        height: { type: "spring", stiffness: 100, damping: 20, duration: 0.3 },
        opacity: { duration: 1.2 }
      }
    }
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        variants={containerVariants}
        initial="collapsed"
        animate={isOpen ? "open" : "collapsed"}
        exit="collapsed"
        style={{ overflow: 'hidden' }}
      >
        <div ref={contentRef}>
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};




const NoProcesoSeleccionadoMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No se ha seleccionado ningún proceso</h3>
      <p className="text-teal-600 text-center mb-6">Por favor, seleccione un proceso para comenzar.</p>
    </div>
  );
};

// Update the TaskInput interface
interface TaskInput {
  id: number;
  name: string;
  description: string;
  followUp: boolean;
  semaphoreStatus: string;
  dependencia: number | null;
  followUpDate: string;
  status: TaskStatus;
  responsible: string;
  progress: number;
  descriptor: string;
  start: string; // Add this line
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string;
  enabled: boolean; // Add this line
  organism: string; // Add this line if needed
}

// Update the Group interface
interface Group {
  id: number;
  nombre: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: TaskInput[]; // Change this from Task[] to TaskInput[]
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organismo: string;
  enabled: boolean;
  color: string;
  tareas: {
    id: number;
    nombre: string;
    responsable: string;
    progreso: number;
    fecha_inicio: string;
    fecha_termino: string;
    color: string;
    descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
    organismo: string;
    enabled: boolean;
    seguimiento: string;
    dependencia: number | null;
    fechaSeguimiento: string;
  }[];
}

interface ProjectSectionProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  isCollapsed: boolean;
  tasks: Task[];
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  onChangeTask: (taskIndex: number, updatedTask: Task) => void;
  onToggleCollapse: () => void;
  subprocessColor: string;
}


enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}

type TaskType = 'task' | 'project' | 'milestone';


interface GanttSubTask {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  st_type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SA+' | 'SA-' | 'OTRO';
  orden: number;
  isClosed: boolean;
  type: TaskType; // Agregamos esta propiedad

  semaphoreStatus: SemaphoreStatus; // Add this required property
  comments: string; // Add this required property
  followUpDate: string; // Add this required property

}

interface GanttTask {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean;
  isTramitacion?: boolean;
  subtasks?: GanttSubTask[];
  hidden?: boolean;
  orden: number;
  color: string;
}

interface GanttGroup {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: GanttTask[];
  enabled: boolean;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organismo: string;
  orden: number;
  color: string;
}

interface GanttTasksState {
  name: string;
  groups: GanttGroup[];
}
interface ProjectSection {
  title: string;
  sectionType: string;
  processingBody: string;
  isCollapsed: boolean;
  tasks: Task[];
  onChangeSectionType: () => void;
  onChangeProcessingBody: () => void;
  onChangeTask: () => void;
  onToggleCollapse: () => void;
}

interface TaskRowProps {
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  rowColor: string;
  inputColor: string;
  allTasks: Task[]; // This should now be an array of Task objects


}





interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
}

const TaskModal: React.FC<TaskModalProps> = ({ isOpen, onClose, task, onChangeTask }) => {
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };

 const [users, setUsers] = useState<User[]>([]);
  

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
        if (!response.ok) throw new Error('Error al obtener los usuarios.');
        
        const data: User[] = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            className="bg-white rounded-lg w-full max-w-2xl"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="bg-[#40E0D0] text-white p-4 flex justify-between items-center rounded-t-lg">
              <h2 className="text-xl font-bold">SEGUIMIENTO</h2>
              <button onClick={onClose} className="text-white hover:text-gray-200">
                <X size={24} />
              </button>
            </div>
            <form className="p-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tipo Tarea</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                      <Plus size={18} className="text-red-500" />
                    </span>
                    <input
                      type="text"
                      name="tipo"
                      value='Tipo'
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <input
                    type="text"
                    name="descripcion"
                    value={task.description}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Creado por:</label>
                  <input
                    type="text"
                    name="creadoPor"
                    value={task.responsible}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                {/*  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      name="tarea"
                      value={'Tarea'}
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                    >
                      <option>Seleccione...</option>
                      <option>{task.responsible}</option>
                    </select>
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                      <Plus size={18} className="text-[#40E0D0]" />
                    </span>
                    <span className="ml-2 inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-50 text-gray-500">
                      <X size={18} className="text-red-500" />
                    </span>
                  </div>*/}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Porcentaje de progreso</label>
                  
  <div className="flex items-center space-x-2">
    <input
      type="number"
      name="porcentajeprogreso"
      value={task.progress}
      onChange={(e) => {
        const value = Math.max(0, Math.min(100, Number(e.target.value))); // Limitar entre 0 y 100
        onChangeTask({ ...task, progress: value });
      }}
      className="mt-1 border focus:ring-indigo-500 focus:border-indigo-500 block w-10 shadow-sm sm:text-sm border-gray-300 rounded-md"
    />%
    <input
      type="range"
      min="0"
      max="100"
      value={task.progress}
      onChange={(e) => onChangeTask({ ...task, progress: Number(e.target.value) })}
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
    />
  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha de Seguimiento</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                      type="date"
                      name="followUpDate"
                      value={task.followUpDate}
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-l-md sm:text-sm border-gray-300"
                    />
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                      <X size={18} className="text-red-500" />
                    </span>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Inicio</label>
                  <input
                    type="date"
                    name="fechaInicio"
                    value={task.start}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Término</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="date"
                      name="fechaTermino"
                      value={task.end}
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-l-md sm:text-sm border-gray-300"
                    />
                    <button
                      type="button"
                      className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-[#40E0D0] text-white"
                    >
                      CAMBIAR PLAZO
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Documento</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="documento"
                    value={'Documento'}
                    onChange={handleInputChange}
                    placeholder="Adjuntar documento"
                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-l-md sm:text-sm border-gray-300"
                  />
                  <span className="inline-flex items-center px-3 border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                    Browse
                  </span>
                  <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                    <X size={18} className="text-red-500" />
                  </span>
                  <span className="ml-2 inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-50 text-gray-500">
                    <Upload size={18} className="text-[#40E0D0]" />
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#FFA07A] hover:bg-[#FF8C69] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA07A]"
                >
                  Historial de comentarios
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};





interface SectionHeaderProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  backgroundColor: string;

}

const SectionHeader: React.FC<SectionHeaderProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  isCollapsed, 
  onToggleCollapse,
  backgroundColor 

}) => {

  const darkenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
  };

  const darkerColor = darkenColor(backgroundColor, 30); // 30 es la cantidad de oscurecimiento, ajusta según sea necesario


  return(
  <div style={{ backgroundColor }} className="text-white px-4 py-2">
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center">
        <button onClick={onToggleCollapse} className="mr-2">
          {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </button>
        <h3 className="text-sm font-bold">{title}</h3>
      </div>
      <div className="relative">
      <select
            value={sectionType}
            onChange={(e) => onChangeSectionType(e.target.value as 'GESTIÓN' | 'PERMISOLOGÍA')}
            className="text-white px-2 py-1 rounded appearance-none pr-8 text-sm"
            style={{ backgroundColor: darkerColor }}
          >
            <option value="GESTIÓN">GESTIÓN</option>
            <option value="PERMISOLOGÍA">PERMISOLOGÍA</option>
          </select>
        <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white" size={14} />
      </div>
    </div>
    {!isCollapsed && (
      <div className="flex items-center">
        <span className="text-sm mr-2">
          {sectionType === "PERMISOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}
        </span>
        <input
          type="text"
          value={processingBody}
          onChange={(e) => onChangeProcessingBody(e.target.value)}
          className=" text-white px-2 py-1 text-white rounded text-sm flex-grow"
          style={{ backgroundColor: darkerColor }}

          placeholder={sectionType === "PERMISOLOGÍA" ? "Ingrese organismo" : "Ingrese gestor"}
        />
      </div>
    )}
  </div>
);
};


const ProjectSection: React.FC<ProjectSectionProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  tasks, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  onChangeTask, 
  isCollapsed, 
  subprocessColor,
  onToggleCollapse 
}) =>{
  
  
/*

  const updateTaskStatus = (tasks: Task[]): Task[] => {
    return tasks.map(task => ({
      ...task,
      semaphoreStatus: calculateSemaphoreStatus(task.start, task.end)
    }));
  };
  useEffect(() => {
    const updatedTasks = updateTaskStatus(tasks);
    if (JSON.stringify(updatedTasks) !== JSON.stringify(tasks)) {
      onChangeTask(0, updatedTasks[0]); // Trigger a re-render with the updated tasks
    }
  }, [tasks]);
*/
  

  const darkenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
  };

  const darkerColor = darkenColor(subprocessColor, 20); // 20 es la cantidad de oscurecimiento, ajusta según sea necesario

  const lightenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );
  };
  
  // Uso
  const lighterColor = lightenColor(subprocessColor, 115);

  return (
  <div className="mb-6 shadow-lg">
    <SectionHeader
      title={title}
      sectionType={sectionType}
      processingBody={processingBody}
      onChangeSectionType={onChangeSectionType}
      onChangeProcessingBody={onChangeProcessingBody}
      isCollapsed={isCollapsed}
      onToggleCollapse={onToggleCollapse}
      backgroundColor={subprocessColor}
    />
         <SmoothCollapse isOpen={!isCollapsed}>


      <div className="overflow-x-auto overflow-y-hidden bg-white">
        <table className="w-full">
          <thead>
            <tr style={{ backgroundColor: lighterColor }}>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Descripción</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Respo2nsable</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Inicio</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Término</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">progreso (%)</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Dependencias</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Seguimiento</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Estado</th>
            </tr>
          </thead>
          <tbody>
              
          </tbody>
        </table>
      </div>
      </SmoothCollapse>

  </div>
);
};

enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
  plantilla: string; // Nombre de la plantilla asociada al proceso

}


interface Subproceso {
  id: number;
  nombre: string;
  agrupadores: Agrupador[];
  subproceso: string;
  color?: string;
}

interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string; // This will be a JSON string
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Plantilla {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}


interface Props extends Partial<CommonComponentProps> {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}



interface SubtaskUpdateData {
  id: number;
  tarea_id: number;
  nombre: string;
  tipo: string;
  responsible: User[] | string;
  organismo: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  dependencias: string; 
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];

  enabled: boolean;
  resolucion_tipo?: string;
  orden: number;
  comments: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
}

const GanttGeneral: React.FC<Props> = ({ 
  selectedProcess,
  setSelectedProcess,
  processes,
  navigateToSubComponent,
  ...props
}) => {

const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
const [projectSections, setProjectSections] = useState<ProjectSectionProps[]>([]);
const [allCollapsed, setAllCollapsed] = useState<boolean>(false);
const [hasPlantilla, setHasPlantilla] = useState<boolean>(true);
const [hasSubprocessData, setHasSubprocessData] = useState<boolean>(true);
const [tasks, setTasks] = useState<Task[]>([]);
const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});
const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);

const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);


const [isLoading, setIsLoading] = useState(false);

const previousProcessRef = useRef<string | null>(null);


const [lastUpdate, setLastUpdate] = useState<Date>(new Date());





const resetSubprocessState = () => {
  setActiveSubprocess(0);
  setCurrentAgrupadores([]);
  localStorage.removeItem('selectedSubprocess');
};

// Modificar loadProcessData para manejar la recarga de datos
const loadProcessData = async (processName: string) => {
  setIsLoading(true);
  
  if (processName === "") {
    resetState();
    setIsLoading(false);
    return;
  }

  const selectedProcessObj = processes.find(p => p.nombreProceso === processName);
  
  if (selectedProcessObj) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${selectedProcessObj.codigo}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: ProcessData = await response.json();
      


      if (data.subprocesos) {
        data.subprocesos.forEach(subproceso => {
          if (subproceso.agrupadores) {
            subproceso.agrupadores.forEach(agrupador => {
              if (agrupador.tareas) {
                agrupador.tareas = agrupador.tareas.map(tarea => ({
                  ...tarea,
                  subtasks: Array.isArray(tarea.subtasks) ? tarea.subtasks : []
                }));
              }
            });
          }
        });
      }

      setSelectedNombreProyecto(data.nombre);
      setSelectedCodigoProyecto(data.proceso);

      // Filtrar subprocesos que tienen agrupadores
      const fetchedSubprocesos: Subproceso[] = data.subprocesos
        .filter((sp: any) => sp.agrupadores && sp.agrupadores.some((agr: Agrupador) => agr.enabled))
        .map((sp: any) => ({
          id: sp.id,
          nombre: sp.nombre,
          subproceso: sp.nombre,
          color: sp.color || "",
          agrupadores: sp.agrupadores.filter((agr: Agrupador) => agr.enabled) || [],
        }));

      setSubprocesos(fetchedSubprocesos);

      const allAgrupadores: Agrupador[] = fetchedSubprocesos.flatMap((subproceso: Subproceso) =>
        subproceso.agrupadores.filter((agrupador: Agrupador) => agrupador.enabled)
          .map((agrupador: Agrupador) => ({
            ...agrupador,
            subproceso: subproceso.nombre
          }))
      );
      
      setAllAgrupadores(allAgrupadores);
      setFilteredSubprocesos(fetchedSubprocesos);
      
      const newSubprocessColors: {[key: string]: string} = {};
      fetchedSubprocesos.forEach(sp => {
        newSubprocessColors[sp.subproceso] = sp.color || "";
      });
      setSubprocessColors(newSubprocessColors);

      setIsProcessLoaded(true);
      setLastUpdate(new Date()); // Forzar re-render del GanttChartView

    } catch (error) {
      console.error('Error loading process data:', error);
      setHasPlantilla(false);
      setIsProcessLoaded(false);
      throw error; // Re-throw para manejar en el caller si es necesario
    } finally {
      setIsLoading(false);
    }
  }
};


const handleSubprocessChange = async (index: number) => {
  try {
    setActiveSubprocess(index);
    const selectedSubprocess = filteredSubprocesos[index];
    
    if (!selectedSubprocess) return;

    const agrupadoresForSubprocess = allAgrupadores.filter(agrupador => 
      agrupador.subproceso === selectedSubprocess.subproceso
    );
    
    setCurrentAgrupadores(agrupadoresForSubprocess);
    const subprocessColor = selectedSubprocess.color || "";

    const newProjectSections = agrupadoresForSubprocess.map(agrupador => ({
      title: agrupador.nombre,
      sectionType: 'GESTIÓN' as 'GESTIÓN' | 'PERMISOLOGÍA',
      processingBody: agrupador.subproceso,
      isCollapsed: false,
      tasks: agrupador.tareas.map(tarea => ({
        id: tarea.id,
        description: tarea.nombre,
        responsible: tarea.responsable,
        progress: tarea.progreso,
        start: tarea.fecha_inicio,
        end: tarea.fecha_termino,
        followUp: false,
        status: TaskStatus.None,
        duracion: tarea.duracion,
        descriptor: tarea.descriptor as 'GESTIÓN' | 'PERMISOLOGÍA',
        followUpDate: '',
        semaphoreStatus: SemaphoreStatus.Gray,
        dependencia: tarea.dependencia,
        name: tarea.nombre,
        organism: tarea.organismo,
        dependsOn: tarea.dependencia ? [{ groupId: 0, taskId: tarea.dependencia }] : [],
        enabled: tarea.enabled,
        isClosed: tarea.isClosed,
        subtasks: tarea.nombre === 'TRAMITACIÓN' && tarea.subtasks ? tarea.subtasks : [],
        color: subprocessColor
      })),
      onChangeSectionType: () => {},
      onChangeProcessingBody: () => {},
      onChangeTask: () => {},
      onToggleCollapse: () => {},
      subprocessColor: subprocessColor
    }));

    setProjectSections(newProjectSections);
    setLastUpdate(new Date()); // Forzar re-render del GanttChartView

  } catch (error) {
    console.error('Error en handleSubprocessChange:', error);
    toast.error('Error al cambiar de subproceso');
  }
};

const handleChangeSectionType = (sectionIndex: number, newType: 'GESTIÓN' | 'PERMISOLOGÍA') => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    sectionType: newType
  };
  setProjectSections(updatedSections);
};

const handleChangeProcessingBody = (sectionIndex: number, newBody: string) => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    processingBody: newBody
  };
  setProjectSections(updatedSections);
};

const handleChangeTask = (sectionIndex: number, taskIndex: number, updatedTask: Task) => {
  const updatedSections = [...projectSections];
  const updatedTasks = [...updatedSections[sectionIndex].tasks];
  updatedTasks[taskIndex] = updatedTask;
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    tasks: updatedTasks
  };
  setProjectSections(updatedSections);
};

const updateSubtaskInDB = async (subtaskData: SubtaskUpdateData): Promise<void> => {
  try {
    const formatDate = (dateStr: string): string => {
      return /^\d{4}-\d{2}-\d{2}$/.test(dateStr) 
        ? dateStr 
        : dateUtils.formatLocalDate(dateUtils.parseLocalDate(dateStr));
    };

    // Convertir el array de usuarios a string si es necesario
    const responsible = Array.isArray(subtaskData.responsible) 
      ? subtaskData.responsible.map(user => user.id).join(',')
      : subtaskData.responsible;

    const formattedSubtask = {
      id: subtaskData.id,
      tarea_id: subtaskData.tarea_id,
      nombre: subtaskData.nombre,
      responsible: responsible,
      organismo: subtaskData.organismo || '',
      tipo: subtaskData.tipo,
      progreso: subtaskData.progreso,
      fecha_inicio: dateUtils.parseLocalDate(subtaskData.fecha_inicio),
      fecha_termino: dateUtils.parseLocalDate(subtaskData.fecha_termino),
      duracion: subtaskData.duracion,
      dependencias: JSON.stringify(subtaskData.dependsOn || []),
      enabled: subtaskData.enabled ? 1 : 0,
      resolucion_tipo: subtaskData.resolucion_tipo || null,
      orden: subtaskData.orden,
      follow_up_date: subtaskData.followUpDate || null,
      comments: subtaskData.comments || '',
      isClosed: subtaskData.isClosed ? 1 : 0,
      reminder_type: null,
      reminder_value: null,
      reminder_calculated_date: null,
      reminder_active: 0,
      reminder_triggered: 0,
      delay_in_days: 0,
      semaphore_status: subtaskData.semaphoreStatus
    };
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        action: 'update',
        subtask: formattedSubtask
      })
    });

    if (!response.ok) throw new Error('Error updating subtask');

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating subtask');
    }

  } catch (error) {
    console.error('Error in updateSubtaskInDB:', error);
    throw error;
  }
};


const handleTaskUpdate = async (updateEvent: UpdateTaskEvent) => {
  try {
    setIsLoading(true);
    const taskId = parseInt(updateEvent.taskId);
    const { updates } = updateEvent;
    // Actualizar en la base de datos
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: updateEvent.taskId,
        ...updateEvent.updates,
        reminder_info: {
          code: updateEvent.updates.reminder_code,
          type: updateEvent.updates.reminder_type,
          value: updateEvent.updates.reminder_value
        }
      })
    });

    if (!response.ok) {
      throw new Error('Error actualizando la tarea');
    }

        // Si la tarea es TRAMITACIÓN, actualizar sus subtareas
        if (updates.subtasks) {
          for (const subtask of updates.subtasks) {
            const subtaskData: SubtaskUpdateData = {
              id: subtask.id!,
              tarea_id: taskId,
              nombre: subtask.name,
              tipo: subtask.type,
              responsible: subtask.responsible,
              organismo: subtask.organism,
              progreso: subtask.progress,
              fecha_inicio: subtask.start,
              fecha_termino: subtask.end,
              duracion: subtask.duration,
              dependencias: JSON.stringify(subtask.dependsOn),
              enabled: subtask.enabled,
              resolucion_tipo: subtask.resolutionType,
              orden: subtask.orden,
              comments: subtask.comments || '',
              isClosed: subtask.isClosed,
              followUpDate: subtask.followUpDate || '',
              semaphoreStatus: subtask.semaphoreStatus
            };
          
            await updateSubtaskInDB(subtaskData);
          }
        }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error en la actualización');
    }

    // Recargar datos después de actualización exitosa
    await loadProcessData(selectedProcess);
    
    return result; // Retornar el resultado para que handleTaskChange sepa que fue exitoso

  } catch (error) {
    console.error('Error en la actualización:', error);
    toast.error('Error al actualizar la tarea');
    throw error; // Re-throw para que handleTaskChange sepa que hubo un error
  } finally {
    setIsLoading(false);
  }
};





const calculateSemaphoreStatus = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  const today = dateUtils.getTodayLocal();

  // Convertir fechas de string a Date
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la fecha de inicio no ha llegado aún
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea está vencida
  if (today >= endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular porcentaje transcurrido
  const totalTime = endDate.getTime() - startDate.getTime();
  const elapsedTime = today.getTime() - startDate.getTime();
  const progressPercentage = (elapsedTime / totalTime) * 100;

  // Determinar el estado del semáforo según los porcentajes
  if (progressPercentage >= 100) {
    return SemaphoreStatus.Red; // Vencida
  } else if (progressPercentage >= 80) {
    return SemaphoreStatus.Orange; // Muy cerca de vencer
  } else if (progressPercentage >= 50) {
    return SemaphoreStatus.Yellow; // Próxima a vencer
  }
  return SemaphoreStatus.Green; // En tiempo
};



 const updateAllTasksStatus = async () => {
    if (!isProcessLoaded || projectSections.length === 0) return;

    try {
      const updatedSections = [...projectSections];
      let tasksToUpdate: Task[] = [];
      let tasksUpdated = true;

      const getMaxEndDate = (dependencies: number[], allTasks: Task[]): Date | null => {
        const dates = dependencies
          .map((depIndex) => {
            const predecessorTask = allTasks[depIndex - 1];
            if (predecessorTask) {
              
              return dateUtils.parseLocalDate(predecessorTask.end);
              
            }
            return null;
          })
          .filter(Boolean) as Date[];

        return dates.length > 0 ? new Date(Math.max(...dates.map((date) => date.getTime()))) : null;
      };

      const handleTramitacionTask = (task: Task): void => {
        if (task.name === 'TRAMITACIÓN' && !task.isClosed) {
          const startDate = dateUtils.parseLocalDate(task.start);
          const durationInDays = task.duracion ? task.duracion * 7: 7;
      
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + durationInDays);
          endDate.setHours(23, 59, 59, 999); // Set to end of day

          task.end = dateUtils.formatLocalDate(endDate);
          tasksToUpdate.push(task);

          if (task.subtasks) {
            task.subtasks.forEach(subtask => {
              if (!subtask.isClosed) {
                subtask.start = task.start;
                subtask.end = task.end;
                
              
              }
            });
          }
        }
      };

      while (tasksUpdated) {
        tasksUpdated = false;

        updatedSections.forEach((section) => {
          section.tasks.forEach((task) => {
            if (task.dependencia && !task.isClosed) {
              
              const dependencias = typeof task.dependencia === 'string'
                ? task.dependencia.split(',').map(Number)
                : [task.dependencia];

              if (task.name === 'TRAMITACIÓN' && dependencias.includes(0)) {
                task.semaphoreStatus = SemaphoreStatus.Green;
                task.start = dateUtils.getTodayString();
                task.end = dateUtils.getTodayString();
                tasksToUpdate.push(task);
                return;
              }

              const maxDependencyEndDate = getMaxEndDate(dependencias, section.tasks);

              if (maxDependencyEndDate) {
                const newStartDate = new Date(maxDependencyEndDate);
                
                const allDependenciesClosed = dependencias.every(depIndex => {
                  const depTask = section.tasks[depIndex - 1];
                  return depTask && depTask.isClosed;
                });

                let maxDaysLate = 0;

                if (!allDependenciesClosed) {
                  const today = dateUtils.getTodayLocal();
                
                  dependencias.forEach(depIndex => {
                    const depTask = section.tasks[depIndex - 1];
                    if (depTask && !depTask.isClosed) {
                      const taskEndDate = dateUtils.parseLocalDate(depTask.end);
                 
                      if (today > taskEndDate) {
                        const timeDiff = today.getTime() - taskEndDate.getTime();
                        const daysLate = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                        maxDaysLate = Math.max(maxDaysLate, daysLate);
                      }
                    }
                  });

                  newStartDate.setDate(newStartDate.getDate() + maxDaysLate + 1);
                }

                const formattedStartDate = dateUtils.formatLocalDate(newStartDate);

                if (task.start !== formattedStartDate) {
                  task.start = formattedStartDate;

                  if (task.name !== 'TRAMITACIÓN' || (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed)) {
                    const newEndDate = new Date(newStartDate);
                    newEndDate.setDate(newStartDate.getDate() + (task.duracion? (task.duracion * 7):7));
                    task.end = dateUtils.formatLocalDate(newEndDate);
                  }

                  tasksUpdated = true;
                  tasksToUpdate.push(task);
                  
                  
                  if (task.name === 'TRAMITACIÓN' && !task.isClosed) {
                      handleTramitacionTask(task);
                    
                   
                  }

                }
              }
            }

            // Actualizar estado del semáforo
            const newSemaphoreStatus = calculateSemaphoreStatus(
              task.start,
              task.end,
              task.id,
              task.dependencia,
              task.isClosed,
              section.tasks
            );

            if (task.semaphoreStatus !== newSemaphoreStatus) {
              task.semaphoreStatus = newSemaphoreStatus;
              tasksToUpdate.push(task);
              
            }
            
          });
        });
      }




      


      // Guardar cambios en la base de datos
      if (tasksToUpdate.length > 0) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_multiple_tasks.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(tasksToUpdate.map(task => ({
              id: task.id,
              start: task.start,
              end: task.end,
              semaphoreStatus: task.semaphoreStatus,
              delayInDays: task.delayInDays || 0,
              subtasks: task.name === 'TRAMITACIÓN' && task.subtasks ? task.subtasks : [],

            })))
          });
        
          if (!response.ok) {
            throw new Error('Failed to update tasks in database');
          }

          setProjectSections(updatedSections);
        } catch (error) {
          console.error('Error updating tasks in database:', error);
        }
      }
    } catch (error) {
      console.error('Error in updateAllTasksStatus:', error);
    }
  };


  useEffect(() => {
    // Ejecutar actualización solo si la información está cargada
    if (isProcessLoaded) {
      updateAllTasksStatus();
    }
  }, [projectSections, isProcessLoaded, calculateSemaphoreStatus]);
  

  const handleToggleCollapse = (sectionIndex: number) => {
    const updatedSections = [...projectSections];
    updatedSections[sectionIndex].isCollapsed = !updatedSections[sectionIndex].isCollapsed;
    setProjectSections(updatedSections);
  };

  const handleToggleAllCollapse = () => {
    const newCollapsedState = !allCollapsed;
    setAllCollapsed(newCollapsedState);
    const updatedSections = projectSections.map(section => ({
      ...section,
      isCollapsed: newCollapsedState
    }));
    setProjectSections(updatedSections);
  };
  
  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setIsProcessLoaded(false);
    setProjectSections([]);
  };


  useEffect(() => {
    if (filteredSubprocesos && filteredSubprocesos.length > 0 && allAgrupadores && allAgrupadores.length > 0) {
      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      
      if (savedSubprocess) {
        const subprocessIndex = filteredSubprocesos.findIndex(sp => sp?.nombre === savedSubprocess);
  
        if (subprocessIndex !== -1) {
          const agrupadoresForSavedSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === filteredSubprocesos[subprocessIndex]?.subproceso);
          
          setCurrentAgrupadores(agrupadoresForSavedSubprocess);
          setActiveSubprocess(subprocessIndex);
          handleSubprocessChange(subprocessIndex);
        } else {
          const firstSubprocess = filteredSubprocesos[0];
          const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === firstSubprocess?.subproceso);
          
          setCurrentAgrupadores(agrupadoresForFirstSubprocess);
          setActiveSubprocess(0);
          handleSubprocessChange(0);
        }
      } else {
        const firstSubprocess = filteredSubprocesos[0];
        const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === firstSubprocess?.subproceso);
        
        setCurrentAgrupadores(agrupadoresForFirstSubprocess);
        setActiveSubprocess(0);
        handleSubprocessChange(0);
      }
    } else {
      setActiveSubprocess(-1);
      setCurrentAgrupadores([]);
    }
  }, [filteredSubprocesos, allAgrupadores]);
  
  useEffect(() => {
    if (activeSubprocess !== -1 && filteredSubprocesos && filteredSubprocesos[activeSubprocess]) {
      localStorage.setItem('selectedSubprocess', filteredSubprocesos[activeSubprocess].nombre);
    }
  }, [activeSubprocess, filteredSubprocesos]);
  
  
  useEffect(() => {
    if (selectedProcess) {

   
        setSelectedProcess(selectedProcess);
        
     loadProcessData(selectedProcess);
    } else {
      resetState();
    }
  }, [selectedProcess]);


  useEffect(() => {
    if (selectedProcess && isProcessLoaded) {
      const processGanttData = () => {
        const ganttData = convertToGanttTasksState(projectSections);
        // Forzar actualización del componente
        setLastUpdate(new Date());
      };
  
      processGanttData();
    }
  }, [selectedProcess, isProcessLoaded, projectSections]);
  


  if (!selectedProcess) {
    return <NoProcesoSeleccionadoMessage />;
  }

/*

  useEffect(() => {
    setSelectedCodigoProyecto('');
      setSubprocesos([]);
  
  
      const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);
  
  */




  // Función auxiliar para generar un color por defecto si no se proporciona uno
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  /*
  const handleProcessChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    alert(selectedValue);
    if (selectedValue === "") {
      // "Asignar proceso" was selected
      setSelectedCodigoProyecto('');
      setSubprocesos([]);
      setIsProcessLoaded(false)
      setProjectSections([]);
    } else {
      setSelectedProcess?.(selectedValue); // Use optional chaining
      const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
      if (selectedProcess) {
        
        setSelectedCodigoProyecto(selectedProcess.codigo);
        
        // Cargar subprocesos
        fetchSubprocesos(selectedProcess.codigo);
        setIsProcessLoaded(true);

         // Fetch plantilla name
         const plantillaNombre = await fetchPlantillaNombre(selectedProcess.codigo);
         if (plantillaNombre) {
          await fetchPlantilla(plantillaNombre); 
          setIsProcessLoaded(true);


         } else {
           console.error('No se pudo obtener el nombre de la plantilla');
           setIsProcessLoaded(false);
         }

      }
      
    }
  };
*/




const handleNavigate = () => {
  if (navigateToSubComponent) {
    navigateToSubComponent('ADMINISTRACIÓN', 'Mis plantillas');
  } else {
    console.error('navigateToSubComponent is not defined');
  }
};
const sectionVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      type: "spring",
      stiffness: 100,
      damping: 15,
      mass: 0.3
    }
  },
  exit: { 
    opacity: 0, 
    y: -10, 
    transition: { 
      duration: 0.2
    }
  }
};

// 1. Animación de deslizamiento lateral
const slideVariants = {
  hidden: { x: '-100%' },
  visible: { 
    x: 0,
    transition: { type: 'spring', stiffness: 120, damping: 20 }
  },
  exit: { x: '100%' }
};

// 2. Animación de fundido con escala
const fadeScaleVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.3 }
  },
  exit: { opacity: 0, scale: 1.2 }
};

// 3. Animación de aparición desde el centro
const expandVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 200, damping: 20 }
  },
  exit: { opacity: 0, scale: 0 }
};

// 4. Animación de rotación y escala
const rotateScaleVariants = {
  hidden: { rotate: -180, scale: 0 },
  visible: { 
    rotate: 0, 
    scale: 1,
    transition: { type: 'spring', stiffness: 150, damping: 20 }
  },
  exit: { rotate: 180, scale: 0 }
};

// 5. Animación de rebote
const bounceVariants = {
  hidden: { y: '-100%', opacity: 0 },
  visible: { 
    y: 0,
    opacity: 1,
    transition: { 
      type: 'spring',
      stiffness: 300,
      damping: 25,
      mass: 0.5
    }
  },
  exit: { y: '100%', opacity: 0 }
};





const lightenColor = (color: string, amount: number): string => {
  const num = parseInt(color.replace("#", ""), 16);
  const r = Math.min(255, Math.max(0, (num >> 16) + amount));
  const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
  const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
  return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
};

// Función para oscurecer colores
const darkenColor = (color: string, amount: number): string => {
  return lightenColor(color, -amount);
};

const filterEnabledTasksAndGroups = (tasksState: TasksState): TasksState => {
  const filteredGroups = tasksState.groups
    .filter(group => group.enabled)
    .map(group => {
      const subprocessColor = subprocessColors[group.subprocess] || "";
      return {
        ...group,
        color: subprocessColor,
        tasks: group.tasks
          .filter(task => task.enabled)
          .map(task => ({
            ...task,
            color: darkenColor(subprocessColor, 60)
          }))
      };
    })
    .filter(group => group.tasks.length > 0);

  // If there are no enabled groups or tasks, create a dummy task
  if (filteredGroups.length === 0) {
    const today = new Date().toISOString().split('T')[0];
    const defaultColor = "#CCCCCC";
    filteredGroups.push({
      id: 0, // Add this line
      nombre: "No hay tareas habilitadas",
      expanded: true,
      subprocess: "",
      agrupador: "Sin agrupador",
      enabled: true,
      descriptor: 'GESTIÓN',
      organismo: '',
      color: defaultColor,
      tasks: [{
        id: 0,
        name: "Sin tareas",
        description: "Sin tareas",
        responsible: "",
        progress: 0,
        start: today,
        end: today,
        followUp: false,
        status: TaskStatus.None,
        descriptor: 'GESTIÓN',
        followUpDate: today,
        semaphoreStatus: SemaphoreStatus.Gray,
        dependencia: null,
        organism: "",
        dependsOn: [],
        enabled: true,
        color: darkenColor(defaultColor, 60)
      }],
      tareas: [] // Add this line
    });
  }

  return {
    ...tasksState,
    groups: filteredGroups
  };
};

const convertToGanttTasksState = (sections: ProjectSectionProps[]): GanttTasksState => {
//  alert(2) 
  if (sections.length === 0) {
    const today = dateUtils.getTodayString();
    const defaultColor = "#CCCCCC";
    return {
      name: selectedNombreProyecto,
      groups: [{
        name: "No hay tareas habilitadas",
        expanded: true,
        subprocess: "", 
        agrupador: "Sin agrupador",
        enabled: true,
        descriptor: 'GESTIÓN',
        organismo: '',
        color: defaultColor,
        orden: 0,
        tasks: [{
          id: 0,
          name: "Sin tareas",
          responsible: "",
          progress: 0,
          start: today,
          end: today,
          descriptor: 'GESTIÓN',
          duration: 0,
          organism: "",
          dependsOn: [],
          enabled: true,
          orden: 0,
          subtasks: [],
          color: '#fff'
        }]
      }]
    };
  }

  return {
    name: selectedNombreProyecto,
    groups: sections.map((section, groupIndex) => {
      const subprocessColor = subprocessColors[subprocesos[activeSubprocess]?.subproceso] || "#CCCCCC";
      
      const enabledTasks = section.tasks.filter(task => task.enabled);
      return {
        name: section.title,
        expanded: !section.isCollapsed,
        subprocess: subprocesos[activeSubprocess]?.subproceso || "",
        agrupador: section.title,
        tasks: enabledTasks.map((task, taskIndex) => {
          // Procesar las subtareas primero
          let processedSubtasks: GanttSubTask[] = [];
          
          if (task.name === 'TRAMITACIÓN' && Array.isArray(task.subtasks)) {
            processedSubtasks = task.subtasks.map((subtask: any): GanttSubTask => {
              // Asegurarnos de que las fechas de las subtareas coincidan con la tarea padre
              const subtaskStart = task.progress===100 ? subtask.start : task.start;
              const subtaskEnd = task.progress===100 ? subtask.end : task.end;
              
              //datos q repercuten en la tabla y en la carta gantt 
              return {
                id: subtask.id,
                name: subtask.nombre || subtask.name,
                responsible: subtask.responsible || task.responsible,
                progress: subtask.progress || 0,
                start: subtask.start,
                end:  subtask.end,
                duration: subtask.duration || calculateDuration(subtaskStart, subtaskEnd),
                organism: subtask.organism || task.organism,
                dependsOn: subtask.dependsOn || [],
                enabled: subtask.enabled !== undefined ? subtask.enabled : true,
                type: subtask.type || 'INGRESO',
                resolutionType: subtask.resolutionType || '',
                orden: subtask.orden || 0,
                isClosed: subtask.isClosed || task.isClosed,
                st_type: subtask.st_type || subtask.type || 'INGRESO',
                semaphoreStatus: calculateSemaphoreStatus(
                  subtaskStart,
                  subtaskEnd,
                  subtask.id,
                  null,
                  subtask.isClosed || task.isClosed
                ),
                comments: subtask.comments || '',
                followUpDate: subtask.followUpDate || task.start
              };
            });
          }

          // Crear la tarea base
          const baseTask: GanttTask = {
            id: task.id,
            name: task.description,
            responsible: task.responsible,
            progress: task.isClosed ? 100 : task.progress,
            start: task.start,
            end: task.end,
            descriptor: task.descriptor,
            duration: calculateDuration(task.start, task.end),
            organism: task.organism,
            dependsOn: parseDependencies(task.dependencia),
            enabled: task.enabled,
            color: subprocessColor,
            orden: taskIndex + 1,
            isTramitacion: task.name === 'TRAMITACIÓN',
            subtasks: processedSubtasks
          };

          return baseTask;
        }),
        enabled: true,
        descriptor: section.sectionType,
        organismo: section.processingBody,
        orden: groupIndex + 1,
        color: subprocessColor
      };
    }).filter(group => group.tasks.length > 0)
  };
};


// Helper function to calculate new end date based on start date and duration
const calculateNewEndDate = (start: string, durationWeeks: number): string => {
  const startDate = new Date(start);
  const endDate = new Date(startDate.getTime() + durationWeeks * 7 * 24 * 60 * 60 * 1000);
  return endDate.toISOString().split('T')[0];
};

// Existing helper functions remain the same
const calculateDuration = (start: string, end: string): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
};

const parseDependencies = (dependencia: string | number | null): { groupId: number; taskId: number }[] => {
  if (!dependencia) return [];
  
  if (typeof dependencia === 'number') {
    return [{ groupId: 0, taskId: dependencia }];
  }
  
  if (typeof dependencia === 'string') {
    return dependencia.split(',')
      .map(id => id.trim())
      .filter(id => id !== '')
      .map(id => ({
        groupId: 0,
        taskId: parseInt(id, 10)
      }));
  }
  
  return [];
};


console.log("===============================")
console.log(convertToGanttTasksState(projectSections));
const isValidGanttData = (data: GanttTasksState): boolean => {

  return data.groups.length > 0 && data.groups.some(group => group.tasks.length > 0);
};


return (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="w-full bg-white rounded-lg p-3 mx-auto"
  >
    {isProcessLoaded && filteredSubprocesos.length > 0 ? ( // Verificamos si hay subprocesos
      <AnimatePresence mode="wait">
        <motion.div
          key="plantilla-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="sticky top-0 z-10 bg-white">
            <div className="p-2 mx-auto">
              <span className="font-bold">Subprocesos:</span>
              <div className="flex justify-between items-center border-b border-none py-2">
                <div className="flex flex-wrap items-center flex-grow">
                  {filteredSubprocesos.map((subprocess, index) => (
                    <SubprocessTab
                      key={index}
                      label={subprocess.subproceso}
                      isActive={activeSubprocess === index}
                      onClick={() => handleSubprocessChange(index)}
                      color={subprocess.color || ''} 
                      index={index}
                      totalTabs={filteredSubprocesos.length}
                      activeIndex={activeSubprocess}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <motion.div 
            className="pr-2 pl-2 mx-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <AnimatePresence>
              {!isValidGanttData(convertToGanttTasksState(projectSections)) ? (
                <motion.div
                  key="no-data-message"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="text-center py-4"
                >
                  <NoPlantillaMessage navigateToSubComponent={handleNavigate} />
                </motion.div>
              ) : (
               <GanttChartView 
               key={lastUpdate.getTime()} // Forzar re-render cuando hay actualizaciones
               tasks={convertToGanttTasksState(projectSections)} 
               processName={selectedNombreProyecto}  
               subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso} 
                          
              onUpdateTask={handleTaskUpdate}

         />                    
              )}
            </AnimatePresence> 
          </motion.div>
        </motion.div>
      </AnimatePresence>
    ) : (
      <NoPlantillaMessage navigateToSubComponent={handleNavigate} />
    )}
  </motion.div>
);
};

export default GanttGeneral;




